import React, { useState, useEffect } from 'react';
import { Row, Col, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
  setUserPermissionsIsEdit,
  updateUserPermissions,
  updateUserSettings,
} from '../redux/actions';
import { ReactComponent as SaveIcon } from '../../../assets/save-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import SpinnerComponent from '../../../components/spinner';
import PermissionsSection from './permissionsSection';
import UseSupAdminOrAdminWithUserManagerPermission from '../../../components/useSupAdminOrAdminWithUserManagerPermission';
import { systemUserPermissions } from './utils';

const GeneralPermissions = ({
  isProfileCase,
  canUpdate,
  userType,
  cannotUpdate,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    popup_notifications: false,
    email_notifications: false,
  });

  let sitesOption = {
    ...(userType === 'system_manager' && {
      add_edit: false,
    }),
    view: false,
    ...(userType === 'system_manager' && {
      delete: false,
      export: false,
      import: false,
    }),
  };

  let ClassesOption = {
    ...(userType !== 'learner' && {
      add_edit: false,
    }),
    view: false,
    ...(userType === 'system_manager' && {
      delete: false,
      export: false,
    }),
    ...(userType !== 'learner' && {
      import: false,
    }),
  };

  const [upperPermissions, setUpperPermissions] = useState({
    report_summary: {
      learners: {
        view: false,
        ...(userType === 'system_manager' && {
          export: false,
        }),
      },
      coaches: {
        view: false,
        ...(userType === 'system_manager' && {
          export: false,
        }),
      },
      sites: { ...sitesOption },
      classes: { ...ClassesOption },
    },
    ...(userType === 'system_manager' && {
      npo_setup: {
        add_edit: false,
        view: false,
        delete: false,
        export: false,
      },
    }),
  });

  const [state, setState] = useState({});
  let canEditEveryThing = UseSupAdminOrAdminWithUserManagerPermission();

  const {
    currentUserObj,
    popupLoading,
    emailLoading,
    permissionsLoading,
    userPermissionsIsEdit,
  } = useSelector((state) => state.userManagerReducer);

  useEffect(() => {
    if (currentUserObj?.id > 0) {
      const { popup_notifications, email_notifications } = currentUserObj;
      setData({
        popup_notifications,
        email_notifications,
      });

      if (currentUserObj?.permissions !== null) {
        const { report_summary, npo_setup, ...rest } =
          currentUserObj.permissions;

        setState({ ...state, ...rest });
        setUpperPermissions({ ...upperPermissions, report_summary, npo_setup });
      } else {
        setState(systemUserPermissions(userType));
      }
    }
  }, [currentUserObj]);

  const handleSave = () => {
    dispatch(
      updateUserPermissions({
        permissions: { ...upperPermissions, ...state },
        id: currentUserObj?.id,
      })
    );
  };

  const onChangeNotification = (key, checked, loadingObj) => {
    setData({ ...data, [key]: checked });
    const { id } = currentUserObj;
    dispatch(
      updateUserSettings({
        data: { id, [key]: checked },
        loadingObj,
      })
    );
  };

  return (
    <>
      <Row>
        <Col span={24} className='heading-col'>
          <Row align='middle' justify='space-between'>
            <Col>
              <div>
                <span className='heading-span'>General Permissions</span>
              </div>
            </Col>
            {!cannotUpdate && (
              <Col>
                {userPermissionsIsEdit ? (
                  permissionsLoading ? (
                    <SpinnerComponent fontSize={14} />
                  ) : (
                    <SaveIcon width={16} height={16} onClick={handleSave} />
                  )
                ) : (
                  canUpdate && (
                    <EditIcon
                      width={16}
                      fill='#eb078e'
                      height={16}
                      onClick={() => dispatch(setUserPermissionsIsEdit(true))}
                    />
                  )
                )}
              </Col>
            )}
          </Row>
        </Col>
        <PermissionsSection
          state={state}
          setState={setState}
          upperPermissions={upperPermissions}
          setUpperPermissions={setUpperPermissions}
          readOnly={userPermissionsIsEdit}
          userType={userType}
        />

        <Col span={24} className='section-body settings-section'>
          <Row
            align='middle'
            justify='space-between'
            className='simple-heading-col'
          >
            {/* <Col md={0} lg={2} xl={1} xxl={1}></Col> */}
            <Col md={23} lg={23} xl={23} xxl={23}>
              <div>
                <span className='heading-span'>
                  General Permissions Notifications
                </span>
              </div>
            </Col>
          </Row>
          <Row style={{ paddingLeft: '12px' }}>
            <Col md={0} lg={2} xl={1} xxl={1}></Col>
            {/* <Col md={8} lg={6} xl={4} xxl={4}>
              <div className='heading-text'>Browser notifications</div>
              <div className='switch-parent-div'>
                <span className={`${!data.popup_notifications && 'off'}`}>
                  Off
                </span>{' '}
                <Switch
                  size='small'
                  defaultChecked={true}
                  checked={data.popup_notifications}
                  disabled={(currentUserObj?.id ? false : true) || popupLoading}
                  onChange={(e) =>
                    onChangeNotification('popup_notifications', e, {
                      popupLoading: false,
                    })
                  }
                />{' '}
                <span className={`${data.popup_notifications && 'on'}`}>
                  On
                </span>
              </div>
            </Col> */}
            <Col>
              <div className='heading-text'>Email notifications</div>
              <div className={'switch-parent-div'}>
                <span className={`${!data.email_notifications && 'off'}`}>
                  Off
                </span>{' '}
                <Switch
                  size='small'
                  defaultChecked={true}
                  disabled={(currentUserObj?.id ? false : true) || emailLoading}
                  checked={data.email_notifications}
                  onChange={(e) =>
                    onChangeNotification('email_notifications', e, {
                      emailLoading: false,
                    })
                  }
                />{' '}
                <span className={`${data.email_notifications && 'on'}`}>
                  On
                </span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default GeneralPermissions;
