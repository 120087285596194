import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchAllNpos } from '../NpoSetup/redux/actions';
import { fetchCoachStatuses } from '../CoachStatuses/redux/actions';
// import GraphSection from './graphSection';
import GraphSection from '../learners/graphSection/index';
import TableSection from './tableSection';
import { fetchImsDocs } from '../Sites/redux/actions';
import { fetchCoachTypes } from '../CoachTypes/redux/actions';
import { fetchAllTemplates } from '../TemplatesManager/redux/actions';
import { baseURL } from '../../globalUtils/axiosConfig';
import axios from 'axios';
const params = {
  search: {},
};

const Coaches = () => {
  const [statusCheckList, setStatusCheckList] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [coachesTypesCheckList, setCoachesTypesCheckList] = useState([]);
  const [coachesTypesOptions, setCoachesTypesOptions] = useState([]);
  const [npoCheckList, setNpoCheckList] = useState([]);
  const [npoOptions, setNpoOptions] = useState([]);
  const [coachesCheckList, setCoachesCheckList] = useState([]);
  const [coachesOptions, setCoachesOptions] = useState([]);
  const { allCoachStatuses } = useSelector((state) => state.coachStatusReducer);
  const { allNpos } = useSelector((state) => state.npoSetupReducer);
  const { allCoachTypes } = useSelector((state) => state.coachTypesReducer);

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(fetchAllNpos(params));
    dispatch(fetchCoachTypes());
    dispatch(fetchImsDocs(params));
    dispatch(fetchCoachStatuses());
    dispatch(fetchAllTemplates({}));
  }, []);

  useEffect(() => {
    let npoOptions = allNpos.map((el) => {
      const { name, id } = el;
      return { value: id, label: name };
    });
    setNpoOptions(npoOptions);
  }, [allNpos]);

  useEffect(() => {
    let options = allCoachTypes.map((el) => {
      const { name, id } = el;
      return { value: id, label: name };
    });
    setCoachesTypesOptions(options);
  }, [allCoachTypes]);

  // useEffect(() => {
  //   let options = allDocs.map((el) => {
  //     const { name, id } = el;
  //     return { value: id, label: name };
  //   });
  //   setSiteOptions(options);
  // }, [allDocs]);

  useEffect(() => {
    let statusOptions = allCoachStatuses.map((el) => {
      const { name, id } = el;
      return { value: id, label: name };
    });
    setStatusOptions(statusOptions);
  }, [allCoachStatuses]);

  // console.count('Render sites');

  const fetchCoachUsers = async () => {
    const res = await axios.get(
      `${baseURL}/api/v1/users?page=1&per_page=all&user_type=coach`
    );

    const { success, data } = res.data;
    if (success) {
      const onlyCoachUsers = data.users.filter(
        (el) => el.user_type === 'coach' && el.status?.deleteable
      );
      // setCoachUsers(onlyCoachUsers);
      let options = onlyCoachUsers.map((el) => {
        const { first_name, last_name, id } = el;
        return { value: id, label: `${first_name} ${last_name}` };
      });
      setCoachesOptions(options);
    }
  };

  useEffect(() => {
    fetchCoachUsers();
  }, []);

  return (
    <div className='page-top-div'>
      <span
        className='text-span'
        style={{
          lineHeight: '17px',
        }}
      >
        REPORT SUMMARY - {pathname?.split('/')[2]}
      </span>
      <GraphSection />
      <TableSection
        statusCheckList={statusCheckList}
        setStatusCheckList={setStatusCheckList}
        statusOptions={statusOptions}
        coachesTypesCheckList={coachesTypesCheckList}
        setCoachesTypesCheckList={setCoachesTypesCheckList}
        coachesTypesOptions={coachesTypesOptions}
        // siteCheckList={siteCheckList}
        // setSiteCheckList={setSiteCheckList}
        // siteOptions={siteOptions}
        coachesCheckList={coachesCheckList}
        setCoachesCheckList={setCoachesCheckList}
        coachesOptions={coachesOptions}
        npoCheckList={npoCheckList}
        setNpoCheckList={setNpoCheckList}
        npoOptions={npoOptions}
        name={pathname?.split('/')[2]}
      />
    </div>
  );
};

export default Coaches;
