import React, { useEffect, useState } from 'react';
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Col,
  Row,
  Space,
  Tag,
  DatePicker,
  message,
  InputNumber,
} from 'antd';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import { ReactComponent as SaveIcon } from '../../../assets/save-icon.svg';
// import { ReactComponent as AttachIcon } from '../../../assets/attach.svg';
import { ReactComponent as FilterIcon } from '../../../assets/filter.svg';
import { ReactComponent as CrossIcon } from '../../../assets/crossIcon.svg';
// import { ReactComponent as BatchUpdateIcon } from '../../../assets/patch-update.svg';
import {
  createImsDoc,
  deleteImsDoc,
  // fetchActivities,
  fetchImsDocs,
  updateActivity,
  updateIsAddIsUpdate,
  fetchBatchUpdate,
} from '../redux/actions';
import { fetchActivities } from '../../learners/redux/actions';
import SpinnerComponent from '../../../components/spinner';
import _ from 'lodash';
import CustomPagination from '../../../components/customPagination/CustomPagination';
// import NonEditRowAttach from './nonEditRowAttachView';
import CustomCalendar from '../../../components/calendar';
import AMultiSelect from '../../../components/AMultiSelect';
import { getOptions } from '../../Sales/utils';
import ExpandedRow from './expandedRow';
import EditableCell from './editableCell';
import Classes from './classes';
// import Distribution from './distributionTable';
import ExportDoc from './export';
import ImportDoc from './import';
import FilterAndBatchUpdate from './filterAndBatchUpdate';
import Tooltip from '../../../components/tooltip-Custom';
import { useLocation } from 'react-router-dom';
// import SingleSelector from '../../../components/singleSelector';
import PillarsSection from './pillarsSection';
import UseWindowSize from '../../container/sidebar/useWindowSize';

const DistributionTable = ({
  npoRegionsCheckList,
  setNpoRegionsCheckList,
  npoRegionsOptions,
  statusCheckList,
  setStatusCheckList,
  statusOptions,
  name,
  siteTypeCheckList,
  setSiteTypeCheckList,
  siteTypeOptions,
  npoCheckList,
  setNpoCheckList,
  npoOptions,
  fetchPillarsData,
  npoDetails,
  sitesCheckList,
  setSitesCheckList,
  sitesOptions,
}) => {
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [sortedInfo, setSortedInfo] = useState(null);
  const [editingLoading, setEditingLoading] = useState(false);
  const [currentUpdateObj, setCurrentUpdateObj] = useState({});
  const [form] = Form.useForm();
  const [fieldsFormRef] = Form.useForm();
  const dispatch = useDispatch();
  const [page, setPage] = useState(() => 1);
  const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);
  // const [images, setImages] = useState([]);
  // images contains all attachments of item
  // const [attachPopupVisible, setAttachPopupVisible] = useState(false);
  const [search, setSearch] = useState({ npo_id: [npoDetails?.npo_id] });
  const [date, setDate] = useState(null);
  // const [dueDate, setDueDate] = useState(null);
  const [deleteDocId, setDeleteDocId] = useState();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [fields, setFields] = useState([]);
  const [datePickerOpened, setDatePickerOpened] = useState(false);
  const [isRevisionUpdateCase, setIsRevisionUpdateCase] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [showBatch, setShowBatch] = useState(false);
  // const [canView, canEdit, canExport, canDelete] = UsePermissions();
  const location = useLocation();
  const [fetchByParams, setFetchByParams] = useState(true);
  const [docNumber, setDocNumber] = useState('');
  const screenWidth = UseWindowSize();
  // const [attachSearch, setAttachSearch] = useState(null);
  // const [pillarsData, setPillarsData] = useState([]);

  // const { allTemplates } = useSelector((state) => state.docTypesReducer);
  // const { allDocumentField, fetchingLoading } = useSelector(
  //   (state) => state.documentFieldReducer
  // );

  const { allNpos } = useSelector((state) => state.npoSetupReducer);
  const { allTemplates } = useSelector(
    (state) => state.templatesManagerReducer
  );

  const { sites } = npoDetails.permissions;
  let canView = sites?.view || false;
  let canEdit = sites?.add_edit || false;
  let canExport = sites?.export || false;
  let canDelete = sites?.delete || false;
  let canImport = sites?.import || false;

  // console.log('fields', fields);
  useEffect(() => {
    if (location?.state?.document_number?.length > 0) {
      const { document_number } = location?.state;
      if (docNumber === document_number) {
        return;
      } else {
        setFetchByParams(true);
      }
      setData([]);
      if (fetchByParams) {
        setDocNumber(document_number);
        setTimeout(() => {
          setData([]);
          setSearch((prev) => ({ ...prev, document_number }));
          debouncedDefaultPageOneCallApi();
          setFetchByParams(false);
        }, 100);
      }
    }
  }, [location, docNumber]);

  useEffect(() => {
    const template = allTemplates.filter(
      (el) => el.name.toLowerCase() === name
    );
    if (template.length > 0) {
      const { template_fields } = template[0];
      setFields(template_fields);
    }
  }, [allTemplates]);

  useEffect(() => {
    if (page && resultPerPage && page > 0 && resultPerPage > 0) {
      dispatch(
        fetchImsDocs({
          page: page,
          per_page: resultPerPage,
          search,
          sort: sortedInfo?.order && sortedInfo?.columnKey,
          order: sortedInfo?.order
            ? sortedInfo?.order === 'ascend'
              ? 'asc'
              : 'desc'
            : null,
          ...filter,
          page_type: 'sites',
        })
      );
      dispatch(
        fetchActivities({
          page: page,
          per_page: resultPerPage,
          search: { ...search, npo_id: [npoDetails?.npo_id] },
          ...filter,
          page_type: 'sites',
          npo_graph: true,
        })
      );
    }
  }, [req]);

  useEffect(() => {
    let paramsPage = 1;
    dispatch(
      fetchImsDocs({
        page: paramsPage,
        per_page: resultPerPage,
        search,
        sort: sortedInfo?.order && sortedInfo?.columnKey,
        order: sortedInfo?.order
          ? sortedInfo?.order === 'ascend'
            ? 'asc'
            : 'desc'
          : null,
        ...filter,
        page_type: 'sites',
      })
    );
    // setFilter({});
    // setFields([]);
    setExpandedRowKeys([]);
    if (paramsPage && resultPerPage && paramsPage > 0 && resultPerPage > 0) {
      dispatch(
        fetchActivities({
          page: paramsPage,
          per_page: resultPerPage,
          search: { ...search, npo_id: [npoDetails?.npo_id] },
          ...filter,
          page_type: 'sites',
          npo_graph: true,
        })
      );
      setPage(1);
    }
    form.resetFields();
    fieldsFormRef.resetFields();
    fetchPillarsData();
  }, [defaultPageOneReq]);

  useEffect(() => {
    debouncedCallApi();
  }, [sortedInfo]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );

  const isEditing = (record) =>
    record.key === editingKey || record.id === editingKey;

  const { user } = useSelector((state) => state.authReducer);
  const {
    allDocs,
    fetchDocsLoading,
    updateLoading,
    totalRecords,
    isAddImsDoc,
    isUpdateImsDoc,
    deletingLoading,
    defaultPillars,
  } = useSelector((state) => state.sitesReducer);

  const handleUpdateStatus = (isAdd, isUpdate) => {
    dispatch(
      updateIsAddIsUpdate({
        isAddImsDoc: isAdd,
        isUpdateImsDoc: isUpdate,
      })
    );
  };

  useEffect(() => {
    setEditingLoading(updateLoading);
    // if (!updateLoading) {
    //   setEditingKey('');
    //   setExpandedRowKeys([]);
    // }

    return () => {
      handleUpdateStatus(false, false);
    };
  }, [updateLoading]);

  // const setUpdatedData = () => {
  // let updatedData = allDocs.map((el) => {
  //   const { document_attachments, ...rest } = el;

  //   let updatedImages;
  //   if (document_attachments?.length > 0) {
  //     updatedImages = document_attachments.map((img) => {
  //       const { url, id } = img;
  //       let name = url.split('/')[url.split('/').length - 1].split('?')[0];

  //       return { id, key: id, url, isLocal: false, name };
  //     });
  //   }

  //   return { ...rest, images: updatedImages };
  // });
  // setData(updatedData);
  // };

  useEffect(() => {
    // console.log('allDocs', allDocs);
    // if (allDocs.length === 0) {
    //   return setData(allDocs);
    // } else if (allDocs.length > 0) {
    //   setUpdatedData();
    // }
    setData(allDocs);
  }, [allDocs]);

  const edit = (record) => {
    const { isAdd } = record;
    setCurrentUpdateObj(record);
    // setImages(record?.images || []);
    const {
      site_date,
      document_template_id,
      document_status_id,
      document_due_date,
      sheets,
      comment,
    } = record;
    form.setFieldsValue({
      ...record,
      site_date: moment(site_date),
      document_due_date: document_due_date ? moment(document_due_date) : null,
      document_template_id,
      document_status_id,
      sheets,
      document_number: '',
      comment,
      username: record?.user?.first_name + ' ' + record?.user?.surname,
      isAdd,
      latest_revision: record?.latest_revision || '',
    });

    if (!isAdd) {
      if (!record?.skipFilter) {
        let filteredItems = data.filter((el) => el.id !== editingKey);
        let final = filteredItems.filter((el) => el.key !== editingKey);
        setData(final);
      }
      // console.log('record', record);

      if (document_template_id !== null) {
        const { sites_field_values } = record;
        let formData = {};
        let updatedSiteFieldsData = sites_field_values.map((el) => {
          const { template_field_id, id, value, date_value, template_field } =
            el;

          if (template_field) {
            const { field_type } = template_field;
            formData[template_field_id] =
              field_type === 'date_field' && date_value !== null
                ? moment(date_value)
                : value;

            return {
              ...el,
              field_type,
            };
          }
        });
        // const template = allTemplates.filter(
        //   (el) => el.id === document_template_id
        // );
        // const template = [];
        // let template_fields =
        //   template.length > 0 ? template[0].template_fields : [];
        // let values = {};
        // template_fields.map((el) => (values[el.id] = ''));
        // let updatedFields = { ...values, ...formData };
        let updatedFields = { ...formData };

        // console.log('updatedFields', updatedFields);
        setTimeout(() => {
          fieldsFormRef.setFieldsValue({ ...updatedFields });
          // form.setFieldsValue({ ...updatedFields });
        }, 100);
        // fieldsFormRef.setFieldsValue({ ...updatedFields });
        console.log('updatedFields sites_field_values', sites_field_values);
        // console.log('template_fields', template_fields);
        // setTimeout(() => {
        // setFields(updatedSiteFieldsData); // template_fields
        // }, 1000);
        handleUpdateStatus(false, true);
      }
    }

    setEditingKey(record.id);
    setExpandedRowKeys([record.id]);
  };

  const cancel = () => {
    if (isUpdateImsDoc) {
      setData(data);
    } else {
      // if (editingKey) {
      let filteredItems = data.filter((el) => el.id !== editingKey);
      let final = filteredItems.filter((el) => el.key !== editingKey);
      setData(final);
      // }
    }
    // setFields([]);
    form.resetFields();
    fieldsFormRef.resetFields();
    handleUpdateStatus(false, false);
    setEditingKey('');
    setExpandedRowKeys([]);
  };

  const save = async (key) => {
    setEditingKey(key);
    try {
      const row = await form.validateFields();
      const formFields = await fieldsFormRef.validateFields();
      // console.log("row", row);
      // console.log("formFields", formFields);
      const newData = [...data];
      const index = newData.findIndex(
        (item) => key === item.key || key === item.id
      );
      if (index > -1) {
        form.submit();
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      // const { errorFields } = errInfo;
      // errorFields.forEach((el) => {
      //   el.errors[0] !== 'Required!' && message.error(el.errors[0]);
      // });
    }
  };

  const searchInput = (key) => (
    <Input
      placeholder={''}
      onClick={(e) => e.stopPropagation()}
      value={search[key] || ''}
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e.target.value,
        });
        debouncedDefaultPageOneCallApi();
      }}
    />
  );

  const numberInput = (key) => (
    <InputNumber
      placeholder={''}
      size='small'
      controls={false}
      max={100}
      min={0}
      onClick={(e) => e.stopPropagation()}
      onPressEnter={(e) => e.stopPropagation()}
      value={search[key] || null}
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e,
        });
        debouncedDefaultPageOneCallApi();
      }}
    />
  );

  const handleDateInput = (value) => {
    setDate(value);
    if (value === null) {
      setSearch({
        ...search,
        start_date: '',
        end_date: '',
      });
    } else {
      setSearch({
        ...search,
        start_date: value[0],
        end_date: value[1],
      });
    }
    debouncedDefaultPageOneCallApi();
  };

  const handleDueDateInput = (value) => {
    // setDueDate(value);
    if (value === null) {
      setSearch({
        ...search,
        document_due_data: '',
        document_due_end_data: '',
      });
    } else {
      setSearch({
        ...search,
        document_due_data: value[0],
        document_due_end_data: value[1],
      });
    }
    debouncedDefaultPageOneCallApi();
  };

  const handleMultiSelect = (list, key) => {
    setSearch({
      ...search,
      [key]: list,
    });
    // debouncedDefaultPageOneCallApi();
  };

  const handleSelectOnchange = (value, options, key, setHandler) => {
    if (value.includes('all')) {
      setHandler([...options.map((item) => item.value)]);
      value = options.map((item) => item.value);
    } else if (value.includes('not-all')) {
      setHandler([]);
      value = [];
    } else {
      setHandler(value);
    }
    handleMultiSelect(value, key);
  };

  const onDeleteSuccess = () => {
    debouncedCallApi();
    setDeleteDocId();
    debouncedDefaultPageOneCallApi();
  };

  const deleteDocHandler = (record) => {
    setDeleteDocId(record.id);
    dispatch(deleteImsDoc({ ...record, onDeleteSuccess }));
  };

  // const getCloumnName = (i) =>
  //   allDocumentField.length > 0 && allDocumentField[i].name;

  const dateInput = (key) => (
    <DatePicker
      style={{ width: '100%', minWidth: '80px' }}
      placeholder=''
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className='activities-time-picker prod-due-date'
      getPopupContainer={() =>
        document.getElementsByClassName(
          'ant-layout-content layout-content site-layout-background custom-textarea'
        )[0]
      }
      suffixIcon={null}
      format='D MMM YYYY'
      // popupClassName="activities-time-picker"
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e,
        });
        debouncedCallApi();
      }}
      onOpenChange={(e) => setDatePickerOpened(e)}
    />
  );

  const handleColOnClick = (record) => {
    if ((isAddImsDoc || isUpdateImsDoc) && !isRevisionUpdateCase) {
      return;
    }

    if (
      isAddImsDoc ||
      ((isRevisionUpdateCase || isUpdateImsDoc) &&
        expandedRowKeys[0] !== record.id)
    ) {
      // console.log('handleColOnClick if');
      setExpandedRowKeys([]);
      setIsRevisionUpdateCase(false);
      setEditingKey('');
      setIsRevisionUpdateCase(false);
      handleUpdateStatus(false, false);
      setData(allDocs); // setUpdatedData();
      form.resetFields();
      fieldsFormRef.resetFields();
      setExpandedRowKeys([record.id]);
      // setFields([]);
      setIsRevisionUpdateCase(true);
      return edit({ ...record, isAdd: false, skipFilter: true });
    }

    if (isRevisionUpdateCase) {
      // console.log('handleColOnClick isRevisionUpdateCase if ');
      if (expandedRowKeys[0] === record.id) {
        setExpandedRowKeys([]);
        setIsRevisionUpdateCase(false);
        setEditingKey('');
        setIsRevisionUpdateCase(false);
        handleUpdateStatus(false, false);
      }
    } else {
      // console.log('handleColOnClick isRevisionUpdateCase else ');
      setExpandedRowKeys([record.id]);
      setIsRevisionUpdateCase(true);
      edit({ ...record, isAdd: false, skipFilter: false });
    }
  };

  // const handleSearchOnchange = (value) => {
  //   if (value === undefined) {
  //     setAttachSearch(null);
  //     handleMultiSelect('', 'has_attach');
  //   } else {
  //     setAttachSearch(value);
  //     handleMultiSelect(value, 'has_attach');
  //   }
  //   debouncedDefaultPageOneCallApi();
  // };

  if (['learner', 'coach'].includes(user.user_type)) {
    npoOptions = npoOptions.filter((c) =>
      data.some((s) => s.npo_id === c.value)
    );
    npoRegionsOptions = npoRegionsOptions.filter((c) =>
      data.some((s) => s.npo.npo_region.id === c.value)
    );
    sitesOptions = sitesOptions.filter((c) =>
      data.some((s) => s.id === c.value)
    );

    statusOptions = statusOptions.filter((c) =>
      data.some((s) => s.status_id === c.value)
    );
    siteTypeOptions = siteTypeOptions.filter((c) =>
      data.some((s) => s.type_id === c.value)
    );
  }

  const columns = [
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Date</div>
            <div>
              <CustomCalendar
                date={date}
                setDate={setDate}
                format={'D MMM'}
                handleDateInput={handleDateInput}
                setDatePickerOpened={setDatePickerOpened}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'site_date',
      key: 'site_date',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'site_date' && sortedInfo?.order,
      width: 110,
      editable: !isRevisionUpdateCase,
      className: 'less-padding-col',
      render: (date, record) => {
        return (
          <div onClick={() => handleColOnClick(record)}>
            {moment(date).format('DD MMM YYYY')}
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>NPO</div>
            <div>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                dropdownClassName='doc-type-selector'
                disabled={false}
                placeholder=''
                selectTags={npoCheckList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    npoOptions,
                    'npo_id',
                    setNpoCheckList
                  )
                }
                data={getOptions(npoOptions, npoCheckList) || []}
                filterOption={(input, option) => {
                  return (
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'npo_id',
      key: 'npo_id',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'npo_id' && sortedInfo?.order,
      width: 80,
      editable: !isRevisionUpdateCase,
      className: 'less-padding-col',
      render: (doc_template, record) => (
        <div onClick={() => handleColOnClick(record)}>{record?.npo?.name}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Region</div>
            <div>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                dropdownClassName='doc-type-selector'
                disabled={false}
                placeholder=''
                selectTags={npoRegionsCheckList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    npoRegionsOptions,
                    'npo_region',
                    setNpoRegionsCheckList
                  )
                }
                data={getOptions(npoRegionsOptions, npoRegionsCheckList) || []}
                filterOption={(input, option) => {
                  return (
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'npo_region',
      key: 'npo_region',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'npo_region' && sortedInfo?.order,
      width: 80,
      editable: !isRevisionUpdateCase,
      className: 'less-padding-col',
      render: (npo_region, record) => {
        const region = record?.npo?.npo_region?.name || '';
        return <div onClick={() => handleColOnClick(record)}>{region}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Site</div>
            <div>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                dropdownClassName='doc-type-selector'
                disabled={false}
                placeholder=''
                selectTags={sitesCheckList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    sitesOptions,
                    'name',
                    setSitesCheckList
                  )
                }
                data={getOptions(sitesOptions, sitesCheckList) || []}
                filterOption={(input, option) => {
                  return (
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      width: 80,
      className: 'name-td less-padding-col',
      editable: !isRevisionUpdateCase,
      render: (name, record) => {
        if (!record?.answers || record?.answers?.length < 1) {
          return <div>{record?.name}</div>;
        }
        const { answers } = record;
        if (answers.length < 1) {
          return;
        }
        const [first, sec, third, fourth] = answers;
        const pillars = [first, sec, third, fourth];
        return (
          <div onClick={() => handleColOnClick(record)}>
            <div>{record?.name}</div>
            <Row gutter={[4, 0]} className='colors-div'>
              {pillars.map((el, i) => {
                const { pillar_status } = el;
                return (
                  <Col key={i}>
                    <div className='actual-div'>
                      <span className={`dot ${pillar_status}`}></span>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              Principal <br /> Name
            </div>
            <div>{searchInput('principal_name')}</div>
          </div>
        );
      },
      dataIndex: 'principal_name',
      key: 'principal_name',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'principal_name' && sortedInfo?.order,
      width: 80,
      className: 'less-padding-col',
      editable: !isRevisionUpdateCase,
      render: (principal_name, record) => (
        <div onClick={() => handleColOnClick(record)}>{principal_name}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              PRINCIPAL <br /> SURNAME
            </div>
            <div>{searchInput('principal_surname')}</div>
          </div>
        );
      },
      dataIndex: 'principal_surname',
      key: 'principal_surname',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'principal_surname' && sortedInfo?.order,
      width: 80,
      editable: !isRevisionUpdateCase,
      className: 'less-padding-col',
      render: (doc_num, record) => (
        <div onClick={() => handleColOnClick(record)}>{doc_num}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Site Type</div>
            <div>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                dropdownClassName='status-selector'
                disabled={false}
                placeholder=''
                selectTags={siteTypeCheckList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    siteTypeOptions,
                    'type_id',
                    setSiteTypeCheckList
                  )
                }
                data={getOptions(siteTypeOptions, siteTypeCheckList) || []}
                filterOption={(input, option) => {
                  return (
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'type_id',
      key: 'type_id',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'type_id' && sortedInfo?.order,
      width: 80,
      align: 'center',
      editable: !isRevisionUpdateCase,
      className: 'less-padding-col',
      // editable: true,
      render: (type_id, record) => (
        <div onClick={() => handleColOnClick(record)}>
          {record?.type?.name || ''}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              TOTAL <br /> CLASSES
            </div>
            <div>{searchInput('total_classes')}</div>
          </div>
        );
      },
      dataIndex: 'total_classes',
      key: 'total_classes',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'total_classes' && sortedInfo?.order,
      width: 80, // "8%"
      align: 'center',
      editable: false, //!isRevisionUpdateCase,
      className: 'less-padding-col',
      render: (total_classes, record) => (
        <div onClick={() => handleColOnClick(record)}> {total_classes} </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              TOTAL <br /> LEARNERS
            </div>
            <div>{searchInput('total_learners')}</div>
          </div>
        );
      },
      dataIndex: 'total_learners',
      key: 'total_learners',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'total_learners' && sortedInfo?.order,
      width: 80,
      className: 'total_learners-td less-padding-col',
      editable: false, // !isRevisionUpdateCase,
      align: 'center',
      render: (total_learners, record) => (
        <div onClick={() => handleColOnClick(record)}>{total_learners}</div>
      ),
    },
    // {
    //   title: () => {
    //     return (
    //       <div align='top' style={{ width: '100%' }}>
    //         <div className='special-table-header-text'>
    //           TARGET <br /> LEARNERS
    //         </div>
    //         <div>
    //           {searchInput('target_learners')}
    //           {/* <SingleSelector
    //             options={attachOptions}
    //             onchange={handleSearchOnchange}
    //             attachSearch={attachSearch}
    //           /> */}
    //         </div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'target_learners',
    //   key: 'target_learners',
    //   sorter: false,
    //   sortOrder:
    //     sortedInfo?.columnKey === 'target_learners' && sortedInfo?.order,
    //   width: 80,
    //   editable: false, // !isRevisionUpdateCase,
    //   align: 'center',
    //   render: (total_classes, record) => (
    //     <div onClick={() => handleColOnClick(record)}> {total_classes} </div>
    //   ),
    // },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              MKT <br />
              Pre
            </div>
            <div>{numberInput('mkt_pre')}</div>
          </div>
        );
      },
      dataIndex: 'mkt_pre',
      key: 'mkt_pre',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'mkt_pre' && sortedInfo?.order,
      width: 40,
      className: 'less-padding-col',
      align: 'center',
      editable: false,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              MKT <br />
              post
            </div>
            <div>{numberInput('mkt_post')}</div>
          </div>
        );
      },
      dataIndex: 'mkt_post',
      key: 'mkt_post',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'mkt_post' && sortedInfo?.order,
      width: 40,
      className: 'less-padding-col',
      align: 'center',
      editable: false,
    },

    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              ses <br />
              Pre
            </div>
            <div>{numberInput('ses_pre')}</div>
          </div>
        );
      },
      dataIndex: 'ses_pre',
      key: 'ses_pre',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'ses_pre' && sortedInfo?.order,
      width: 40,
      className: 'less-padding-col',
      align: 'center',
      editable: false,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              ses <br />
              post
            </div>
            <div>{numberInput('ses_post')}</div>
          </div>
        );
      },
      dataIndex: 'ses_post',
      key: 'ses_post',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'ses_post' && sortedInfo?.order,
      width: 40,
      className: 'less-padding-col',
      align: 'center',
      editable: false,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              leq <br />
              Pre
            </div>
            <div>{numberInput('leq_pre')}</div>
          </div>
        );
      },
      dataIndex: 'leq_pre',
      key: 'leq_pre',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'leq_pre' && sortedInfo?.order,
      width: 40,
      className: 'less-padding-col',
      align: 'center',
      editable: false,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              leq <br />
              post
            </div>
            <div>{numberInput('leq_post')}</div>
          </div>
        );
      },
      dataIndex: 'leq_post',
      key: 'leq_post',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'leq_post' && sortedInfo?.order,
      width: 40,
      className: 'less-padding-col',
      align: 'center',
      editable: false,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Status</div>
            <div>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                dropdownClassName='status-selector'
                disabled={false}
                placeholder=''
                selectTags={statusCheckList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    statusOptions,
                    'status_id',
                    setStatusCheckList
                  )
                }
                data={getOptions(statusOptions, statusCheckList) || []}
                filterOption={(input, option) => {
                  return (
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'status_id',
      key: 'status_id',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'status_id' && sortedInfo?.order,
      width: 80,
      align: 'center',
      editable: !isRevisionUpdateCase,
      className: 'less-padding-col',
      render: (id, record) => {
        if (!Boolean(record?.status)) {
          return;
        }

        const { status } = record;
        const { color, name } = status;
        return (
          <Tag
            color={color}
            className='doc-status-tag'
            onClick={() => handleColOnClick(record)}
          >
            {name}
          </Tag>
        );
      },
    },
    {
      title: '',
      dataIndex: 'operation',
      align: 'center',
      className: 'less-padding-col',
      width: 60,
      render: (_, record) => {
        const editable = isEditing(record);
        let key = record?.key || record?.id;

        let shouldDisabled =
          isAddImsDoc ||
          (isRevisionUpdateCase && !editable) ||
          (isUpdateImsDoc && editingKey !== key);

        return editable && !isRevisionUpdateCase ? (
          editingLoading && editingKey === key ? (
            <SpinnerComponent fontSize={14} />
          ) : (
            <Space size={20} align='center'>
              <Popconfirm
                placement='topRight'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                overlayClassName='activities-popconfirm'
                title='Are you sure you want to save?'
                onConfirm={() => save(record.key || record.id)}
                okText='Yes'
                cancelText='No'
              >
                <SaveIcon className='custom-icon' />
              </Popconfirm>
              {/* {!isUpdateImsDoc && ( */}
              <Popconfirm
                placement='topRight'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                title='Sure to cancel?'
                onConfirm={cancel}
              >
                <CrossIcon
                  className='custom-icon'
                  style={{
                    height: '20px',
                    width: '20px',
                  }}
                />
              </Popconfirm>
              {/* // )} */}
            </Space>
          )
        ) : (
          <div>
            <Space size={20} align='center'>
              {deletingLoading && record.id === deleteDocId ? (
                <SpinnerComponent fontSize={16} />
              ) : (
                <>
                  {canEdit && (
                    <EditIcon
                      className={`custom-icon ${
                        shouldDisabled ? 'disable-icon' : ''
                      }`}
                      disabled={editingKey !== ''}
                      onClick={() => {
                        if (shouldDisabled) {
                          return;
                        }
                        if (isRevisionUpdateCase) {
                          setIsRevisionUpdateCase(false);
                          form.setFieldValue(
                            'npo_region',
                            record?.npo?.npo_region?.name
                          );
                        } else {
                          edit({
                            ...record,
                            isAdd: false,
                            skipFilter: false,
                            npo_region: record?.npo?.npo_region?.name,
                          });
                        }
                      }}
                    />
                  )}
                  {!shouldDisabled
                    ? canDelete && (
                        <Popconfirm
                          getPopupContainer={() =>
                            document.getElementsByClassName(
                              'ant-layout-content layout-content site-layout-background custom-textarea'
                            )[0]
                          }
                          placement='topRight'
                          title='Are you sure you want to delete?'
                          onConfirm={() => deleteDocHandler(record)}
                        >
                          <DeleteIcon className={`custom-icon`} />
                        </Popconfirm>
                      )
                    : canDelete && (
                        <DeleteIcon
                          className={`custom-icon ${
                            (isAddImsDoc || isUpdateImsDoc) && 'disable-icon'
                          }`}
                        />
                      )}
                </>
              )}
            </Space>
          </div>
        );
      },
    },
  ];

  const handleAdd = () => {
    // setFields([]);
    // const template = allTemplates.filter(
    //   (el) => el.name.toLowerCase() === name
    // );
    // if (template.length > 0) {
    //   const { template_fields } = template[0];
    //   setFields(template_fields);
    // }
    form.resetFields();
    fieldsFormRef.resetFields();
    window.pillarsData = defaultPillars;
    setSortedInfo(null);
    handleUpdateStatus(true, false);
    setCurrentUpdateObj({});
    let key = Math.ceil(Math.random() * 2345678);
    const newData = {
      key,
      id: key,
      site_date: moment(),
      username: user.first_name + ' ' + user.surname,
      document_template_id: null,
      document_status_id: null,
      document_due_date: null,
      document_number: '',
      sheets: '',
      comment: '',
      isAdd: true,
      npo_id: npoDetails.npo_id,
      npo_region: npoDetails.npo.npo_region.name,
    };
    let final = { ...newData, sites_field_values: [] };
    setData((data) => [final, ...data]);
    edit(final);
    // setImages([]);
    form.setFieldsValue({
      ...newData,
    });

    setEditingKey(key);
    setExpandedRowKeys([key]);
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          // inputType:,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        };
      },
    };
  });

  const handleSuccess = () => {
    setEditingKey('');
    setExpandedRowKeys([]);
  };

  const handleFinish = (item) => {
    let values = fieldsFormRef.getFieldsValue();

    const {
      site_date,
      document_template_id,
      document_status_id,
      document_due_date,
      sheets,
      comment,
    } = item;

    let dueDateisValid = moment(document_due_date).isValid();
    let duedate = dueDateisValid ? moment(document_due_date) : null;

    let pillarsData = window.pillarsData;

    let filteredPillars = pillarsData.filter(
      (pillar) => pillar.status === null
    );

    if (filteredPillars.length > 0) {
      return message.error('Pillars statuses are missing!');
    }
    const { id, npo_region } = npoDetails.npo;
    if (isUpdateImsDoc) {
      dispatch(
        updateActivity({
          ...item,
          site_date: moment(site_date),
          document_template_id,
          document_status_id,
          document_due_date: duedate,
          sheets,
          comment,
          user_id: user.id,
          id: currentUpdateObj.id,
          // ...(images.length > 0 && { images }),
          field_values: values,
          debouncedDefaultPageOneCallApi,
          pillarsData,
          handleSuccess,
        })
      );
    } else {
      dispatch(
        createImsDoc({
          ...item,
          site_date: moment(site_date),
          document_template_id,
          document_status_id,
          document_due_date: duedate,
          sheets,
          comment,
          user_id: user.id,
          // ...(images.length > 0 && { images }),
          field_values: values,
          pillarsData,
          debouncedDefaultPageOneCallApi,
          handleSuccess,
          npo_id: id,
          npo_region: npo_region?.name,
        })
      );
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    if (datePickerOpened) {
      return;
    }
    setSortedInfo(sorter);
  };

  const expandedRowRender = (record) => {
    if (expandedRowKeys.includes(record.id)) {
      let { document_template_id } = form.getFieldsValue();
      // if (document_template_id > 0) {
      return (
        <>
          {/* {fields.length > 0 && ( */}
          <>
            <ExpandedRow
              record={record}
              fields={fields}
              formRef={fieldsFormRef}
              onFinish={onFinishFields}
              isRevisionUpdateCase={isRevisionUpdateCase}
              isAdd={isAddImsDoc}
            />
            <hr />
            <PillarsSection
              // data={pillarsData}
              // setData={setPillarsData}
              record={record}
              isUpdate={isUpdateImsDoc}
              isRevisionUpdateCase={isRevisionUpdateCase}
            />
          </>
          {/* )} */}
          {isRevisionUpdateCase && (
            <Row gutter={[0, 60]} style={{ marginBottom: '30px' }}>
              {/* {record?.document_template?.revision_control && canView && ( */}
              <Col span={24}>
                <Classes
                  record={record}
                  permissions={npoDetails.permissions.sites}
                  isLearner={['learner', 'coach'].includes(user.user_type)}
                />
              </Col>
              {/* )} */}
              {record?.document_template?.distribution_control && canView && (
                <Col span={24}>{/* <Distribution record={record} /> */}</Col>
              )}
            </Row>
          )}
        </>
      );
      // }
    }
  };

  const handleChangeDocType = (id) => {
    const { name } = allNpos.filter((el) => el.id === id)[0].npo_region;

    form.setFieldValue('npo_region', name);

    // const { template_fields } = allTemplates.filter((el) => el.id === id)[0];
    // template_fields.map((el) => {
    //   fieldsFormRef.setFieldValue({ [el.id]: '' });
    //   return { [el.id]: null };
    // });
    // setFields(template_fields);
  };

  const onFinishFields = (values) => {
    console.log('values', values);
  };

  const handleCloseBatchSection = () => {
    setShowBatch(false);
  };

  const handleCloseFilterSection = () => {
    setShowFilter(false);
    setFilter({});
    handleCloseBatchSection();
    debouncedDefaultPageOneCallApi();
  };

  const handleFilter = (filterObj) => {
    setFilter(filterObj);
    debouncedDefaultPageOneCallApi();
  };

  const handleBatchUpdate = (filterObj) => {
    setExpandedRowKeys([]);
    setEditingKey('');
    setIsRevisionUpdateCase(false);
    let document_control = data.map((el) => el.id);
    let revisions_ids = [];
    let distributions_ids = [];
    data.map((doc) => {
      const { doc_distributions, doc_revisions } = doc;
      doc_revisions.map((rev) => revisions_ids.push(rev.id));
      doc_distributions.map((dist) => distributions_ids.push(dist.id));
    });

    dispatch(
      fetchBatchUpdate({
        ...filterObj,
        document_control,
        revisions_ids,
        distributions_ids,
      })
    );
  };

  const shouldAddBtnDisable = () => {
    let shouldDisabled =
      isAddImsDoc || fetchDocsLoading || isRevisionUpdateCase || isUpdateImsDoc;
    return shouldDisabled;
  };

  const hanldeBatch = () => {
    setShowFilter(true);
    setShowBatch(true);
  };

  return (
    <Row className='doc-control-parent-row'>
      <Col span={24} className='heading-col'>
        <Row align='middle' gutter={[50, 0]} justify='space-between'>
          <Col>
            <Row gutter={[50, 0]} align='middle'>
              <Col>
                <div>
                  <span className='heading-span'>{name} Register</span>
                </div>
              </Col>
              {canEdit && (
                <Col>
                  <Button
                    icon={<PlusOutlined />}
                    className='add-doc-btn site-btn'
                    size='middle'
                    onClick={handleAdd}
                    disabled={shouldAddBtnDisable()}
                  >
                    Site
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
          <Col>
            <Space size={35} align='center'>
              <Tooltip text='Filter'>
                <FilterIcon
                  onClick={() => {
                    setShowFilter(!showFilter);
                    showFilter && debouncedDefaultPageOneCallApi();
                  }}
                />
              </Tooltip>
              {canExport && (
                <Tooltip text='Export'>
                  {<ExportDoc npo_id={npoDetails.npo_id} />}
                </Tooltip>
              )}
              {canImport && (
                <Tooltip text='Import'>
                  <ImportDoc
                    debounce={debouncedDefaultPageOneCallApi}
                    npo_id={npoDetails.npo_id}
                  />
                </Tooltip>
              )}
              {/* {canEdit && (
                <Tooltip text='Batch Update'>
                  <BatchUpdateIcon onClick={hanldeBatch} />
                </Tooltip>
              )} */}
            </Space>
          </Col>
        </Row>
      </Col>
      {showFilter && (
        <FilterAndBatchUpdate
          handleCloseFilter={handleCloseFilterSection}
          handleFilter={handleFilter}
          handleBatchUpdate={handleBatchUpdate}
          handleCloseBatch={handleCloseBatchSection}
          loading={fetchDocsLoading}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          showBatch={showBatch}
          setShowBatch={setShowBatch}
        />
      )}

      <Col span={24}>
        <Form form={form} component={false} onFinish={handleFinish}>
          <Table
            scroll={screenWidth < 1900 && { x: true }}
            className={`special-table ims-doc-table ${
              shouldAddBtnDisable() ? 'doc-table-is-exapnded' : ''
            }`}
            components={{
              body: {
                cell: (props) => (
                  <EditableCell
                    cell={props}
                    expandedRowKeys={expandedRowKeys}
                    setExpandedRowKeys={setExpandedRowKeys}
                    // images={images}
                    // setImages={setImages}
                    // attachPopupVisible={attachPopupVisible}
                    // setAttachPopupVisible={setAttachPopupVisible}
                    currentUpdateObj={currentUpdateObj}
                    values={form.getFieldsValue()}
                    setFields={setFields}
                    fields={fields}
                    handleChangeDocType={handleChangeDocType}
                    // getCloumnName={getCloumnName}
                    isRevisionUpdateCase={isRevisionUpdateCase}
                    dueDateRequired={false}
                  />
                ),
              },
            }}
            expandable={{
              expandedRowRender,
              expandIcon: null,
              showExpandColumn: false,
              expandedRowKeys: expandedRowKeys,
              rowExpandable: ({ id }) => expandedRowKeys.includes(id),
            }}
            onRow={({ id }) => {
              if (expandedRowKeys.length > 0) {
                let isExpanded = expandedRowKeys.includes(id);
                let expandClassNames = `expand-doc-parent ${
                  isRevisionUpdateCase ? 'ims-doc-readonly-input' : ''
                }`;
                let unExpandClassnames = isRevisionUpdateCase
                  ? ''
                  : 'disabled-row';
                return {
                  className: isExpanded ? expandClassNames : unExpandClassnames,
                };
              }
            }}
            rowKey={(record) => record.id}
            loading={fetchDocsLoading} // fetchingLoading
            rowClassName='editable-row'
            dataSource={data}
            columns={mergedColumns}
            onChange={handleChange}
            pagination={false}
          />
        </Form>
      </Col>
      <Col span={24}>
        <CustomPagination
          page={page}
          setPage={setPage}
          resultPerPage={resultPerPage}
          setResultPerPage={setResultPerPage}
          debouncedCallApi={debouncedCallApi}
          totalRecord={totalRecords}
        />
      </Col>
    </Row>
  );
};

export default DistributionTable;
