import React, { useEffect, useState, useRef } from 'react';
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Col,
  Row,
  Space,
  Divider,
} from 'antd';
import {
  DownCircleOutlined,
  PlusOutlined,
  UpCircleOutlined,
} from '@ant-design/icons';
// import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as EditIcon } from '../../../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete-icon.svg';
import { ReactComponent as SaveIcon } from '../../../../assets/save-icon.svg';
import { ReactComponent as AttachIcon } from '../../../../assets/attach.svg';
import { ReactComponent as CrossIcon } from '../../../../assets/cross-icon.svg';
// import * as actions from './redux/actions';
import SpinnerComponent from '../../../../components/spinner';
import NonEditRowAttach from './nonEditRowAttachView';
// import CustomCalendar from '../../../../components/calendar';
import RevisionsEditableCell from './revisionsEditableCell';
// import AMultiSelect from '../../../../components/AMultiSelect';
import _, { toNumber } from 'lodash';
// import { getOptions } from '../../../Sales/utils';
import UseImsPermission from '../../usePermissions';
import UseLearners from './useLearners';
// import { capitalizeFirstLetterofEachWord } from '../../../../globalUtils/capitalizeFirstLetterofEachWord';
import SummaryComponent from './summary';
import { filterData } from './utils';

function LearnerTable({ program_id, learnerUsers, debouncedCallApi }) {
  // const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [sortedInfo, setSortedInfo] = useState(null);
  const [editingLoading, setEditingLoading] = useState(false);
  const [currentUpdateObj, setCurrentUpdateObj] = useState({});
  const [form] = Form.useForm();
  // const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  // images contains all attachments of item
  const [attachPopupVisible, setAttachPopupVisible] = useState(false);
  const [search, setSearch] = useState({});
  const [deletingActivityId, setDeleteActivityId] = useState();
  const [showTable, setShowTable] = useState(false);
  const [req, setReq] = useState(false);
  // const [statusCheckedList, setStatusCheckedList] = useState([]);
  const [canView, canEdit, , canDelete] = UseImsPermission();
  const [update, setUpdate] = useState({
    isUpdate: false,
    columnName: '',
  });
  let inputEls = useRef([]);

  const {
    learnersData,
    data,
    setData,
    setLearnersData,
    fetchLearners,
    deleteLearners,
    handleCreate,
    handleIsIsUpdate,
    handleDeleteAttach,
    handleUpdate,
  } = UseLearners();

  const isEditing = (record) =>
    record.key === editingKey || record.id === editingKey;

  const {
    allImsRevisions,
    fetchingLoading,
    updateLoading,
    isAddImsRevision,
    isUpdateImsRevision,
    deletingLoading,
    updateColLoading,
  } = learnersData;

  const handleUpdateStatus = (isAdd, isUpdate) => {
    handleIsIsUpdate(isAdd, isUpdate);
  };

  useEffect(() => {
    // let order = sortedInfo?.order === 'ascend' ? 'asc' : 'desc';

    // if (doc_revisions.length > 0) {
    // let revision_id = doc_revisions.map((rev) => rev.id);
    //   dispatch(
    //     actions.fetchImsRevisions({
    //       id: program_id,
    //       search: { ...search }, // revision_id
    //       sort: sortedInfo?.order && sortedInfo?.columnKey,
    //       order: sortedInfo?.order ? order : null,
    //     })
    //   );
    // } else {
    // dispatch(actions.fetchImsRevisionsSuccess([]));
    // }
    fetchLearners(program_id);
  }, []); // req, doc_revisions

  // useEffect(() => {
  //   debouncedCallApi();
  // }, [sortedInfo]);

  // const [debouncedCallApi] = useState(() =>
  //   _.debounce(() => setReq((prev) => !prev), 1000)
  // );

  useEffect(() => {
    setEditingLoading(updateLoading);
    if (!updateLoading) {
      setEditingKey('');
    }

    // return () => {
    //   handleUpdateStatus(false, false);
    // };
  }, [updateLoading]);

  // useEffect(() => {
  //   if (allImsRevisions.length === 0) {
  //     return setData(allImsRevisions);
  //   } else if (allImsRevisions.length > 0) {
  //     let updatedData = allImsRevisions.map((el) => {
  //       const { revision_attachments, ...rest } = el;

  //       let updatedImages;
  //       if (revision_attachments?.length > 0) {
  //         updatedImages = revision_attachments.map((img) => {
  //           const { url, id } = img;
  //           let name = url.split('/')[url.split('/').length - 1].split('?')[0];

  //           return { key: id, id, image: url, isLocal: false, name };
  //         });
  //       }

  //       return { ...rest, images: updatedImages };
  //     });
  //     setData(updatedData);
  //   }
  // }, [allImsRevisions]);

  const edit = (record) => {
    const { isAdd } = record;
    if (!isAdd) {
      setCurrentUpdateObj(record);
      let filteredItems = data.filter((el) => el.id !== editingKey);
      let final = filteredItems.filter((el) => el.key !== editingKey);
      setData(final);
      setImages(record?.images || []);
    }
    form.setFieldsValue({
      ...record,
    });
    !isAdd && handleUpdateStatus(false, true);
    setEditingKey(record.id);
  };

  const cancel = () => {
    if (isUpdateImsRevision) {
      setData(data);
    } else {
      // if (editingKey) {
      let filteredItems = data.filter((el) => el.id !== editingKey);
      let final = filteredItems.filter((el) => el.key !== editingKey);
      setData(final);
      // }
    }
    handleUpdateStatus(false, false);
    setEditingKey('');
  };

  const save = async (key) => {
    setEditingKey(key);
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex(
        (item) => key === item.key || key === item.id
      );
      if (index > -1) {
        form.submit();
      } else {
        // newData.push(row);
        // setData(newData);
        // setEditingKey("");
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const searchInput = (key, placeholder) => (
    <Input
      placeholder={''}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        const { value } = e.target;
        let values = { ...search, [key]: value };
        filterData({ values, data: allImsRevisions, setData });
        setSearch({ ...values });
        // debouncedCallApi();
      }}
    />
  );

  const handleDateInput = (value) => {
    // setDate(value);
    if (value === null) {
      setSearch({
        ...search,
        revision_start_date: '',
        revision_end_date: '',
      });
    } else {
      setSearch({
        ...search,
        revision_start_date: value[0],
        revision_end_date: value[1],
      });
    }
    // debouncedCallApi();
  };

  const handleMultiSelect = (list, key) => {
    setSearch({
      ...search,
      [key]: list,
    });
    // debouncedCallApi();
  };

  const handleSelectOnchange = (value, options, key, setHandler) => {
    if (value.includes('all')) {
      setHandler([...options.map((item) => item.value)]);
      value = options.map((item) => item.value);
    } else if (value.includes('not-all')) {
      setHandler([]);
      value = [];
    } else {
      setHandler(value);
    }
    handleMultiSelect(value, key);
  };

  const onDeleteSuccess = () => {
    setDeleteActivityId();
    debouncedCallApi();
  };

  const deleteRevisionHandler = (record) => {
    setDeleteActivityId(record.id);
    deleteLearners(record.id, onDeleteSuccess);
    // dispatch(
    //   actions.deleteImsRevision({ ...record, onDeleteSuccess, program_id })
    // );
  };

  const onEditColumn = (e, name) => {
    e.stopPropagation();
    data.forEach((el) => {
      inputEls[el.id] = el[name];
      form.setFieldValue(`${name}_${el.id}`, el[name]);
    });
    setUpdate({
      isUpdate: true,
      columnName: name,
    });
  };

  const onSaveColumn = async (e) => {
    e.stopPropagation();
    try {
      const row = await form.validateFields();
      form.submit();
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const EditDiv = ({ name }) => {
    if (
      !name ||
      isAddImsRevision ||
      (update.columnName !== name && update.isUpdate)
    ) {
      return (
        <EditIcon
          className={`custom-icon`}
          style={{
            opacity: 0,
          }}
        />
      );
    }

    if (update.columnName === name) {
      return updateColLoading ? (
        <SpinnerComponent fontSize={8} />
      ) : (
        <SaveIcon className='custom-icon' onClick={(e) => onSaveColumn(e)} />
      );
    }

    return (
      <EditIcon
        className={`custom-icon`}
        disabled={editingKey !== ''}
        onClick={(e) =>
          !isAddImsRevision && !isUpdateImsRevision && onEditColumn(e, name)
        }
      />
    );
  };

  const columns = [
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>No</div>
            <div>{searchInput('no')}</div>
            {canEdit && <EditDiv name={false} />}
          </div>
        );
      },
      dataIndex: 'no',
      key: 'no',
      sorter: (a, b) => b.no - a.no,
      sortOrder: sortedInfo?.columnKey === 'no' && sortedInfo?.order,
      width: 80,
      editable: false,
      align: 'center',
      render: (no, record) => {
        return record.no;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>First Name</div>
            <div>{searchInput('first_name')}</div>
            {canEdit && <EditDiv name={false} />}
          </div>
        );
      },
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => a.user?.first_name.localeCompare(b.user?.first_name),
      sortOrder: sortedInfo?.columnKey === 'first_name' && sortedInfo?.order,
      width: 100,
      editable: true,
      render: (username, record) => record?.user?.first_name,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Last Name</div>
            <div>{searchInput('last_name')}</div>
            {canEdit && <EditDiv name={false} />}
          </div>
        );
      },
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a, b) => a.user?.last_name.localeCompare(b.user?.last_name),
      sortOrder: sortedInfo?.columnKey === 'last_name' && sortedInfo?.order,
      width: 100,
      editable: true,
      render: (username, record) => record?.user?.last_name,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Attendance</div>
            <div>{searchInput('attendance')}</div>
            {canEdit && <EditDiv name='attendance' />}
          </div>
        );
      },
      dataIndex: 'attendance',
      key: 'attendance',
      sorter: (a, b) => a.attendance - b.attendance,
      sortOrder: sortedInfo?.columnKey === 'attendance' && sortedInfo?.order,
      width: 80,
      align: 'center',
      editable: true,
      render: (attendance) => <div>{attendance}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>DEMOGRAPHIC</div>
            <div>{searchInput('demographic')}</div>
            {canEdit && <EditDiv name={false} />}
          </div>
        );
      },
      dataIndex: 'demographic',
      key: 'demographic',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'demographic' && sortedInfo?.order,
      width: 120,
      align: 'center',
      editable: true,
      render: (demo, record) => {
        if (!record.user) {
          return;
        }
        const { age_type, gender, race } = record.user;
        let demographic = `${race} ${gender} ${age_type || ''}`;
        return (
          <div
            style={{
              textTransform: 'capitalize',
            }}
          >
            {demographic}
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>camp</div>
            <div>{searchInput('learner_camp_id')}</div>
            {canEdit && <EditDiv name='learner_camp_id' />}
          </div>
        );
      },
      dataIndex: 'learner_camp_id',
      key: 'learner_camp_id',
      sorter: (a, b) =>
        a?.learner_camp?.title.localeCompare(b?.learner_camp?.title),
      sortOrder:
        sortedInfo?.columnKey === 'learner_camp_id' && sortedInfo?.order,
      width: 100,
      align: 'center',
      editable: true,
      render: (learner_camp_id, record) => (
        <div>{record?.learner_camp?.title}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text-center'>
              Term 1<br />
              (Baseline)
            </div>
            <div>{searchInput('first_term')}</div>
            {canEdit && <EditDiv name='first_term' />}
          </div>
        );
      },
      dataIndex: 'first_term',
      key: 'first_term',
      sorter: (a, b) => a.first_term - b.first_term,
      sortOrder: sortedInfo?.columnKey === 'first_term' && sortedInfo?.order,
      width: 100,
      align: 'center',
      editable: true,
    },
    {
      title: () => {
        return (
          <div align='top'>
            <div className='special-table-header-text-center'>Term 2</div>
            <div>{searchInput('second_term')}</div>
            {canEdit && <EditDiv name='second_term' />}
          </div>
        );
      },
      dataIndex: 'second_term',
      key: 'second_term',
      sorter: (a, b) => a.second_term - b.second_term,
      sortOrder: sortedInfo?.columnKey === 'second_term' && sortedInfo?.order,
      width: 100,
      align: 'center',
      editable: true,
    },
    {
      title: () => {
        return (
          <div align='top'>
            <div className='special-table-header-text-center'>Term 3 </div>
            <div>{searchInput('third_term')}</div>
            {canEdit && <EditDiv name='third_term' />}
          </div>
        );
      },
      dataIndex: 'third_term',
      key: 'third_term',
      sorter: (a, b) => a.third_term - b.third_term,
      sortOrder: sortedInfo?.columnKey === 'third_term' && sortedInfo?.order,
      width: 100,
      align: 'center',
      editable: true,
    },
    {
      title: () => {
        return (
          <div align='top'>
            <div
              className='special-table-header-text-center'
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              Term 4 <br />
              (FInal Exam)
            </div>
            <div>{searchInput('fourth_term')}</div>
            {canEdit && <EditDiv name='fourth_term' />}
          </div>
        );
      },
      dataIndex: 'fourth_term',
      key: 'fourth_term',
      sorter: (a, b) => a.fourth_term - b.fourth_term,
      sortOrder: sortedInfo?.columnKey === 'fourth_term' && sortedInfo?.order,
      width: 100,
      align: 'center',
      editable: true,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              MKT <br />
              Pre
            </div>
            <div>{searchInput('mkt_pre')}</div>
            {canEdit && <EditDiv name='mkt_pre' />}
          </div>
        );
      },
      dataIndex: 'mkt_pre',
      key: 'mkt_pre',
      sorter: (a, b) => a.mkt_pre - b.mkt_pre,
      sortOrder: sortedInfo?.columnKey === 'mkt_pre' && sortedInfo?.order,
      width: 70,
      align: 'center',
      editable: true,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              MKT <br />
              post
            </div>
            <div>{searchInput('mkt_post')}</div>
            {canEdit && <EditDiv name='mkt_post' />}
          </div>
        );
      },
      dataIndex: 'mkt_post',
      key: 'mkt_post',
      sorter: (a, b) => a.mkt_post - b.mkt_post,
      sortOrder: sortedInfo?.columnKey === 'mkt_post' && sortedInfo?.order,
      width: 70,
      align: 'center',
      editable: true,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              ses <br />
              Pre
            </div>
            <div>{searchInput('ses_pre')}</div>
            {canEdit && <EditDiv name='ses_pre' />}
          </div>
        );
      },
      dataIndex: 'ses_pre',
      key: 'ses_pre',
      sorter: (a, b) => a.ses_pre - b.ses_pre,
      sortOrder: sortedInfo?.columnKey === 'ses_pre' && sortedInfo?.order,
      width: 70,
      align: 'center',
      editable: true,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              ses <br />
              post
            </div>
            <div>{searchInput('ses_post')}</div>
            {canEdit && <EditDiv name='ses_post' />}
          </div>
        );
      },
      dataIndex: 'ses_post',
      key: 'ses_post',
      sorter: (a, b) => a.ses_post - b.ses_post,
      sortOrder: sortedInfo?.columnKey === 'ses_post' && sortedInfo?.order,
      width: 70,
      align: 'center',
      editable: true,
    },

    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              leq <br />
              Pre
            </div>
            <div>{searchInput('leq_pre')}</div>
            {canEdit && <EditDiv name='leq_pre' />}
          </div>
        );
      },
      dataIndex: 'leq_pre',
      key: 'leq_pre',
      sorter: (a, b) => a.leq_pre - b.leq_pre,
      sortOrder: sortedInfo?.columnKey === 'leq_pre' && sortedInfo?.order,
      width: 70,
      align: 'center',
      editable: true,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              leq <br />
              post
            </div>
            <div>{searchInput('leq_post')}</div>
            {canEdit && <EditDiv name='leq_post' />}
          </div>
        );
      },
      dataIndex: 'leq_post',
      key: 'leq_post',
      sorter: (a, b) => a.leq_post - b.leq_post,
      sortOrder: sortedInfo?.columnKey === 'leq_post' && sortedInfo?.order,
      width: 70,
      align: 'center',
      editable: true,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              <div>
                <div>Attach</div>
                <div>{searchInput('attach')}</div>
                {/* <div style={{ color: 'transparent' }}>_</div> */}
                {canEdit && <EditDiv name={false} />}
              </div>
            </div>
          </div>
        );
      },
      dataIndex: 'images',
      key: 'images',
      sorter: (a, b) =>
        a?.attachments_urls?.length - b?.attachments_urls?.length,
      sortOrder: sortedInfo?.columnKey === 'images' && sortedInfo?.order,
      width: 60, // "4%"
      editable: true,
      align: 'center',
      render: (_, record) => {
        if (currentUpdateObj.id !== record.id) {
          return (
            <div
              className={`attach-parent-div  ${
                (isAddImsRevision || isUpdateImsRevision) && 'disable-icon'
              }`}
              onClick={() => {
                if (isAddImsRevision || isUpdateImsRevision) {
                  return;
                } else {
                  setCurrentUpdateObj(record);
                  setAttachPopupVisible(true);
                }
              }}
            >
              <AttachIcon
                className='rotate-icon activities-attach'
                width={16}
                fill='#231f20'
              />
              <div>{record?.images?.length || 0}</div>
            </div>
          );
        }

        return (
          <NonEditRowAttach
            images={record?.images || []}
            setImages={setImages}
            attachPopupVisible={attachPopupVisible}
            setAttachPopupVisible={setAttachPopupVisible}
            record={record}
            setCurrentUpdateObj={setCurrentUpdateObj}
            currentUpdateObj={currentUpdateObj}
            program_id={program_id}
            handleDeleteAttach={handleDeleteAttach}
            setLearnersData={setLearnersData}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: 'operation',
      align: 'center',
      width: 60,
      render: (_, record) => {
        const editable = isEditing(record);
        let key = record?.key || record?.id;
        return editable ? (
          editingLoading && editingKey === key ? (
            <SpinnerComponent fontSize={14} />
          ) : (
            <Space size={20} align='center'>
              <Popconfirm
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                placement='rightTop'
                overlayClassName='activities-popconfirm'
                title='Are you sure you want to save this learner?'
                onConfirm={() => save(record.key || record.id)}
                okText='Yes'
                cancelText='No'
              >
                <SaveIcon className='custom-icon' />
              </Popconfirm>
              {/* {!isUpdateImsRevision && ( */}
              <Popconfirm
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                title='Sure to cancel?'
                onConfirm={cancel}
              >
                <CrossIcon
                  style={{ height: '20px', width: '20px' }}
                  className='custom-icon'
                />
              </Popconfirm>
              {/* // )} */}
            </Space>
          )
        ) : (
          <div>
            <Space size={20} align='center'>
              {deletingLoading && record.id === deletingActivityId ? (
                <SpinnerComponent fontSize={16} />
              ) : (
                <>
                  {/* {canEdit && (
                    <EditIcon
                      className={`custom-icon ${
                        (isAddImsRevision || isUpdateImsRevision) && 
                        'disable-icon'
                      }`}
                      disabled={editingKey !== ''}
                      onClick={() =>
                        // !isAddImsRevision &&
                        // !isUpdateImsRevision &&
                        !status.isAdd &&
                        !status.isUpdate &&
                        edit({ ...record, isAdd: false })
                      }
                    />
                  )} */}
                  {!isAddImsRevision &&
                  !isUpdateImsRevision &&
                  !update.isUpdate ? (
                    canDelete && (
                      <Popconfirm
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            'ant-layout-content layout-content site-layout-background custom-textarea'
                          )[0]
                        }
                        placement='rightTop'
                        title='Are you sure you want to delete this learner?'
                        onConfirm={() => deleteRevisionHandler(record)}
                      >
                        <DeleteIcon className={`custom-icon`} />
                      </Popconfirm>
                    )
                  ) : (
                    <DeleteIcon
                      className={`custom-icon ${
                        (isAddImsRevision ||
                          isUpdateImsRevision ||
                          update.isUpdate) &&
                        'disable-icon'
                      }`}
                    />
                  )}
                </>
              )}
            </Space>
          </div>
        );
      },
    },
  ];

  const handleAdd = (e) => {
    e.stopPropagation();
    setShowTable(true);
    setSortedInfo(null);
    handleUpdateStatus(true, false);
    setCurrentUpdateObj({});
    let key = Math.ceil(Math.random() * 2345678);
    const newData = {
      key,
      id: key,
      no: '',
      first_name: null,
      last_name: '',
      attendance: null,
      first_term: null,
      second_term: null,
      third_term: null,
      fourth_term: null,
      learner_camp_id: null,
      images: null,
      isAdd: true,
      user: {
        age_type: '',
        gender: '',
        nationality: '',
        race: '',
      },
      mkt_pre: null,
      mkt_post: null,
      ses_pre: null,
      ses_post: null,
      leq_pre: null,
      leq_post: null,
    };
    setData((data) => [newData, ...data]);
    // setCount(1);
    edit(newData);
    setImages([]);
    form.setFieldsValue({
      ...newData,
    });

    setEditingKey(key);
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          // inputType:,
          dataIndex: col.dataIndex,
          title: col.title,
          editing:
            isEditing(record) ||
            (update.isUpdate && update.columnName === col.dataIndex),
        };
      },
    };
  });

  const handleUpdateSuccess = () => {
    setUpdate({
      isUpdate: false,
      columnName: '',
    });
    inputEls = [];
    debouncedCallApi();
  };

  const handleFinish = (item) => {
    // actions.updateImsRevision
    if (update.isUpdate) {
      let program_learners = [];
      for (const [key, field_value] of Object.entries(item)) {
        let learner_id = key.split('_').pop();
        let obj = {
          learner_id,
          field_value,
        };
        program_learners.push(obj);
      }
      handleUpdate({
        program_id,
        program_learners,
        field_name: update.columnName,
        debouncedCallApi,
        handleUpdateSuccess,
      });
    } else {
      const { first_name, ...rest } = item;
      // console.log('item', item);
      let params = {
        ...rest,
        ...(images.length > 0 && { images }),
        program_id,
        user_id: first_name,
        debouncedCallApi,
      };
      handleCreate(params);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleChangeUser = (id, option) => {
    const { last_name, age_type, gender, id_number, nationality, race } =
      option;
    let demographic = `${race} ${gender} ${age_type || ''}`;

    form.setFieldValue('last_name', last_name);
    form.setFieldValue('demographic', demographic);
  };

  return (
    <Row className='revisions-parent-row'>
      <Col span={24} className='heading-col'>
        <Row align='middle'>
          <Col>
            <div className='revisions-heading-div'>
              <div onClick={() => setShowTable(!showTable)}>
                {showTable ? (
                  <UpCircleOutlined className='revision-up-down-icon' />
                ) : (
                  <DownCircleOutlined className='revision-up-down-icon' />
                )}
              </div>
              <div>
                <span className='heading-span'>
                  Learners{' '}
                  {allImsRevisions.length > 0 && `(${allImsRevisions.length})`}
                </span>
              </div>
            </div>
          </Col>
          {canEdit && (
            <Col>
              <Button
                icon={<PlusOutlined />}
                className='add-revisions-btn'
                size='small'
                onClick={handleAdd}
                disabled={
                  isAddImsRevision || fetchingLoading || isUpdateImsRevision
                }
              >
                Learner
              </Button>
            </Col>
          )}
        </Row>
      </Col>

      {showTable && (
        <Col span={24}>
          <Form form={form} component={false} onFinish={handleFinish}>
            <Table
              scroll={{ x: true }}
              className='special-table ims-doc-table revisions-table classes-learners-table'
              components={{
                body: {
                  cell: (props) => (
                    <RevisionsEditableCell
                      cell={props}
                      images={images}
                      setImages={setImages}
                      attachPopupVisible={attachPopupVisible}
                      setAttachPopupVisible={setAttachPopupVisible}
                      currentUpdateObj={currentUpdateObj}
                      values={form.getFieldsValue()}
                      handleChangeUser={handleChangeUser}
                      learnerUsers={learnerUsers}
                      allLearners={data}
                      inputEls={inputEls}
                      isColUpdate={update.isUpdate}
                    />
                  ),
                },
              }}
              rowKey={(record) => record.id}
              loading={fetchingLoading}
              rowClassName='editable-row'
              dataSource={data}
              columns={mergedColumns}
              onChange={handleChange}
              pagination={false}
              summary={(pageData) => <SummaryComponent pageData={pageData} />}
            />
          </Form>
        </Col>
      )}
    </Row>
  );
}

export default LearnerTable;
