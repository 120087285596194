import { useSelector } from 'react-redux';

const UsePermissions = () => {
  const { user } = useSelector((state) => state.authReducer);

  let { learners } = user.permissions.report_summary;

  let canView = learners.view;
  let canEdit = learners.add_edit;
  let canExport = learners.export;
  let canDelete = learners.delete;

  return [canView, canEdit, canExport, canDelete];
};

export default UsePermissions;
