export const statusOptions = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
];

export const userTypeOptions = [
  {
    label: 'System Manager',
    value: 'system_manager',
  },
  {
    label: 'Super Admin',
    value: 'super_admin',
  },
];
