const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS";
const UPDATE_TEMPLATE_ERROR = "UPDATE_TEMPLATE_ERROR";
const DELETE_TEMPLATE = "DELETE_TEMPLATES";
const DELETE_TEMPLATE_SUCCESS  = "DELETE_TEMPLATE_SUCCESS";
const DELETE_TEMPLATE_ERROR = " DELETE_TEMPLATE_ERROR";
const CREATE_TEMPLATE = " CREATE_TEMPLATE";
const CREATE_TEMPLATE_SUCCESS  = " CREATE_TEMPLATE_SUCCESS";
const CREATE_TEMPLATE_ERROR = "  CREATE_TEMPLATE_ERROR";
const FETCH_ALL_TEMPLATES = "FETCH_ALL_TEMPLATES"
const FETCH_ALL_TEMPLATES_SUCCESS = "FETCH_ALL_TEMPLATES_SUCCESS";
const FETCH_ALL_TEMPLATES_ERROR = "FETCH_ALL_TEMPLATES_ERROR";
const DOCUMENT_TEMPLATE_IS_EDIT = "DOCUMENT_TEMPLATE_IS_EDIT";
const IS_ADDING_UPDATE = "IS_ADDING_UPDATE";
const FETCH_TEMPLATE = "FETCH_TEMPLATE";
const TEMPLATE_CREATION_CLEANUP_FUNC = "TEMPLATE_CREATION_CLEANUP_FUNC";
const FETCH_TEMPLATE_SUCCESS = "FETCH_TEMPLATE_SUCCESS";
const FETCH_TEMPLATE_ERROR = "FETCH_TEMPLATE_ERROR";
export {
  DOCUMENT_TEMPLATE_IS_EDIT,
  FETCH_ALL_TEMPLATES,
  FETCH_ALL_TEMPLATES_SUCCESS,
  FETCH_ALL_TEMPLATES_ERROR,
  CREATE_TEMPLATE,
  CREATE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE_ERROR,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_ERROR,
  DELETE_TEMPLATE,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_ERROR,
  IS_ADDING_UPDATE,
  FETCH_TEMPLATE,
  TEMPLATE_CREATION_CLEANUP_FUNC,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATE_ERROR,
};