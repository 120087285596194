import { Comment, Tooltip, List, Input, Popover } from "antd";
import moment from "moment";
import { ReactComponent as SendIcon } from "../../assets/send-icon.svg";
import { ReactComponent as ChatIcon } from "../../assets/chat-icon.svg";
import { ReactComponent as CrossIcon } from "../../assets/cross-icon.svg";
import UseGetUserRole from "../../components/useGetUserRole";
import { useSelector } from "react-redux";

const data = [
  {
    actions: [<span key="comment-list-reply-to-0">Reply to</span>],
    author: "John McClane",
    avatar: "https://joeschmoe.io/api/v1/random",
    content: (
      <p>
        We supply a series of design principles, practical patterns and high
        quality design resources (Sketch and Axure), to help people create their
        product prototypes beautifully and efficiently.
      </p>
    ),
    datetime: (
      <Tooltip
        title={moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss")}
      >
        <span>{moment().subtract(1, "days").fromNow()}</span>
      </Tooltip>
    ),
  },
  {
    actions: [<span key="comment-list-reply-to-0">Reply to</span>],
    author: "Isaac Vaughan",
    avatar: "https://joeschmoe.io/api/v1/random",
    content: (
      <p>
        We supply a series of design principles, practical patterns and high
        quality design resources (Sketch and Axure), to help people create their
        product prototypes beautifully and efficiently.
      </p>
    ),
    datetime: (
      <Tooltip
        title={moment().subtract(2, "days").format("YYYY-MM-DD HH:mm:ss")}
      >
        <span>{moment().subtract(2, "days").fromNow()}</span>
      </Tooltip>
    ),
  },
  {
    actions: [<span key="comment-list-reply-to-0">Reply to</span>],
    author: "John McClane",
    avatar: "https://joeschmoe.io/api/v1/random",
    content: (
      <p>
        We supply a series of design principles, practical patterns and high
        quality design resources (Sketch and Axure), to help people create their
        product prototypes beautifully and efficiently.
      </p>
    ),
    datetime: (
      <Tooltip
        title={moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss")}
      >
        <span>{moment().subtract(1, "days").fromNow()}</span>
      </Tooltip>
    ),
  },
  {
    actions: [<span key="comment-list-reply-to-0">Reply to</span>],
    author: "Isaac Vaughan",
    avatar: "https://joeschmoe.io/api/v1/random",
    content: (
      <p>
        We supply a series of design principles, practical patterns and high
        quality design resources (Sketch and Axure), to help people create their
        product prototypes beautifully and efficiently.
      </p>
    ),
    datetime: (
      <Tooltip
        title={moment().subtract(2, "days").format("YYYY-MM-DD HH:mm:ss")}
      >
        <span>{moment().subtract(2, "days").fromNow()}</span>
      </Tooltip>
    ),
  },
  {
    actions: [<span key="comment-list-reply-to-0">Reply to</span>],
    author: "John McClane",
    avatar: "https://joeschmoe.io/api/v1/random",
    content: (
      <p>
        We supply a series of design principles, practical patterns and high
        quality design resources (Sketch and Axure), to help people create their
        product prototypes beautifully and efficiently.
      </p>
    ),
    datetime: (
      <Tooltip
        title={moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss")}
      >
        <span>{moment().subtract(1, "days").fromNow()}</span>
      </Tooltip>
    ),
  },
  {
    actions: [<span key="comment-list-reply-to-0">Reply to</span>],
    author: "Isaac Vaughan",
    avatar: "https://joeschmoe.io/api/v1/random",
    content: (
      <p>
        We supply a series of design principles, practical patterns and high
        quality design resources (Sketch and Axure), to help people create their
        product prototypes beautifully and efficiently.
      </p>
    ),
    datetime: (
      <Tooltip
        title={moment().subtract(2, "days").format("YYYY-MM-DD HH:mm:ss")}
      >
        <span>{moment().subtract(2, "days").fromNow()}</span>
      </Tooltip>
    ),
  },
];

const ChatPopup = ({
  handleChatOnClick,
  chatPopupVisible,
  setChatPopupVisible,
  record,
  setCurrentPopupRecordId,
}) => {
  const { Search } = Input;

  const [isSuperAdmin, isAdmin, , isSalesUser] = UseGetUserRole();
  const { user } = useSelector((state) => state.authReducer);

  const onSearch = (value) => console.log(value);

  const handleCrossClick = () => {
    console.log("clicked");
    setCurrentPopupRecordId(null);
    setChatPopupVisible(false);
  };

  const isAllow = () => {
    let salesUserCond =
      isSalesUser && record?.cpy_staff_id == user?.quick_easy_id;
    return isSuperAdmin || isAdmin || salesUserCond;
  };

  return (
    <Popover
      placement="bottomRight"
      // content={<a onClick={hide}>Close</a>}
      title={
        <>
          <div className="cross-icon-div">
            <CrossIcon onClick={handleCrossClick} />
          </div>
          <List
            className="comment-list"
            // header={`${data.length} replies`}
            itemLayout="horizontal"
            dataSource={data}
            style={{ width: "617px" }}
            footer={
              isAllow() ? (
                <Search
                  placeholder="Type a Message"
                  allowClear
                  enterButton={<SendIcon width={22} height={22} />}
                  size="middle"
                  onSearch={onSearch}
                />
              ) : null
            }
            renderItem={(item, index) => (
              <li>
                <div className="comment-list-date-div">{item.datetime}</div>
                <Comment
                  className={`${index % 2 === 0 ? "send" : "received"}`}
                  actions={null} // item.actions
                  author={item.author}
                  avatar={item.avatar}
                  content={item.content}
                  datetime={item.datetime}
                />
              </li>
            )}
          />
        </>
      }
      trigger="click"
      visible={chatPopupVisible}
      openClassName=""
      overlayClassName="prod-chat-popup"
      // onVisibleChange={handleVisibleChange}
      getPopupContainer={() =>
        document.getElementsByClassName(
          "ant-layout-content layout-content site-layout-background custom-textarea"
        )[0]
      }
      onVisibleChange={(e) => {
        setChatPopupVisible(e);
        // setCurrentObj(record);
      }}
    >
      <ChatIcon
        width={18}
        className="custom-icon"
        onClick={() => handleChatOnClick(record)}
      />
    </Popover>
  );
};

export default ChatPopup;
