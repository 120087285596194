import { useState } from 'react';
import { message as AntdAlert } from 'antd';
import axios from 'axios';
import { baseURL } from '../../../globalUtils/axiosConfig';
import { useSelector } from 'react-redux';

export const UseTemplateFields = () => {
  const [inputFields, setInputFields] = useState([]);
  const { currentTemplateObj } = useSelector(
    (state) => state.templatesManagerReducer
  );

  const createField = async (item) => {
    const { name, field_type, index } = item;

    if (name?.trim()?.length < 1) {
      return AntdAlert.error('Field should not be blank!');
    }

    const response = await axios.post(`${baseURL}/api/v1/template_fields`, {
      name,
      field_type,
      position: index + 1,
      template_id: currentTemplateObj.id,
    });
    const { success, data, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      const updated = inputFields.map((el) =>
        el.id === item.id
          ? { ...el, ...data, isLocal: false, isEdit: false }
          : el
      );
      setInputFields(updated);
    }
  };

  const deleteField = async (item) => {
    const { id } = item;
    const response = await axios.delete(
      `${baseURL}/api/v1/template_fields/${id}`
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      const updated = inputFields.filter((el) => el.id !== item.id);
      setInputFields(updated);
    }
  };

  const updateField = async (item) => {
    const { name, field_type, id } = item;
    const response = await axios.put(
      `${baseURL}/api/v1/template_fields/${id}`,
      {
        name,
        field_type,
      }
    );
    const { success, data, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      const updated = inputFields.map((el) =>
        el.id === item.id
          ? { ...el, ...data, isLocal: false, isEdit: false }
          : el
      );
      setInputFields(updated);
    }
  };

  const onEditClick = (item) => {
    const updated = inputFields.map((el) =>
      el.id === item.id ? { ...el, isEdit: true } : el
    );
    setInputFields(updated);
  };

  const createOption = async (item) => {
    const { name, parentId } = item;
    const response = await axios.post(
      `${baseURL}/api/v1/template_fields/${parentId}/field_options`,
      {
        name,
      }
    );
    const { success, data, message } = response.data;
    if (success) {
      AntdAlert.success(message);

      const inputItem = inputFields.filter((el) => el.id === parentId)[0];

      const updatedOptions = inputItem.options.map((el) =>
        el.id === item.id
          ? { ...el, ...data, isLocal: false, isEdit: false }
          : el
      );

      const final = inputFields.map((el) =>
        el.id === parentId ? { ...el, options: updatedOptions } : el
      );
      setInputFields(final);
    }
  };

  const updateOption = async (item) => {
    const { name, template_field_id, id } = item;
    const response = await axios.put(
      `${baseURL}/api/v1/template_fields/${template_field_id}/field_options/${id}`,
      {
        name,
      }
    );
    const { success, data, message } = response.data;
    if (success) {
      AntdAlert.success(message);

      const inputItem = inputFields.filter(
        (el) => el.id === template_field_id
      )[0];

      const updatedOptions = inputItem.options.map((el) =>
        el.id === id ? { ...el, ...data, isEdit: false } : el
      );

      const final = inputFields.map((el) =>
        el.id === template_field_id ? { ...el, options: updatedOptions } : el
      );
      setInputFields(final);
    }
  };

  const deleteOption = async (item) => {
    const { template_field_id, id } = item;
    const response = await axios.delete(
      `${baseURL}/api/v1/template_fields/${template_field_id}/field_options/${id}`
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);

      const inputItem = inputFields.filter(
        (el) => el.id === template_field_id
      )[0];

      const updatedOptions = inputItem.options.filter((el) => el.id !== id);

      const final = inputFields.map((el) =>
        el.id === template_field_id ? { ...el, options: updatedOptions } : el
      );
      setInputFields(final);
    }
  };

  const onEditClickOption = ({ template_field_id, id }) => {
    const inputItem = inputFields.filter(
      (el) => el.id === template_field_id
    )[0];

    const updatedOptions = inputItem.options.map((el) =>
      el.id === id ? { ...el, isEdit: true } : el
    );

    const final = inputFields.map((el) =>
      el.id === template_field_id ? { ...el, options: updatedOptions } : el
    );
    setInputFields(final);
  };

  return [
    inputFields,
    setInputFields,
    createField,
    deleteField,
    updateField,
    onEditClick,
    createOption,
    updateOption,
    deleteOption,
    onEditClickOption,
  ];
};
