import React from 'react';
import { Row } from 'antd';
import UsersRegisterTable from './registerTable';
// import { setUserDetailsIsEdit } from './redux/actions';
import './index.less';
import UseUserManager from './hooks/useUserManager';

const UserManager = () => {
  const [userType, userTypeName] = UseUserManager();

  if (userType === '') {
    return;
  }

  return (
    <div className='page-top-div'>
      <span className='text-span'>{userTypeName} users Manager</span>
      <Row className='production-manager-parent-row users-manager-parent-row'>
        <UsersRegisterTable userType={userType} userTypeName={userTypeName} />
      </Row>
    </div>
  );
};

export default UserManager;
