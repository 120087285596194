import React, { useEffect, useState, useRef } from 'react';
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Space,
  Col,
  Row,
  message,
  Checkbox,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { ReactComponent as SaveIcon } from '../../assets/save-icon.svg';
import { ReactComponent as CrossIcon } from '../../assets/cross-icon.svg';
import SpinnerComponent from '../../components/spinner';
import { Colorpicker } from 'antd-colorpicker';
import { arrayMoveImmutable } from 'array-move';
import CheckSvg from '../../assets/check.svg.png';
import DragDropIcon from '../../assets/dragdropicon.png';
import {
  createLearnerStatuses,
  deleteLearnerStatuses,
  fetchLearnerStatuses,
  isAddingAction,
  updateLearnerOrder,
  updateLearnerStatuses,
} from './redux/actions';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);
const DragHandle = SortableHandle(() => <img alt='' src={DragDropIcon} />);

const LearnerTable = ({ canUpdate, canDelete }) => {
  const [editingLoading, setEditingLoading] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const ref = useRef();
  const isEditing = (record) => record.id === editingKey;

  const { allLearnerStatuses, fetchingLoading, updateLoading, isAdding } =
    useSelector((state) => state.learnerStatusReducer);

  useEffect(() => {
    dispatch(fetchLearnerStatuses());

    return () => {
      dispatch(isAddingAction(false));
    };
  }, []);

  useEffect(() => {
    setEditingLoading(updateLoading);
    if (!updateLoading) {
      setEditingKey('');
    }
  }, [updateLoading]);

  useEffect(() => {
    setData(allLearnerStatuses);
  }, [allLearnerStatuses]);

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const textInput = (
      <Input
        placeholder='Insert Status Name'
        ref={ref}
        readOnly={!record?.deleteable}
        disabled={!record?.deleteable}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            save(record.id);
          }
        }}
      />
    );

    let checkboxInput = <Checkbox disabled={!record?.deleteable} />;
    let colorInput = <Colorpicker popup />;

    let inputNode = null;

    if (dataIndex === 'show_by_default') {
      inputNode = checkboxInput;
    } else if (dataIndex === 'color') {
      inputNode = colorInput;
    }

    if (dataIndex === 'name') {
      return (
        <td {...restProps}>
          {editing ? (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: true,
                  message: `This field is required!`,
                },
                {
                  min: 1,
                  message: `invalid!`,
                },
              ]}
            >
              {textInput}
            </Form.Item>
          ) : (
            children
          )}
        </td>
      );
    }
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            valuePropName={
              dataIndex === 'show_by_default' ? 'checked' : 'value'
            }
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Required!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const edit = (record) => {
    dispatch(isAddingAction(true));

    form.setFieldsValue({
      ...record,
      color: record?.color || '',
    });
    setEditingKey(record.id);
    setTimeout(() => {
      ref.current?.focus();
    }, 300);
  };

  const cancel = (record) => {
    dispatch(isAddingAction(false));
    form.resetFields();
    setEditingKey('');
    if (record?.isAdd) {
      return setData((data) => data.filter((el) => el.id !== record.id));
    } else {
      setData(data);
    }
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[index];
        if (item?.isAdd) {
          form.submit();
        } else {
          let col = row.color.hex || row.color;

          if (isAlreadyExist({ ...item, ...row })) {
            return message.error('Status name already exist!');
          }

          dispatch(
            updateLearnerStatuses({
              ...item,
              ...row,
              color: col,
            })
          );
        }
      }
    } catch (errInfo) {
      if (errInfo?.values?.name === '') {
        message.error('Name should not be blank!');
      }
      if (errInfo?.values?.color === '') {
        message.error('Color should not be blank!');
      }
    }
  };

  const handleDelete = (record) => {
    setEditingKey(record.id);
    dispatch(deleteLearnerStatuses(record));
  };

  const handleAdd = () => {
    dispatch(isAddingAction(true));
    let id = Math.floor(Math.random() * 1122123456);
    const newData = {
      id,
      color: '',
      name: '',
      show_by_default: false,
      isAdd: true,
      deleteable: true,
    };
    setData((data) => [...data, newData]);
    setEditingKey(id);
    edit(newData);
  };

  const isAlreadyExist = (record) => {
    const { name } = record;
    const alreadyExist = data.filter(
      (el) => el.name?.trim()?.toLowerCase() === name?.trim()?.toLowerCase()
    );

    return alreadyExist.length > 0 && record.id !== alreadyExist[0]?.id
      ? true
      : false;
  };

  const hanldeFinish = (values) => {
    const { color, name, show_by_default } = values;

    if (isAlreadyExist(values)) {
      return message.error('Status name already exist!');
    }

    dispatch(
      createLearnerStatuses({
        show_by_default,
        name: name.trim(),
        color: color.hex,
        id: editingKey,
        status_type: 'learner',
      })
    );
  };

  const columns = [
    {
      title: 'Status Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      editable: true,
    },
    {
      title: 'Colour',
      dataIndex: 'color',
      key: 'color',
      width: '20%',
      editable: true,
      render: (color) => {
        return (
          <div style={{ pointerEvents: 'none' }}>
            <Colorpicker
              blockStyles={{ border: 'none' }}
              value={color || ''}
              popup
              picker={null}
            />
          </div>
        );
      },
    },
    {
      title: 'Show By Default',
      dataIndex: 'show_by_default',
      key: 'show_by_default',
      width: '25%',
      editable: true,
      align: 'center',
      render: (show_by_default) => {
        return <div>{show_by_default && <img alt='' src={CheckSvg} />}</div>;
      },
    },
    {
      title: '',
      key: 'action',
      dataIndex: '',
      width: '13%',
      align: 'left',
      render: (_, record) => {
        if (!canUpdate && !canDelete) {
          return;
        }

        const { deleteable } = record;

        const editable = isEditing(record);
        return editable ? (
          editingLoading && editingKey === record.id ? (
            <SpinnerComponent fontSize={14} />
          ) : (
            <Space size={20} align='center'>
              <SaveIcon
                className='custom-icon'
                onClick={() => save(record.id)}
              />
              <Popconfirm
                title='Sure to cancel?'
                onConfirm={() => cancel(record)}
              >
                <CrossIcon
                  style={{ height: '20px', width: '20px' }}
                  className='custom-icon'
                />
              </Popconfirm>
            </Space>
          )
        ) : (
          <Space size={20} align='center'>
            {canUpdate && (
              <EditIcon
                className={`custom-icon ${isAdding ? 'disable-icon' : ''}`}
                disabled={editingKey !== ''}
                onClick={() => !isAdding && edit(record)}
              />
            )}
            {deleteable &&
              canDelete &&
              data.length >= 1 &&
              (isAdding ? (
                <DeleteIcon className={`custom-icon disable-icon`} />
              ) : (
                <Popconfirm
                  title='Are you sure you want to delete this status?'
                  onConfirm={() => handleDelete(record)}
                  okText='Yes'
                  cancelText='No'
                >
                  <DeleteIcon className='custom-icon' />
                </Popconfirm>
              ))}
          </Space>
        );
      },
    },
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
      width: '12%',
      className: 'drag-visible',
      render: (order, record) => {
        const { deleteable } = record;
        return (
          !Boolean(editingKey) && canUpdate && deleteable && <DragHandle />
        );
      },
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const { deleteable } = data[newIndex];

    let shouldNotReorder = !deleteable;

    if (shouldNotReorder || newIndex === 0) {
      return setData((prevData) => prevData);
    }

    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        data.slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);

      dispatch(updateLearnerOrder(newData));
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = data.findIndex(
      (x) => x.position == restProps['data-row-key']
    );

    return <SortableItem index={index} {...restProps} />;
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Row justify='center' className='production-manager-parent-row'>
      <Col xxl={24} xl={24} lg={24} md={24} className='heading-col'>
        <Row align='middle' gutter={[30, 0]}>
          <Col>
            <div>
              <span className='heading-span'>Learner Statuses</span>
            </div>
          </Col>
        </Row>
      </Col>

      <Col lg={23} xl={18} xxl={17} md={24} style={{ minHeight: '70vh' }}>
        <Form
          form={form}
          component={false}
          onFinish={hanldeFinish}
          className='table-style'
        >
          <Table
            className='special-table activity-type-table project-statuses-table'
            components={{
              body: {
                cell: EditableCell,
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
            scroll={{ x: !fetchingLoading }}
            rowClassName='editable-row'
            dataSource={data}
            loading={fetchingLoading}
            columns={mergedColumns}
            pagination={false}
            rowKey='position'
          />
          <br />
          <Col offset={1} lg={24} xl={18} xxl={17} md={24} className='table'>
            {canUpdate && (
              <Button
                icon={<PlusOutlined />}
                className='add-status-btn'
                size='middle'
                disabled={isAdding}
                onClick={handleAdd}
              >
                STATUS
              </Button>
            )}
          </Col>
        </Form>
      </Col>
    </Row>
  );
};

export default LearnerTable;
