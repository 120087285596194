import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

let permissionsObj = {
  canView: false,
  canEdit: false,
  canImport: false,
  canExport: false,
  canDelete: false,
};

const UsePermissions = () => {
  const { user } = useSelector((state) => state.authReducer);
  const { pathname } = useLocation();
  const [permissions, setPermissions] = useState(permissionsObj);

  const handlePermissions = () => {
    const { user_type } = user;

    if (user_type === 'super_admin' || user_type === 'system_manager') {
      let { system_users_manager, coach_users_manager, learner_users_manager } =
        user.permissions;

      if (pathname.includes('system-users-manager')) {
        let canView = system_users_manager?.view || false;
        let canEdit = system_users_manager?.add_edit || false;
        let canImport = system_users_manager?.import || false;
        let canExport = system_users_manager?.export || false;
        let canDelete = system_users_manager?.delete || false;
        setPermissions({
          canView,
          canEdit,
          canImport,
          canExport,
          canDelete,
        });
      } else if (pathname.includes('coach-users-manager')) {
        let canView = coach_users_manager?.view || false;
        let canEdit = coach_users_manager?.add_edit || false;
        let canImport = coach_users_manager?.import || false;
        let canExport = coach_users_manager?.export || false;
        let canDelete = coach_users_manager?.delete || false;
        setPermissions({
          canView,
          canEdit,
          canImport,
          canExport,
          canDelete,
        });
      } else if (pathname.includes('learner-users-manager')) {
        let canView = learner_users_manager?.view || false;
        let canEdit = learner_users_manager?.add_edit || false;
        let canImport = learner_users_manager?.import || false;
        let canExport = learner_users_manager?.export || false;
        let canDelete = learner_users_manager?.delete || false;
        setPermissions({
          canView,
          canEdit,
          canImport,
          canExport,
          canDelete,
        });
      }
    } else if (user_type === 'coach') {
      let { learner_users_manager } = user.permissions;
      if (pathname.includes('learner-users-manager')) {
        let canView = learner_users_manager?.view || false;
        let canEdit = learner_users_manager?.add_edit || false;
        let canImport = learner_users_manager?.import || false;
        let canExport = learner_users_manager?.export || false;
        let canDelete = learner_users_manager?.delete || false;
        setPermissions({
          canView,
          canEdit,
          canImport,
          canExport,
          canDelete,
        });
      }
    }
  };

  useEffect(() => {
    if (user) {
      handlePermissions();
    }
  }, [pathname, user]);

  return { ...permissions };
};

export default UsePermissions;
