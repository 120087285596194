import { useSelector } from 'react-redux';

const UsePermissions = () => {
  const { user } = useSelector((state) => state.authReducer);

  let { sites } = user.permissions.report_summary;

  let canView = sites.view;
  let canEdit = sites.add_edit;
  let canExport = sites.export;
  let canDelete = sites.delete;
  let canImport = sites.import;

  return [canView, canEdit, canExport, canDelete, canImport];
};

export default UsePermissions;
