import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Table from './Table';

const TableComponent = ({ record, permissions, isLearner }) => {
  const [statusOptions, setStatusOptions] = useState([]);
  const { allLearnerStatuses } = useSelector(
    (state) => state.learnerStatusReducer
  );

  useEffect(() => {
    let updatedStatuses = allLearnerStatuses.map((el) => {
      const { status_name, id } = el;
      return { value: id, label: status_name };
    });
    setStatusOptions(updatedStatuses);
  }, [allLearnerStatuses]);

  // if (isLearner) {
  //   record.programs = record.programs.filter(
  //     (el) => el.manual_type === 'modular'
  //   );
  // }

  return (
    <Table
      parentRecordID={record.id}
      statusOptions={statusOptions}
      doc_revisions={record.doc_revisions}
      classes={record.programs || []}
      permissions={permissions}
    />
  );
};

export default TableComponent;
