import React, { useEffect, useState } from 'react';
import { Table, Button, Popconfirm, Form, Col, Row, Space } from 'antd';
import {
  DownCircleOutlined,
  PlusOutlined,
  UpCircleOutlined,
} from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../../../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/delete-icon.svg';
import { ReactComponent as SaveIcon } from '../../../../../assets/save-icon.svg';
import { ReactComponent as AttachIcon } from '../../../../../assets/attach.svg';
import { ReactComponent as CrossIcon } from '../../../../../assets/cross-icon.svg';
import SpinnerComponent from '../../../../../components/spinner';
import NonEditRowAttach from './nonEditRowAttachView';
import RevisionsEditableCell from './editableCell';
import UseLearners from './useMassLearner';

function MassLearnerTable({
  program_id,
  massLearner,
  debouncedCallApi,
  permissions,
}) {
  const [editingKey, setEditingKey] = useState('');
  const [editingLoading, setEditingLoading] = useState(false);
  const [currentUpdateObj, setCurrentUpdateObj] = useState({});
  const [form] = Form.useForm();
  const [images, setImages] = useState([]);
  // images contains all attachments of item
  const [attachPopupVisible, setAttachPopupVisible] = useState(false);
  //   const [search, setSearch] = useState({});
  const [deletingActivityId, setDeleteActivityId] = useState();
  const [showTable, setShowTable] = useState(false);

  let canEdit = permissions?.add_edit || false;
  let canDelete = permissions?.delete || false;

  const {
    learnersData,
    data,
    setData,
    setLearnersData,
    fetchLearners,
    deleteLearners,
    handleCreate,
    handleIsIsUpdate,
    handleDeleteAttach,
    handleUpdate,
  } = UseLearners();

  const isEditing = (record) =>
    record.key === editingKey || record.id === editingKey;

  const {
    allImsRevisions,
    fetchingLoading,
    updateLoading,
    isAddImsRevision,
    isUpdateImsRevision,
    deletingLoading,
    updateColLoading,
  } = learnersData;

  const handleUpdateStatus = (isAdd, isUpdate) => {
    handleIsIsUpdate(isAdd, isUpdate);
  };

  useEffect(() => {
    if (massLearner.length > 0) {
      fetchLearners(massLearner);
    }
  }, []);

  useEffect(() => {
    setEditingLoading(updateLoading);
    if (!updateLoading) {
      setEditingKey('');
    }

    // return () => {
    //   handleUpdateStatus(false, false);
    // };
  }, [updateLoading]);

  const edit = (record) => {
    const { isAdd } = record;
    if (!isAdd) {
      setCurrentUpdateObj(record);
      let filteredItems = data.filter((el) => el.id !== editingKey);
      let final = filteredItems.filter((el) => el.key !== editingKey);
      setData(final);
      setImages(record?.images || []);
    }
    form.setFieldsValue({
      ...record,
      description: 'Mass Class',
    });
    !isAdd && handleUpdateStatus(false, true);
    setEditingKey(record.id);
  };

  const cancel = () => {
    if (isUpdateImsRevision) {
      setData(data);
    } else {
      // if (editingKey) {
      let filteredItems = data.filter((el) => el.id !== editingKey);
      let final = filteredItems.filter((el) => el.key !== editingKey);
      setData(final);
      // }
    }
    handleUpdateStatus(false, false);
    setEditingKey('');
  };

  const save = async (key) => {
    setEditingKey(key);
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex(
        (item) => key === item.key || key === item.id
      );
      if (index > -1) {
        form.submit();
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleOnchange = (key, value) => {
    const values = form.getFieldsValue();
    let total_attendence = 0;

    for (const [key1, value1] of Object.entries(values)) {
      let isInclude = key1.includes('male') || key1.includes('lgbt');
      if (isInclude && value1 !== null && value1 !== undefined) {
        total_attendence += value1;
      }
    }

    form.setFieldValue('total_attendence', total_attendence);
    if (values.total_age > 0) {
      form.setFieldValue(
        'average_age',
        Math.round(values.total_age / total_attendence)
      );
    }
  };

  const onDeleteSuccess = () => {
    setDeleteActivityId();
    debouncedCallApi();
  };

  const deleteRevisionHandler = (record) => {
    setDeleteActivityId(record.id);
    deleteLearners(record.id, onDeleteSuccess);
    // dispatch(
    //   actions.deleteImsRevision({ ...record, onDeleteSuccess, program_id })
    // );
  };

  const columns = [
    {
      title: () => <div className='special-table-header-text'>Description</div>,
      dataIndex: 'description',
      key: 'description',
      width: 80,
      editable: true,
      align: 'center',
    },
    {
      title: () => (
        <div className='special-table-header-text-center'>
          AFRICAN <br /> MALE
        </div>
      ),
      dataIndex: 'african_male',
      key: 'african_male',
      width: 100,
      editable: true,
      align: 'center',
      render: (african_male, record) => african_male,
    },
    {
      title: () => {
        return (
          <div className='special-table-header-text-center'>
            AFRICAN <br /> FEMALE
          </div>
        );
      },
      dataIndex: 'african_female',
      key: 'african_female',
      width: 100,
      editable: true,
      align: 'center',
      render: (african_female, record) => african_female,
    },
    // {
    //   title: () => {
    //     return (
    //       <div align='top' className='select-parent-div'>
    //         <div className='special-table-header-text-center'>
    //           AFRICAN <br /> LGBTQ+
    //         </div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'african_lgbt',
    //   key: 'african_lgbt',
    //   width: 120,
    //   align: 'center',
    //   editable: true,
    //   render: (african_lgbt) => <div>{african_lgbt}</div>,
    // },

    {
      title: () => (
        <div className='special-table-header-text-center'>
          asian <br /> MALE
        </div>
      ),
      dataIndex: 'asian_male',
      key: 'asian_male',
      width: 100,
      editable: true,
      align: 'center',
      render: (asian_male, record) => asian_male,
    },
    {
      title: () => {
        return (
          <div className='special-table-header-text-center'>
            asian <br /> FEMALE
          </div>
        );
      },
      dataIndex: 'asian_female',
      key: 'asian_female',
      width: 100,
      editable: true,
      align: 'center',
      render: (asian_female, record) => asian_female,
    },
    // {
    //   title: () => {
    //     return (
    //       <div align='top' className='select-parent-div'>
    //         <div className='special-table-header-text-center'>
    //           asian <br /> LGBTQ+
    //         </div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'asian_lgbt',
    //   key: 'asian_lgbt',
    //   width: 120,
    //   align: 'center',
    //   editable: true,
    //   render: (asian_lgbt) => <div>{asian_lgbt}</div>,
    // },
    {
      title: () => (
        <div className='special-table-header-text-center'>
          coloured <br /> MALE
        </div>
      ),
      dataIndex: 'color_male',
      key: 'color_male',
      width: 100,
      align: 'center',
      editable: true,
      render: (color_male, record) => color_male,
    },
    {
      title: () => {
        return (
          <div className='special-table-header-text-center'>
            coloured <br /> FEMALE
          </div>
        );
      },
      dataIndex: 'color_female',
      key: 'color_female',
      width: 100,
      align: 'center',
      editable: true,
      render: (color_female, record) => color_female,
    },
    // {
    //   title: () => {
    //     return (
    //       <div align='top' className='select-parent-div'>
    //         <div className='special-table-header-text-center'>
    //           coloured <br /> LGBTQ+
    //         </div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'color_lgbt',
    //   key: 'color_lgbt',
    //   width: 120,
    //   align: 'center',
    //   editable: true,
    //   render: (color_lgbt) => <div>{color_lgbt}</div>,
    // },

    {
      title: () => (
        <div className='special-table-header-text-center'>
          white <br /> MALE
        </div>
      ),
      dataIndex: 'white_male',
      key: 'white_male',
      width: 100,
      editable: true,
      align: 'center',
      render: (white_male, record) => white_male,
    },
    {
      title: () => {
        return (
          <div className='special-table-header-text-center'>
            white <br /> FEMALE
          </div>
        );
      },
      dataIndex: 'white_female',
      key: 'white_female',
      width: 100,
      editable: true,
      align: 'center',
      render: (white_female, record) => white_female,
    },
    // {
    //   title: () => (
    //     <div className='special-table-header-text-center'>
    //       white <br /> LGBTQ+
    //     </div>
    //   ),
    //   dataIndex: 'white_lgbt',
    //   key: 'white_lgbt',
    //   width: 120,
    //   align: 'center',
    //   editable: true,
    //   render: (white_lgbt) => <div>{white_lgbt}</div>,
    // },
    {
      title: () => (
        <div className='special-table-header-text-center'>
          total <br />
          att.
        </div>
      ),
      dataIndex: 'total_attendence',
      key: 'total_attendence',
      width: 60,
      align: 'center',
      editable: true,
    },
    {
      title: () => (
        <div className='special-table-header-text-center'>
          Total <br />
          age
        </div>
      ),
      dataIndex: 'total_age',
      key: 'total_age',
      width: 60,
      align: 'center',
      editable: true,
    },
    {
      title: () => (
        <div className='special-table-header-text-center'>
          AVG. <br />
          age
        </div>
      ),
      dataIndex: 'average_age',
      key: 'average_age',
      width: 60,
      align: 'center',
      editable: true,
    },
    {
      title: () => (
        <div className='special-table-header-text-center'>Attach</div>
      ),
      dataIndex: 'images',
      key: 'images',
      width: 70,
      editable: true,
      align: 'center',
      render: (_, record) => {
        if (currentUpdateObj.id !== record.id) {
          return (
            <div
              className={`attach-parent-div  ${
                (isAddImsRevision || isUpdateImsRevision) && 'disable-icon'
              }`}
              onClick={() => {
                if (isAddImsRevision || isUpdateImsRevision) {
                  return;
                } else {
                  setCurrentUpdateObj(record);
                  setAttachPopupVisible(true);
                }
              }}
            >
              <AttachIcon
                className='rotate-icon activities-attach'
                width={16}
                fill='#231f20'
              />
              <div>{record?.images?.length || 0}</div>
            </div>
          );
        }

        return (
          <NonEditRowAttach
            images={record?.images || []}
            setImages={setImages}
            attachPopupVisible={attachPopupVisible}
            setAttachPopupVisible={setAttachPopupVisible}
            record={record}
            setCurrentUpdateObj={setCurrentUpdateObj}
            currentUpdateObj={currentUpdateObj}
            program_id={program_id}
            handleDeleteAttach={handleDeleteAttach}
            setLearnersData={setLearnersData}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: 'operation',
      align: 'center',
      width: 80,
      render: (_, record) => {
        const editable = isEditing(record);
        let key = record?.key || record?.id;
        return editable ? (
          editingLoading && editingKey === key ? (
            <SpinnerComponent fontSize={14} />
          ) : (
            <Space size={20} align='center'>
              <Popconfirm
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                placement='rightTop'
                overlayClassName='activities-popconfirm'
                title='Are you sure you want to save this learner?'
                onConfirm={() => save(record.key || record.id)}
                okText='Yes'
                cancelText='No'
              >
                <SaveIcon className='custom-icon' />
              </Popconfirm>
              {/* {!isUpdateImsRevision && ( */}
              <Popconfirm
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                title='Sure to cancel?'
                onConfirm={cancel}
              >
                <CrossIcon
                  style={{ height: '20px', width: '20px' }}
                  className='custom-icon'
                />
              </Popconfirm>
              {/* // )} */}
            </Space>
          )
        ) : (
          <div>
            <Space size={20} align='center'>
              {deletingLoading && record.id === deletingActivityId ? (
                <SpinnerComponent fontSize={16} />
              ) : (
                <>
                  {canEdit && (
                    <EditIcon
                      className={`custom-icon ${
                        (isAddImsRevision || isUpdateImsRevision) &&
                        'disable-icon'
                      }`}
                      disabled={editingKey !== ''}
                      onClick={() =>
                        !isAddImsRevision &&
                        !isUpdateImsRevision &&
                        edit({ ...record, isAdd: false })
                      }
                    />
                  )}
                  {!isAddImsRevision && !isUpdateImsRevision ? (
                    canDelete && (
                      <Popconfirm
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            'ant-layout-content layout-content site-layout-background custom-textarea'
                          )[0]
                        }
                        placement='rightTop'
                        title='Are you sure you want to delete this learner?'
                        onConfirm={() => deleteRevisionHandler(record)}
                      >
                        <DeleteIcon className={`custom-icon`} />
                      </Popconfirm>
                    )
                  ) : (
                    <DeleteIcon
                      className={`custom-icon ${
                        (isAddImsRevision || isUpdateImsRevision) &&
                        'disable-icon'
                      }`}
                    />
                  )}
                </>
              )}
            </Space>
          </div>
        );
      },
    },
  ];

  const handleAdd = (e) => {
    e.stopPropagation();
    setShowTable(true);
    handleUpdateStatus(true, false);
    setCurrentUpdateObj({});
    let key = Math.ceil(Math.random() * 2345678);
    const newData = {
      key,
      id: key,
      description: 'Mass Class',
      african_male: null,
      african_female: null,
      african_lgbt: null,
      asian_male: null,
      asian_female: null,
      asian_lgbt: null,
      color_male: null,
      color_female: null,
      color_lgbt: null,
      white_male: null,
      white_female: null,
      white_lgbt: null,
      total_age: null,
      images: null,
      isAdd: true,
      total_attendence: null,
      average_age: null,
    };
    setData((data) => [newData, ...data]);
    // setCount(1);k
    edit(newData);
    setImages([]);
    form.setFieldsValue({
      ...newData,
    });

    setEditingKey(key);
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        };
      },
    };
  });

  const handleFinish = (item) => {
    let params = {
      ...item,
      ...(images.length > 0 && { images }),
      program_id,
      debouncedCallApi,
    };
    if (isUpdateImsRevision) {
      handleUpdate({
        ...params,
        program_id,
        debouncedCallApi,
        id: massLearner[0].id,
      });
    } else {
      handleCreate(params);
    }
  };

  return (
    <Row className='revisions-parent-row'>
      <Col span={24} className='heading-col'>
        <Row align='middle'>
          <Col>
            <div className='revisions-heading-div'>
              <div onClick={() => setShowTable(!showTable)}>
                {showTable ? (
                  <UpCircleOutlined className='revision-up-down-icon' />
                ) : (
                  <DownCircleOutlined className='revision-up-down-icon' />
                )}
              </div>
              <div>
                <span className='heading-span'>
                  Learners{' '}
                  {allImsRevisions.length > 0 && `(${allImsRevisions.length})`}
                </span>
              </div>
            </div>
          </Col>
          {canEdit && massLearner.length === 0 && (
            <Col>
              <Button
                icon={<PlusOutlined />}
                className='add-revisions-btn'
                size='small'
                onClick={handleAdd}
                disabled={
                  isAddImsRevision || fetchingLoading || isUpdateImsRevision
                }
              >
                Learner
              </Button>
            </Col>
          )}
        </Row>
      </Col>

      {showTable && (
        <Col span={24}>
          <Form form={form} component={false} onFinish={handleFinish}>
            <Table
              scroll={{ x: true }}
              className='special-table ims-doc-table revisions-table classes-learners-table'
              components={{
                body: {
                  cell: (props) => (
                    <RevisionsEditableCell
                      cell={props}
                      images={images}
                      setImages={setImages}
                      attachPopupVisible={attachPopupVisible}
                      setAttachPopupVisible={setAttachPopupVisible}
                      currentUpdateObj={currentUpdateObj}
                      handleOnchange={handleOnchange}
                    />
                  ),
                },
              }}
              rowKey={(record) => record.id}
              loading={fetchingLoading}
              rowClassName='editable-row'
              dataSource={data}
              columns={mergedColumns}
              pagination={false}
            />
          </Form>
        </Col>
      )}
    </Row>
  );
}

export default MassLearnerTable;
