import { Col, Row } from 'antd';
import countriesList from '../countries.json';

export const userProjectPermissions = (userType) => {
  let sitesOption = {
    ...(userType === 'system_manager' && {
      add_edit: false,
    }),
    view: false,
    ...(userType === 'system_manager' && {
      delete: false,
      export: false,
      import: false,
    }),
  };

  let ClassesOption = {
    ...(userType !== 'learner' && {
      add_edit: false,
    }),
    view: false,
    ...(userType === 'system_manager' && {
      delete: false,
      export: false,
    }),
    ...(userType !== 'learner' && {
      import: false,
    }),
  };
  return {
    learners: {
      view: false,
      ...(userType === 'system_manager' && {
        export: false,
      }),
    },
    coaches: {
      view: false,
      ...(userType === 'system_manager' && {
        export: false,
      }),
    },
    sites: { ...sitesOption },
    classes: { ...ClassesOption },
  };
};

export const initialEditingRowData = {
  popup_notifications: true,
  email_notifications: true,
};

const permissionsCol = (permissions) => (
  <Col span={24} className='name-col'>
    <span>
      {Object.entries(permissions).map(([key, value]) => {
        let filtered = Object.entries(value).filter((el) => el[1]);
        if (filtered.length === 0) {
          return '';
        }
        let str = filtered
          .map((ele) => ele[0])
          .join(', ')
          .replace('_', '/');

        return (
          <div key={key}>
            {key.charAt(0).toUpperCase() + key.slice(1)} - {str}
          </div>
        );
      })}
    </span>
  </Col>
);

export const handlePermissionsCol = ({ permissions }) => {
  const { learners, coaches, sites, classes } = permissions;
  let updatedOrdered = { learners, coaches, sites, classes };

  return (
    <Row gutter={[0, 0]} className='permission-col'>
      {permissionsCol(updatedOrdered)}
    </Row>
  );
};

export const systemUsersOptions = [
  { key: 'super_admin' },
  { key: 'system_manager' },
];

export const genderOptions = [{ key: 'female' }, { key: 'male' }];

export const raceOptions = [
  { key: 'african' },
  { key: 'asian' },
  { key: 'coloured' },
  { key: 'white' },
];

export const statusOptions = [{ key: 'active' }, { key: 'inactive' }];

export const martialOptions = [{ key: 'single' }, { key: 'married' }];

export const drivingOptions = [{ key: 'yes' }, { key: 'no' }];

export const nationalityOptions = [
  ...new Map(
    countriesList.filter(Boolean).map((item) => [item['key'], item])
  ).values(),
].sort((a, b) => a.key.localeCompare(b.key));

let AddViewDelete = { add_edit: false, view: false, delete: false };
let exportImport = { export: false, import: false };

const systemPermissions = {
  system_users_manager: { ...AddViewDelete, ...exportImport },
  coach_users_manager: { ...AddViewDelete, ...exportImport },
  learner_users_manager: { ...AddViewDelete, ...exportImport },
  templates_manager: { add_edit: false, view: false },
  programme_types: { ...AddViewDelete },
  intervention_types: { ...AddViewDelete },
  npo_regions: { ...AddViewDelete },
  npo_statuses: { ...AddViewDelete },
  site_types: { ...AddViewDelete },
  site_statuses: { ...AddViewDelete },
  coach_types: { add_edit: false, view: false },
  coach_statuses: { ...AddViewDelete },
  learner_types: { ...AddViewDelete },
  learner_statuses: { ...AddViewDelete },
  learner_camps: { ...AddViewDelete },
};

export const systemUserPermissions = (userType) => {
  if (userType === 'system_manager') {
    return systemPermissions;
  } else if (userType === 'coach') {
    return {
      learner_users_manager: { ...AddViewDelete, import: false },
    };
  } else return false;
};

export const hideKeys = [
  'nationality',
  'id_number',
  'dob',
  'contact_no',
  'site_id',
  'kinship_attributes_name',
  'kinship_attributes_surname',
  'kinship_attributes_contact',
  'home_address',
  'postal_address',
];
