import React, { useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Col, Row } from 'antd';
import { BasicTooltip } from '@nivo/tooltip';

const LabelRow = ({ type }) => {
  if (type === 'modular') {
    return (
      <>
        <div className='dot-parent-div' title='Modular Actual'>
          <span className='modular-actual'></span>Modular Actual
        </div>
        <div className='dot-parent-div' title='Modular Target'>
          <span className='modular-target'></span>
          Modular Target
        </div>
      </>
    );
  }
  if (type === 'preAndPost') {
    return (
      <>
        <div className='dot-parent-div' title='Modular Actual'>
          <span className='modular-actual'></span>Pre Total Attend
        </div>
        <div className='dot-parent-div' title='Modular Target'>
          <span className='modular-target'></span>
          Post Total Attend
        </div>
      </>
    );
  }
  if (type === 'preAndPostPer') {
    return (
      <>
        <div className='dot-parent-div' title='Pre MKT'>
          <span className='modular-actual'></span>Pre MKT
        </div>
        <div className='dot-parent-div' title='Post MKT'>
          <span className='modular-target'></span>
          Post MKT
        </div>
        <div className='dot-parent-div' title='Pre SES'>
          <span className='modular-actual'></span>Pre SES
        </div>
        <div className='dot-parent-div' title='Post SES'>
          <span className='modular-target'></span>
          Post SES
        </div>
        <div className='dot-parent-div' title='Pre LEQ'>
          <span className='modular-actual'></span>Pre LEQ
        </div>
        <div className='dot-parent-div' title='Post LEQ'>
          <span className='modular-target'></span>
          Post LEQ
        </div>
      </>
    );
  }
  if (type === 'attByRace') {
    return (
      <>
        <div className='dot-parent-div' title='Total African'>
          <span className='modular-actual'></span>Total African
        </div>
        <div className='dot-parent-div' title='Total Coloured'>
          <span className='mass-actual'></span>
          Total Coloured
        </div>
        <div className='dot-parent-div' title='Total Asian'>
          <span className='modular-target'></span>Total Asian
        </div>
        <div className='dot-parent-div' title='Total White'>
          <span className='mass-target'></span>
          Total White
        </div>
      </>
    );
  }
  if (type === 'attByGender') {
    return (
      <>
        <div className='dot-parent-div' title='Male Total'>
          <span className='modular-actual'></span>Male Total
        </div>
        <div className='dot-parent-div' title='Female Total'>
          <span className='modular-target'></span>
          Female Total
        </div>
        {/* <div className='dot-parent-div' title='Other Total'>
          <span className='mass-actual'></span>Other Total
        </div> */}
      </>
    );
  }
  if (type === 'attByAgeGroup') {
    return (
      <>
        <div className='dot-parent-div' title='Total Children'>
          <span className='modular-actual'></span>Total Children
        </div>
        <div className='dot-parent-div' title='Total Youth'>
          <span className='modular-target'></span>
          Total Youth
        </div>
        <div className='dot-parent-div' title='Total Adult'>
          <span className='mass-actual'></span>Total Adult
        </div>
      </>
    );
  }
};

const formatBottomAxisLabel = (label) => {
  // Split the label into two lines
  console.log('label', label);
  const lines = label.split(' ');
  console.log('lines', lines);
  // Return the label with line breaks
  return lines.join('\n');
};

const SixColumnsFullWidthGraph = ({
  data,
  label,
  LeftLegend,
  tickRotation,
  type,
  keys,
  colors,
  screenWidth,
}) => {
  const ValueOutside = ({ bars }) => {
    return bars.map((bar) => {
      const { key, width, height, x, y, data } = bar;
      const { value } = data;
      let updatedValue = value
        ? value > 999
          ? `${(value / 1000).toFixed(1)}K`
          : Math.trunc(value)
        : value;

      return (
        <g transform={`translate(${x}, ${y})`} key={key}>
          {/* <rect
          width={width}
          height={height}
          fill={color}
          strokeWidth='0'
          stroke='black'
        /> */}

          <text
            x={width / 2}
            y={-10} // the important bit!!
            textAnchor='middle'
            dominantBaseline='central'
            style={{
              fontSize: 12,
              pointerEvents: 'none',
              fill: 'black',
              opacity: 0.75,
            }}
          >
            {updatedValue}
          </text>
        </g>
        // <g key={key} transform={`translate(${x - 25}, ${y})`}>
        //   <text
        //     transform={`translate(${width}, ${height / 10 - 10})`}
        //     textAnchor='middle'
        //     fontSize='11px'
        //     fill='#757575'
        //   >
        //     {updatedValue}
        //   </text>
        // </g>
      );
    });
  };

  const CustomLeftLabel = (data) => {
    const { x, y, value } = data;
    return (
      <g>
        <text x={x - 25} y={y + 4} fontSize='12px' fill='#757575'>
          {value > 999 ? `${value / 1000}K` : value}
        </text>
      </g>
    );
  };

  //   console.log('data', data);

  const CustomBottomLabel = ({
    textAnchor,
    textBaseline,
    textX,
    textY,
    value,
    x,
    y,
  }) => {
    // console.log('CustomBottomLabel', value);

    return (
      <g transform={`translate(${x},${y})`}>
        {value.split('-').map((el, i) => {
          return (
            <text
              alignmentBaseline={textBaseline}
              textAnchor={textAnchor}
              transform={`translate(${textX},${textY + i * 12})`}
              fontSize='10px'
            >
              <tspan>{el}</tspan>
            </text>
          );
        })}
      </g>
    );
  };

  const getWidth = (length) => {
    if (screenWidth > 1599) {
      if (length < 10) {
        return '100%';
      }
      return length * 140;
    } else if (screenWidth > 1199) {
      if (length < 6) {
        return '100%';
      }
      return length * 140;
    } else if (screenWidth > 991) {
      if (length < 6) {
        return '100%';
      }
      return length * 140;
    } else {
      if (length < 5) {
        return '100%';
      }
      return length * 150;
    }
  };

  let width = getWidth(data.length);
  let hasScroll = width !== '100%';

  const getId = (str) => {
    let words = str.split('_');

    if (
      (str.includes('pre') || str.includes('post')) &&
      !str.includes('total')
    ) {
      let [a, b] = words;
      words = [b, a.toUpperCase()];
    }
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const BarTooltip = (props) => {
    let { value, color, id } = props;

    const memoizedValue = useMemo(() => getId(id), [id]);

    return (
      <BasicTooltip
        id={memoizedValue}
        value={value}
        color={color}
        enableChip={false}
      />
    );
  };

  return (
    <>
      <div className='production-by-client-div'>
        <span className='graph-text-span' title={label}>
          {label}
        </span>
      </div>
      <div className='ordered-compl-label-with-text'>
        <LabelRow type={type} />
      </div>
      <Row>
        <Col
          style={{
            maxWidth: '16px',
          }}
          className='left-axis-legend-col-no-padding'
        >
          <div>{LeftLegend}</div>
        </Col>
        <Col span={23}>
          <div
            style={{
              maxWidth: '100%',
              overflowX: hasScroll && 'scroll',
              height: '200px',
              overflowY: hasScroll && 'hidden',
            }}
            className={hasScroll && 'graphs-parent-div'}
          >
            <div style={{ width, height: '100%' }} className='graph-parent-div'>
              <ResponsiveBar
                data={data}
                keys={keys}
                indexBy='label'
                margin={{
                  top: 20,
                  right: 0,
                  bottom: 60,
                  left: 40,
                }}
                colors={colors}
                padding={0.4}
                groupMode='grouped'
                layout='vertical'
                borderColor='inherit:darker(1.6)'
                enableLabel={false}
                enableGridX={false}
                enableGridY={true}
                theme={{
                  axis: {
                    ticks: {
                      line: {
                        stroke: null,
                      },
                    },
                    domain: {
                      line: {
                        stroke: '',
                        strokeWidth: 2,
                      },
                    },
                    legend: {
                      text: {
                        fontWeight: '600',
                        // letterSpacing: "2px",
                        color: '#383838',
                        fill: '#383838',
                        fontSize: '13px',
                        fontFamily: 'Open Sans',
                        lineHeight: '16px',
                      },
                    },
                  },

                  grid: {
                    line: {
                      stroke: `rgb(231 232 236 / 80%)`,
                      strokeWidth: 2,
                      strokeDasharray: '2, 4',
                    },
                  },
                }}
                labelTextColor='white'
                layers={['grid', 'axes', 'bars', 'markers', ValueOutside]}
                legends={[
                  {
                    dataFrom: 'keys',
                    // data: keys.map((id, index) => ({
                    //   color: colorsArray[index],
                    //   id,
                    //   //   label: id === "id" ? 1 : 2,
                    //   label: 1,
                    // })),
                    anchor: 'bottom',
                    direction: 'rows',
                    translateY: 80,
                    itemWidth: 50,
                    itemHeight: 20,
                    itemDirection: 'top-to-bottom',
                    symbolSize: 20,
                  },
                ]}
                tooltip={BarTooltip}
                axisLeft={{
                  format: (v) => {
                    return v.length > 22 ? (
                      <tspan>
                        {v.substring(0, 22) + '...'}
                        <title>{v}</title>
                      </tspan>
                    ) : (
                      v
                    );
                  },
                  tickValues: 1,
                  tickSize: 1,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: -10,
                  renderTick: CustomLeftLabel,
                }}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  // legend: BottomLegend,
                  legendPosition: 'end',
                  // legendOffset: 50,
                  renderTick: CustomBottomLabel,
                  // tickValues: 6,
                  // format: (value) => formatBottomAxisLabel(value),
                  // renderTick: (props) => {
                  //   console.log('props', props);
                  //   const { value } = props;
                  //   return (
                  //     <g transform='translate(0, 10)'>
                  //       <text
                  //         textAnchor='middle'
                  //         style={{
                  //           // fontSize: '12px',
                  //           // fill: 'currentColor',
                  //           // lineHeight: '1.2em',
                  //           // whiteSpace: 'pre-wrap',
                  //           // textAlign: 'center',
                  //         }}
                  //       >
                  //         {value?.split(' ').join('\n')}
                  //       </text>
                  //     </g>
                  //   );
                  // },
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SixColumnsFullWidthGraph;
