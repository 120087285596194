import { useState } from 'react';
import { message as antdAlert } from 'antd';
import axios from 'axios';
import { baseURL } from '../../globalUtils/axiosConfig';

const UseForgotPassword = (Form) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleEmail = async ({ email }) => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${baseURL}/api/v1/users/forgot_password`,
        {
          params: {
            email,
          },
        }
      );

      const { success, message } = response.data;

      if (success) {
        antdAlert.success(message);
        setLoading(false);
        form?.resetFields();
      } else {
        antdAlert.error(message);
        setLoading(false);
      }
    } catch (error) {
      antdAlert.error('Something went wrong!');
      setLoading(false);
    }
  };

  const onFinish = (values) => {
    let { email } = values;
    email = email.trim();

    handleEmail({ email });
  };

  const onFinishFailed = (errorInfo) => {};

  return [onFinish, onFinishFailed, loading, form];
};

export default UseForgotPassword;
