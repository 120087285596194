import React from 'react';
import { Checkbox, Row, Col } from 'antd';
import { useSelector } from 'react-redux';

const PermissionsSection = ({
  state,
  upperPermissions,
  setState,
  setUpperPermissions,
  readOnly,
  userType,
}) => {
  const { user } = useSelector((state) => state.authReducer);

  const onChange = (checked, label, key, prevData, setHandler) => {
    if (!prevData?.view && checked && ['export', 'delete'].includes(key)) {
      return;
    }

    if (prevData?.add_edit && !checked && key === 'view') {
      return;
    }

    if (key === 'add_edit' && checked) {
      let obj = {
        [label]: {
          ...prevData,
          [key]: checked,
          view: true,
        },
      };
      return setHandler((prev) => ({
        ...prev,
        ...obj,
      }));
    }

    if (key === 'view' && !checked && ['npo_setup', 'ims'].includes(label)) {
      let obj = {
        [label]: {
          ...prevData,
          [key]: checked,
          export: false,
          delete: false,
        },
      };
      return setHandler((prev) => ({
        ...prev,
        ...obj,
      }));
    }

    let obj = {
      [label]: {
        ...prevData,
        [key]: checked,
      },
    };
    setHandler((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  const onChangeUpper = (checked, label, key, prevData, setHandler) => {
    if (!prevData?.view && checked && ['export', 'delete'].includes(key)) {
      return;
    }

    if (prevData?.add_edit && !checked && key === 'view') {
      return;
    }

    if (key === 'add_edit' && checked) {
      let obj = {
        [label]: {
          ...prevData,
          [key]: checked,
          view: true,
        },
      };

      if (label === 'npo_setup') {
        return setHandler((prev) => ({
          ...prev,
          ...obj,
        }));
      } else
        return setHandler((prev) => ({
          ...prev,
          report_summary: {
            ...prev.report_summary,
            ...obj,
          },
        }));
    }

    if (
      key === 'view' &&
      !checked &&
      ['npo_setup', 'report_summary'].includes(label)
    ) {
      let obj = {
        [label]: {
          ...prevData,
          [key]: checked,
          export: false,
          delete: false,
        },
      };

      if (label === 'npo_setup') {
        return setHandler((prev) => ({
          ...prev,
          ...obj,
        }));
      } else
        return setHandler((prev) => ({
          ...prev,
          report_summary: {
            ...prev.report_summary,
            ...obj,
          },
        }));

      // return setHandler((prev) => ({
      //   ...prev,
      //   ...obj,
      // }));
    }

    let obj = {
      [label]: {
        ...prevData,
        [key]: checked,
      },
    };
    if (label === 'npo_setup') {
      return setHandler((prev) => ({
        ...prev,
        ...obj,
      }));
    } else
      return setHandler((prev) => ({
        ...prev,
        report_summary: {
          ...prev.report_summary,
          ...obj,
        },
      }));
    // setHandler((prev) => ({
    //   ...prev,
    //   ...obj,
    // }));
  };

  const handleLabel = (labelText) => {
    let [first, ...rest] = labelText.split('_');
    if (first.includes('npo')) {
      first = 'NPO';
    }
    return [first, ...rest].join(' ');
  };

  const checkText = (labelText) => {
    let [first, ...rest] = labelText.split('_');
    if (first.includes('npo')) {
      first = 'NPO';
    }
    return [first, ...rest].join(' / ');
  };

  const NpoSetupCol = () => {
    return Object?.entries(upperPermissions)?.map((data) => {
      const [label, checkBoxData] = data;
      if (label === 'npo_setup') {
        return (
          <Col
            key={label}
            className='general-permissions-section upper-row border multiple-col npo-col'
          >
            <div className='settings-div'>
              <span className='uppercase-label'>{handleLabel(label)}</span>
            </div>
            <div className='label-div'> &nbsp;</div>
            {Object?.entries(checkBoxData)?.map((data2) => {
              const [key, value] = data2;
              return (
                <div className='checkbox-div ' key={key}>
                  <Checkbox
                    key={key}
                    checked={value}
                    disabled={
                      !readOnly ||
                      (!checkBoxData.view && ['export', 'delete'].includes(key))
                    }
                    onChange={({ target }) =>
                      onChangeUpper(
                        target.checked,
                        label,
                        key,
                        checkBoxData,
                        setUpperPermissions
                      )
                    }
                  >
                    {checkText(key)}
                  </Checkbox>
                </div>
              );
            })}
          </Col>
        );
      }
    });
  };

  const upperRow = Object?.entries(upperPermissions)?.map((data) => {
    const [label, checkBoxData] = data;
    if (label === 'report_summary') {
      return Object?.entries(checkBoxData)?.map((data) => {
        const [label1, checkBoxData1] = data;
        return (
          <Col
            key={label1}
            className='general-permissions-section upper-row multiple-col'
          >
            <div className='label-div'>
              <span className='uppercase-label'>{handleLabel(label1)}</span>
            </div>
            {Object?.entries(checkBoxData1)?.map((data2) => {
              const [key, value] = data2;
              return (
                <div className='checkbox-div ' key={key}>
                  <Checkbox
                    key={key}
                    checked={value}
                    disabled={
                      !readOnly ||
                      (!checkBoxData1.view &&
                        ['export', 'delete'].includes(key))
                    }
                    onChange={({ target }) =>
                      onChangeUpper(
                        target.checked,
                        label1,
                        key,
                        checkBoxData1,
                        setUpperPermissions
                      )
                    }
                  >
                    {checkText(key)}
                  </Checkbox>
                </div>
              );
            })}
          </Col>
        );
      });
    }
  });

  let isCoach = user.user_type === 'coach' || userType === 'coach';

  const jsx = Object.entries(state).map((data) => {
    const [label, checkBoxData] = data;
    return (
      <Col
        key={label}
        className='general-permissions-section multiple-col'
        style={{
          width: userType === 'coach' && '100%',
        }}
      >
        <div style={{ paddingLeft: '10px' }}>
          <div
            className='label-div'
            style={{
              height: '35px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div>
              <span
                style={{
                  textTransform: 'capitalize',
                  fontWeight: 600,
                  fontSize: '13px',
                  lineHeight: '16px',
                  paddingLeft: '0px',
                }}
              >
                {handleLabel(label)}
              </span>
            </div>
          </div>
        </div>
        {Object.entries(checkBoxData).map((data2) => {
          const [key, value] = data2;
          let keyText = key;
          if (label === 'coach_types' && key === 'add_edit') {
            keyText = 'edit';
          }
          if (
            label === 'learner_users_manager' &&
            key === 'add_edit' &&
            isCoach
          ) {
            keyText = 'add';
          }

          return (
            <div className='checkbox-div' key={key}>
              <Checkbox
                key={key}
                disabled={!readOnly}
                checked={value}
                onChange={({ target }) =>
                  onChange(target.checked, label, key, checkBoxData, setState)
                }
              >
                {checkText(keyText)}
              </Checkbox>
            </div>
          );
        })}
      </Col>
    );
  });

  return (
    <>
      <Col className='parent-div'>
        <Row gutter={[20, 30]}>
          <Col
            className='general-permission-upper-div'
            xxl={22}
            xl={22}
            lg={22}
            md={24}
          >
            <Row gutter={[20, 30]}>
              <Col className='border' md={17} lg={17} xl={16} xxl={12}>
                <div className='settings-div'>Reports Summary</div>
                <Row justify='start' className='permission-row-div'>
                  {upperRow}
                </Row>
              </Col>
              {upperPermissions.hasOwnProperty('npo_setup') &&
                userType === 'system_manager' && <NpoSetupCol />}
            </Row>
          </Col>
          {state && userType !== 'learner' && (
            <Col
              style={{
                maxWidth: userType === 'coach' && '160px',
              }}
              className='permissions  setting-permissions'
              xxl={22}
              xl={22}
              lg={22}
              md={24}
            >
              <div className='settings-div'>Settings</div>
              <Row classname='permission-row-div' gutter={[0, 25]}>
                {jsx}
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    </>
  );
};

export default PermissionsSection;
