import axios from "axios";
import { message as AntdAlert } from "antd";
import { put } from "redux-saga/effects";
import * as actions from "../actions";
import { baseURL } from "../../../../globalUtils/axiosConfig";
import { errorHandler } from "../../../../components/unauthorizeHandler";

export function* handleFetchDocumentField({ data }) {
  try {
    const response = yield axios.get(`${baseURL}/api/v1/document_fields`);
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchDocumentFieldSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchDocumentFieldError());
    AntdAlert.error("Something went wrong!");
    console.log(error);
  }
}

export function* handleUpdateDocumentField({ data }) {
  const { name, id } = data;
  try {
    const response = yield axios.patch(
      `${baseURL}/api/v1/document_fields/${id}`,
      {
        name,
      }
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateDocumentFieldSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateDocumentFieldError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateDocumentFieldError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}
