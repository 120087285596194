import React, { useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Col, Row } from 'antd';
import { BasicTooltip } from '@nivo/tooltip';

// const keys = ['modularActual', 'modularTarget', 'massActual', 'massTarget'];
const colors = ['#FFC732', '#E31C21', '#E56C37', '#D74550'];

// const massKeys = ['massActual', 'massTarget'];
// const massColors = ['#FFC732', '#E31C21', '#E56C37', '#D74550'];

const LabelRow = ({ onlyMass, label }) => {
  if (label === 'Programme Attendance Actual') {
    return (
      <div className='ordered-compl-label-with-text'>
        <div className='dot-parent-div' title='Modular Actual'>
          <span className='modular-actual'></span>Mod. Actual
        </div>
        <div className='dot-parent-div' title='Mass Actual'>
          <span className='mass-actual'></span>Mass Actual
        </div>
      </div>
    );
  }

  return (
    <div className='ordered-compl-label-with-text'>
      {!onlyMass && (
        <>
          <div className='dot-parent-div' title='Modular Actual'>
            <span className='modular-actual'></span>Mod. Actual
          </div>
          <div className='dot-parent-div' title='Modular Target'>
            <span className='modular-target'></span>
            Mod. Target
          </div>
        </>
      )}
      <div className='dot-parent-div' title='Mass Actual'>
        <span className='mass-actual'></span>Mass Actual
      </div>
      <div className='dot-parent-div' title='Mass Target'>
        <span className='mass-target'></span>
        Mass Target
      </div>
    </div>
  );
};

const BarComponent = ({ x, y, width, height, color, label }) => {
  return (
    <g transform={`translate(${x}, ${y})`}>
      <rect
        width={width}
        height={height}
        fill={color}
        strokeWidth='0'
        stroke='black'
      />

      <text
        x={width / 2}
        y={-15} // the important bit!!
        textAnchor='middle'
        dominantBaseline='central'
        style={{
          fontSize: 12,
          pointerEvents: 'none',
          fill: 'black',
          opacity: 0.75,
        }}
      >
        {label}
      </text>
    </g>
  );
};

const formatBottomAxisLabel = (label) => {
  // Split the label into two lines
  // console.log('label', label);
  const lines = label.split(' ');
  // console.log('lines', lines);
  // Return the label with line breaks
  return lines.join('\n');
};

// massActual
// massTarget

const VerticalGroupedGraph = ({
  data,
  label,
  BottomLegend,
  tickRotation,
  onlyMass,
  screenWidth,
  keys,
}) => {
  const ValueOutside = ({ bars }) => {
    return bars.map((bar) => {
      const { key, width, height, x, y, data, color } = bar;

      const { value } = data;

      let updatedValue = value
        ? value > 999
          ? `${(value / 1000).toFixed(1)}K`
          : Math.trunc(value)
        : value;

      return (
        <g transform={`translate(${x}, ${y})`} key={key}>
          {/* <rect
            width={width}
            height={height}
            fill={color}
            strokeWidth='0'
            stroke='black'
          /> */}

          <text
            x={width / 2}
            y={-15} // the important bit!!
            textAnchor='middle'
            dominantBaseline='central'
            style={{
              fontSize: 11,
              pointerEvents: 'none',
              fill: 'black',
              opacity: 0.75,
            }}
          >
            {updatedValue}
          </text>
        </g>
        // <g key={key} transform={`translate(${x - 15}, ${y})`}>
        //   <text
        //     transform={`translate(${width}, ${height / 10 - 20})`}
        //     textAnchor='middle'
        //     fontSize='11px'
        //     fill='#757575'
        //   >
        //     {updatedValue}
        //   </text>
        // </g>
      );
    });
  };

  const CustomLeftLabel = (data) => {
    const { x, y, value } = data;
    return (
      <g>
        <text x={x - 25} y={y + 4} fontSize='12px' fill='#757575'>
          {value > 999 ? `${value / 1000}K` : value}
        </text>
      </g>
    );
  };

  // const getHeight = (length) => {
  //   if (length < 6) {
  //     return '500px';
  //   }
  //   return length * 100;
  // };

  if (onlyMass) {
    // console.log('data', data)
  }

  const HorizontalTick = ({ textAnchor, textBaseline, value, x, y }) => {
    const MAX_LINE_LENGTH = 13;
    const MAX_LINES = 2;
    const LENGTH_OF_ELLIPSIS = 2;
    const TRIM_LENGTH = MAX_LINE_LENGTH * MAX_LINES - LENGTH_OF_ELLIPSIS;
    const trimWordsOverLength = new RegExp(`^(.{${TRIM_LENGTH}}[^\\w]*).*`);
    // console.log('TRIM_LENGTH, trimWordsOverLength', TRIM_LENGTH, trimWordsOverLength)
    const groupWordsByLength = new RegExp(
      `([^\\s].{0,${MAX_LINE_LENGTH}}(?=[\\s\\W]|$))`,
      'gm'
    );

    const splitValues = value
      .replace(trimWordsOverLength, '')
      .match(groupWordsByLength)
      .slice(0, 1)
      .map((val, i) => (
        <tspan
          key={val}
          dy={12 * i}
          x={-10}
          style={{ fontFamily: 'sans-serif', fontSize: '11px' }}
        >
          {val}
        </tspan>
      ));
    console.log('splitValues', splitValues);
    return (
      <g transform={`translate(${x},${y}) rotate(-20)`}>
        <text alignmentBaseline={textBaseline} textAnchor={textAnchor}>
          {splitValues}
        </text>
      </g>
    );
  };

  const formatLabel = (label) => {
    const maxLabelLength = 8; // Maximum length of the label before adding ellipsis

    if (label.length > maxLabelLength) {
      return label.substring(0, maxLabelLength) + '...';
    }
    return label;
  };

  const getWidth = (length) => {
    if (screenWidth > 1599) {
      if (length < 3) {
        return '100%';
      }
      return length * 130;
    } else if (screenWidth > 1199) {
      if (length < 4) {
        return '100%';
      }
      return length * 130;
    } else if (screenWidth > 991) {
      if (length < 3) {
        return '100%';
      }
      return length * 140;
    } else {
      if (length < 3) {
        return '100%';
      }
      return length * 140;
    }
  };

  let width = getWidth(data.length);
  let hasScroll = width !== '100%';

  const getId = (str) => {
    let words = str.split(/(?=[A-Z])/);
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const BarTooltip = (props) => {
    let { value, color, id } = props;

    const memoizedValue = useMemo(() => getId(id), [id]);

    return (
      <BasicTooltip
        id={memoizedValue}
        value={value}
        color={color}
        enableChip={false}
      />
    );
  };

  return (
    <>
      <div className='production-by-client-div'>
        <span className='graph-text-span' title={label}>
          {label}
        </span>
      </div>
      <LabelRow onlyMass={onlyMass} label={label} />
      <Row>
        <Col span={1} className='left-axis-legend-col'>
          <div>Mod. Actual. Mod. Target</div>
        </Col>
        <Col span={23}>
          <div
            style={{
              maxWidth: '100%',
              overflowX: hasScroll && 'scroll',
              height: '280px',
              overflowY: hasScroll && 'hidden',
            }}
            className={hasScroll && 'graphs-parent-div'}
          >
            <div style={{ width, height: '100%' }}>
              <ResponsiveBar
                data={data}
                keys={keys}
                indexBy='label'
                margin={{
                  top: 20,
                  right: 0,
                  bottom: 80,
                  left: 40,
                }}
                // colors={!onlyMass ? colors : massColors}
                colors={colors}
                // barComponent={BarComponent}
                // padding={!onlyMass ? 0.4 : 0.2}
                padding={0.2}
                groupMode='grouped'
                layout='vertical'
                borderColor='inherit:darker(1.6)'
                enableLabel={false}
                enableGridX={false}
                enableGridY={true}
                theme={{
                  axis: {
                    ticks: {
                      line: {
                        stroke: null,
                      },
                    },
                    domain: {
                      line: {
                        stroke: '',
                        strokeWidth: 2,
                      },
                    },
                    legend: {
                      text: {
                        fontWeight: '600',
                        // letterSpacing: "2px",
                        color: '#383838',
                        fill: '#383838',
                        fontSize: '13px',
                        fontFamily: 'Open Sans',
                        lineHeight: '16px',
                      },
                    },
                  },

                  grid: {
                    line: {
                      stroke: `rgb(231 232 236 / 80%)`,
                      strokeWidth: 2,
                      strokeDasharray: '2, 4',
                    },
                  },
                }}
                labelTextColor='white'
                layers={['grid', 'axes', 'bars', 'markers', ValueOutside]}
                legends={[
                  {
                    dataFrom: 'keys',
                    // data: keys.map((id, index) => ({
                    //   color: colorsArray[index],
                    //   id,
                    //   //   label: id === "id" ? 1 : 2,
                    //   label: 1,
                    // })),
                    anchor: 'bottom',
                    direction: 'row',
                    translateY: 80,
                    itemWidth: 50,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    symbolSize: 20,
                  },
                ]}
                axisLeft={{
                  format: (v) => {
                    return v.length > 22 ? (
                      <tspan>
                        {v.substring(0, 22) + '...'}
                        <title>{v}</title>
                      </tspan>
                    ) : (
                      v
                    );
                  },
                  tickValues: 3,
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: -100,
                  renderTick: CustomLeftLabel,
                }}
                tooltip={BarTooltip}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation,
                  legend: BottomLegend,
                  legendPosition: 'middle',
                  legendOffset: 60,
                  // renderTick: HorizontalTick,
                  tickValues: 6,
                  format: (v) => {
                    if (tickRotation !== 0) {
                      return v.length > 9 ? (
                        <tspan>
                          {v.substring(0, 9) + '...'}
                          <title>{v}</title>
                        </tspan>
                      ) : (
                        v
                      );
                    }

                    let arr = v.split(' ');

                    return arr.map((el, i) =>
                      el.length > 6 ? (
                        <tspan dy={12 * i} x={8}>
                          {el.substring(0, 6) + '...'}
                          <title>{v}</title>
                        </tspan>
                      ) : (
                        el
                      )
                    );
                  },
                  // format: (value) => formatBottomAxisLabel(value),
                  // renderTick: (props) => {
                  //   console.log('props', props);
                  //   const { value } = props;
                  //   return (
                  //     <g transform='translate(0, 10)'>
                  //       <text
                  //         textAnchor='middle'
                  //         style={{
                  //           // fontSize: '12px',
                  //           // fill: 'currentColor',
                  //           // lineHeight: '1.2em',
                  //           // whiteSpace: 'pre-wrap',
                  //           // textAlign: 'center',
                  //         }}
                  //       >
                  //         {value?.split(' ').join('\n')}
                  //       </text>
                  //     </g>
                  //   );
                  // },
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(VerticalGroupedGraph);
