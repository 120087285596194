import React, { useEffect, useState } from 'react';
import { Col, Input, Row, Select, Form, DatePicker } from 'antd';
import { ReactComponent as SaveIcon } from '../../../assets/save-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { createNpo, setNpoDetailsIsEdit, updateNpo } from '../redux/actions';
import SpinnerComponent from '../../../components/spinner';
// import { isValidNumber } from '../../../globalUtils/axiosConfig';
import moment from 'moment';
import AttachmentsSection from './attachmentsSection';
import { useLocation } from 'react-router-dom';
// import { fetchUserDetailsUtils } from '../../UserManager/redux/actions';

const NpoDetailsSection = ({ isNpoUpdate, canUpdate }) => {
  const [documents, setDocuments] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [utils, setUtils] = useState({});
  const [currentNpo, setCurrentNpo] = useState({});

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Option } = Select;

  const { createNpoLoading, currentNpoObj, npoDetailsIsEdit, utilsData } =
    useSelector((state) => state.npoSetupReducer);

  const { allNpoStatuses } = useSelector((state) => state.npoStatusReducer);
  const { allNpoRegions } = useSelector((state) => state.npoRegionsReducer);

  // useEffect(() => {
  //   dispatch(fetchUserDetailsUtils());
  // }, []);

  useEffect(() => {
    setCurrentNpo(currentNpoObj);

    const { onboard_date, attachments_urls } = currentNpoObj;
    form.setFieldsValue({
      ...currentNpoObj,
      onboard_date: onboard_date ? moment(onboard_date) : null,
    });

    if (attachments_urls && attachments_urls?.length >= 0) {
      let updatedImages = attachments_urls.map((img) => {
        const { id, url } = img;
        let name = url.split('/')[url.split('/').length - 1].split('?')[0];
        return { key: id, image: url, isLocal: false, name };
      });

      setDocuments(updatedImages);
    }
    setUtils(utilsData);
  }, [currentNpoObj, utilsData]);

  useEffect(() => {
    setLoading(createNpoLoading);
    // if (createNpoLoading) {
    //   setIsUserImgSelected(false);
    // }
  }, [createNpoLoading]);

  useEffect(() => {
    if (pathname.includes('create') && currentNpoObj?.id === undefined) {
      setIsEdit(true);
    } else {
      setIsEdit(npoDetailsIsEdit);
    }
  }, [npoDetailsIsEdit, currentNpoObj]);

  const onChange = (value) => {
    // console.log(`selected ${value}`);
  };

  const onFinish = (values) => {
    const { ...res } = values;

    isNpoUpdate
      ? dispatch(
          updateNpo({
            ...res,
            ...(documents && { documents }),
            id: currentNpoObj.id,
          })
        )
      : dispatch(
          createNpo({
            ...res,
            documents,
          })
        );
  };
  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const handleSave = () => {
    form.submit();
  };

  // const capitalizeFirstLetter = (str) =>
  //   str && str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <>
      <Row>
        <Col span={24} className='heading-col'>
          <Row align='middle' justify='space-between'>
            <Col>
              <div>
                <span className='heading-span'>NPO Details</span>
              </div>
            </Col>
            <Col>
              {isEdit ? (
                loading ? (
                  <SpinnerComponent fontSize={14} />
                ) : (
                  <SaveIcon width={16} height={16} onClick={handleSave} />
                )
              ) : (
                canUpdate && (
                  <EditIcon
                    width={16}
                    fill='#e21c21'
                    height={16}
                    onClick={() => dispatch(setNpoDetailsIsEdit(true))}
                  />
                )
              )}
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          className={`user-details-section project-details-section ${
            !isEdit && 'is-edit'
          }`}
        >
          <Row justify='center'>
            <Col md={24} lg={23} xl={20} xxl={16}>
              <Form
                {...formItemLayout}
                form={form}
                name='user-details'
                onFinish={onFinish}
                initialValues={currentNpo}
                scrollToFirstError
                labelWrap={true}
                layout='horizontal'
                className='user-detail-form'
                requiredMark={false}
              >
                <Row gutter={[20, 10]}>
                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='name'
                      label='NPO Name'
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate}
                        placeholder='NPO Name'
                      />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='npo_region_id'
                      label='Region'
                    >
                      <Select
                        style={{
                          width: '100%',
                        }}
                        disabled={!isEdit || !canUpdate}
                        readOnly={true}
                        className='department-select'
                        dropdownClassName='select-dropdown-custom'
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            'ant-layout-content layout-content site-layout-background custom-textarea'
                          )[0]
                        }
                        showSearch
                        placeholder='Select Region'
                        optionFilterProp='children'
                        onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {allNpoRegions.map(({ id, name }) => {
                          return (
                            <Option key={id} value={id}>
                              {name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='status_id'
                      label='NPO Status'
                    >
                      <Select
                        style={{
                          width: '100%',
                        }}
                        disabled={!isEdit || !canUpdate}
                        readOnly={true}
                        className='department-select'
                        dropdownClassName='select-dropdown-custom'
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            'ant-layout-content layout-content site-layout-background custom-textarea'
                          )[0]
                        }
                        showSearch
                        placeholder='Select NPO Status'
                        optionFilterProp='children'
                        onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {allNpoStatuses.map(({ id, name }) => {
                          return (
                            <Option key={id} value={id}>
                              {name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='contact_name'
                      label='Director Name'
                    >
                      <Input
                        readOnly={loading}
                        disabled={
                          !isEdit ||
                          // isNpoUpdate ||
                          !canUpdate
                        }
                        placeholder='Director Name'
                      />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name='contact_no'
                      label='Director Contact Number'
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit} // || isNpoUpdate
                        placeholder='Director Contact Number'
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name='email'
                      label='Director Email'
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                        {
                          type: 'email',
                          message: 'The input is not valid Email!',
                        },
                      ]}
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate} // || isUserUpdate
                        placeholder='Director Email'
                      />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='manager_name'
                      label='PROGRAMME MANAGER NAME'
                    >
                      <Input
                        readOnly={loading}
                        disabled={
                          !isEdit ||
                          // isNpoUpdate ||
                          !canUpdate
                        }
                        placeholder='Programme Manager Name'
                      />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name='manager_no'
                      label='PROGRAMME MANAGER NUMBER'
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit} // || isNpoUpdate
                        placeholder='Programme Manager Number'
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name='manager_email'
                      label='PROGRAMME MANAGER EMAIL'
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                        {
                          type: 'email',
                          message: 'The input is not valid Email!',
                        },
                      ]}
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate} // || isUserUpdate
                        placeholder='Programme Manager Email'
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='project_type'
                      label='Type of NPO'
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate}
                        placeholder='Type of NPO'
                      />
                    </Form.Item>
                  </Col> */}

                  <Col md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='onboard_date'
                      label='NPO Onboard Date'
                    >
                      <DatePicker
                        style={{
                          width: '100%',
                        }}
                        format='D-MM-YYYY'
                        allowClear={false}
                        suffixIcon={null}
                        placeholder='NPO Onboard Date'
                        disabled={!isEdit || !canUpdate || loading}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={16} lg={16} xl={16} xxl={16}>
                    <Form.Item
                      name='address'
                      label='NPO Address'
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                    >
                      <Input
                        readOnly={loading}
                        disabled={!isEdit} // || isNpoUpdate
                        placeholder='NPO Address'
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                      ]}
                      name='description'
                      label='Npo Description'
                    >
                      <TextArea
                        className='scope'
                        rows={7}
                        readOnly={loading}
                        disabled={!isEdit || !canUpdate}
                        placeholder='Insert NPO Description'
                      />
                    </Form.Item>
                    <AttachmentsSection
                      isEdit={isEdit}
                      loading={loading}
                      documents={documents}
                      setDocuments={setDocuments}
                      currentNpo={currentNpo}
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className='empty-div'></div>
    </>
  );
};

export default NpoDetailsSection;
