import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import VerticalGroupedGraph from '../../../components/graphs/verticalGrouped';
import VerticalGroupedTwoColumnsGraph from '../../../components/graphs/verticalGroupedTwoColumns';
import FullWidthGraph from '../../../components/graphs/fullWidthGraph';
import { preAndPostColors, preAndPostKeys } from './utils';
import SixColumnsFullWidthGraph from '../../../components/graphs/SixColumnsfullWidthGraph';

const keys = ['modularActual', 'modularTarget', 'massActual', 'massTarget'];
const massKeys = ['massActual', 'massTarget'];
const progAttKeys = ['modularActual', 'massActual'];

const ByPerformance = ({ screenWidth }) => {
  const {
    npo_attendence,
    program_attendence,
    region_attendence,
    mass_interventions,
    modular_actual_vs_target,
    modular_pre_and_post,
    modular_pre_and_post_percentage,
  } = useSelector((state) => state.learnersReducer);

  return (
    <Row gutter={[20, 40]}>
      <Col md={12} lg={12} xl={12} xxl={6}>
        <VerticalGroupedGraph
          data={region_attendence}
          label='Region Attendance Actual vs. Target'
          BottomLegend='Region'
          tickRotation={-20}
          onlyMass={false}
          screenWidth={screenWidth}
          keys={keys}
        />
      </Col>
      <Col md={12} lg={12} xl={12} xxl={6}>
        <VerticalGroupedGraph
          data={npo_attendence}
          label='NPO Attendance Actual vs. Target'
          BottomLegend='NPO'
          tickRotation={-20}
          onlyMass={false}
          screenWidth={screenWidth}
          keys={keys}
        />
      </Col>
      <Col md={12} lg={12} xl={12} xxl={6}>
        <VerticalGroupedTwoColumnsGraph
          data={program_attendence}
          label='Programme Attendance Actual'
          BottomLegend='Programme Type'
          tickRotation={0}
          onlyMass={false}
          screenWidth={screenWidth}
          keys={progAttKeys}
          colors={['#ffc732', '#e56c37']}
        />
      </Col>
      <Col md={12} lg={12} xl={12} xxl={6}>
        <VerticalGroupedTwoColumnsGraph
          data={mass_interventions}
          label='Mass Intervention Attendance Actual vs. Target'
          BottomLegend='Intervention Type'
          tickRotation={-20}
          onlyMass={true}
          screenWidth={screenWidth}
          keys={massKeys}
          colors={['#e56c37', '#d74550']}
        />
      </Col>

      <Col span={24}>
        <FullWidthGraph
          data={modular_actual_vs_target}
          label='Modular Manuals Attendance Actual vs. Target'
          LeftLegend='Modular...'
          tickRotation={-5}
          type='modular'
          keys={['modular_actual', 'modular_target']}
          colors={['#FFC732', '#E31C21']}
          screenWidth={screenWidth}
        />
      </Col>
      <Col span={24}>
        <FullWidthGraph
          data={modular_pre_and_post}
          label='Modular Manuals Attendance Pre and Post'
          LeftLegend='Pre Total...'
          tickRotation={-6}
          type='preAndPost'
          keys={['pre_total', 'post_total']}
          colors={['#FFC732', '#E31C21']}
          screenWidth={screenWidth}
        />
      </Col>

      <Col span={24}>
        <SixColumnsFullWidthGraph
          data={modular_pre_and_post_percentage}
          label='Modular Manuals Pre and Post'
          LeftLegend='Pre Total...'
          tickRotation={0}
          type='preAndPostPer'
          keys={preAndPostKeys}
          colors={preAndPostColors}
          screenWidth={screenWidth}
        />
      </Col>
    </Row>
  );
};

export default React.memo(ByPerformance);
