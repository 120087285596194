const CREATE_USER = "CREATE_USER";
const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
const CREATE_USER_ERROR = "CREATE_USER_ERROR";
const UPDATE_USER = "UPDATE_USER";
const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
const FETCH_USER = "FETCH_USER";
const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
const FETCH_USER_ERROR = "FETCH_USER_ERROR";
const USER_DETAILS_IS_EDIT = "USER_DETAILS_IS_EDIT";
const ADD_USER_IN_MANAGER = "ADD_USER_IN_MANAGER";
const FETCH_USERS_UTIL_DATA = "FETCH_USERS_UTIL_DATA";
const FETCH_USERS_UTIL_DATA_SUCCESS = "FETCH_USERS_UTIL_DATA_SUCCESS";
const FETCH_ALL_USERS = "FETCH_ALL_USERS";
const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
const FETCH_ALL_USERS_ERROR = "FETCH_ALL_USERS_ERROR";

const DELETE_USER = "DELETE_USER";
const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
const DELETE_USER_ERROR = "DELETE_USER_ERROR";
const SAVE_DEPARTMENT = "SAVE_DEPARTMENT";
const SAVE_DEPARTMENT_SUCCESS = "SAVE_DEPARTMENT_SUCCESS";
const SAVE_DEPARTMENT_ERROR = "SAVE_DEPARTMENT_ERROR";
const USER_DETAIL_CLEANUP_FUNC = "USER_DETAIL_CLEANUP_FUNC";
const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";
const UPDATE_USER_SETTINGS_SUCCESS = "UPDATE_USER_SETTINGS_SUCCESS";
const UPDATE_USER_SETTINGS_ERROR = "UPDATE_USER_SETTINGS_ERROR";
const SEND_DETAILS_TO_USER = "SEND_DETAILS_TO_USER";
const SEND_DETAILS_TO_USER_SUCCESS = "SEND_DETAILS_TO_USER_SUCCESS";
const SEND_DETAILS_TO_USER_ERROR = "SEND_DETAILS_TO_USER_ERROR";
const SET_DEPART_DISABLE_INDEX = "SET_DEPART_DISABLE_INDEX";
const UPDATE_USER_PERMISSIONS = "UPDATE_USER_PERMISSIONS";
const UPDATE_USER_PERMISSIONS_SUCCESS = "UPDATE_USER_PERMISSIONS_SUCCESS";
const UPDATE_USER_PERMISSIONS_ERROR = "UPDATE_USER_PERMISSIONS_ERROR";
const SET_USER_PERMISSIONS_IS_EDIT = "SET_USER_PERMISSIONS_IS_EDIT";
const CREATE_USER_PROJECT_SUCCESS = "CREATE_USER_PROJECT_SUCCESS";
const CREATE_USER_PROJECT = "CREATE_USER_PROJECT";
const CREATE_USER_PROJECT_ERROR = "CREATE_USER_PROJECT_ERROR,";
const DELETE_USER_PROJECT = "DELETE_USER_PROJECT";
const DELETE_USER_PROJECT_SUCCESS = "DELETE_USER_PROJECT_SUCCESS";
const DELETE_USER_PROJECT_ERROR = "DELETE_USER_PROJECT_ERROR";
const SET_USER_PROJECT_PERMISSIONS_IS_EDIT =
  "SET_USER_PROJECT_PERMISSIONS_IS_EDIT";
const UPDATE_USER_PROJECT = " UPDATE_USER_PROJECT";
const UPDATE_USER_PROJECT_SUCCESS = " UPDATE_USER_PROJECT_SUCCESS";
const UPDATE_USER_PROJECT_ERROR = " UPDATE_USER_PROJECT_ERROR";
const SET_EXPANDED_ROW_KEYS = "SET_EXPANDED_ROW_KEYS";
const FETCH_ALL_USER_PROJECTS = "FETCH_ALL_USER_PROJECTS";
const FETCH_ALL_USER_PROJECTS_SUCCESS = "FETCH_ALL_USER_PROJECTS_SUCCESS";
const FETCH_ALL_USER_PROJECTS_ERROR = "FETCH_ALL_USER_PROJECTS_ERROR";

export {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_USER_SETTINGS,
  UPDATE_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS_ERROR,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  ADD_USER_IN_MANAGER,
  USER_DETAILS_IS_EDIT,
  FETCH_USERS_UTIL_DATA,
  FETCH_USERS_UTIL_DATA_SUCCESS,
  FETCH_ALL_USERS,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_ALL_USERS_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  SAVE_DEPARTMENT,
  SAVE_DEPARTMENT_SUCCESS,
  SAVE_DEPARTMENT_ERROR,
  USER_DETAIL_CLEANUP_FUNC,
  SEND_DETAILS_TO_USER,
  SEND_DETAILS_TO_USER_SUCCESS,
  SEND_DETAILS_TO_USER_ERROR,
  SET_DEPART_DISABLE_INDEX,
  UPDATE_USER_PERMISSIONS,
  UPDATE_USER_PERMISSIONS_SUCCESS,
  UPDATE_USER_PERMISSIONS_ERROR,
  SET_USER_PERMISSIONS_IS_EDIT,
  CREATE_USER_PROJECT_SUCCESS,
  CREATE_USER_PROJECT,
  CREATE_USER_PROJECT_ERROR,
  DELETE_USER_PROJECT,
  DELETE_USER_PROJECT_SUCCESS,
  DELETE_USER_PROJECT_ERROR,
  SET_USER_PROJECT_PERMISSIONS_IS_EDIT,
  UPDATE_USER_PROJECT,
  UPDATE_USER_PROJECT_SUCCESS,
  UPDATE_USER_PROJECT_ERROR,
  SET_EXPANDED_ROW_KEYS,
  FETCH_ALL_USER_PROJECTS,
  FETCH_ALL_USER_PROJECTS_SUCCESS,
  FETCH_ALL_USER_PROJECTS_ERROR,
};
