const FETCH_SITE_TYPES = 'FETCH_SITE_TYPES';
const FETCH_SITE_TYPES_SUCCESS = 'FETCH_SITE_TYPES_SUCCESS';
const FETCH_SITE_TYPES_ERROR = 'FETCH_SITE_TYPES_ERROR';
const UPDATE_SITE_TYPES = 'UPDATE_SITE_TYPES';
const UPDATE_SITE_TYPES_SUCCESS = 'UPDATE_SITE_TYPES_SUCCESS';
const UPDATE_SITE_TYPES_ERROR = 'UPDATE_SITE_TYPES_ERROR';
const DELETE_SITE_TYPES = 'DELETE_SITE_TYPES';
const DELETE_SITE_TYPES_SUCCESS = 'DELETE_SITE_TYPES_SUCCESS';
const DELETE_SITE_TYPES_ERROR = 'DELETE_SITE_TYPES_ERROR';
const CREATE_SITE_TYPES = 'CREATE_SITE_TYPES';
const CREATE_SITE_TYPES_SUCCESS = 'CREATE_SITE_TYPES_SUCCESS';
const CREATE_SITE_TYPES_ERROR = 'CREATE_SITE_TYPES_ERROR';
const UPDATE_ORDER_SITE_TYPES = 'UPDATE_ORDER_SITE_TYPES';
const UPDATE_ORDER_SITE_TYPES_SUCCESS = 'UPDATE_ORDER_SITE_TYPES_SUCCESS';
const UPDATE_ORDER_SITE_TYPES_ERROR = 'UPDATE_ORDER_SITE_TYPES_ERROR';
const IS_ADDING_UPDATE = 'IS_ADDING_UPDATE';

export {
  FETCH_SITE_TYPES,
  FETCH_SITE_TYPES_SUCCESS,
  FETCH_SITE_TYPES_ERROR,
  UPDATE_SITE_TYPES,
  UPDATE_SITE_TYPES_SUCCESS,
  UPDATE_SITE_TYPES_ERROR,
  DELETE_SITE_TYPES,
  DELETE_SITE_TYPES_SUCCESS,
  DELETE_SITE_TYPES_ERROR,
  CREATE_SITE_TYPES,
  CREATE_SITE_TYPES_SUCCESS,
  CREATE_SITE_TYPES_ERROR,
  UPDATE_ORDER_SITE_TYPES,
  UPDATE_ORDER_SITE_TYPES_SUCCESS,
  UPDATE_ORDER_SITE_TYPES_ERROR,
  IS_ADDING_UPDATE,
};
