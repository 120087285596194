const FETCH_CLASSES_DOCS = 'FETCH_CLASSES_DOCS';
const FETCH_CLASSES_DOCS_SUCCESS = 'FETCH_CLASSES_DOCS_SUCCESS';
const FETCH_CLASSES_DOCS_ERROR = 'FETCH_CLASSES_DOCS_ERROR';
const FETCH_ACTIVITIES = 'FETCH_CLASSES_ACTIVITIES';
const FETCH_ACTIVITIES_SUCCESS = 'FETCH_CLASSES_ACTIVITIES_SUCCESS';
const FETCH_ACTIVITIES_ERROR = 'FETCH_CLASSES_ACTIVITIES_ERROR';
const FETCH_ACTIVITIES_GRAPH_DATA = 'FETCH_CLASSES_ACTIVITIES_GRAPH_DATA';
const FETCH_ACTIVITIES_GRAPH_DATA_SUCCESS =
  'FETCH_CLASSES_ACTIVITIES_GRAPH_DATA_SUCCESS';
const FETCH_ACTIVITIES_GRAPH_DATA_ERROR =
  'FETCH_CLASSES_ACTIVITIES_GRAPH_DATA_ERROR';
const UPDATE_ACTIVITY = 'UPDATE_CLASSES_ACTIVITY';
const UPDATE_ACTIVITY_SUCCESS = 'UPDATE_CLASSES_ACTIVITY_SUCCESS';
const UPDATE_ACTIVITY_ERROR = 'UPDATE_CLASSES_ACTIVITY_ERROR';
const FETCH_ACTIVITIES_SEARCH_DATA = 'FETCH_CLASSES_ACTIVITIES_SEARCH_DATA';
const FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS =
  'FETCH_CLASSES_ACTIVITIES_SEARCH_DATA_SUCCESS';
const FETCH_ACTIVITIES_SEARCH_DATA_ERROR =
  'FETCH_CLASSES_ACTIVITIES_SEARCH_DATA_ERROR';
const DELETE_CLASSES_DOC = 'DELETE_CLASSES_DOC';
const DELETE_CLASSES_DOC_SUCCESS = 'DELETE_CLASSES_DOC_SUCCESS';
const DELETE_CLASSES_DOC_ERROR = 'DELETE_CLASSES_DOC_ERROR';
const CREATE_CLASSES_DOC = 'CREATE_CLASSES_DOC';
const CREATE_CLASSES_DOC_SUCCESS = 'CREATE_CLASSES_DOC_SUCCESS';
const CREATE_CLASSES_DOC_ERROR = 'CREATE_CLASSES_DOC_ERROR';
const DELETE_CLASSES_DOC_ATTACH = 'DELETE_CLASSES_DOC_ATTACH';
const DELETE_CLASSES_DOC_ATTACH_SUCCESS = 'DELETE_CLASSES_DOC_ATTACH_SUCCESS';
const DELETE_CLASSES_DOC_ATTACH_ERROR = 'DELETE_CLASSES_DOC_ATTACH_ERROR';
const UPDATE_ISADD_ISUPDATE = 'UPDATE_CLASSES_ISADD_ISUPDATE';
const ADD_CLASSES_DOC_ATTACH = 'ADD_CLASSES_DOC_ATTACH';
const FETCH_BATCH_UPDATE = 'FETCH_CLASSES_BATCH_UPDATE';
const FETCH_BATCH_UPDATE_SUCCESS = 'FETCH_CLASSES_BATCH_UPDATE_SUCCESS';
const FETCH_BATCH_UPDATE_ERROR = 'FETCH_CLASSES_BATCH_UPDATE_ERROR';
const SET_PILLARS_DATA = 'SET_CLASSES_PILLARS_DATA';

export {
  FETCH_CLASSES_DOCS,
  FETCH_CLASSES_DOCS_SUCCESS,
  FETCH_CLASSES_DOCS_ERROR,
  FETCH_ACTIVITIES,
  FETCH_ACTIVITIES_SUCCESS,
  FETCH_ACTIVITIES_ERROR,
  FETCH_ACTIVITIES_GRAPH_DATA,
  FETCH_ACTIVITIES_GRAPH_DATA_SUCCESS,
  FETCH_ACTIVITIES_GRAPH_DATA_ERROR,
  FETCH_ACTIVITIES_SEARCH_DATA,
  FETCH_ACTIVITIES_SEARCH_DATA_SUCCESS,
  FETCH_ACTIVITIES_SEARCH_DATA_ERROR,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_ERROR,
  DELETE_CLASSES_DOC,
  DELETE_CLASSES_DOC_SUCCESS,
  DELETE_CLASSES_DOC_ERROR,
  CREATE_CLASSES_DOC,
  CREATE_CLASSES_DOC_SUCCESS,
  CREATE_CLASSES_DOC_ERROR,
  DELETE_CLASSES_DOC_ATTACH,
  DELETE_CLASSES_DOC_ATTACH_SUCCESS,
  DELETE_CLASSES_DOC_ATTACH_ERROR,
  UPDATE_ISADD_ISUPDATE,
  ADD_CLASSES_DOC_ATTACH,
  FETCH_BATCH_UPDATE,
  FETCH_BATCH_UPDATE_SUCCESS,
  FETCH_BATCH_UPDATE_ERROR,
  SET_PILLARS_DATA,
};
