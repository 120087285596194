import React, { useEffect, useState } from 'react';
import { Table as ATable, Input } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './redux/actions';
import SpinnerComponent from '../../components/spinner';
import { useNavigate } from 'react-router-dom';

const Table = ({ canUpdate }) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { allTemplates, fetchTemplateLoading, deleteTemplateLoading } =
    useSelector((state) => state.templatesManagerReducer);

  useEffect(() => {
    dispatch(actions.fetchAllTemplates({}));
  }, []);

  useEffect(() => {
    setData(allTemplates);
  }, [allTemplates]);

  const searchInput = () => (
    <Input
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        const { value } = e.target;
        if (value) {
          let result = allTemplates.filter((obj) =>
            obj.name.toLowerCase().includes(value?.trim()?.toLowerCase())
          );
          setData(result);
        } else {
          setData(allTemplates);
        }
      }}
    />
  );

  const handleNavigate = (id) => navigate(`/settings/templates-manager/${id}`);

  const columns = [
    {
      title: () => {
        return (
          <div align='center' className='select-parent-div '>
            <div className='special-table-header-text columns-text'>
              Template NAME
            </div>
            <div>{searchInput()}</div>
          </div>
        );
      },
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: true,
      // width: '15%',
      render: (name, { id }) => <div>{name}</div>, // onClick={() => handleNavigate(id)}
    },
    {
      title: null,
      dataIndex: '',
      key: 'action',
      // width: '10%',
      align: 'center',
      render: (_, record) => {
        if (!canUpdate) {
          return;
        }

        return deleteTemplateLoading ? (
          <SpinnerComponent fontSize={14} />
        ) : (
          <EditIcon
            width={18}
            height={18}
            className='custom-icon'
            onClick={() => {
              handleNavigate(record.id);
              dispatch(actions.setDocumentTemplateIsEdit(true));
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <div className='table-main-div-holder'>
        <ATable
          style={{ width: '420px', minHeight: '580px', marginLeft: '65px' }}
          className='special-table doc-type-table'
          columns={columns}
          scroll={{ x: !fetchTemplateLoading }}
          dataSource={data}
          pagination={false}
          sortUpIcon={<UpOutlined />}
          sortDownIcon={<DownOutlined />}
          loading={fetchTemplateLoading}
        />
      </div>
    </>
  );
};
export default Table;
