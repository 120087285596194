import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';

export function* handleFetchNpoStatuses({ data }) {
  try {
    const response = yield axios.get(`${baseURL}/api/v1/statuses?type=npo`, {});
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchNpoStatusesSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchNpoStatusesError());
    AntdAlert.error('Something went wrong!');
    console.log(error);
  }
}

export function* handleUpdateNpoStatuses({ data }) {
  const { color, id, show_by_default, name } = data;

  try {
    const response = yield axios.put(
      `${baseURL}/api/v1/statuses/${id}?type=npo`,
      {
        show_by_default,
        name,
        color,
      }
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateNpoStatusesSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateNpoStatusesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateNpoStatusesError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleDeleteNpoStatuses({ data }) {
  const { id } = data;
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v1/statuses/${id}?type=npo`
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteNpoStatusesSuccess(id));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteNpoStatusesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteNpoStatusesError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleCreateNpoStatuses({ data }) {
  try {
    const newData = JSON.parse(JSON.stringify(data));
    delete newData.id;
    const response = yield axios.post(`${baseURL}/api/v1/statuses`, {
      ...newData,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createNpoStatusesSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createNpoStatusesError(data.id));
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateNpoOrder({ data }) {
  const fd = new FormData();
  data.map((el, i) => fd.append(`statuses[${el.id}]`, i + 1));

  try {
    const response = yield axios.patch(`${baseURL}/api/v1/statuses/sort`, fd);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateNpoOrderSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateNpoOrderError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateNpoOrderError());
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}
