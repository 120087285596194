import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Popconfirm, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import SpinnerComponent from '../../../components/spinner';
import { deleteNpoAttach } from '../redux/actions';

const AttachmentsSection = ({ isEdit, loading, documents, setDocuments }) => {
  const [delAttachKey, setDelAttachKey] = useState([]);
  const inputFile = useRef(null);

  const dispatch = useDispatch();

  const { deleteAttachLoading, createNpoLoading } = useSelector(
    (state) => state.npoSetupReducer
  );

  const handleShowImage = (image) => {
    let url = image.isLocal ? URL.createObjectURL(image) : image.image;
    window.open(url, '_blank');
  };

  const changeHandler = (event) => {
    // event.currentTarget.value = null;

    if (event.target.files.length < 1) {
      return;
    }

    Object.entries(event.target.files).forEach((item) => {
      console.log(item);
      item[1]['key'] = Math.round(Math.random() * 1000000);
      item[1]['isLocal'] = true;
      console.log('item[1]', item[1]);
      setDocuments((prev) => [...prev, item[1]]);
    });
  };

  const handleDeleteImage = (record) => {
    console.log('record', record);

    if (record?.isLocal) {
      let filteredImages = documents.filter(
        (image) => image.key !== record.key
      );

      setDocuments(filteredImages);
    } else {
      setDelAttachKey([...delAttachKey, record.key]);
      dispatch(deleteNpoAttach(record.key));
    }
  };

  return (
    <Col md={22} lg={22} xl={19} xxl={18} style={{ paddingLeft: '0px' }}>
      <div className='project-attach-parent-div'>
        <span className='text-span'>ATTACHMENTS</span>
        <div className={`img-parent-div ${isEdit && 'edit-image'}`}>
          {isEdit && (
            <div className='edit-icon-div'>
              <input
                readOnly={loading}
                disabled={!isEdit}
                type='file'
                name='profileImg'
                id='profileImg'
                accept='doc/docx/xls/xlsx/ppt/pptx/pdf/jpg/jpeg/gif/png/mp4'
                style={{ display: 'none' }}
                onChange={changeHandler}
                multiple
                // accept="*"
                ref={inputFile}
              />
              <label htmlFor='profileImg'>
                <Button
                  icon={<PlusOutlined />}
                  className='add-activity-btn '
                  size='middle'
                  onClick={() => inputFile.current.click()}
                  loading={createNpoLoading}
                >
                  Attachment
                </Button>
              </label>
            </div>
          )}
        </div>
      </div>
      <Row gutter={[20, 10]} className='attach-listing-div add-attach-btn'>
        {documents.map((el) => {
          return (
            <Col span={24}>
              <Row justify='space-between'>
                <Col
                  span={20}
                  onClick={() => handleShowImage(el)}
                  className='name-col'
                >
                  <span className='name'>
                    {el.name.replaceAll(/[^a-zA-Z0-9.-]/g, ' ')}
                  </span>
                </Col>
                <Col span={4}>
                  {deleteAttachLoading && delAttachKey.includes(el?.key) ? (
                    <SpinnerComponent fontSize={14} />
                  ) : (
                    <Popconfirm
                      title='Are you sure you want to delete this attachment?'
                      onConfirm={() => handleDeleteImage(el)}
                      okText='Yes'
                      cancelText='No'
                    >
                      {isEdit && <DeleteIcon className='del-icon' />}
                    </Popconfirm>
                  )}
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    </Col>
  );
};

export default AttachmentsSection;
