import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';

export function* handleFetchAllInterventionTypes({ data }) {
  const { search, ...rest } = data;
  Object.keys(search).forEach((key) => {
    if (search[key]?.length < 1 || search[key] === null) delete search[key];
  });

  try {
    const response = yield axios.get(`${baseURL}/api/v1/interventions`, {
      params: { ...rest, ...(Object.keys(search).length > 0 && { ...search }) },
    });
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchAllInterventionTypesSuccess(response.data.data));
    }
    errorHandler(response.data);
  } catch (error) {
    AntdAlert.error('Something went wrong!');
    yield put(actions.fetchAllInterventionTypesError());
    console.log(error);
  }
}

export function* handleUpdateInterventionTypes({ data }) {
  const { navigate, id, ...rest } = data;

  const fd = new FormData();

  for (const [key, value] of Object.entries(rest)) {
    if (value === null || value === undefined) {
    } else if (key === 'manuals_attributes' && value.length > 0) {
      value.forEach((ele) => {
        for (const [key1, value1] of Object.entries(ele)) {
          fd.append(`manuals_attributes[][${key1}]`, value1);
        }
      });
    } else if (key === 'manual_ids' && value.length > 0) {
      value.forEach((ele) => {
        fd.append(`manual_ids[]`, ele);
      });
    } else if (value !== null && value !== undefined) {
      fd.append(key, value);
    }
  }

  try {
    const response = yield axios.put(
      `${baseURL}/api/v1/interventions/${id}`,
      fd
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateInterventionTypesSuccess(data));
      navigate('/settings/intervention-types-manager');
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateInterventionTypesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateInterventionTypesError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}
export function* handleDeleteInterventionType({ data }) {
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v1/interventions/${data.id}`
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteInterventionTypeSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
      yield put(actions.deleteInterventionTypeError());
    }
  } catch ({ response }) {
    yield put(actions.deleteInterventionTypeError());
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}
export function* handleCreateInterventionType({ data }) {
  const { navigate, ...rest } = data;

  const fd = new FormData();

  for (const [key, value] of Object.entries(rest)) {
    if (value === null || value === undefined) {
    } else if (key === 'manuals_attributes' && value.length > 0) {
      value.forEach((ele) => {
        for (const [key1, value1] of Object.entries(ele)) {
          fd.append(`manuals_attributes[][${key1}]`, value1);
        }
      });
    } else if (value !== null && value !== undefined) {
      fd.append(key, value);
    }
  }

  try {
    const response = yield axios.post(`${baseURL}/api/v1/interventions`, fd);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.createInterventionTypeSuccess(response.data.data));
      navigate('/settings/intervention-types-manager');
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createInterventionTypeError());
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchInterventionType(data) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v1/interventions/${data.data}`
    );
    console.log('response', response);
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchInterventionTypeSuccess(response.data.data));
    }
    errorHandler(response.data);
  } catch (error) {
    AntdAlert.error('Something went wrong!');
    yield put(actions.fetchInterventionTypeError());
    console.log(error);
  }
}
