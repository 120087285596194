import * as constants from "./constants";

export const fetchImsRevisions = (data) => {
  return {
    type: constants.FETCH_CLASSES_LEARNERS,
    data,
  };
};

export const fetchImsRevisionsSuccess = (data) => {
  return {
    type: constants.FETCH_CLASSES_LEARNERS_SUCCESS,
    data: data,
  };
};

export const fetchImsRevisionsError = (data) => {
  return {
    type: constants.FETCH_CLASSES_LEARNERS_ERROR,
    data: data,
  };
};

export const createImsRevision = (data) => {
  return {
    type: constants.CREATE_CLASSES_LEARNERS,
    data: data,
  };
};

export const createImsRevisionSuccess = (data) => {
  return {
    type: constants.CREATE_CLASSES_LEARNERS_SUCCESS,
    data: data,
  };
};

export const createImsRevisionError = () => {
  return {
    type: constants.CREATE_CLASSES_LEARNERS_ERROR,
  };
};

export const updateImsRevision = (data) => {
  return {
    type: constants.UPDATE_CLASSES_LEARNERS,
    data: data,
  };
};

export const updateImsRevisionSuccess = (data) => {
  return {
    type: constants.UPDATE_CLASSES_LEARNERS_SUCCESS,
    data: data,
  };
};

export const updateImsRevisionError = () => {
  return {
    type: constants.UPDATE_CLASSES_LEARNERS_ERROR,
  };
};

export const deleteImsRevision = (data) => {
  return {
    type: constants.DELETE_CLASSES_LEARNERS,
    data: data,
  };
};

export const deleteImsRevisionSuccess = (data) => {
  return {
    type: constants.DELETE_CLASSES_LEARNERS_SUCCESS,
    data: data,
  };
};

export const deleteImsRevisionError = () => {
  return {
    type: constants.DELETE_CLASSES_LEARNERS_ERROR,
  };
};

export const deleteImsRevisionAttach = (data) => {
  return {
    type: constants.DELETE_CLASSES_LEARNERS_ATTACH,
    data: data,
  };
};

export const deleteImsRevisionAttachSuccess = (data) => {
  return {
    type: constants.DELETE_CLASSES_LEARNERS_ATTACH_SUCCESS,
    data: data,
  };
};

export const deleteImsRevisionAttachError = () => {
  return {
    type: constants.DELETE_CLASSES_LEARNERS_ATTACH_ERROR,
  };
};

export const updateImsRevisionsIsAddIsUpdate = (data) => {
  return {
    type: constants.UPDATE_CLASSES_LEARNERS_ISADD_ISUPDATE,
    data,
  };
};

export const addImsRevisionAttach = (data) => {
  return {
    type: constants.ADD_CLASSES_LEARNERS_ATTACH,
    data: data,
  };
};
