import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';

export function* handleFetchProgrammeTypes({ data }) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v1/types?type=programme`,
      {}
    );
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchProgrammeTypesSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchProgrammeTypesError());
    AntdAlert.error('Something went wrong!');
    console.log(error);
  }
}

export function* handleUpdateProgrammeTypes({ data }) {
  const { position, id, name } = data;

  try {
    const response = yield axios.put(
      `${baseURL}/api/v1/types/${id}?type=programme`,
      {
        name,
        position,
      }
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateProgrammeTypesSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateProgrammeTypesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateProgrammeTypesError(response.data.data));
    AntdAlert.error('Something went wrong!');
  }
}

export function* handleDeleteProgrammeTypes({ data }) {
  const { id } = data;
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v1/types/${id}?type=programme`
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteProgrammeTypesSuccess(id));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteProgrammeTypesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteProgrammeTypesError(response.data.data));
    AntdAlert.error('Something went wrong!');
  }
}

export function* handleCreateProgrammeTypes({ data }) {
  try {
    const newData = JSON.parse(JSON.stringify(data));
    delete newData.id;
    const response = yield axios.post(`${baseURL}/api/v1/types`, {
      ...newData,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createProgrammeTypesSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createProgrammeTypesError(data.id));
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateProgrammeOrder({ data }) {
  const fd = new FormData();
  data.map((el, i) => fd.append(`types[${el.id}]`, i + 1));

  try {
    const response = yield axios.patch(`${baseURL}/api/v1/types/sort`, fd);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateProgrammeTypesOrderSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateProgrammeTypesOrderError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateProgrammeTypesOrderError());
    AntdAlert.error('Something went wrong!');
  }
}
