import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';

export function* handleFetchAllTemplates() {
  try {
    const response = yield axios.get(`${baseURL}/api/v1/templates`);
    const { success, data } = response.data;
    if (success) {
      yield put(actions.fetchAllTemplatesSuccess(data));
    }
    errorHandler(response.data);
  } catch (error) {
    AntdAlert.error('Something went wrong!');
    yield put(actions.fetchAllTemplatesError());
    console.log(error);
  }
}

export function* handleUpdateTemplates({ data }) {
  const { navigate, template_fields, id } = data;
  try {
    const response = yield axios.put(`${baseURL}/api/v1/templates/${id}`, {
      template_fields,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateTemplatesSuccess(data));
      navigate('/settings/templates-manager');
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateTemplatesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateTemplatesError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}
export function* handleDeleteTemplate({ data }) {
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v1/templates/${data.id}`
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteTemplateSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
      yield put(actions.deleteTemplateError(response.data.data));
    }
  } catch ({ response }) {
    yield put(actions.deleteTemplateError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleCreateTemplate({ data }) {
  const { navigate, ...rest } = data;
  try {
    const response = yield axios.post(`${baseURL}/api/v1/templates`, {
      ...rest,
    });
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.createTemplateSuccess(response.data.data));
      navigate('/settings/templates-manager');
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createTemplateError());
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchTemplate(data) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v1/templates/${data.data}`
    );
    console.log('response', response.data);
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchTemplateSuccess(response.data.data));
    }
    errorHandler(response.data);
  } catch (error) {
    AntdAlert.error('Something went wrong!');
    yield put(actions.fetchTemplateError());
    console.log(error);
  }
}
