import { Table } from 'antd';
import React from 'react';

const SummaryComponent = ({ pageData }) => {
  let firstTermTotal = 0;
  let secondTermTotal = 0;
  let thirdTermTotal = 0;
  let fourthTermTotal = 0;
  // let mktPreTotal = 0;
  // let mktPostTotal = 0;
  // let sesPreTotal = 0;
  // let sesPostTotal = 0;
  // let leqPreTotal = 0;
  // let leqPostTotal = 0;

  pageData.forEach(
    ({
      first_term,
      second_term,
      third_term,
      fourth_term,
      // mkt_pre,
      // mkt_post,
      // ses_pre,
      // ses_post,
      // leq_pre,
      // leq_post,
    }) => {
      firstTermTotal += first_term ? first_term : 0;
      secondTermTotal += second_term ? second_term : 0;
      thirdTermTotal += third_term ? third_term : 0;
      fourthTermTotal += fourth_term ? fourth_term : 0;
      // mktPreTotal += mkt_pre ? mkt_pre : 0;
      // mktPostTotal += mkt_post ? mkt_post : 0;
      // sesPreTotal += ses_pre ? ses_pre : 0;
      // sesPostTotal += ses_post ? ses_post : 0;
      // leqPreTotal += leq_pre ? leq_pre : 0;
      // leqPostTotal += leq_post ? leq_post : 0;
    }
  );

  let length = pageData.length;

  let aveFirstTerm =
    firstTermTotal > 0 ? Math.round(firstTermTotal / length) : firstTermTotal;

  let aveSecTerm =
    secondTermTotal > 0
      ? Math.round(secondTermTotal / length)
      : secondTermTotal;

  let aveThirdTerm =
    thirdTermTotal > 0 ? Math.round(thirdTermTotal / length) : thirdTermTotal;

  let aveFourthTerm =
    fourthTermTotal > 0
      ? Math.round(fourthTermTotal / length)
      : fourthTermTotal;

  return (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}></Table.Summary.Cell>
        <Table.Summary.Cell index={1}></Table.Summary.Cell>
        <Table.Summary.Cell index={2}></Table.Summary.Cell>
        <Table.Summary.Cell index={3}></Table.Summary.Cell>
        <Table.Summary.Cell index={4}></Table.Summary.Cell>
        <Table.Summary.Cell index={5}></Table.Summary.Cell>
        <Table.Summary.Cell index={6} align='center'>
          <div className='total'>{aveFirstTerm}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={7} align='center'>
          <div className='total'>{aveSecTerm}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={8} align='center'>
          <div className='total'>{aveThirdTerm}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={9} align='center'>
          <div className='total'>{aveFourthTerm}</div>
        </Table.Summary.Cell>
        {/* <Table.Summary.Cell index={10} align='center'>
          <div className='total'>{mktPreTotal}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={11} align='center'>
          <div className='total'>{mktPostTotal}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={12} align='center'>
          <div className='total'>{sesPreTotal}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={13} align='center'>
          <div className='total'>{sesPostTotal}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={14} align='center'>
          <div className='total'>{leqPreTotal}</div>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={15} align='center'>
          <div className='total'>{leqPostTotal}</div>
        </Table.Summary.Cell> */}
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}></Table.Summary.Cell>
        <Table.Summary.Cell index={1}></Table.Summary.Cell>
        <Table.Summary.Cell index={2}></Table.Summary.Cell>
        <Table.Summary.Cell index={3}></Table.Summary.Cell>
        <Table.Summary.Cell index={4}></Table.Summary.Cell>
        <Table.Summary.Cell index={5}></Table.Summary.Cell>
        <Table.Summary.Cell index={6}></Table.Summary.Cell>
        <div className='total-text'>OVERALL AVERAGE PER TERM</div>
      </Table.Summary.Row>
    </>
  );
};

export default SummaryComponent;
