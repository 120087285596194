import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';

export function* handleLoginUser({ data }) {
  const { navigate, ...rest } = data;
  try {
    const response = yield axios.post(`${baseURL}/api/v1/login`, rest);
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(
        actions.loginUserSuccess({
          ...response.data.data,
          navigate,
          shouldNavigate: true,
        })
      );
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.loginUserError());
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.loginUserError());
    AntdAlert.error('Something went wrong!');
    console.log(error);
  }
}

export function* handleLogoutUser({ data }) {
  const { navigate, id } = data;
  try {
    const response = yield axios.post(`${baseURL}/api/v1/users/logout`, { id });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.logoutUserSuccess({ ...response.data.data, navigate }));
      navigate('/');
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.logoutUserError());
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchSidebarProjects({ data }) {
  const { user_id } = data;
  try {
    const response = yield axios.get(`${baseURL}/api/v1/users/${user_id}/npos`);
    const { success } = response.data;

    if (success) {
      yield put(actions.fetchSidebarProjectSuccess(response.data.data));
    } else {
      yield put(actions.fetchSidebarProjectError());
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchSidebarProjectError());
    console.log(error);
  }
}
