import { useState } from "react";
import { message } from "antd";

export const UseRevisionFields = () => {
  const [inputFields, setInputFields] = useState([]);

  const createField = (item) => {
    if (item?.option?.children === undefined) {
      return message.error(`${item?.label} should not be blank!`);
    }

    const { option } = item;

    let label = option.children;
    let placeholder = option.children;

    const updated = inputFields.map((el) =>
      el.id === item.id
        ? {
            ...el,
            ...item,
            // isLocal: false,
            // isEdit: false,
            label,
            placeholder,
            isSelect: false,
          }
        : el
    );
    setInputFields(updated);
  };

  const deleteField = (item) => {
    const { id } = item;

    const updated = inputFields.filter((el) => el.id !== id);
    setInputFields(updated);
  };

  const updateField = (item) => {
    const { name, label } = item;

    if (name?.length < 1) {
      return message.error(`${label} should not be blank!`);
    }
    const updated = inputFields.map((el) =>
      el.id === item.id ? { ...el, ...item, isLocal: false, isEdit: false } : el
    );
    setInputFields(updated);
  };

  const onEditClick = (item) => {
    const updated = inputFields.map((el) =>
      el.id === item.id ? { ...el, isEdit: true } : el
    );
    setInputFields(updated);
  };

  return [
    inputFields,
    setInputFields,
    createField,
    deleteField,
    updateField,
    onEditClick,
  ];
};
