import React from 'react';
import { Col, DatePicker, Row, Select, Form, Input } from 'antd';
import moment from 'moment';
const { Option } = Select;

const FormItem = ({ item, isRevisionUpdateCase }) => {
  const { id, name, field_type, value, date_value, ...rest } = item;
  let classname = `${isRevisionUpdateCase ? 'ims-doc-readonly-input' : ''}`;
  let placeholder = isRevisionUpdateCase ? '' : name;

  if (field_type === 'date_field') {
    return (
      <Form.Item
        className={classname}
        label={name}
        name={id}
        key={id}
        labelCol={{ span: 24 }}
      >
        <DatePicker
          size='small'
          style={{
            width: '100%',
          }}
          format={'DD MMM YYYY'}
          suffixIcon={null}
          placeholder={placeholder}
          disabled={isRevisionUpdateCase}
        />
      </Form.Item>
    );
  }
  if (field_type === 'text_field') {
    return (
      <Form.Item
        className={classname}
        label={name}
        name={id}
        key={id}
        labelCol={{ span: 24 }}
      >
        <Input
          placeholder={placeholder}
          readOnly={isRevisionUpdateCase}
          disabled={isRevisionUpdateCase}
          size='small'
        />
      </Form.Item>
    );
  }

  if (field_type === 'select_field') {
    const { field_options } = rest;

    return (
      <Form.Item
        className={classname}
        label={name}
        name={id}
        key={id}
        labelCol={{ span: 24 }}
      >
        <Select placeholder={placeholder} disabled={isRevisionUpdateCase}>
          {field_options?.map((el) => {
            const { id, name } = el;
            return (
              <Option value={name} key={id}>
                {name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }
};

const ExpandedRow = ({
  record,
  fields,
  formRef,
  onFinish,
  isRevisionUpdateCase,
}) => {

  let region = record?.npo?.npo_region?.name;
  let dateAdded = moment(record?.created_at).format('DD MMM YYYY');
  let dateUpdated = moment(record?.updated_at).format('DD MMM YYYY');

  return (
    <Row>
      <Col span={24}>
        <Row className='static-fields-row'>
          <Col span={6} style={{ paddingLeft: '10px' }}>
            <Form.Item labelCol={{ span: 7 }} label='learner no:'>
              {record?.user_no}
            </Form.Item>
            <Form.Item labelCol={{ span: 7 }} label='region:'>
              {region}
            </Form.Item>
          </Col>
          <Col span={6} style={{ paddingLeft: '10px' }}>
            <Form.Item labelCol={{ span: 7 }} label='Date Added:'>
              {dateAdded}
            </Form.Item>
            <Form.Item labelCol={{ span: 7 }} label='Added By:'>
              {record?.added_by?.creator_name}
            </Form.Item>
          </Col>
          <Col span={6} style={{ paddingLeft: '10px' }}>
            <Form.Item labelCol={{ span: 7 }} label='LAST UPDATED:'>
              {dateUpdated}
            </Form.Item>
            <Form.Item labelCol={{ span: 7 }} label='UPDATED BY:'>
              {record?.updated_by?.updator_name}
            </Form.Item>
          </Col>
          <Col span={6} style={{ paddingLeft: '10px' }}>
            <Form.Item
              label='ID / PASSPORT NUMBER'
              labelCol={{ span: 22 }}
            ></Form.Item>
            <Form.Item label=''>{record.id_number}</Form.Item>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Form
          className='ims-doc-fields-form '
          form={formRef}
          onFinish={onFinish}
        >
          <Row gutter={[0, 30]}>
            {fields.map((el) => {
              return (
                <Col span={6} key={el.id}>
                  <FormItem
                    item={el}
                    isRevisionUpdateCase={isRevisionUpdateCase}
                  />
                </Col>
              );
            })}
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default ExpandedRow;
