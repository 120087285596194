import axios from "axios";
import { message as AntdAlert } from "antd";
import { put } from "redux-saga/effects";
import * as actions from "../actions";
import { baseURL } from "../../../../globalUtils/axiosConfig";
import { errorHandler } from "../../../../components/unauthorizeHandler";

export function* handleFetchDocStatuses({ data }) {
  // const { search, ...rest } = data;
  // Object.keys(search).forEach((key) => {
  //   if (search[key]?.length < 1 || search[key] === null) delete search[key];
  // });

  try {
    const response = yield axios.get(`${baseURL}/api/v1/document_statuses`, {
      // params: { ...rest, ...(Object.keys(search).length > 0 && { ...search }) },
    });
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchDocStatusesSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchDocStatusesError());
    AntdAlert.error("Something went wrong!");
    console.log(error);
  }
}

export function* handleUpdateDocStatuses({ data }) {
  const { color, id, show_by_default, status_name } = data;

  try {
    const response = yield axios.put(
      `${baseURL}/api/v1/document_statuses/${id}`,
      {
        show_by_default,
        status_name,
        color,
      }
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateDocStatusesSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateDocStatusesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateDocStatusesError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleUpdateDocCompletedStatuses({ data }) {
  const { id, completed_status } = data;

  try {
    const response = yield axios.put(
      `${baseURL}/api/v1/document_statuses/${id}`,
      {
        completed_status,
      }
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateDocStatusesSuccess(data));
      data?.handleSuccess();
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateDocStatusesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateDocStatusesError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleDeleteDocStatuses({ data }) {
  const { id } = data;
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v1/document_statuses/${id}`
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteDocStatusesSuccess(id));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteDocStatusesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteDocStatusesError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleCreateDocStatuses({ data }) {
  try {
    const newData = JSON.parse(JSON.stringify(data));
    delete newData.id;
    const response = yield axios.post(`${baseURL}/api/v1/document_statuses`, {
      ...newData,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createDocStatusesSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createDocStatusesError(data.id));
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateDocOrder({ data }) {
  const fd = new FormData();
  data.map((el, i) => fd.append(`document_status[${el.id}]`, i + 1));

  try {
    const response = yield axios.patch(
      `${baseURL}/api/v1/document_statuses/sort`,
      fd
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateDocStatusesOrderSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateDocStatusesOrderError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateDocStatusesOrderError());
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}
