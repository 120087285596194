import React, { useRef } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Col, Popconfirm, Popover, Row, message as AntdAlert } from 'antd';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete-icon.svg';
import { ReactComponent as AttachIcon } from '../../../../assets/attach.svg';
import { useDispatch } from 'react-redux';
import * as actions from './redux/actions';
import UseImsPermission from '../../usePermissions';
import axios from 'axios';
import { baseURL } from '../../../../globalUtils/axiosConfig';

const NonEditRowAttachments = ({
  images,
  setImages,
  attachPopupVisible,
  setAttachPopupVisible,
  setCurrentUpdateObj,
  currentUpdateObj,
  record,
  parentRecordID,
  handleDeleteAttach,
  setLearnersData,
}) => {
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const [, canEdit, , canDelete] = UseImsPermission();

  // const handleDeleteImage = (record) => {
  //   let filteredImages = images.filter((image) => image.key !== record.key);

  //   setImages(filteredImages);
  //   if (!record.isLocal) {
  //     handleDeleteAttach({
  //       ...record,
  //       recordId: currentUpdateObj.id,
  //       parentRecordID,
  //     });
  //     // dispatch(
  //     //   actions.deleteImsRevisionAttach({
  //     //     ...record,
  //     //     recordId: currentUpdateObj.id,
  //     //     parentRecordID,
  //     //   })
  //     // );
  //     // setCurrentUpdateObj({ images: filteredImages });
  //   }
  // };

  const handleDeleteImage = async ({ key, isLocal }) => {
    let filteredImages = images.filter((image) => image.key !== key);
    if (isLocal) {
      setImages(filteredImages);
    } else {
      setLearnersData((learnersData) => ({
        ...learnersData,
        deleteAttachLoading: true,
      }));

      const res = await axios.delete(
        `${baseURL}/api/v1/sites/delete_attachment?id=${key}`
      );

      const { success, message } = res.data;
      if (success) {
        console.log('res.data', res.data);
        AntdAlert.success(message);
        console.log('filteredImages', filteredImages);
        setImages(filteredImages);
        handleDeleteAttach({
          recordId: currentUpdateObj.id,
          key,
          filteredImages,
        });
      } else {
        AntdAlert.error('Something went wrong!');
      }
    }
  };

  const handleShowImage = (image) => {
    let url = image.isLocal ? URL.createObjectURL(image) : image.image;
    window.open(url, '_blank');
  };

  const hide = () => {
    setAttachPopupVisible(false);
  };

  const handleImage = (imgObj, e) => {
    e.currentTarget.value = null;

    const isValid =
      /\.(doc|docx|xls|xlsx|ppt|pptx|pdf|jpg|jpeg|gif|png|mp4|)$/i.test(
        imgObj.name
      );

    if (isValid) {
      imgObj['key'] = Math.round(Math.random() * 1000000);
      imgObj['isLocal'] = true;
      if (imgObj) {
        const { user_id, id } = currentUpdateObj;
        dispatch(
          actions.addImsRevisionAttach({
            data: { user_id, id, images: [...images, imgObj] },
            setCurrentUpdateObj,
            parentRecordID,
          })
        );
      }
    } else {
      AntdAlert.error('This file format is not supported.');
    }
  };

  return (
    <Popover
      placement='bottom'
      overlayClassName='activities-attach-popover'
      getPopupContainer={() =>
        document.getElementsByClassName(
          'ant-layout-content layout-content site-layout-background custom-textarea'
        )[0]
      }
      content={
        <>
          <Row>
            <Col span={24}>
              <div className='close-icon-div'>
                <CloseOutlined onClick={hide} />
              </div>
            </Col>
            {images.map((el) => {
              const { name, key } = el;
              return (
                <Col span={24} key={key}>
                  <Row justify='start' align='middle'>
                    <Col span={3}>
                      <AttachIcon
                        className='rotate-icon activities-attach'
                        width={16}
                        fill='#231f20'
                      />
                    </Col>
                    <Col span={18}>
                      <span
                        className='name-span'
                        onClick={() => handleShowImage(el)}
                      >
                        {name}
                      </span>
                    </Col>
                    {canDelete && (
                      <Col span={3}>
                        <Popconfirm
                          overlayClassName='activities-popconfirm'
                          title='Are you sure to delete this attachment?'
                          onConfirm={() => handleDeleteImage(el)}
                          okText='Yes'
                          cancelText='No'
                        >
                          <DeleteIcon
                            fill='#E21C21'
                            width={18}
                            height={18}
                            className='del-icon'
                          />
                        </Popconfirm>
                      </Col>
                    )}
                  </Row>
                </Col>
              );
            })}
          </Row>
          {/* {canEdit && (
            <>
              <Button
                icon={<PlusOutlined />}
                className="add-attach-btn"
                size="middle"
                onClick={() => inputFile.current.click()}
              >
                Attachment
              </Button>
              <input
                type="file"
                id="file"
                multiple
                accept=""
                ref={inputFile}
                style={{ display: "none" }}
                onChange={(e) => handleImage(e.target.files[0], e)}
              />
            </>
          )} */}
        </>
      }
      title={null}
      trigger='click'
      visible={attachPopupVisible}
      onVisibleChange={(visible) => {
        setImages(record?.attachments_urls || []);
        setAttachPopupVisible(visible);
      }}
    >
      <div className='attach-parent-div'>
        <AttachIcon
          className='rotate-icon activities-attach'
          width={16}
          fill='#231f20'
        />
        <div>{images?.length || 0}</div>
      </div>
    </Popover>
  );
};

export default NonEditRowAttachments;
