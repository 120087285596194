import axios from "axios";
import { message as AntdAlert } from "antd";
import { put } from "redux-saga/effects";
import * as actions from "./notificationActions";
import { baseURL } from "../../../../globalUtils/axiosConfig";
import { errorHandler } from "../../../../components/unauthorizeHandler";

export function* handleFetchAllNotifications({ data }) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v1/users/${data}/notifications`
    );
    const { success } = response.data;

    if (success) {
      yield put(actions.fetchAllNotificationsSuccess(response.data.data));
    } else {
      yield put(actions.fetchAllNotificationsError());
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchAllNotificationsError());
    console.log(error);
  }
}

export function* handleFetchUnreadNotificationCount({ data }) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v1/users/fetch_unread_notifications`
    );
    const { success } = response.data;

    if (success) {
      const { total_unread_notifications } = response.data;

      yield put(
        actions.fetchUnreadNotificationsSuccess(total_unread_notifications)
      );
    } else {
      yield put(actions.fetchUnreadNotificationsError());
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchUnreadNotificationsError());
    console.log(error);
  }
}

export function* handleUpdateNotification({ data }) {
  const { id, user_id } = data;
  // try {
  const response = yield axios.get(
    `${baseURL}/api/v1/users/update_notification_status?id=${id}`
  );
  const { success, message } = response.data;
  console.log("response.data", response.data);
  if (success) {
    // AntdAlert.success(message);

    yield put(actions.fetchAllNotifications(user_id));
    yield put(actions.fetchUnreadNotifications());

    // return yield put(actions.updateNotificationSuccess(response.data.data));
  } else if (!success) {
    AntdAlert.error(message);
    yield put(actions.updateNotificationError());
    errorHandler(response.data);
  }
  // } catch ({ response }) {
  //   // yield put(actions.updateNotificationError());
  //   // AntdAlert.error("Something went wrong!");
  //   console.log(response);
  // }
}
