import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchAllNpos } from '../NpoSetup/redux/actions';
import { fetchSiteStatuses } from '../SiteStatuses/redux/actions';
import { fetchSiteTypes } from '../SiteTypes/redux/actions';
import { fetchAllTemplates } from '../TemplatesManager/redux/actions';
// import GraphSection from './graphSection';
import GraphSection from '../learners/graphSection/index';
import TableSection from './tableSection';
import './index.less';
import { fetchNpoRegions } from '../NpoRegions/redux/actions';
import axios from 'axios';
import { baseURL } from '../../globalUtils/axiosConfig';
import { setPillarData } from './redux/actions';
import { Col, message, Row } from 'antd';
import SpinnerComponent from '../../components/spinner';

const Sites = ({ npoDetails }) => {
  const [statusCheckList, setStatusCheckList] = useState([]);
  const [npoRegionsCheckList, setNpoRegionsCheckList] = useState([]);
  const [siteTypeCheckList, setSiteTypeCheckList] = useState([]);
  const [npoCheckList, setNpoCheckList] = useState([]);
  const [sitesCheckList, setSitesCheckList] = useState([]);
  const [allSites, setAllSites] = useState([]);

  const { allSiteStatuses, allSiteTypes, allNpos, allNpoRegions } = useSelector(
    (state) => ({
      allSiteStatuses: state.siteStatusReducer.allSiteStatuses,
      allSiteTypes: state.siteTypesReducer.allSiteTypes,
      allNpos: state.npoSetupReducer.allNpos,
      allNpoRegions: state.npoRegionsReducer.allNpoRegions,
    })
  );

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const fetchPillarsData = async () => {
    const res = await axios.get(`${baseURL}/api/v1/sites/pillars`);
    const { data, success } = res.data;

    if (success) {
      dispatch(setPillarData(data));
      window.defaultPillars = data;
    }
  };

  useEffect(() => {
    fetchSites();
    const fetchData = async () => {
      await Promise.all([
        dispatch(fetchAllTemplates({})),
        dispatch(fetchAllNpos({ search: {} })),
        dispatch(fetchSiteTypes()),
        dispatch(fetchSiteStatuses()),
        dispatch(fetchNpoRegions()),
      ]);
    };
    fetchData();
  }, [dispatch]);

  const nporegOptions = useMemo(
    () =>
      allNpoRegions.map((el) => {
        const { name, id } = el;
        return { value: id, label: name };
      }),
    [allNpoRegions]
  );

  const statusOptions = useMemo(
    () =>
      allSiteStatuses.map((el) => {
        const { name, id } = el;
        return { value: id, label: name };
      }),
    [allSiteStatuses]
  );

  const siteTypeOptions = useMemo(
    () =>
      allSiteTypes.map((el) => {
        const { name, id } = el;
        return { value: id, label: name };
      }),
    [allSiteTypes]
  );

  const npoOptions = useMemo(
    () =>
      allNpos.map((el) => {
        const { name, id } = el;
        return { value: id, label: name };
      }),
    [allNpos]
  );

  const fetchSites = async () => {
    const res = await axios.get(
      `${baseURL}/api/v1/sites?page=1&per_page=all&sort=name&order=asc&page_type=sites&npo_id[]=${npoDetails.npo_id}`
    );

    const { success, data } = res.data;
    if (success) {
      setAllSites(data.sites);
    }
  };

  const sitesOptions = useMemo(
    () =>
      allSites.map((el) => {
        const { name, id } = el;
        return { value: id, label: name };
      }),
    [allSites]
  );

  return (
    <div className='page-top-div'>
      <span
        className='text-span'
        style={{
          lineHeight: '17px',
        }}
      >
        NPO - {pathname?.split('/')[2]}
      </span>
      <GraphSection />
      <TableSection
        statusCheckList={statusCheckList}
        setStatusCheckList={setStatusCheckList}
        statusOptions={statusOptions}
        npoRegionsCheckList={npoRegionsCheckList}
        setNpoRegionsCheckList={setNpoRegionsCheckList}
        npoRegionsOptions={nporegOptions} // npoRegionsOptions
        siteTypeCheckList={siteTypeCheckList}
        setSiteTypeCheckList={setSiteTypeCheckList}
        siteTypeOptions={siteTypeOptions}
        npoCheckList={npoCheckList}
        setNpoCheckList={setNpoCheckList}
        npoOptions={npoOptions}
        sitesCheckList={sitesCheckList}
        setSitesCheckList={setSitesCheckList}
        sitesOptions={sitesOptions}
        name={pathname?.split('/')[2]}
        fetchPillarsData={fetchPillarsData}
        npoDetails={npoDetails}
      />
    </div>
  );
};

const NPOSites = () => {
  const { npo_id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [npoDetails, setNpoDetails] = useState(null);

  const onPermissionError = () => {
    message.error(
      "You don't have the permission to view NPO Sites. Please contact system admin."
    );
    return navigate('/profile');
  };

  const fetchDetails = async () => {
    setLoading(true);
    const res = await axios.get(`${baseURL}/api/v1/user_npos/${npo_id}`);
    const { success } = res.data;
    if (success) {
      const { data } = res.data;
      const { permissions } = data;
      setNpoDetails(data);
      let viewPermission = permissions?.sites?.view || false;
      if (!viewPermission) {
        return onPermissionError();
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    !loading && fetchDetails();
  }, [npo_id]);

  if (loading) {
    return (
      <Row justify='center' align='middle' style={{ height: '90vh' }}>
        <Col>
          <SpinnerComponent fontSize={50} />;
        </Col>
      </Row>
    );
  } else if (npoDetails !== null) {
    return <Sites npoDetails={npoDetails} />;
  }
};

export default NPOSites;
