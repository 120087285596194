import moment from 'moment';

export const sortByAlpha = (data, order) => {
  if (order) {
    return data.sort((a, b) => a.label.localeCompare(b.label));
  } else {
    return data.sort((a, b) => b.label.localeCompare(a.label));
  }
};

export const sortByAmount = (data, order) => {
  const sumValues = (obj) => {
    const { Completed, Total } = obj;

    return Object.values({ Completed, Total }).reduce((a, b) => a + b, 0);
  };

  if (order) {
    return data.sort((a, b) => sumValues(a) - sumValues(b));
  } else {
    return data.sort((a, b) => sumValues(b) - sumValues(a));
  }
};

export const colorsArray = [
  '#690277',
  '#188bf6',
  '#a1d1ff',
  '#7450ba',
  '#a0a6d8',
  '#191970',
  '#2f4f4f',
  '#800000',
  '#696969',
  '#00ffff',
  '#4b0082',
  'red',
  'yellow',
  'green',
  'black',
  'orange',
];

export const sortActivitiesSalesRep = (salesRep) =>
  salesRep.sort((a, b) => (a.username || '').localeCompare(b.username || ''));

// export const sortActivityTypes = (data) =>
export const sortDepartments = (data) =>
  data.sort((a, b) =>
    // (a.activity_type || "").localeCompare(b.activity_type || "")
    (a.department || '').localeCompare(b.department || '')
  );

export const requiredFeilds = [
  'document_date',
  'document_template_id',
  'document_status_id',
];

const columnNameAndError = [
  {
    dataIndex: 'document_template_id',
    error: 'Document Type is Required!',
  },
  {
    dataIndex: 'document_status_id',
    error: 'Status is Required!',
  },
  {
    dataIndex: 'document_due_date',
    error: 'Due Date is Required!',
  },
];

export const getErrorText = (dataIndex) => {
  let items = columnNameAndError.filter((el) => el.dataIndex === dataIndex);
  if (items.length > 0) {
    return items[0].error;
  } else return '';
};

export const revisionOptions = [
  {
    label: 'Learner No',
    value: 'learner_no',
  },
  {
    label: 'Region',
    value: 'region',
  },
  {
    label: 'Date Added',
    value: 'date_added',
  },
  {
    label: 'Added By',
    value: 'added_by',
  },
  {
    label: 'Last Updated',
    value: 'last_updated',
  },
  {
    label: 'Updated By',
    value: 'updated_by',
  },
  {
    label: 'ID / Passport Number',
    value: 'id_number',
  },
];

export const distOptions = [
  {
    label: 'Copies Size',
    value: 'copy_size',
  },
  {
    label: 'Date',
    value: 'distribution_date',
  },
  {
    label: 'Issued By',
    value: 'issued_by',
  },
  {
    label: 'Issued To',
    value: 'issued_to',
  },
  {
    label: 'Number Of Copies',
    value: 'no_of_copies',
  },
  {
    label: 'Revision',
    value: 'rev',
  },
  {
    label: 'Transmittal Number',
    value: 'transmittal_no',
  },
  {
    label: 'User',
    value: 'username',
  },
];

export const shouldAllow = (el) => {
  const { name, isEdit } = el;

  let isDate = moment(name)?.isValid() || false;
  let isNumber = name >= 0;
  let isString = name.length > 0;

  let allow = !isEdit && (isNumber || isDate || isString);

  return allow;
};

export const attachOptions = [
  {
    label: 'Has Attach',
    value: true,
  },
  {
    label: 'No Attach',
    value: false,
  },
];

export const genderOptions = [
  {
    label: 'Female',
    value: 1,
  },
  {
    label: 'Male',
    value: 2,
  },
];

export const userTypeOptions = [
  {
    label: 'System Manager',
    value: 'system_manager',
  },
  {
    label: 'Super Admin',
    value: 'super_admin',
  },
];

export const raceOptions = [
  { value: 1, label: 'African' },
  { value: 2, label: 'Asian' },
  { value: 3, label: 'Coloured' },
  { value: 4, label: 'White' },
];
