import React, { useEffect, useState } from 'react';
import {
  Col,
  Input,
  Row,
  message,
  Select,
  Form,
  // Spin,
  DatePicker,
  Divider,
  Checkbox,
} from 'antd';
import { ReactComponent as SaveIcon } from '../../../assets/save-icon.svg';
import sampleImg from '../../../assets/user-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  createUser,
  fetchUserDetailsUtils,
  sendDetailsToUser,
  setUserDetailsIsEdit,
  updateUser,
} from '../redux/actions';
import CreatePasswordModal from './createPasswordModal';
import SpinnerComponent from '../../../components/spinner';
// import { isValidNumber } from '../../../globalUtils/axiosConfig';
import { capitalizeFirstLetterofEachWord } from '../../../globalUtils/capitalizeFirstLetterofEachWord';
import UpdatePasswordModal from './updatePasswordModal';
// import UseGetUserRole from '../../../components/useGetUserRole';
import {
  drivingOptions,
  genderOptions,
  hideKeys,
  martialOptions,
  nationalityOptions,
  raceOptions,
  statusOptions,
  systemUsersOptions,
} from './utils';
import moment from 'moment';
import TemplateFields from './templateFields';
// const regex = /^[-+\d\s]+$/;
const regex = /^\+?\d{1,3}[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/;

const UserDetailsSection = ({
  isUserUpdate,
  isProfileCase,
  canUpdate,
  userType,
  cannotUpdate,
  showHideFields,
}) => {
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [userProfileImg, setUserProfileImg] = useState(null);
  const [isUserImgSelected, setIsUserImgSelected] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  // const [pinModalOpen, setPinModalOpen] = useState(false);
  const [utils, setUtils] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  // const [pin, setPin] = useState('');
  const [fields, setFields] = useState([]);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fieldsFormRef] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const {
    createUserLoading,
    currentUserObj,
    userDetailsIsEdit,
    utilsData,
    sendDetailLoading,
  } = useSelector((state) => state.userManagerReducer);

  const { allNpos } = useSelector((state) => state.npoSetupReducer);
  const { allCoachTypes } = useSelector((state) => state.coachTypesReducer);
  const { allLearnerTypes } = useSelector((state) => state.learnerTypesReducer);
  const { allCoachStatuses } = useSelector((state) => state.coachStatusReducer);
  const { allLearnerStatuses } = useSelector(
    (state) => state.learnerStatusReducer
  );
  const { allDocs } = useSelector((state) => state.sitesReducer);
  const { allTemplates } = useSelector(
    (state) => state.templatesManagerReducer
  );

  // const [isSuperAdmin] = UseGetUserRole();

  useEffect(() => {
    // form.setFieldsValue(currentUserObj);
    // setCurrentUser(currentUserObj);
    if (
      typeof currentUserObj?.user_type === 'number' &&
      utilsData?.user_types?.length > 0
    ) {
      let item = utilsData.user_types.filter(
        (el) => el.value === currentUserObj?.user_type
      )[0];
      let statusItem = utilsData.status.filter(
        (el) => el.value === currentUserObj?.status
      )[0];

      setCurrentUser({
        ...currentUserObj,
        user_type: item.key,
        status: statusItem.key,
        dob: moment(currentUserObj.dob).format('D MMM YYYY'),
      });

      form.setFieldsValue({
        ...currentUserObj,
        user_type: item.key,
        status: statusItem.key,
        dob: moment(currentUserObj.dob).format('D MMM YYYY'),
      });
    } else {
      const { kinship, supervisor, user_field_value } = currentUserObj;
      setCurrentUser(currentUserObj);
      let dob = currentUserObj.dob ? moment(currentUserObj.dob) : null;
      let attributes = {};
      if (kinship) {
        for (const [key, value] of Object.entries(kinship)) {
          attributes[`kinship_attributes_${key}`] = value;
        }
      }
      if (supervisor) {
        for (const [key, value] of Object.entries(supervisor)) {
          attributes[`supervisor_attributes_${key}`] = value;
        }
      }
      form.setFieldsValue({ ...currentUserObj, dob, ...attributes });

      if (user_field_value) {
        let formData = {};
        user_field_value.map((el) => {
          const { template_field_id, value, date_value, template_field } = el;
          if (template_field) {
            const { field_type } = template_field;

            formData[template_field_id] =
              field_type === 'date_field' && date_value !== null
                ? moment(date_value)
                : value;

            return {
              ...el,
              field_type,
            };
          }
        });
        let updatedFields = { ...formData };

        setTimeout(() => {
          fieldsFormRef.setFieldsValue({ ...updatedFields });
        }, 100);
      }
    }
    setUtils(utilsData);
  }, [currentUserObj, utilsData]);

  useEffect(() => {
    setLoading(createUserLoading);
    // if (createUserLoading) {
    //   setIsUserImgSelected(false);
    // }
  }, [createUserLoading]);

  useEffect(() => {
    setIsEdit(userDetailsIsEdit);
  }, [userDetailsIsEdit]);

  useEffect(() => {
    let types = ['learner', 'coach'];
    if (allTemplates.length > 0 && types.includes(userType)) {
      let filtered = allTemplates.filter((el) =>
        el.name.toLocaleLowerCase().includes(userType)
      );
      if (filtered.length === 1) {
        const { template_fields } = filtered[0];
        setFields(template_fields);
      }
    }
  }, [allTemplates, userType]);

  const changeHandler = (event) => {
    const image = event.target.files[0];
    if (!image) {
      return false;
    }
    if (!image.name.match(/\.(jpg|jpeg|png)$/)) {
      message.error('Photo should be png or jpg format.');
      return false;
    }
    if (image.size > 5120000) {
      message.error('Photo size should be less than 5MB.');
      return false;
    }
    setUserProfileImg(event.target.files[0]);
    // handleChange("image", event.target.files[0]);
    setIsUserImgSelected(true);
  };

  const onChange = (value) => {
    // console.log(`selected ${value}`);
  };

  const onFinish = (values) => {
    let field_values = fieldsFormRef.getFieldsValue();

    if (password?.length < 6 && !isUserUpdate) {
      return message.error('Password is required');
    }

    let { age, age_type } = currentUser;

    age = userType === 'learner' ? age : null;
    age_type = userType === 'learner' ? age_type : null;

    let permissions = {
      report_summary: {
        learners: {
          view: true,
        },
        coaches: {
          view: true,
        },
        sites: {
          view: true,
        },
        classes: {
          view: true,
        },
      },
    };
    // This permissions is for when a learner is being creating.
    let learnerCreatingPermissions =
      userType === 'learner' ? permissions : null;

    isUserUpdate
      ? dispatch(
          updateUser({
            user_type: userType,
            ...values,
            ...(password.length > 5 && { password }),
            ...(userProfileImg && { image: userProfileImg }),
            id: currentUserObj.id,
            kinship_attributes_id: currentUserObj?.kinship?.id,
            supervisor_attributes_id: currentUserObj?.supervisor?.id,
            age,
            age_type,
            field_values,
          })
        )
      : dispatch(
          createUser({
            user_type: userType,
            ...values,
            password,
            image: userProfileImg,
            age,
            age_type,
            field_values,
            permissions: learnerCreatingPermissions,
          })
        );
  };

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const handleSave = async (key) => {
    try {
      const row = await form.validateFields();
      const formFields = await fieldsFormRef.validateFields();

      form.submit();
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      // const { errorFields } = errInfo;
      // errorFields.forEach((el) => {
      //   el.errors[0] !== 'Required!' && message.error(el.errors[0]);
      // });
    }
  };

  function capitalizeFirstLetters(str) {
    return str.replace(/\b\w/g, function (match) {
      return match.toUpperCase();
    });
  }

  const capitalizeFirstLetter = (str) =>
    str && str.charAt(0).toUpperCase() + str.slice(1);

  let nonRequired = [
    'id_number',
    'contact_no',
    'kinship_attributes_name',
    'kinship_attributes_surname',
    'kinship_attributes_contact',
  ];

  let rules = [
    {
      required: true,
      message: 'Required!',
    },
  ];

  let maxRule = [
    // ...rules,
    {
      max: 13,
      message: 'ID Number too long!',
    },
  ];

  let numRule = [
    {
      pattern: regex,
      message: 'Invalid!',
    },
  ];

  let hideText = '**********';

  let HideField = ({ label }) => {
    return (
      <Form.Item name={null} label={label}>
        <Input
          readOnly={true}
          value={hideText}
          defaultValue={hideText}
          disabled={true}
        />
      </Form.Item>
    );
  };

  const InputComponent = ({ name, label, placeholder }) => {
    let requiredRule = nonRequired.includes(name) ? [] : rules;
    let rulesUpdated =
      name === 'id_number' ? [...maxRule, ...requiredRule] : requiredRule;
    let rulesFinal =
      name === 'contact_no' ? [...rulesUpdated, ...numRule] : rulesUpdated;
    return (
      <Col md={12} lg={12} xl={8} xxl={8}>
        {showHideFields && hideKeys.includes(name) ? (
          <HideField label={label} />
        ) : (
          <Form.Item rules={rulesFinal} name={name} label={label}>
            <Input
              readOnly={loading}
              disabled={!isEdit || !canUpdate || cannotUpdate}
              placeholder={placeholder}
            />
          </Form.Item>
        )}
      </Col>
    );
  };

  const TextAreaComponent = ({ name, label, placeholder }) => (
    <Col md={12} lg={12} xl={8} xxl={8}>
      {showHideFields && hideKeys.includes(name) ? (
        <HideField label={label} />
      ) : (
        <Form.Item
          // rules={[
          //   {
          //     required: true,
          //     message: 'Required!',
          //   },
          // ]}
          name={name}
          label={label}
        >
          <TextArea
            // rows={5}
            autoSize={{
              minRows: 2,
              maxRows: 6,
            }}
            readOnly={loading}
            disabled={!isEdit || !canUpdate || cannotUpdate}
            placeholder={placeholder}
          />
        </Form.Item>
      )}
    </Col>
  );

  const StaticOptionsSelect = ({ name, label, placeholder, options }) => {
    let isGenderField = name === 'gender';
    let xxl = isGenderField ? 4 : 8;
    let xl = isGenderField ? 8 : 12;
    let isNationality = name === 'nationality';

    return (
      <>
        <Col md={xl} lg={xl} xl={xxl} xxl={xxl}>
          {showHideFields && hideKeys.includes(name) ? (
            <HideField label={label} />
          ) : (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Required!',
                },
              ]}
              name={name}
              label={label}
            >
              <Select
                style={{
                  width: '100%',
                }}
                disabled={!isEdit || !canUpdate || cannotUpdate}
                className='department-select'
                dropdownClassName='select-dropdown-custom'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                showSearch
                placeholder={placeholder}
                optionFilterProp='children'
                // onSearch={onSearch}
                filterOption={(input, option) => {
                  const { children } = option;
                  let isArray = Array.isArray(children);
                  let str = isArray ? children.join('') : children;
                  return str
                    .toLocaleLowerCase()
                    .includes(input.toLocaleLowerCase());
                }}
              >
                {options.map(({ key }) => {
                  return (
                    <Option key={key} value={key}>
                      {isNationality
                        ? capitalizeFirstLetters(key || '')
                        : capitalizeFirstLetterofEachWord(key || '')}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
        </Col>
        {isGenderField && (
          <Col md={4} lg={4} xl={4} xxl={4}>
            <Form.Item
              name='lgbtq'
              label=' '
              className='lgbtq_field'
              valuePropName='checked'
            >
              <Checkbox
                disabled={!isEdit || !canUpdate || cannotUpdate}
                style={{
                  height: '24px',
                  width: '24px',
                }}
              >
                lgbtq+
              </Checkbox>
            </Form.Item>
          </Col>
        )}
      </>
    );
  };

  const DynamicOptionsSelect = ({ name, label, placeholder, options }) => (
    <Col md={12} lg={12} xl={8} xxl={8}>
      {showHideFields && hideKeys.includes(name) ? (
        <HideField label={label} />
      ) : (
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Required!',
            },
          ]}
          name={name}
          label={label}
        >
          <Select
            style={{
              width: '100%',
            }}
            disabled={!isEdit || !canUpdate || cannotUpdate}
            className='department-select'
            dropdownClassName='select-dropdown-custom'
            getPopupContainer={() =>
              document.getElementsByClassName(
                'ant-layout-content layout-content site-layout-background custom-textarea'
              )[0]
            }
            showSearch
            placeholder={placeholder}
            optionFilterProp='children'
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children
                .toLocaleLowerCase()
                .includes(input.toLocaleLowerCase())
            }
          >
            {options.map(({ name, id }) => {
              return (
                <Option key={id} value={id}>
                  {name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      )}
    </Col>
  );

  const handleAge = (e) => {
    let age = moment().diff(e, 'years', false);
    let age_type = age >= 36 ? 'adult' : age >= 18 ? 'youth' : 'child';
    setCurrentUser({ ...currentUser, age, age_type });
  };

  const DateInput = ({ name, label, placeholder }) => {
    let xxl = userType === 'learner' ? 5 : 8;
    let xl = userType === 'learner' ? 8 : 12;
    return (
      <>
        <Col md={xl} lg={xl} xl={xxl} xxl={xxl}>
          {showHideFields && hideKeys.includes(name) ? (
            <HideField label={label} />
          ) : (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Required!',
                },
              ]}
              name={name}
              label={label}
            >
              <DatePicker
                style={{ width: '100%', minWidth: '100px' }}
                placeholder={placeholder}
                className='prod-due-date'
                disabled={!isEdit || !canUpdate || cannotUpdate}
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                suffixIcon={null}
                onChange={handleAge}
                format='D MMM YYYY'
                allowClear={false}
                disabledDate={(current) => {
                  let customDate = moment().format('YYYY-MM-DD');
                  return current && current > moment(customDate, 'YYYY-MM-DD');
                }}
                // popupClassName="activities-time-picker"
              />
            </Form.Item>
          )}
        </Col>
        {userType === 'learner' && (
          <Col md={3} lg={3} xl={3} xxl={3}>
            <div class='ant-form-item'>
              <div class='ant-row ant-form-item-row'>
                <div class='ant-col ant-col-24 ant-form-item-label ant-form-item-label-wrap'>
                  <label for='age' title='Age' style={{ paddingLeft: '0px' }}>
                    Age
                  </label>
                </div>
                <div class='ant-col ant-col-24 ant-form-item-control'>
                  <div
                    class='ant-form-item-control-input'
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {showHideFields && hideText}

                    {!showHideFields && (currentUser?.age || '-')}
                    {!showHideFields &&
                      currentUser?.age_type &&
                      ` (${capitalizeFirstLetter(currentUser?.age_type)})`}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        )}
      </>
    );
  };

  const CoachOrLearnerTypeSelect = ({ name, label, placeholder, options }) => (
    <Col md={12} lg={12} xl={8} xxl={8}>
      <Form.Item
        rules={[
          {
            required: true,
            message: 'Required!',
          },
        ]}
        name={name}
        label={label}
      >
        <Select
          style={{
            width: '100%',
          }}
          disabled={!isEdit || !canUpdate || cannotUpdate}
          className='department-select'
          dropdownClassName='select-dropdown-custom'
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          showSearch
          placeholder={placeholder}
          optionFilterProp='children'
          // onSearch={onSearch}
          filterOption={(input, option) =>
            option.children
              .toLocaleLowerCase()
              .includes(input.toLocaleLowerCase())
          }
        >
          {options.map(({ name, id }) => {
            return (
              <Option key={id} value={id}>
                {name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </Col>
  );

  const getLabel = () => {
    let str = '';
    if (userType) {
      str = userType === 'system_manager' ? 'user' : userType;
    }
    return str;
  };

  const KinRow = () => (
    <Row gutter={[30, 0]}>
      <InputComponent
        name='kinship_attributes_name'
        label='Next of Kin Name'
        placeholder='Next of Kin Name'
      />
      <InputComponent
        name='kinship_attributes_surname'
        label='Next of Kin Surname'
        placeholder='Next of Kin Surname'
      />
      <InputComponent
        name='kinship_attributes_contact'
        label='Next of Kin Contact Number'
        placeholder='Next of Kin Contact Number'
      />
    </Row>
  );

  const SupervisorRow = () => (
    <Row gutter={[30, 0]}>
      <InputComponent
        name='supervisor_attributes_name'
        label='Supervisor Name'
        placeholder='Supervisor Name'
      />
      <InputComponent
        name='supervisor_attributes_surname'
        label='Supervisor Surname'
        placeholder='Supervisor Surname'
      />
      <InputComponent
        name='supervisor_attributes_contact'
        label='Supervisor Contact Number'
        placeholder='Supervisor Contact Number'
      />
    </Row>
  );

  const onFinishFields = (values) => {
    console.log('values', values);
  };

  const TemplateFieldsComponent = () => (
    <>
      <Divider />
      <TemplateFields
        fields={fields}
        formRef={fieldsFormRef}
        onFinish={onFinishFields}
        disabled={!isEdit || !canUpdate || cannotUpdate}
      />
    </>
  );

  return (
    <>
      {isUserUpdate ? (
        <UpdatePasswordModal
          isVisible={passwordModalOpen}
          setVisible={setPasswordModalOpen}
          email={currentUserObj.email}
          isClient={false}
        />
      ) : (
        <CreatePasswordModal
          setPassword={setPassword}
          isVisible={passwordModalOpen}
          setVisible={setPasswordModalOpen}
          isUpdate={isUserUpdate}
        />
      )}
      <Row>
        <Col span={24} className='heading-col'>
          <Row align='middle' justify='space-between'>
            <Col>
              <div>
                <span className='heading-span'>User Details</span>
              </div>
            </Col>
            <Col>
              {isEdit ? (
                loading ? (
                  <SpinnerComponent fontSize={14} />
                ) : (
                  <SaveIcon width={16} height={16} onClick={handleSave} />
                )
              ) : (
                (isProfileCase || canUpdate) &&
                !showHideFields && (
                  <EditIcon
                    width={16}
                    fill='#e21c21'
                    height={16}
                    onClick={() => dispatch(setUserDetailsIsEdit(true))}
                  />
                )
              )}
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          className={`user-details-section ${!isEdit && 'is-edit'}`}
        >
          <Row justify='start'>
            <Col md={5} lg={5} xl={5} xxl={4} className='photo-col'>
              <div>
                <span>PHOTO</span>
                <div className={`img-parent-div ${isEdit && 'edit-image'}`}>
                  <div className='img-nested-div'>
                    <img
                      width='100%'
                      height='100%'
                      src={
                        isUserImgSelected
                          ? URL.createObjectURL(userProfileImg)
                          : currentUserObj?.profile_picture
                          ? currentUserObj?.profile_picture
                          : sampleImg
                      }
                      alt='user-profile'
                    />
                  </div>
                  {isEdit && (
                    <div className='edit-icon-div'>
                      <input
                        readOnly={loading}
                        disabled={!isEdit}
                        type='file'
                        name='profileImg'
                        id='profileImg'
                        accept='image/*'
                        style={{ display: 'none' }}
                        onChange={changeHandler}
                      />
                      <label htmlFor='profileImg'>
                        <EditIcon height={14} width={14} fill='#e21c21' />
                      </label>
                    </div>
                  )}
                </div>
                {isEdit && (
                  <div className='text-div'>
                    <span className='text'>
                      Upload a JPG or PNG file.
                      <br />
                      Recommended image size 800 x 800: Max 5 MB{' '}
                    </span>
                  </div>
                )}
              </div>
            </Col>
            <Col
              md={isEdit ? 19 : 15}
              lg={isEdit ? 19 : 15}
              xl={isEdit ? 16 : 15}
              xxl={16}
            >
              <Form
                {...formItemLayout}
                form={form}
                name='user-details'
                onFinish={onFinish}
                initialValues={currentUser}
                scrollToFirstError
                labelWrap={true}
                layout='horizontal'
                className='user-detail-form'
                requiredMark={false}
              >
                <Row gutter={[30, 0]}>
                  <InputComponent
                    name='first_name'
                    label='First Name'
                    placeholder='First Name'
                  />
                  <InputComponent
                    name='last_name'
                    label='last name'
                    placeholder='Last Name'
                  />
                  {userType.includes('system') ? (
                    <InputComponent
                      name='job_title'
                      label='Job Title'
                      placeholder='Job Title'
                    />
                  ) : (
                    <Col md={12} lg={12} xl={8} xxl={8}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'Required!',
                          },
                        ]}
                        name={'npo_id'}
                        label={'Name Of NPO'}
                      >
                        <Select
                          style={{
                            width: '100%',
                          }}
                          disabled={!isEdit || !canUpdate || cannotUpdate}
                          className='department-select'
                          dropdownClassName='select-dropdown-custom'
                          getPopupContainer={() =>
                            document.getElementsByClassName(
                              'ant-layout-content layout-content site-layout-background custom-textarea'
                            )[0]
                          }
                          showSearch
                          placeholder={'Select NPO'}
                          optionFilterProp='children'
                          // onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.children
                              .toLocaleLowerCase()
                              .includes(input.toLocaleLowerCase())
                          }
                        >
                          {allNpos.map(({ name, id }) => {
                            return (
                              <Option key={id} value={id}>
                                {name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  {userType.includes('system') && (
                    <StaticOptionsSelect
                      name='user_type'
                      label='user type'
                      placeholder='Select User Type'
                      options={systemUsersOptions}
                    />
                  )}
                  {userType.includes('coach') && (
                    <CoachOrLearnerTypeSelect
                      name={'type_id'}
                      label={'Coach Type'}
                      placeholder={'Select Coach Type'}
                      options={allCoachTypes}
                    />
                  )}
                  {userType.includes('learner') && (
                    <CoachOrLearnerTypeSelect
                      name={'type_id'}
                      label={'Learner Type'}
                      placeholder={'Select Learner Type'}
                      options={allLearnerTypes}
                    />
                  )}

                  <Col md={12} lg={12} xl={8} xxl={8}>
                    <Form.Item
                      name='email'
                      label='Email Address'
                      rules={[
                        {
                          required: true,
                          message: 'Required!',
                        },
                        {
                          type: 'email',
                          message: 'The input is not valid Email!',
                        },
                      ]}
                    >
                      <Input
                        readOnly={loading || !isEdit || !canUpdate}
                        disabled={!isEdit || !canUpdate || cannotUpdate} // || isUserUpdate
                        placeholder='Email Address'
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={8} xxl={8}>
                    <label>password</label>
                    <div
                      className='password-div'
                      style={{
                        cursor: !isEdit && 'text',
                      }}
                    >
                      <span
                        onClick={() => isEdit && setPasswordModalOpen(true)}
                      >
                        {`${isUserUpdate ? 'Change' : 'Create'} Password`}
                      </span>
                    </div>
                  </Col>

                  <StaticOptionsSelect
                    name='gender'
                    label='gender'
                    placeholder='Select Gender'
                    options={genderOptions}
                  />

                  <StaticOptionsSelect
                    name='race'
                    label='Race'
                    placeholder='Select Race'
                    options={raceOptions}
                  />
                  {userType.includes('system') ? (
                    <StaticOptionsSelect
                      name='user_status'
                      label='status'
                      placeholder='Select Status'
                      options={statusOptions}
                    />
                  ) : (
                    <DynamicOptionsSelect
                      name='status_id'
                      label='status'
                      placeholder='Select Status'
                      options={
                        userType.includes('coach')
                          ? allCoachStatuses
                          : allLearnerStatuses
                      }
                    />
                  )}

                  <StaticOptionsSelect
                    name='nationality'
                    label='nationality'
                    placeholder='Select Nationality'
                    options={nationalityOptions}
                  />

                  <InputComponent
                    name='id_number'
                    label='ID NUMBER (Non SA Resident - Passport No)'
                    placeholder='ID Number'
                  />

                  <DateInput
                    name='dob'
                    label='Date of Birth'
                    placeholder='Select Date'
                  />
                  <InputComponent
                    name='contact_no'
                    label='contact number'
                    placeholder='Contact Number'
                  />
                  {userType.includes('learner') && (
                    <DynamicOptionsSelect
                      name='site_id'
                      label='Site'
                      placeholder='Select Site'
                      options={allDocs}
                    />
                  )}
                  {!userType.includes('learner') && (
                    <>
                      <StaticOptionsSelect
                        name='marital_status'
                        label='Marital Status'
                        placeholder='Select Marital Status'
                        options={martialOptions}
                      />
                      <StaticOptionsSelect
                        name='driver_licence'
                        label='Drivers Licence'
                        placeholder='Select'
                        options={drivingOptions}
                      />
                    </>
                  )}
                </Row>
                <Row gutter={[30, 0]}>
                  <TextAreaComponent
                    name='home_address'
                    label='Home Address'
                    placeholder='Insert Full Home Address'
                  />
                  <TextAreaComponent
                    name='postal_address'
                    label='Postal Address'
                    placeholder='Insert Full Postal Address'
                  />
                </Row>
                {userType.includes('system') && <Divider />}
                {!userType.includes('coach') && <KinRow />}
                {userType.includes('system') && <Divider />}
                {!userType.includes('learner') && <SupervisorRow />}
                {userType.includes('coach') && <KinRow />}
                {(userType.includes('learner') ||
                  userType.includes('coach')) && <TemplateFieldsComponent />}
                {!isEdit && (
                  <>
                    <Divider />
                    <Row gutter={[30, 20]} className='auto-fill-parent-row'>
                      <Col md={12} lg={12} xl={12} xxl={8} className='fill-col'>
                        <Row className='block-row'>
                          <Col md={13} lg={12} xl={10} xxl={10}>
                            <div className='label'>{getLabel()} NO:</div>
                          </Col>
                          <Col>
                            <div className='fill-div'>
                              {currentUserObj?.user_no}
                            </div>
                          </Col>
                        </Row>
                        {!userType.includes('system') && (
                          <Row>
                            <Col md={13} lg={12} xl={10} xxl={10}>
                              <div className='label'>Region:</div>
                            </Col>
                            <Col>
                              <div className='fill-div'>
                                {currentUserObj?.npo?.npo_region?.name}
                              </div>
                            </Col>
                          </Row>
                        )}
                      </Col>
                      <Col md={12} lg={12} xl={12} xxl={8} className='fill-col'>
                        <Row className='block-row'>
                          <Col md={13} lg={12} xl={10} xxl={10}>
                            <div className='label'>Date Added:</div>
                          </Col>
                          <Col>
                            <div className='fill-div'>
                              {currentUserObj?.created_at &&
                                moment(currentUserObj?.created_at).format(
                                  'D MMM YYYY'
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={13} lg={12} xl={10} xxl={10}>
                            <div className='label'>Added By:</div>
                          </Col>
                          <Col>
                            <div className='fill-div'>
                              {currentUserObj?.added_by?.creator_name}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={12} lg={12} xl={12} xxl={8} className='fill-col'>
                        <Row className='block-row'>
                          <Col md={13} lg={12} xl={10} xxl={10}>
                            <div className='label'>Last Updated:</div>
                          </Col>
                          <Col>
                            <div className='fill-div'>
                              {currentUserObj?.updated_at &&
                                moment(currentUserObj?.updated_at).format(
                                  'D MMM YYYY'
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={13} lg={12} xl={10} xxl={10}>
                            <div className='label'>Updated By:</div>
                          </Col>
                          <Col className='fill-div'>
                            <div>
                              {currentUserObj?.updated_by?.updator_name}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
              </Form>
            </Col>

            {/* {currentUserObj?.id && !isProfileCase && !isEdit && (
              <Col span={4}>
                <div className='send-details-parent-div'>
                  <Spin
                    spinning={sendDetailLoading}
                    className={`send-details-div`}
                  >
                    <div
                      className={`send-details-div ${
                        sendDetailLoading && 'disabled-onclick'
                      }`}
                      onClick={() =>
                        dispatch(sendDetailsToUser(currentUserObj.id))
                      }
                    >
                      <span>Send Login</span>
                      <span>Details to User</span>
                    </div>
                  </Spin>
                </div>
              </Col>
            )} */}
          </Row>
        </Col>
      </Row>
      <div className='empty-div'></div>
    </>
  );
};

export default UserDetailsSection;
