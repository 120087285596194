import React, { useEffect, useState } from 'react';
import { Table, Input, Tag, Space, Popconfirm, DatePicker } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { deleteNpo, fetchAllNpos, setNpoDetailsIsEdit } from './redux/actions';
import SpinnerComponent from '../../components/spinner';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import CustomPagination from '../../components/customPagination/CustomPagination';
// import moment from 'moment';
// import CustomCalendar from '../../components/calendar';
import AMultiSelect from '../../components/AMultiSelect';

const NpoTable = ({ canUpdate, canDelete }) => {
  const [sortedInfo, setSortedInfo] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [currentDelRecord, setCurrentDelRecord] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(() => 1);
  const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [search, setSearch] = useState({});
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);
  const [statusCheckedList, setStatusCheckedList] = useState([]);
  const [regionsCheckedList, setRegionsCheckedList] = useState([]);
  const [datePickerOpened, setDatePickerOpened] = useState(false);
  const [date, setDate] = useState(null);
  const [npoStatuses, SetNpoStatuses] = useState([]);
  const [npoRegions, SetNpoRegions] = useState([]);

  const { allNpos, fetchNposLoading, deleteNpoLoading, totalRecords } =
    useSelector((state) => state.npoSetupReducer);

  const { allNpoStatuses } = useSelector((state) => state.npoStatusReducer);

  const { allNpoRegions } = useSelector((state) => state.npoRegionsReducer);

  useEffect(() => {
    let updatedStatuses = allNpoStatuses.map((el) => {
      const { id, name } = el;
      return { value: id, label: name };
    });
    SetNpoStatuses(updatedStatuses);
  }, [allNpoStatuses]);

  useEffect(() => {
    let updatedRegions = allNpoRegions.map((el) => {
      const { id, name } = el;
      return { value: id, label: name };
    });
    SetNpoRegions(updatedRegions);
  }, [allNpoRegions]);

  useEffect(() => {
    if (page && resultPerPage && page > 0 && resultPerPage > 0) {
      dispatch(
        fetchAllNpos({
          page: page,
          per_page: resultPerPage,
          search,
          sort: sortedInfo?.order && sortedInfo?.columnKey,
          order: sortedInfo?.order
            ? sortedInfo?.order === 'ascend'
              ? 'asc'
              : 'desc'
            : null,
          show_by_default: true,
        })
      );
    }
  }, [req]);

  useEffect(() => {
    let paramsPage = 1;
    if (paramsPage && resultPerPage && paramsPage > 0 && resultPerPage > 0) {
      dispatch(
        fetchAllNpos({
          page: paramsPage,
          per_page: resultPerPage,
          search,
          show_by_default: true,
        })
      );
      setPage(1);
    }
  }, [defaultPageOneReq]);

  useEffect(() => {
    debouncedCallApi();
  }, [sortedInfo]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );

  useEffect(() => {
    setDataSource(allNpos);
  }, [allNpos]);

  const handleSelectOnchange = (value, options, key, setHandler) => {
    if (value.includes('all')) {
      setHandler([...options.map((item) => item.value)]);
      value = options.map((item) => item.value);
    } else if (value.includes('not-all')) {
      setHandler([]);
      value = [];
    } else {
      setHandler(value);
    }
    handleMultiSelect(value, key);
  };
  const handleMultiSelect = (list, key) => {
    setSearch({
      ...search,
      [key]: list,
    });
  };

  const handleChange = (pagination, filters, sorter) => {
    if (datePickerOpened) {
      return;
    }
    setSortedInfo(sorter);
  };

  const handleDelete = (record) => {
    setCurrentDelRecord(record);
    dispatch(deleteNpo(record));
  };

  const searchInput = (key) => (
    <Input
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e.target.value,
        });
        debouncedDefaultPageOneCallApi();
      }}
    />
  );

  const dateInput = (key) => (
    <DatePicker
      placeholder=''
      className='activities-time-picker prod-due-date'
      getPopupContainer={() =>
        document.getElementsByClassName(
          'ant-layout-content layout-content site-layout-background custom-textarea'
        )[0]
      }
      suffixIcon={null}
      format='D MMM YYYY'
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e,
        });
        debouncedDefaultPageOneCallApi();
      }}
      onOpenChange={(e) => setDatePickerOpened(e)}
    />
  );

  const handleNavigate = (id) => navigate(`/npo-setup/update/${id}`);

  const handleDateInput = (value) => {
    setDate(value);
    if (value === null) {
      setSearch({
        ...search,
        start_date: '',
        end_date: '',
      });
    } else {
      setSearch({
        ...search,
        start_date: value[0],
        end_date: value[1],
      });
    }
    debouncedDefaultPageOneCallApi();
  };

  const columns = [
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>Npo Name</div>
            <div>{searchInput('name')}</div>
          </div>
        );
      },
      dataIndex: 'name',
      key: 'name',
      // sorter: (a, b) => a.name.localeCompare(b.name),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      ellipsis: true,
      width: '20%',
      render: (name, { id }) => (
        <div onClick={() => handleNavigate(id)}>{name}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>Region</div>
            <div style={{ width: '100%' }}>
              <AMultiSelect
                dropdownClassName='status-selector'
                debouncedCallApi={debouncedCallApi}
                disabled={false}
                placeholder=''
                selectTags={regionsCheckedList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    npoRegions,
                    'npo_region_id',
                    setRegionsCheckedList
                  )
                }
                data={npoRegions}
                filterOption={(input, option) => {
                  return (
                    String(option?.label)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'npo_region_id',
      key: 'npo_region_id',
      // sorter: (a, b) => a.npo_region_id.localeCompare(b.npo_region_id),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'npo_region_id' && sortedInfo?.order,
      ellipsis: true,
      width: '15%',
      render: (npo_region_id, { id, npo_region }) => (
        <div onClick={() => handleNavigate(id)}>{npo_region?.name}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>
              DIRECTOR CONTACT NAME
            </div>
            <div>{searchInput('contact_name')}</div>
          </div>
        );
      },
      dataIndex: 'contact_name',
      key: 'contact_name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'contact_name' && sortedInfo?.order,
      ellipsis: true,
      width: '20%',
      render: (contact_name, { id }) => (
        <div onClick={() => handleNavigate(id)}>{contact_name}</div>
      ),
    },

    {
      title: () => {
        return (
          <div
            align='top'
            style={{ width: '100%' }}
            className='select-parent-div'
          >
            <div className='special-table-header-text'>
              DIRECTOR CONTACT NUMBER
            </div>
            <div>{searchInput('contact_no')}</div>
          </div>
        );
      },
      dataIndex: 'contact_no',
      key: 'contact_no',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'contact_no' && sortedInfo?.order,
      ellipsis: true,
      width: '20%',
      render: (contact_no, { id }) => (
        <div
          style={{
            whiteSpace: 'break-spaces',
          }}
          onClick={() => handleNavigate(id)}
        >
          {contact_no.length > 180
            ? `${contact_no.substring(0, 180) + ' ...'}`
            : contact_no}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>Status</div>
            <div style={{ width: '100%' }}>
              <AMultiSelect
                dropdownClassName='status-selector'
                debouncedCallApi={debouncedCallApi}
                disabled={false}
                placeholder=''
                selectTags={statusCheckedList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    npoStatuses,
                    'status_id',
                    setStatusCheckedList
                  )
                }
                data={npoStatuses}
                filterOption={(input, option) => {
                  return (
                    String(option?.label)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'status_id',
      key: 'status_id',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'status_id' && sortedInfo?.order,
      ellipsis: true,
      width: '15%',
      align: 'center',
      render: (statusname, { status, id }) => {
        const { name, color } = status;
        return (
          <Tag
            onClick={() => handleNavigate(id)}
            color={color}
            style={{
              color: '#444',
              width: '84px',
              height: '20px',
              textAlign: 'center',
              fontSize: '12px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {name}
          </Tag>
        );
      },
    },
    {
      title: null,
      dataIndex: '',
      key: 'action',
      width: '10%',
      align: 'center',
      render: (_, record) => {
        if (!canUpdate && !canDelete) {
          return;
        }
        return deleteNpoLoading && record.id === currentDelRecord.id ? (
          <SpinnerComponent fontSize={14} />
        ) : (
          <Space size={15} align='baseline'>
            {canUpdate && (
              <EditIcon
                width={18}
                height={18}
                className='custom-icon'
                onClick={() => {
                  handleNavigate(record.id);
                  dispatch(setNpoDetailsIsEdit(true));
                }}
              />
            )}
            {canDelete && (
              <Popconfirm
                title='Are you sure you want to delete this NPO?'
                onConfirm={() => handleDelete(record)}
                okText='Yes'
                cancelText='No'
              >
                <DeleteIcon width={18} height={18} className='custom-icon' />
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table
        className='special-table'
        columns={columns}
        scroll={{ x: !fetchNposLoading }}
        onChange={handleChange}
        dataSource={dataSource}
        pagination={false}
        sortUpIcon={<UpOutlined />}
        sortDownIcon={<DownOutlined />}
        loading={fetchNposLoading}
      />
      <CustomPagination
        page={page}
        setPage={setPage}
        resultPerPage={resultPerPage}
        setResultPerPage={setResultPerPage}
        debouncedCallApi={debouncedCallApi}
        totalRecord={totalRecords}
      />
    </>
  );
};

export default NpoTable;
