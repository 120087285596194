import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Input, Row, Select, InputNumber, message } from 'antd';
// import { ReactComponent as AttachIcon } from '../../../../assets/attach.svg';
import PillarAttachPopup from './pillarsAttachmentsPopup';
import axios from 'axios';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { useSelector } from 'react-redux';
// import { useDispatch, useSelector } from 'react-redux';
// import { setPillarData } from '../../redux/actions';

const { Option } = Select;
const statusOptions = [
  {
    value: 'good',
    label: 'Good',
  },
  {
    value: 'medium',
    label: 'Medium',
  },
  {
    value: 'poor',
    label: 'Poor',
  },
];

const PillarsSection = ({ record, isUpdate, isRevisionUpdateCase }) => {
  const [data, setData] = useState([]);
  const { defaultPillars } = useSelector((state) => state.sitesReducer);
  // const [currentRecordId, setCurrentRecordId] = useState(null);
  // const { isUpdateImsDoc } = useSelector((state) => state.sitesReducer);
  // const dispatch = useDispatch();

  // const fetchPillarsData = async () => {
  //   const res = await axios.get(`${baseURL}/api/v1/sites/pillars`);
  //   // console.log('fetchPillarsData', res.data);
  //   const { data, success } = res.data;

  //   if (success) {
  //     setData(data);
  //     window.pillarsData = [...data];
  //     // dispatch(setPillarData(data));
  //   }
  // };

  const updatePillarsData = () => {
    const { answers } = record;

    let pillar1Q = [];
    let pillar2Q = [];
    let pillar3Q = [];
    let pillar4Q = [];
    if (answers.length > 0) {
      const [first, sec, third, fourth, ...rest] = answers;
      let firstPillarObj = {};
      let secPillarObj = {};
      let thirdPillarObj = {};
      let fourthPillarObj = {};
      rest.forEach((element) => {
        const {
          attachments_urls,
          id,
          has_quantity,
          is_checked,
          quantity,
          pillar_status,
          question,
        } = element;

        const attachment = attachments_urls.map((img) => {
          const { id, url } = img;
          let name = url.split('/')[url.split('/').length - 1].split('?')[0];
          return { id, key: id, name, isLocal: false };
        });

        const { pillar_id } = question;
        let obj = {
          // has_quantity,
          is_checked,
          quantity,
          attachment,
          status: pillar_status,
        };
        if (pillar_id === 1) {
          pillar1Q.push({
            ...question,
            ...obj,
            value: element?.title === '- Other' ? element.value : element.value,
          });
        }
        if (pillar_id === 2) {
          pillar2Q.push({
            ...question,
            ...obj,
          });
        }
        if (pillar_id === 3) {
          pillar3Q.push({
            ...question,
            ...obj,
          });
        }
        if (pillar_id === 4) {
          pillar4Q.push({
            ...question,
            ...obj,
          });
        }
      });
      firstPillarObj = {
        ...first.pillar,
        status: first.pillar_status,
        questions: pillar1Q,
      };
      secPillarObj = {
        ...sec.pillar,
        status: sec.pillar_status,
        questions: pillar2Q,
      };
      thirdPillarObj = {
        ...third.pillar,
        status: third.pillar_status,
        questions: pillar3Q,
      };
      fourthPillarObj = {
        ...fourth.pillar,
        status: fourth.pillar_status,
        questions: pillar4Q,
      };

      let final = [
        firstPillarObj,
        secPillarObj,
        thirdPillarObj,
        fourthPillarObj,
      ];
      // console.log('final', final);
      setData(final);
      window.pillarsData = [...final];
    }
  };

  const handleUpdateUpdate = (item, setImages) => {
    message.success('Attachment deleted successfully.');

    const pillarsData = window.pillarsData;

    const { pillarId, key, questionId } = item;

    const updated = pillarsData.map((el) =>
      el.id == pillarId
        ? {
            ...el,
            questions: el.questions.map((q) => {
              if (q.id == questionId) {
                let att = q.attachment.filter((img) => img.key != key);
                setImages(att);
                return {
                  ...q,
                  attachment: att,
                };
              }
              return q;
            }),
          }
        : el
    );

    setData([...updated]);
    window.pillarsData = [...updated];
  };

  useEffect(() => {
    if (isUpdate) {
      updatePillarsData();
    } else {
      // fetchPillarsData();
      window.pillarsData = defaultPillars;
      setData(defaultPillars);
    }
  }, []);

  // useEffect(() => {
  //   if (record.id !== currentRecordId) {
  //     fetchDetails();
  //   }
  // }, [record]);

  const onChangeStatus = (status, parentIndex) => {
    // console.log('data', data);
    // const updated = data.map((x) => (x.id === id ? { ...x, status } : x));
    // setData(updated);

    data[parentIndex].status = status;
    // setData([...data]);
    window.pillarsData = [...data];
    // dispatch(setPillarData([...data]));
  };

  const [attachPopupVisible, setAttachPopupVisible] = useState(false);
  const [currentOpenedId, setCurrentOpenedId] = useState(null);

  const FieldRow = ({ fieldData, parentIndex, index }) => {
    const {
      id,
      attachment,
      has_quantity,
      quantity,
      is_checked,
      title,
      value,
      pillar_id,
    } = fieldData;
    const [otherValue, setOtherValue] = useState(value);
    const [qty, setQty] = useState(quantity);
    const [isChecked, setIsChecked] = useState(is_checked);
    const [images, setImages] = useState(attachment || []);

    const handleClick = (e) => {
      e.stopPropagation();
      const { checked } = e.target;
      data[parentIndex].questions[index].is_checked = checked;
      setIsChecked(checked);
      // setData([...data]);
      window.pillarsData = [...data];
      // dispatch(setPillarData([...data]));
    };

    const onChangeQty = (e) => {
      const { value } = e.target;
      data[parentIndex].questions[index].quantity = value;
      // setData([...data]);
      // dispatch(setPillarData([...data]));
      window.pillarsData = [...data];
    };

    const updateOther = (e) => {
      const { value } = e.target;
      data[parentIndex].questions[index].value = value;
      setData([...data]);
      window.pillarsData = [...data];

      setOtherValue(value);
      // dispatch(setPillarData([...data]));
    };

    const handleAddImage = (img) => {
      let updatedImages = [
        img,
        ...data[parentIndex].questions[index].attachment,
      ];
      data[parentIndex].questions[index].attachment = updatedImages;
      // console.log('handleAddImage data', data);
      setData([...data]);
      window.pillarsData = [...data];
      // setImages(updatedImages);
      // dispatch(setPillarData([...data]));
    };
    let isOtherField = title.includes('What partnerships do they have?');

    const deleteAttachment = async (item) => {
      const { isLocal, key } = item;

      if (isLocal) {
        handleUpdateUpdate(item, setImages);
      } else {
        const res = await axios.delete(
          `${baseURL}/api/v1/sites/delete_attachment?id=${key}`
        );

        const { success } = res.data;
        if (success) {
          handleUpdateUpdate(item, setImages);
        } else {
          message.error('Something went wrong!');
        }
      }
    };

    return (
      <>
        <Col span={24}>
          <Row justify='start'>
            <Col span={20}>
              {!isOtherField ? (
                <Checkbox
                  id={id}
                  name={title}
                  onChange={handleClick}
                  checked={isChecked}
                  disabled={isRevisionUpdateCase}
                >
                  <div className='label question-label'>
                    <span>{title}</span>
                    {/* {title === '- Other' && ( */}
                    <Input
                      size='small'
                      value={otherValue}
                      readOnly={isRevisionUpdateCase}
                      className='other'
                      onChange={(e) => setOtherValue(e.target.value)}
                      onBlur={updateOther}
                      style={{
                        display: title !== '- Other' && 'none',
                      }}
                    />
                    {/* )} */}
                  </div>
                </Checkbox>
              ) : (
                <div className='label question-label'>
                  <span>{title}</span>
                </div>
              )}
            </Col>
            <Col>
              {!isOtherField && (
                <PillarAttachPopup
                  images={images}
                  attachPopupVisible={attachPopupVisible}
                  setAttachPopupVisible={setAttachPopupVisible}
                  // record={record}
                  // setCurrentUpdateObj={setCurrentImgUpdateObj}
                  // currentUpdateObj={currentImgUpdateObj}
                  id={id}
                  currentOpenedId={currentOpenedId}
                  setCurrentOpenedId={setCurrentOpenedId}
                  handleAddImage={handleAddImage}
                  deleteAttachment={deleteAttachment}
                  pillarId={pillar_id}
                  isRevisionUpdateCase={isRevisionUpdateCase}
                />
              )}
            </Col>
            <Col>
              {!isOtherField && has_quantity && (
                <InputNumber
                  width={100}
                  min={0}
                  placeholder='Qty'
                  value={qty}
                  readOnly={isRevisionUpdateCase}
                  disabled={isRevisionUpdateCase}
                  onBlur={onChangeQty}
                  onChange={(e) => setQty(e)}
                />
              )}
            </Col>
          </Row>
        </Col>
      </>
    );
  };

  const Selector = ({ status, parentIndex }) => {
    const [statusValue, setStatusValue] = useState(status);

    return (
      <Select
        size='middle'
        // disabled={!isEdit || isProfileCase}
        // readOnly={true}
        className='department-select status-select'
        dropdownClassName='select-dropdown-custom status-select-dropdown'
        getPopupContainer={() =>
          document.getElementsByClassName(
            'ant-layout-content layout-content site-layout-background custom-textarea'
          )[0]
        }
        showSearch
        placeholder='Select Status'
        optionFilterProp='children'
        filterOption={(input, option) =>
          option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        value={statusValue}
        disabled={isRevisionUpdateCase}
        onChange={(e) => {
          setStatusValue(e);
          onChangeStatus(e, parentIndex);
        }}
      >
        {statusOptions.map(({ value, label }) => {
          return (
            <Option key={value} value={value}>
              <div className=''>
                <span className={`dot ${value}`}></span>
                {label}
              </div>
            </Option>
          );
        })}
      </Select>
    );
  };

  const PillarLabelRow = ({ data, parentIndex }) => {
    const { name, status } = data;
    return (
      <Col span={24}>
        <Row justify='space-between'>
          <Col>
            <label>{name}</label>
          </Col>
          <Col>
            <Selector status={status} parentIndex={parentIndex} />
          </Col>
        </Row>
      </Col>
    );
  };

  if (data.length === 0) {
    return <></>;
  }

  const PillarCol = ({ pillarColData, parentIndex }) => {
    const { questions } = pillarColData;

    return (
      <Row gutter={[0, 20]}>
        <PillarLabelRow data={pillarColData} parentIndex={parentIndex} />
        {questions.map((question, i) => (
          <FieldRow fieldData={question} parentIndex={parentIndex} index={i} />
        ))}
      </Row>
    );
  };

  let [first, second, third, fourth] = data;
  // console.count('Render PillarsSection');

  return (
    <Row className='pillar-section-parent-row' gutter={[20, 20]} align='top'>
      <Col span={8} className='border-right'>
        <PillarCol pillarColData={first} parentIndex={0} />
      </Col>
      <Col span={8} className='border-right'>
        <div className='pillar2-div'>
          <PillarCol pillarColData={second} parentIndex={1} />
        </div>
        <div>
          <PillarCol pillarColData={third} parentIndex={2} />
        </div>
      </Col>
      <Col span={8}>
        <PillarCol pillarColData={fourth} parentIndex={3} />
      </Col>
    </Row>
  );
};

export default React.memo(PillarsSection);
