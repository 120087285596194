import * as constants from './constants';

const initialState = {
  allCoachTypes: [],
  fetchingLoading: true,
  updateLoading: false,
  totalRecords: 0,
  isAdding: false,
};

const learnerStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_COACH_TYPES:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_COACH_TYPES_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allCoachTypes: [...state.allCoachTypes, action.data],
        isAdding: false,
      };
    case constants.CREATE_COACH_TYPES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };

    case constants.UPDATE_ORDER_COACH_TYPES:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.UPDATE_ORDER_COACH_TYPES_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allCoachTypes: action.data,
      };
    case constants.UPDATE_ORDER_COACH_TYPES_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };

    case constants.FETCH_COACH_TYPES:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_COACH_TYPES_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allCoachTypes: action.data,
        isAdding: false,
      };
    case constants.FETCH_COACH_TYPES_ERROR:
      return {
        ...state,
        fetchingLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_COACH_TYPES:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_COACH_TYPES_SUCCESS:
      const updated = state.allCoachTypes.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        allCoachTypes: updated,
        updateLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_COACH_TYPES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_COACH_TYPES:
      return {
        ...state,
        updateLoading: true,
        isAdding: false,
      };
    case constants.DELETE_COACH_TYPES_SUCCESS:
      const updatedData = state.allCoachTypes.filter(
        (el) => el.id !== action.data
      );
      return {
        ...state,
        allCoachTypes: updatedData,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_COACH_TYPES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.IS_ADDING_UPDATE:
      return {
        ...state,
        isAdding: action.data,
      };

    default:
      return state;
  }
};

export default learnerStatusReducer;
