import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchAllNpos } from '../NpoSetup/redux/actions';
import { fetchSiteStatuses } from '../SiteStatuses/redux/actions';
import { fetchSiteTypes } from '../SiteTypes/redux/actions';
import { fetchAllTemplates } from '../TemplatesManager/redux/actions';
// import GraphSection from './graphSection';
import GraphSection from '../learners/graphSection/index';
import TableSection from './tableSection';
import './index.less';
import { fetchNpoRegions } from '../NpoRegions/redux/actions';
import axios from 'axios';
import { baseURL } from '../../globalUtils/axiosConfig';
import { setPillarData } from './redux/actions';

const Sites = () => {
  const [statusCheckList, setStatusCheckList] = useState([]);
  const [npoRegionsCheckList, setNpoRegionsCheckList] = useState([]);
  const [siteTypeCheckList, setSiteTypeCheckList] = useState([]);
  const [npoCheckList, setNpoCheckList] = useState([]);
  const [sitesCheckList, setSitesCheckList] = useState([]);
  const [allSites, setAllSites] = useState([]);

  const { allSiteStatuses, allSiteTypes, allNpos, allNpoRegions } = useSelector(
    (state) => ({
      allSiteStatuses: state.siteStatusReducer.allSiteStatuses,
      allSiteTypes: state.siteTypesReducer.allSiteTypes,
      allNpos: state.npoSetupReducer.allNpos,
      allNpoRegions: state.npoRegionsReducer.allNpoRegions,
    })
  );

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const fetchPillarsData = async () => {
    const res = await axios.get(`${baseURL}/api/v1/sites/pillars`);
    const { data, success } = res.data;

    if (success) {
      dispatch(setPillarData(data));
      window.defaultPillars = data;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(fetchAllTemplates({})),
        dispatch(fetchAllNpos({ search: {} })),
        dispatch(fetchSiteTypes()),
        dispatch(fetchSiteStatuses()),
        dispatch(fetchNpoRegions()),
      ]);
    };
    fetchData();
    fetchSites();
  }, [dispatch]);

  const nporegOptions = useMemo(
    () =>
      allNpoRegions.map((el) => {
        const { name, id } = el;
        return { value: id, label: name };
      }),
    [allNpoRegions]
  );

  const statusOptions = useMemo(
    () =>
      allSiteStatuses.map((el) => {
        const { name, id } = el;
        return { value: id, label: name };
      }),
    [allSiteStatuses]
  );

  const siteTypeOptions = useMemo(
    () =>
      allSiteTypes.map((el) => {
        const { name, id } = el;
        return { value: id, label: name };
      }),
    [allSiteTypes]
  );

  const npoOptions = useMemo(
    () =>
      allNpos.map((el) => {
        const { name, id } = el;
        return { value: id, label: name };
      }),
    [allNpos]
  );

  const fetchSites = async () => {
    const res = await axios.get(
      `${baseURL}/api/v1/sites?page=1&per_page=all&sort=name&order=asc&page_type=sites`
    );

    const { success, data } = res.data;
    if (success) {
      setAllSites(data.sites);
    }
  };

  const sitesOptions = useMemo(
    () =>
      allSites.map((el) => {
        const { name, id } = el;
        return { value: id, label: name };
      }),
    [allSites]
  );

  return (
    <div className='page-top-div'>
      <span
        className='text-span'
        style={{
          lineHeight: '17px',
        }}
      >
        REPORT SUMMARY - {pathname?.split('/')[2]}
      </span>
      <GraphSection />
      <TableSection
        statusCheckList={statusCheckList}
        setStatusCheckList={setStatusCheckList}
        statusOptions={statusOptions}
        npoRegionsCheckList={npoRegionsCheckList}
        setNpoRegionsCheckList={setNpoRegionsCheckList}
        npoRegionsOptions={nporegOptions} // npoRegionsOptions
        siteTypeCheckList={siteTypeCheckList}
        setSiteTypeCheckList={setSiteTypeCheckList}
        siteTypeOptions={siteTypeOptions}
        npoCheckList={npoCheckList}
        setNpoCheckList={setNpoCheckList}
        npoOptions={npoOptions}
        sitesCheckList={sitesCheckList}
        setSitesCheckList={setSitesCheckList}
        sitesOptions={sitesOptions}
        name={pathname?.split('/')[2]}
        fetchPillarsData={fetchPillarsData}
      />
    </div>
  );
};

export default Sites;
