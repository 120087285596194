import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { Route, Routes, useNavigate } from 'react-router-dom';
// import Sale from '../Sales';
import Sites from '../Sites';
import Learners from '../learners';
import Production from '../Production';
import SidebarComponent from './sidebar';
import UserManager from '../UserManager';
import ProjectStatuses from '../ProjectStatuses';
// import DocumentControl from '../DocumentControl';
import LearnerStatuses from '../LearnerStatuses';
import DefaultDocumentFields from '../DefaultDocumentFields';
import ClientsManager from '../ClientsManager';
import NpoSetup from '../NpoSetup';
// import UserTypes from '../UserTypes';
// import Departments from '../ActivityTypes';
import './index.less';
import UserDetails from '../UserManager/userDetails';
import ClientDetails from '../ClientsManager/clientDetails';
import axios from 'axios';
import { baseURL } from '../../globalUtils/axiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../login/redux/actions';
import LearnerCamps from '../LearnerCamps';
import LearnerTypes from '../LearnerTypes';
import CoachTypes from '../CoachTypes';
import CoachStatuses from '../CoachStatuses';
import SiteTypes from '../SiteTypes';
import SiteStatuses from '../SiteStatuses';
import NpoStatuses from '../NpoStatuses';
import NpoRegions from '../NpoRegions';
import ProgrammeTypes from '../ProgrammeTypes';
import NpoDetail from '../NpoSetup/detail/index.jsx';
import InterventionTypes from '../InterventionTypesManager';
import InterventionTypesManager from '../InterventionTypesManager/create';
import TemplatesManager from '../TemplatesManager';
import TemplatesManagerDetails from '../TemplatesManager/create';
import Classes from '../Classes';
import Coaches from '../coaches';
import NPOsLearners from '../NPOsLearners';
import NPOsCoaches from '../NPOsCoaches';
import NPOsSites from '../NPOsSites';
import NPOsClasses from '../NPOsClasses';

// import DocStatuses from '../DocumentStatuses';
// import DocumentTypesManager from '../DocumentsTypesManager';
// import TemplateCreation from '../DocumentsTypesManager/documentTemplateCreate/index.jsx';
// import Projects from '../Projects';

const LayoutComponent = () => {
  const { Content } = Layout;
  const { user } = useSelector((state) => state.authReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const validateUser = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/v1/users/check_user_token`
        );
        const { success } = response.data;
        if (success) {
          dispatch(
            actions.loginUserSuccess({
              user: response.data.data,
              navigate,
              shouldNavigate: false,
            })
          );
        }
        if (!success) {
          dispatch(actions.logoutUserSuccess(response.data.data));
        }
      } catch (error) {
        console.log(error);
      }
    };
    validateUser();
  }, []);

  return (
    <Layout className='most-parent-layout'>
      <Layout className='site-layout'>
        <SidebarComponent />
        <Content className='layout-content site-layout-background custom-textarea'>
          <Routes>
            {user?.permissions?.report_summary?.coaches?.view && (
              <Route path='/reports-summary/coaches' element={<Coaches />} />
            )}
            {user?.permissions?.report_summary?.learners?.view && (
              <Route path='/reports-summary/learners' element={<Learners />} />
            )}
            {user?.permissions?.report_summary?.sites?.view && (
              <Route path='/reports-summary/sites' element={<Sites />} />
            )}
            {user?.permissions?.report_summary?.classes?.view && (
              <Route path='/reports-summary/classes' element={<Classes />} />
            )}
            <Route path='/npo/learners/:npo_id' element={<NPOsLearners />} />
            <Route path='/npo/coaches/:npo_id' element={<NPOsCoaches />} />
            <Route path='/npo/sites/:npo_id' element={<NPOsSites />} />
            <Route path='/npo/classes/:npo_id' element={<NPOsClasses />} />

            {user?.permissions?.system_users_manager?.view && (
              <>
                <Route
                  path='/settings/system-users-manager'
                  element={<UserManager />}
                />
                <Route
                  path='/settings/system-users-manager/update/:id'
                  element={<UserDetails />}
                />
                {user?.permissions?.system_users_manager?.add_edit && (
                  <>
                    <Route
                      path='/settings/system-users-manager/create'
                      element={<UserDetails />}
                    />
                  </>
                )}
              </>
            )}
            {user?.permissions?.coach_users_manager?.view && (
              <>
                <Route
                  path='/settings/coach-users-manager'
                  element={<UserManager />}
                />
                <Route
                  path='/settings/coach-users-manager/update/:id'
                  element={<UserDetails />}
                />
                {user?.permissions?.coach_users_manager?.add_edit && (
                  <>
                    <Route
                      path='/settings/coach-users-manager/create'
                      element={<UserDetails />}
                    />
                  </>
                )}
              </>
            )}
            {user?.permissions?.learner_users_manager?.view && (
              <>
                <Route
                  path='/settings/learner-users-manager'
                  element={<UserManager />}
                />
                <Route
                  path='/settings/learner-users-manager/update/:id'
                  element={<UserDetails />}
                />
                {user?.permissions?.learner_users_manager?.add_edit && (
                  <>
                    <Route
                      path='/settings/learner-users-manager/create'
                      element={<UserDetails />}
                    />
                  </>
                )}
              </>
            )}
            {user?.permissions?.templates_manager?.view && (
              <>
                <Route
                  path='/settings/templates-manager'
                  element={<TemplatesManager />}
                />
                {user?.permissions?.templates_manager?.add_edit && (
                  <Route
                    path='/settings/templates-manager/:id'
                    element={<TemplatesManagerDetails />}
                  />
                )}
              </>
            )}

            {user?.permissions?.programme_types?.view && (
              <Route
                path='/settings/programme-types'
                element={<ProgrammeTypes />}
              />
            )}

            {user?.permissions?.intervention_types?.view && (
              <>
                <Route
                  path='/settings/intervention-types-manager'
                  element={<InterventionTypes />}
                />
                <Route
                  path='/settings/intervention-types-manager/update/:id'
                  element={<InterventionTypesManager />}
                />
                {user?.permissions?.intervention_types?.add_edit && (
                  <>
                    <Route
                      path='/settings/intervention-types-manager/create'
                      element={<InterventionTypesManager />}
                    />
                  </>
                )}
              </>
            )}
            {user?.permissions?.npo_regions?.view && (
              <Route path='/settings/npo-regions' element={<NpoRegions />} />
            )}
            {user?.permissions?.npo_statuses?.view && (
              <Route path='/settings/npo-statuses' element={<NpoStatuses />} />
            )}
            {user?.permissions?.site_types?.view && (
              <Route path='/settings/site-types' element={<SiteTypes />} />
            )}
            {user?.permissions?.site_statuses?.view && (
              <Route
                path='/settings/site-statuses'
                element={<SiteStatuses />}
              />
            )}
            {user?.permissions?.coach_types?.view && (
              <Route path='/settings/coach-types' element={<CoachTypes />} />
            )}
            <Route
              path='/settings/coach-statuses'
              element={<CoachStatuses />}
            />
            {user?.permissions?.learner_types?.view && (
              <Route
                path='/settings/learner-types'
                element={<LearnerTypes />}
              />
            )}
            {user?.permissions?.learner_statuses?.view && (
              <Route
                path='/settings/learner-statuses'
                element={<LearnerStatuses />}
              />
            )}
            {user?.permissions?.learner_camps?.view && (
              <Route
                path='/settings/learner-camps'
                element={<LearnerCamps />}
              />
            )}
            {user?.permissions?.project_statuses?.view && (
              <Route
                path='/settings/project-statuses'
                element={<ProjectStatuses />}
              />
            )}
            <Route path='/profile' element={<UserDetails />} />
            {user?.permissions?.npo_setup?.view && (
              <>
                <Route path='/npo-setup' element={<NpoSetup />} />
                <Route path='/npo-setup/update/:id' element={<NpoDetail />} />
              </>
            )}
            {user?.permissions?.npo_setup?.add_edit && (
              <>
                <Route path='/npo-setup/create' element={<NpoDetail />} />
              </>
            )}
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;
