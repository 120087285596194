import React from 'react';
import { Col, DatePicker, Row, Select, Form, Input, Switch } from 'antd';
import ImageComponent from '../useAttachments';

const { Option } = Select;

const FormItem = ({ item, isRevisionUpdateCase }) => {
  const { id, name, field_type, ...rest } = item;

  let classname = `${isRevisionUpdateCase ? 'ims-doc-readonly-input' : ''}`;

  let placeholder = isRevisionUpdateCase ? '' : name;

  if (field_type === 'date_field') {
    return (
      <Form.Item
        className={classname}
        label={name}
        name={id}
        key={id}
        labelCol={{ span: 24 }}
      >
        <DatePicker
          size='small'
          style={{
            width: '100%',
          }}
          format={'DD MMM YYYY'}
          suffixIcon={null}
          placeholder={placeholder}
          disabled={isRevisionUpdateCase}
        />
      </Form.Item>
    );
  }
  if (field_type === 'text_field') {
    return (
      <Form.Item
        className={classname}
        label={name}
        name={id}
        key={id}
        labelCol={{ span: 24 }}
      >
        <Input
          placeholder={placeholder}
          readOnly={isRevisionUpdateCase}
          disabled={isRevisionUpdateCase}
          size='small'
        />
      </Form.Item>
    );
  }

  if (field_type === 'select_field') {
    const { field_options } = rest;

    return (
      <Form.Item
        className={classname}
        label={name}
        name={id}
        key={id}
        labelCol={{ span: 24 }}
      >
        <Select placeholder={placeholder} disabled={isRevisionUpdateCase}>
          {field_options.map((el) => {
            const { id, name } = el;
            return (
              <Option value={name} key={id}>
                {name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }
};

const ExpandedRow = ({
  fields,
  formRef,
  onFinish,
  isRevisionUpdateCase,
  setExpandedRowKeys,
  images,
  setImages,
  attachPopupVisible,
  setAttachPopupVisible,
  currentUpdateObj,
  shouldDisabled,
  record,
}) => {
  const { isAdd, class_id } = record;
  return (
    <Row>
      <Col span={12}>
        <Row gutter={[10, 0]} className='static-fields-row'>
          <Col span={12} className=''>
            <Form.Item label='class ID #:'>
              {isAdd ? '[Auto Fill]' : class_id}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='AYDF Audited'
              name='aydf_audited'
              key='aydf_audited'
              labelCol={{ span: 13 }}
              rules={[
                {
                  required: true,
                  message: 'Required!',
                },
              ]}
            >
              <Switch
                size='small'
                disabled={isRevisionUpdateCase}
                defaultChecked={record?.aydf_audited}
              />
            </Form.Item>
          </Col>
          <Col span={12} className=''>
            <Form.Item label='Region:'>
              {isAdd ? '[Auto Fill]' : record?.npo?.npo_region?.name}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='AYDF Coordinator Audited'
              name='aydf_coordinator_audited'
              key='aydf_coordinator_audited'
              labelCol={{ span: 13 }}
              rules={[
                {
                  required: true,
                  message: 'Required!',
                },
              ]}
            >
              <Switch
                size='small'
                disabled={isRevisionUpdateCase}
                defaultChecked={record?.aydf_coordinator_audited}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Row
              justify='start'
              align='top'
              gutter={[15, 0]}
              className='attach-row ant-form-item'
            >
              <Col className=''>
                <Form.Item
                  label='Attachments:'
                  labelCol={{ span: 24 }}
                ></Form.Item>
              </Col>
              <Col className='count-div'>
                <div>{images?.length}</div>
              </Col>
              <Col>
                <ImageComponent
                  images={images}
                  setImages={setImages}
                  attachPopupVisible={attachPopupVisible}
                  setAttachPopupVisible={setAttachPopupVisible}
                  currentUpdateObj={currentUpdateObj}
                  shouldDisabled={shouldDisabled}
                  isRevisionUpdateCase={isRevisionUpdateCase}
                  record={record}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Form.Item
              label='NPO Coordinator Audited'
              name='npo_coordinator_audited'
              key='npo_coordinator_audited'
              labelCol={{ span: 13 }}
              rules={[
                {
                  required: true,
                  message: 'Required!',
                },
              ]}
            >
              <Switch
                size='small'
                disabled={isRevisionUpdateCase}
                defaultChecked={record?.npo_coordinator_audited}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <Form
          className='ims-doc-fields-form'
          form={formRef}
          onFinish={onFinish}
        >
          <Row gutter={[20, 0]}>
            {fields.map((el) => {
              return (
                <Col span={12} key={el.id}>
                  <FormItem
                    item={el}
                    isRevisionUpdateCase={isRevisionUpdateCase}
                  />
                </Col>
              );
            })}
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default ExpandedRow;
