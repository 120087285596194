import * as constants from "./learnerConstants";

export const fetchLearnerStatuses = (data) => {
  return {
    type: constants.FETCH_LEARNER_STATUSES,
    data,
  };
};

export const fetchLearnerStatusesSuccess = (data) => {
  return {
    type: constants.FETCH_LEARNER_STATUSES_SUCCESS,
    data: data,
  };
};

export const fetchLearnerStatusesError = (data) => {
  return {
    type: constants.FETCH_LEARNER_STATUSES_ERROR,
    data: data,
  };
};

export const updateLearnerStatuses = (data) => {
  return {
    type: constants.UPDATE_LEARNER_STATUSES,
    data: data,
  };
};

export const updateLearnerStatusesSuccess = (data) => {
  return {
    type: constants.UPDATE_LEARNER_STATUSES_SUCCESS,
    data: data,
  };
};

export const updateLearnerStatusesError = () => {
  return {
    type: constants.UPDATE_LEARNER_STATUSES_ERROR,
  };
};

export const updateLearnerOrder = (data) => {
  return {
    type: constants.UPDATE_ORDER_LEARNER_STATUS,
    data: data,
  };
};

export const updateLearnerOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_ORDER_LEARNER_STATUS_SUCCESS,
    data: data,
  };
};

export const updateLearnerOrderError = () => {
  return {
    type: constants.UPDATE_ORDER_LEARNER_STATUS_ERROR,
  };
};

export const deleteLearnerStatuses= (data) => {
  return {
    type: constants.DELETE_LEARNER_STATUSES,
    data: data,
  };
};

export const deleteLearnerStatusesSuccess = (data) => {
  return {
    type: constants.DELETE_LEARNER_STATUSES_SUCCESS,
    data: data,
  };
};

export const deleteLearnerStatusesError = () => {
  return {
    type: constants.DELETE_LEARNER_STATUSES_ERROR,
  };
};

export const createLearnerStatuses = (data, id) => {
  return {
    type: constants.CREATE_LEARNER_STATUSES,
    data: data,
  };
};

export const createLearnerStatusesSuccess = (data) => {
  return {
    type: constants.CREATE_LEARNER_STATUSES_SUCCESS,
    data: data,
  };
};

export const createLearnerStatusesError = (id) => {
  return {
    type: constants.CREATE_LEARNER_STATUSES_ERROR,
    id,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};
