import { useSelector } from 'react-redux';

const UsePermissions = () => {
  const { user } = useSelector((state) => state.authReducer);

  let { coaches } = user.permissions.report_summary;

  let canView = coaches.view;
  let canEdit = coaches.add_edit;
  let canExport = coaches.export;
  let canDelete = coaches.delete;

  return [canView, canEdit, canExport, canDelete];
};

export default UsePermissions;
