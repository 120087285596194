import React from "react";

const TooltipCustom = ({ text, children }) => {
  return (
    <span data-text={text} className="tooltip">
      {children}
    </span>
  );
};

export default TooltipCustom;
