import React, { useEffect, useState } from 'react';
import { Table, Input, Form, Col, Row, DatePicker } from 'antd';
import moment from 'moment';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
// import { ReactComponent as EditIcon } from '../../../../assets/edit-icon.svg';
// import { ReactComponent as DeleteIcon } from '../../../../assets/delete-icon.svg';
// import { ReactComponent as SaveIcon } from '../../../../assets/save-icon.svg';
// import { ReactComponent as AttachIcon } from '../../../../assets/attach.svg';
// import { ReactComponent as CrossIcon } from '../../../../assets/cross-icon.svg';
import * as actions from './redux/actions';
// import SpinnerComponent from '../../../../components/spinner';
// import NonEditRowAttach from './nonEditRowAttachView';
// import CustomCalendar from '../../../../components/calendar';
import RevisionsEditableCell from './revisionsEditableCell';
// import AMultiSelect from '../../../../components/AMultiSelect';
import _ from 'lodash';
// import { getOptions } from '../../../Sales/utils';
import { filterData } from './utils';

function ClassesTable({
  parentRecordID,
  statusOptions,
  doc_revisions,
  classes,
  permissions,
}) {
  const [data, setData] = useState(classes);
  const [editingKey, setEditingKey] = useState('');
  const [sortedInfo, setSortedInfo] = useState(null);
  const [editingLoading, setEditingLoading] = useState(false);
  const [currentUpdateObj, setCurrentUpdateObj] = useState({});
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  // images contains all attachments of item
  const [attachPopupVisible, setAttachPopupVisible] = useState(false);
  // const [search, setSearch] = useState({});
  const [date, setDate] = useState(null);
  const [statusCheckedList, setStatusCheckedList] = useState([]);
  const [deletingActivityId, setDeleteActivityId] = useState();
  const [showTable, setShowTable] = useState(false);
  const [req, setReq] = useState(false);

  const [search, setSearch] = useState({
    createdDateString: '',
  });

  const isEditing = (record) =>
    record.key === editingKey || record.id === editingKey;

  const {
    allImsRevisions,
    fetchingLoading,
    updateLoading,
    isAddImsRevision,
    isUpdateImsRevision,
    deletingLoading,
  } = useSelector((state) => state.imsRevisionsReducer);

  const { user } = useSelector((state) => state.authReducer);

  const handleUpdateStatus = (isAdd, isUpdate) => {
    dispatch(
      actions.updateImsRevisionsIsAddIsUpdate({
        isAddImsRevision: isAdd,
        isUpdateImsRevision: isUpdate,
      })
    );
  };

  // useEffect(() => {
  //   let order = sortedInfo?.order === "ascend" ? "asc" : "desc";

  //   if (doc_revisions.length > 0) {
  //     let revision_id = doc_revisions.map((rev) => rev.id);
  //     dispatch(
  //       actions.fetchImsRevisions({
  //         id: parentRecordID,
  //         search: { ...search, revision_id },
  //         sort: sortedInfo?.order && sortedInfo?.columnKey,
  //         order: sortedInfo?.order ? order : null,
  //       })
  //     );
  //   } else {
  //     dispatch(actions.fetchImsRevisionsSuccess([]));
  //   }
  // }, [req, doc_revisions]);

  // useEffect(() => {
  //   debouncedCallApi();
  // }, [sortedInfo]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  useEffect(() => {
    setEditingLoading(updateLoading);
    if (!updateLoading) {
      setEditingKey('');
    }

    // return () => {
    //   handleUpdateStatus(false, false);
    // };
  }, [updateLoading]);

  // useEffect(() => {
  //   if (allImsRevisions.length === 0) {
  //     return setData(allImsRevisions);
  //   } else if (allImsRevisions.length > 0) {
  //     let updatedData = allImsRevisions.map((el) => {
  //       const { revision_attachments, ...rest } = el;

  //       let updatedImages;
  //       if (revision_attachments?.length > 0) {
  //         updatedImages = revision_attachments.map((img) => {
  //           const { url, id } = img;
  //           let name = url.split('/')[url.split('/').length - 1].split('?')[0];

  //           return { key: id, id, image: url, isLocal: false, name };
  //         });
  //       }

  //       return { ...rest, images: updatedImages };
  //     });
  //     setData(updatedData);
  //   }
  // }, [allImsRevisions]);

  // const edit = (record) => {
  //   const { isAdd } = record;
  //   if (!isAdd) {
  //     setCurrentUpdateObj(record);
  //     let filteredItems = data.filter((el) => el.id !== editingKey);
  //     let final = filteredItems.filter((el) => el.key !== editingKey);
  //     // setData(final);
  //     setImages(record?.images || []);
  //   }
  //   const { revision_date } = record;
  //   form.setFieldsValue({
  //     ...record,
  //     revision_date: moment(revision_date),
  //     username: record?.user?.first_name + ' ' + record?.user?.surname,
  //   });
  //   !isAdd && handleUpdateStatus(false, true);
  //   setEditingKey(record.id);
  // };

  // const cancel = () => {
  //   if (isUpdateImsRevision) {
  //     setData(data);
  //   } else {
  //     // if (editingKey) {
  //     let filteredItems = data.filter((el) => el.id !== editingKey);
  //     let final = filteredItems.filter((el) => el.key !== editingKey);
  //     setData(final);
  //     // }
  //   }
  //   handleUpdateStatus(false, false);
  //   setEditingKey('');
  // };

  // const save = async (key) => {
  //   setEditingKey(key);
  //   try {
  //     const row = await form.validateFields();
  //     const newData = [...data];
  //     const index = newData.findIndex(
  //       (item) => key === item.key || key === item.id
  //     );
  //     if (index > -1) {
  //       form.submit();
  //     } else {
  //       // newData.push(row);
  //       // setData(newData);
  //       // setEditingKey("");
  //     }
  //   } catch (errInfo) {
  //     console.log('Validate Failed:', errInfo);
  //   }
  // };

  const handleDateInput = (value) => {
    setDate(value);
    if (value === null) {
      setSearch({
        ...search,
        revision_start_date: '',
        revision_end_date: '',
      });
    } else {
      setSearch({
        ...search,
        revision_start_date: value[0],
        revision_end_date: value[1],
      });
    }
    // debouncedCallApi();
  };

  const handleMultiSelect = (list, key) => {
    setSearch({
      ...search,
      [key]: list,
    });
    // debouncedCallApi();
  };

  const handleSelectOnchange = (value, options, key, setHandler) => {
    if (value.includes('all')) {
      setHandler([...options.map((item) => item.value)]);
      value = options.map((item) => item.value);
    } else if (value.includes('not-all')) {
      setHandler([]);
      value = [];
    } else {
      setHandler(value);
    }
    handleMultiSelect(value, key);
  };

  const onDeleteSuccess = () => {
    setDeleteActivityId();
    // debouncedCallApi();
  };

  const deleteRevisionHandler = (record) => {
    setDeleteActivityId(record.id);
    dispatch(
      actions.deleteImsRevision({ ...record, onDeleteSuccess, parentRecordID })
    );
  };

  const dateInput = (key) => (
    <DatePicker
      style={{ width: '100%', minWidth: '80px' }}
      placeholder=''
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className='activities-time-picker prod-due-date'
      getPopupContainer={() =>
        document.getElementsByClassName(
          'ant-layout-content layout-content site-layout-background custom-textarea'
        )[0]
      }
      suffixIcon={null}
      format='D MMM YY'
      // popupClassName="activities-time-picker"
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e,
        });
        // debouncedCallApi();
      }}
      // onOpenChange={(e) => setDatePickerOpened(e)}
    />
  );

  const searchInput = (key, placeholder) => (
    <Input
      placeholder={''}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        const { value } = e.target;
        let values = { ...search, [key]: value };
        filterData({ values, classes, setData });
        setSearch({ ...values });
        // debouncedCallApi();
      }}
    />
  );

  const columns = [
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>IMPL. Date</div>
            <div>{searchInput('program_date')}</div>
          </div>
        );
      },
      dataIndex: 'program_date',
      key: 'program_date',
      sorter: (a, b) =>
        moment(a.program_date).unix() - moment(b.program_date).unix(),
      sortOrder: sortedInfo?.columnKey === 'program_date' && sortedInfo?.order,
      width: 110,
      // editable: true,
      render: (date) => {
        return moment(date).format('DD MMMM YY');
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Site</div>
            <div>{searchInput('site_id')}</div>
          </div>
        );
      },
      dataIndex: 'site_id',
      key: 'site_id',
      sorter: (a, b) => a.site_id - b.site_id,
      sortOrder: sortedInfo?.columnKey === 'site_id' && sortedInfo?.order,
      width: 110,
      align: 'center',
      // editable: true,
      render: (site_id, record) => {
        return record?.site?.name;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Coach</div>
            <div>{searchInput('user_id')}</div>
          </div>
        );
      },
      dataIndex: 'user_id',
      key: 'user_id',
      sorter: (a, b) =>
        `${a.coach.first_name} ${a.coach.last_name}`.localeCompare(
          `${b.coach.first_name} ${b.coach.last_name}`
        ),
      sortOrder: sortedInfo?.columnKey === 'user_id' && sortedInfo?.order,
      width: 110,
      align: 'center',
      // editable: true,
      render: (user_id, record) => {
        const { first_name, last_name } = record.coach;
        let name = `${first_name} ${last_name}`;
        return <div>{name}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              class <br />
              name
            </div>
            <div>{searchInput('name')}</div>
          </div>
        );
      },
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      width: 80,
      align: 'center',
      // editable: true,
      // render: (name) => <div>{name}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Topic title</div>
            <div>{searchInput('topic')}</div>
          </div>
        );
      },
      dataIndex: 'topic',
      key: 'topic',
      sorter: (a, b) => a.topic.localeCompare(b.topic),
      sortOrder: sortedInfo?.columnKey === 'topic' && sortedInfo?.order,
      width: 110,
      align: 'center',
      // editable: true,
      // render: (topic) => <div>{topic}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>NPO</div>
            <div>{searchInput('npo_id', '')}</div>
          </div>
        );
      },
      dataIndex: 'npo_id',
      key: 'npo_id',
      align: 'center',
      sorter: (a, b) => a.npo.name.localeCompare(b.npo.name),
      sortOrder: sortedInfo?.columnKey === 'npo_id' && sortedInfo?.order,
      width: 110,
      // editable: true,
      render: (topic, record) => <div>{record.npo.name}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              Programme <br />
              Type
            </div>
            <div>{searchInput('type')}</div>
          </div>
        );
      },
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.name.localeCompare(b.type.name),
      sortOrder: sortedInfo?.columnKey === 'type' && sortedInfo?.order,
      width: 150,
      align: 'left',
      editable: true,
      render: (type, record) => <div>{record?.type?.name}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              Intervention <br />
              Type
            </div>
            <div>{searchInput('intervention')}</div>
          </div>
        );
      },
      dataIndex: 'intervention',
      key: 'intervention',
      sorter: (a, b) =>
        a?.intervention?.name.localeCompare(b?.intervention?.name),
      sortOrder: sortedInfo?.columnKey === 'intervention' && sortedInfo?.order,
      width: 150,
      align: 'left',
      editable: true,
      render: (intervention, record) => <div>{record?.intervention?.name}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              Manual <br />
              Type
            </div>
            <div>{searchInput('manual')}</div>
          </div>
        );
      },
      dataIndex: 'manual',
      key: 'manual',
      sorter: (a, b) => a?.manual?.name?.localeCompare(b?.manual?.name),
      sortOrder: sortedInfo?.columnKey === 'manual' && sortedInfo?.order,
      width: 150,
      align: 'left',
      editable: true,
      render: (received_by, record) => {
        let isMass = record.manual_type === 'mass';
        let manual = isMass ? 'Mass' : record?.manual?.name;
        return <div>{manual}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              MKT <br />
              Pre
            </div>
            <div>{searchInput('mkt_pre')}</div>
          </div>
        );
      },
      dataIndex: 'mkt_pre',
      key: 'mkt_pre',
      sorter: (a, b) => a.mkt_pre - b.mkt_pre,
      sortOrder: sortedInfo?.columnKey === 'mkt_pre' && sortedInfo?.order,
      width: 80,
      align: 'center',
      editable: true,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              MKT <br />
              post
            </div>
            <div>{searchInput('mkt_post')}</div>
          </div>
        );
      },
      dataIndex: 'mkt_post',
      key: 'mkt_post',
      sorter: (a, b) => a.mkt_post - b.mkt_post,
      sortOrder: sortedInfo?.columnKey === 'mkt_post' && sortedInfo?.order,
      width: 80,
      align: 'center',
      editable: true,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              ses <br />
              Pre
            </div>
            <div>{searchInput('ses_pre')}</div>
          </div>
        );
      },
      dataIndex: 'ses_pre',
      key: 'ses_pre',
      sorter: (a, b) => a.ses_pre - b.ses_pre,
      sortOrder: sortedInfo?.columnKey === 'ses_pre' && sortedInfo?.order,
      width: 80,
      align: 'center',
      editable: true,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              ses <br />
              post
            </div>
            <div>{searchInput('ses_post')}</div>
          </div>
        );
      },
      dataIndex: 'ses_post',
      key: 'ses_post',
      sorter: (a, b) => a.ses_post - b.ses_post,
      sortOrder: sortedInfo?.columnKey === 'ses_post' && sortedInfo?.order,
      width: 80,
      align: 'center',
      editable: true,
    },

    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              leq <br />
              Pre
            </div>
            <div>{searchInput('leq_pre')}</div>
          </div>
        );
      },
      dataIndex: 'leq_pre',
      key: 'leq_pre',
      sorter: (a, b) => a.leq_pre - b.leq_pre,
      sortOrder: sortedInfo?.columnKey === 'leq_pre' && sortedInfo?.order,
      width: 80,
      align: 'center',
      editable: true,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              leq <br />
              post
            </div>
            <div>{searchInput('leq_post')}</div>
          </div>
        );
      },
      dataIndex: 'leq_post',
      key: 'leq_post',
      sorter: (a, b) => a.leq_post - b.leq_post,
      sortOrder: sortedInfo?.columnKey === 'leq_post' && sortedInfo?.order,
      width: 80,
      align: 'center',
      editable: true,
    },

    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              Total <br />
              att
            </div>
            <div>{searchInput('total_attendance')}</div>
          </div>
        );
      },
      dataIndex: 'total_attendance',
      key: 'total_attendance',
      sorter: false,
      sortOrder:
        sortedInfo?.columnKey === 'total_attendance' && sortedInfo?.order,
      width: 60,
      align: 'left',
      editable: true,
      render: (total_attendance) => <div>{total_attendance}</div>,
    },
    // {
    //   title: () => {
    //     return (
    //       <div align='top' className='select-parent-div'>
    //         <div className='special-table-header-text'>Rev Status</div>
    //         <div>
    //           <AMultiSelect
    //             debouncedCallApi={debouncedCallApi}
    //             dropdownClassName='status-selector'
    //             disabled={false}
    //             placeholder=''
    //             selectTags={statusCheckedList}
    //             onSelectChange={(value) =>
    //               handleSelectOnchange(
    //                 value,
    //                 statusOptions,
    //                 'revision_status_id',
    //                 setStatusCheckedList
    //               )
    //             }
    //             data={getOptions(statusOptions, statusCheckedList) || []}
    //             filterOption={(input, option) => {
    //               return (
    //                 option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
    //                 0
    //               );
    //             }}
    //           />
    //         </div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'revision_status_id',
    //   key: 'revision_status_id',
    //   sorter: true,
    //   sortOrder:
    //     sortedInfo?.columnKey === 'revision_status_id' && sortedInfo?.order,
    //   width: 120,
    //   editable: true,
    //   render: (id, record) => {
    //     if (!Boolean(record?.revision_status)) {
    //       return;
    //     }

    //     const { revision_status } = record;
    //     const { color, status_name } = revision_status;
    //     return (
    //       <Tag color={color} className='doc-status-tag'>
    //         {status_name}
    //       </Tag>
    //     );
    //   },
    // },
    // {
    //   title: () => {
    //     return (
    //       <div align='top' className='select-parent-div'>
    //         <div className='special-table-header-text'>
    //           <div>
    //             <div>Attach</div>
    //             <div style={{ color: 'transparent' }}>_</div>
    //           </div>
    //         </div>
    //       </div>
    //     );
    //   },
    //   dataIndex: 'images',
    //   key: 'images',
    //   sorter: false, // true
    //   sortOrder: sortedInfo?.columnKey === 'images' && sortedInfo?.order,
    //   width: 70, // "4%"
    //   editable: true,
    //   align: 'center',
    //   render: (_, record) => {
    //     if (currentUpdateObj.id !== record.id) {
    //       return (
    //         <div
    //           className={`attach-parent-div  ${
    //             (isAddImsRevision || isUpdateImsRevision) && 'disable-icon'
    //           }`}
    //           onClick={() => {
    //             if (isAddImsRevision || isUpdateImsRevision) {
    //               return;
    //             } else {
    //               setCurrentUpdateObj(record);
    //               setAttachPopupVisible(true);
    //             }
    //           }}
    //         >
    //           <AttachIcon
    //             className='rotate-icon activities-attach'
    //             width={16}
    //             fill='#231f20'
    //           />
    //           <div>{record?.images?.length || 0}</div>
    //         </div>
    //       );
    //     }

    //     return (
    //       <NonEditRowAttach
    //         images={record?.images || []}
    //         setImages={setImages}
    //         attachPopupVisible={attachPopupVisible}
    //         setAttachPopupVisible={setAttachPopupVisible}
    //         record={record}
    //         setCurrentUpdateObj={setCurrentUpdateObj}
    //         currentUpdateObj={currentUpdateObj}
    //         parentRecordID={parentRecordID}
    //       />
    //     );
    //   },
    // },
    // {
    //   title: '',
    //   dataIndex: 'operation',
    //   align: 'center',
    //   width: 80,
    //   render: (_, record) => {
    //     const editable = isEditing(record);
    //     let key = record?.key || record?.id;
    //     return editable ? (
    //       editingLoading && editingKey === key ? (
    //         <SpinnerComponent fontSize={14} />
    //       ) : (
    //         <Space size={20} align='center'>
    //           <Popconfirm
    //             getPopupContainer={() =>
    //               document.getElementsByClassName(
    //                 'ant-layout-content layout-content site-layout-background custom-textarea'
    //               )[0]
    //             }
    //             placement='rightTop'
    //             overlayClassName='activities-popconfirm'
    //             title='Are you sure you want to save this Revision?'
    //             onConfirm={() => save(record.key || record.id)}
    //             okText='Yes'
    //             cancelText='No'
    //           >
    //             <SaveIcon className='custom-icon' />
    //           </Popconfirm>
    //           {/* {!isUpdateImsRevision && ( */}
    //           <Popconfirm
    //             getPopupContainer={() =>
    //               document.getElementsByClassName(
    //                 'ant-layout-content layout-content site-layout-background custom-textarea'
    //               )[0]
    //             }
    //             title='Sure to cancel?'
    //             onConfirm={cancel}
    //           >
    //             <CrossIcon
    //               style={{ height: '20px', width: '20px' }}
    //               className='custom-icon'
    //             />
    //           </Popconfirm>
    //           {/* // )} */}
    //         </Space>
    //       )
    //     ) : (
    //       <div>
    //         <Space size={20} align='center'>
    //           {deletingLoading && record.id === deletingActivityId ? (
    //             <SpinnerComponent fontSize={16} />
    //           ) : (
    //             <>
    //               {canEdit && (
    //                 <EditIcon
    //                   className={`custom-icon ${
    //                     (isAddImsRevision || isUpdateImsRevision) &&
    //                     'disable-icon'
    //                   }`}
    //                   disabled={editingKey !== ''}
    //                   onClick={() =>
    //                     !isAddImsRevision &&
    //                     !isUpdateImsRevision &&
    //                     edit({ ...record, isAdd: false })
    //                   }
    //                 />
    //               )}
    //               {!isAddImsRevision && !isUpdateImsRevision ? (
    //                 canDelete && (
    //                   <Popconfirm
    //                     getPopupContainer={() =>
    //                       document.getElementsByClassName(
    //                         'ant-layout-content layout-content site-layout-background custom-textarea'
    //                       )[0]
    //                     }
    //                     placement='rightTop'
    //                     title='Are you sure you want to delete this revision?'
    //                     onConfirm={() => deleteRevisionHandler(record)}
    //                   >
    //                     <DeleteIcon className={`custom-icon`} />
    //                   </Popconfirm>
    //                 )
    //               ) : (
    //                 <DeleteIcon
    //                   className={`custom-icon ${
    //                     (isAddImsRevision || isUpdateImsRevision) &&
    //                     'disable-icon'
    //                   }`}
    //                 />
    //               )}
    //             </>
    //           )}
    //         </Space>
    //       </div>
    //     );
    //   },
    // },
  ];

  const handleAdd = () => {
    setShowTable(true);
    setSortedInfo(null);
    handleUpdateStatus(true, false);
    setCurrentUpdateObj({});
    let key = Math.ceil(Math.random() * 2345678);
    const newData = {
      key,
      id: key,
      revision_date: moment(),
      username: user?.first_name + ' ' + user?.surname,
      rev: null,
      received_by: null,
      transmittal_no: null,
      comment: null,
      revision_status_id: null,
      images: null,
      isAdd: true,
    };
    // setData((data) => [newData, ...data]);
    // setCount(1);
    // edit(newData);
    setImages([]);
    form.setFieldsValue({
      ...newData,
    });

    setEditingKey(key);
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          // inputType:,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        };
      },
    };
  });

  const handleFinish = (item) => {
    const { revision_date } = item;
    if (isUpdateImsRevision) {
      dispatch(
        actions.updateImsRevision({
          ...item,
          revision_date: moment(revision_date),
          id: currentUpdateObj.id,
          ...(images.length > 0 && { images }),
          parentRecordID,
          debouncedCallApi,
        })
      );
    } else {
      dispatch(
        actions.createImsRevision({
          ...item,
          revision_date: moment(revision_date),
          ...(images.length > 0 && { images }),
          parentRecordID,
          debouncedCallApi,
        })
      );
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  return (
    <Row className='revisions-parent-row'>
      <Col span={24} className='heading-col'>
        <Row align='middle'>
          <Col>
            <div className='revisions-heading-div'>
              <div onClick={() => setShowTable(!showTable)}>
                {showTable ? (
                  <UpCircleOutlined className='revision-up-down-icon' />
                ) : (
                  <DownCircleOutlined className='revision-up-down-icon' />
                )}
              </div>
              <div>
                <span className='heading-span'>
                  Classes {classes?.length > 0 && `(${classes.length})`}
                </span>
              </div>
            </div>
          </Col>
          {/* {canEdit && (
            <Col>
              <Button
                icon={<PlusOutlined />}
                className="add-revisions-btn"
                size="small"
                onClick={handleAdd}
                disabled={
                  isAddImsRevision || fetchingLoading || isUpdateImsRevision
                }
              >
                Revision
              </Button>
            </Col>
          )} */}
        </Row>
      </Col>

      {showTable && (
        <Col span={24}>
          <Form form={form} component={false} onFinish={handleFinish}>
            <Table
              scroll={{ x: 1200 }}
              className='special-table ims-doc-table revisions-table'
              components={{
                body: {
                  cell: (props) => (
                    <RevisionsEditableCell
                      cell={props}
                      images={images}
                      setImages={setImages}
                      attachPopupVisible={attachPopupVisible}
                      setAttachPopupVisible={setAttachPopupVisible}
                      currentUpdateObj={currentUpdateObj}
                      values={form.getFieldsValue()}
                      permissions={permissions}
                    />
                  ),
                },
              }}
              rowKey={(record) => record.id}
              loading={fetchingLoading}
              rowClassName='editable-row'
              dataSource={data}
              columns={mergedColumns}
              onChange={handleChange}
              pagination={false}
            />
          </Form>
        </Col>
      )}
    </Row>
  );
}

export default ClassesTable;
