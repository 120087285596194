import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import BatchUpdateSection from '../batchUpdate';
import FilterSection from '../filterSection';

const FilterAndBatchUpdate = ({
  handleCloseFilter,
  handleCloseBatch,
  handleFilter,
  loading,
  showFilter,
  setShowFilter,
  showBatch,
  setShowBatch,
  handleBatchUpdate,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [dynamicFieldsOptions, setDynamicFieldsOptions] = useState([]);
  const [filterDataFetched, setFilterDataFetched] = useState(false);

  const { allTemplates } = useSelector(
    (state) => state.templatesManagerReducer
  );

  useEffect(() => {
    if (allTemplates.length > 0) {
      let filtered = allTemplates.filter(
        (el) => el.name.toLocaleLowerCase() === 'classes'
      );
      if (filtered.length === 1) {
        const { template_fields } = filtered[0];
        setDynamicFieldsOptions(template_fields);
      }
    }
  }, [allTemplates]);

  const closeHandler = () => {
    handleCloseFilter();
    setSelectedTemplate({});
    // setDynamicFieldsOptions([]);
    setFilterDataFetched(false);
  };

  return (
    <>
      {showFilter && (
        <FilterSection
          handleClose={closeHandler}
          handleFilter={handleFilter}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          setFilterDataFetched={setFilterDataFetched}
          dynamicFieldsOptions={dynamicFieldsOptions}
          setDynamicFieldsOptions={setDynamicFieldsOptions}
        />
      )}
      {/* {showBatch && (
        <BatchUpdateSection
          handleClose={handleCloseBatch}
          handleFilter={handleBatchUpdate}
          loading={loading}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          filterDataFetched={filterDataFetched}
          dynamicFieldsOptions={dynamicFieldsOptions}
          setDynamicFieldsOptions={setDynamicFieldsOptions}
        />
      )} */}
    </>
  );
};

export default FilterAndBatchUpdate;
