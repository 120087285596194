import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Input,
  Select,
  Col,
  Row,
  Popconfirm,
  Space,
  DatePicker,
} from 'antd';
import { ReactComponent as DeleteIcon } from '../../../../../assets/delete-icon.svg';
import { ReactComponent as SaveIcon } from '../../../../../assets/circular-save-btn.svg';
import { ReactComponent as CrossIcon } from '../../../../../assets/crossIcon.svg';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { revisionOptions } from '../../../utils';
const { Option } = Select;

const RevisionFieldsComponent = ({
  inputFields,
  setInputFields,
  createField,
  deleteField,
  updateField,
  canUpdate,
  selectedTemplate,
}) => {
  const { allNpos, allNpoRegions, allProgrammeTypes, user } = useSelector(
    (state) => ({
      allNpos: state.npoSetupReducer.allNpos,
      allNpoRegions: state.npoRegionsReducer.allNpoRegions,
      allProgrammeTypes: state.programmeTypesReducer.allProgrammeTypes,
      user: state.authReducer.user,
    })
  );

  const handleDeleteLocally = (item) => {
    const filteredInputs = inputFields.filter((el) => el.id !== item.id);
    setInputFields(filteredInputs);
  };

  const addChooseField = () => {
    let field = {
      isEdit: true,
      name: '',
      isLocal: true,
      id: Math.floor(Math.random() * 10000000),
      label: 'Select Field',
      placeholder: `Select Classes System Field`,
      isSelect: true,
    };
    setInputFields([...inputFields, { ...field }]);
  };

  const handleOnchangeStatus = (value, option, id) => {
    const updated = inputFields.map((el) =>
      el.id === id ? { ...el, name: value, selectOption: option } : el
    );
    setInputFields(updated);
  };

  const handleChangeField = (value, ele, option) => {
    const { index } = ele;

    let fields = [...inputFields];
    fields[index] = {
      ...ele,
      // id: value,
      value,
      option,
    };
    setInputFields(fields);
  };

  const handleDisable = (value) => {
    let item = inputFields.filter((el) => el.value === value && !el.isSelect);

    let shouldDisabled = item.length > 0 ? true : false;

    return shouldDisabled;
  };

  const SelectOption = ({ placeholder, ele }) => (
    <Select
      style={{ display: 'block' }}
      getPopupContainer={() =>
        document.getElementsByClassName(
          'ant-layout-content layout-content site-layout-background custom-textarea'
        )[0]
      }
      placeholder={placeholder}
      size='small'
      disabled={!canUpdate}
      onChange={(e, option) => handleChangeField(e, ele, option)}
      value={ele?.value || null}
    >
      {revisionOptions.map((el) => {
        const { value, label } = el;
        return (
          <Option value={value} key={value} disabled={handleDisable(value)}>
            {label}
          </Option>
        );
      })}
    </Select>
  );

  const RenderFieldTypes = ({ item, localName, setLocalName }) => {
    const { id, placeholder, value, index } = item;

    if (value === 'region') {
      return (
        <Select
          placeholder='Select Region'
          size='small'
          disabled={!canUpdate}
          value={localName || null}
          onChange={(e, option) => handleOnchangeStatus(e, option, id)}
        >
          {allNpoRegions.map((el) => {
            const { id, name } = el;
            if (['coach', 'learner'].includes(user.user_type)) {
              if (id === user?.npo?.npo_region?.id) {
                return (
                  <Option value={id} key={id}>
                    {name}
                  </Option>
                );
              }
              return <></>;
            }
            return (
              <Option value={id} key={id}>
                {name}
              </Option>
            );
          })}
        </Select>
      );
    }
    if (value === 'type_id') {
      return (
        <Select
          placeholder='Select Programme Type'
          size='small'
          disabled={!canUpdate}
          value={localName || null}
          onChange={(e, option) => handleOnchangeStatus(e, option, id)}
        >
          {allProgrammeTypes.map((el) => {
            const { id, name } = el;
            return (
              <Option value={id} key={id}>
                {name}
              </Option>
            );
          })}
        </Select>
      );
    }

    if (value === 'npo') {
      return (
        <Select
          placeholder='Select NPO'
          size='small'
          disabled={!canUpdate}
          value={localName || null}
          onChange={(e, option) => handleOnchangeStatus(e, option, id)}
        >
          {allNpos.map((el) => {
            const { id, name } = el;
            return (
              <Option value={id} key={id}>
                {name}
              </Option>
            );
          })}
        </Select>
      );
    }

    if (value === 'revision_date') {
      return (
        <DatePicker
          format={'DD MMM YYYY'}
          size='small'
          allowClear={false}
          suffixIcon={null}
          placeholder={'Select Date'}
          value={localName}
          disabled={!canUpdate}
          onChange={(e) => {
            setLocalName(moment(e));
            inputFields[index].name = moment(e);
            setInputFields(inputFields);
          }}
        />
      );
    }

    if (value === 'revision_status_id') {
      return (
        <Select
          placeholder='Select Status'
          size='small'
          disabled={!canUpdate}
          value={localName || null}
          onChange={(e, option) => handleOnchangeStatus(e, option, id)}
        >
          {/* {allLearnerStatuses.map((el) => {
            const { id, status_name } = el;
            return (
              <Option value={id} key={id}>
                {status_name}
              </Option>
            );
          })} */}
        </Select>
      );
    }

    return (
      <Input
        placeholder={placeholder}
        value={localName}
        disabled={!canUpdate}
        onChange={(e) => {
          const { value } = e.target;
          setLocalName(value);
          inputFields[index].name = value;
          setInputFields(inputFields);
        }}
      />
    );
  };

  const RenderReadOnlyFields = ({ item }) => {
    let { value, name } = item;

    if (value === 'revision_date') {
      name = moment(name).format('DD MMM YYYY');
    }

    if (value === 'region' || value === 'type_id') {
      name = item.selectOption.children;
    }

    return (
      <Input size='small' className='border-less-input' readOnly value={name} />
    );
  };

  const RenderInputs = ({ ele }) => {
    const { isEdit, label, placeholder, name } = ele;
    const [localName, setLocalName] = useState(name);

    return (
      <Row
        align='bottom'
        justify='start'
        className='field-row'
        gutter={[15, 0]}
      >
        <Col span={18} lg={17} xl={18}>
          <label>{label}</label>

          {ele?.isEdit ? (
            ele?.isSelect ? (
              <SelectOption placeholder={placeholder} ele={ele} />
            ) : (
              <RenderFieldTypes
                item={ele}
                localName={localName}
                setLocalName={setLocalName}
              />
            )
          ) : (
            <RenderReadOnlyFields item={ele} />
          )}
        </Col>
        {canUpdate && (
          <Col>
            {isEdit ? (
              <Space size={10} align='baseline'>
                <SaveIcon
                  onClick={() =>
                    ele?.isSelect
                      ? createField({ ...ele, name: localName })
                      : updateField({ ...ele, name: localName })
                  }
                />
                <Popconfirm
                  title='Are you sure to cancel?'
                  placement='topRight'
                  onConfirm={() => handleDeleteLocally(ele)}
                  okText='Yes'
                  cancelText='No'
                >
                  <CrossIcon />
                </Popconfirm>
              </Space>
            ) : (
              <Space size={10} align='baseline'>
                {/* <EditIcon onClick={() => onEditClick(ele)} /> */}
                <Popconfirm
                  title='Are you sure you want to delete?'
                  placement='topRight'
                  onConfirm={() => deleteField(ele)}
                  okText='Yes'
                  cancelText='No'
                >
                  <DeleteIcon width={18} height={18} fill='#e21c21' />
                </Popconfirm>
              </Space>
            )}
          </Col>
        )}
      </Row>
    );
  };

  return (
    <Row gutter={[20, 0]} className='custom-fields-parent-div'>
      {inputFields.map((el, i) => {
        return (
          <Col span={24} key={`item-${el.id}`} index={i}>
            <RenderInputs ele={{ ...el, index: i }} />
          </Col>
        );
      })}
      {/* {canUpdate && selectedTemplate?.id > 0 && ( */}
      <Col span={24}>
        <Button
          onClick={addChooseField}
          icon={<PlusOutlined />}
          size='small'
          className='add-field-btn'
        >
          Filter
        </Button>
      </Col>
      {/* )} */}
    </Row>
  );
};

export default RevisionFieldsComponent;
