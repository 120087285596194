import React, { useState } from 'react';
import { ReactComponent as ExportIcon } from '../../../../assets/upload-edit.svg';
import { Row, Col, Button, Popover, Select, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { baseURL } from '../../../../globalUtils/axiosConfig';

const { Option } = Select;

const ExportDoc = () => {
  const [selectedId, setSelectedId] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  // const { allTemplates } = useSelector((state) => state.docTypesReducer);

  const handleDownload = (filename) => {
    let url = `${baseURL}/${filename}`;
    window.open(url, '_blank');
  };

  const handleSave = async () => {
    setLoading(true);
    const res = await axios.get(
      `${baseURL}/api/v1/sites/export` // ?document_template_id=${selectedId}
    );

    const { status } = res;

    if (status === 200) {
      const { filename } = res.data;
      let updatedFileName = res.data?.file?.to_path
        ? res.data?.file?.to_path?.slice(7)
        : filename;
      handleDownload(updatedFileName);
      setSuccess(true);
    } else {
      message.error('Something went wrong!');
    }
    setLoading(false);
  };

  const cleanupFun = () => {
    setSelectedId(null);
    setSuccess(false);
  };

  const onOpenChange = (e) => {
    if (!e) {
      console.log('onOpenChange', e);
      cleanupFun();
    }
  };

  const handleClose = () => {
    setIsVisible(false);
    cleanupFun();
  };

  // useEffect(() => {
  //   return () => {
  //     console.log("cleanup func");
  //     setSelectedId(null);
  //   };
  // }, []);

  const SuccessSection = () => {
    return (
      <Col>
        <Row justify='center' gutter={[0, 20]} className='success-row'>
          <Col span={24}>
            <div className='success-text'>Document Exported Successfully</div>
          </Col>
          <Col span={24}>
            <Button size='small' onClick={handleClose} className='close-btn'>
              CLOSE
            </Button>
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <Popover
      overlayClassName='export-popover'
      placement='topRight'
      getPopupContainer={() =>
        document.getElementsByClassName(
          'ant-layout-content layout-content site-layout-background custom-textarea'
        )[0]
      }
      content={
        <Row justify='center' className='parent-div'>
          <Col span={24} className='choose-col'>
            <div className='choose-select-div'>
              {/* <label>{'DOCUMENT TYPE'}</label> */}
              <Row className={success ? 'readonly-select-row' : ''}>
                {/* <Select
                  placeholder='Select Document Type'
                  disabled={success}
                  size='small'
                  getPopupContainer={() =>
                    document.getElementsByClassName(
                      'ant-layout-content layout-content site-layout-background custom-textarea'
                    )[0]
                  }
                  onChange={(e) => setSelectedId(e)}
                  value={selectedId}
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {allTemplates.map((el) => {
                    const { id, name } = el;
                    return (
                      <Option value={id} key={id}>
                        {name}
                      </Option>
                    );
                  })}
                </Select> */}
              </Row>
            </div>
          </Col>
          {success ? (
            <SuccessSection />
          ) : (
            <Col>
              {/* {selectedId > 0 && ( */}
              <Button
                size='middle'
                icon={<ExportIcon />}
                loading={loading}
                onClick={handleSave}
                className={`export-btn`}
              >
                EXPORT FILE
              </Button>
              {/* )} */}
            </Col>
          )}
        </Row>
      }
      onOpenChange={onOpenChange}
      title={
        <Row justify='space-between'>
          <Col className='title'>EXPORT</Col>
          <Col>
            <CloseOutlined onClick={handleClose} />
          </Col>
        </Row>
      }
      trigger='click'
      open={isVisible}
      onVisibleChange={(e) => {
        setIsVisible(e);
      }}
    >
      <ExportIcon />
    </Popover>
  );
};

export default ExportDoc;
