import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { baseURL } from '../../../../globalUtils/axiosConfig';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchLearnerCamps } from '../../../LearnerCamps/redux/actions';
// import { fetchAllUsers } from '../../../UserManager/redux/actions';
import Table from './Table';
import MassTable from './massTable';

let params = {
  search: {},
  sort: 'first_name',
  order: 'asc',
  user_type: 'learner',
  per_page: 'all',
};

const TableComponent = ({ record, debouncedCallApi }) => {
  // const [statusOptions, setStatusOptions] = useState([]);
  const [learnerUsers, setLearnerUsers] = useState([]);

  // const [learnersCheckList, setLearnersCheckList] = useState([]);
  const [learnersOptions, setLearnersOptions] = useState([]);
  // const dispatch = useDispatch();

  // const { allLearnerStatuses } = useSelector(
  //   (state) => state.learnerStatusReducer
  // );

  const fetchLearners = async () => {
    const response = await axios.get(`${baseURL}/api/v1/users`, {
      params,
    });
    const { success } = response.data;
    if (success) {
      const { users } = response.data.data;
      setLearnerUsers(users);

      let options = users.map((el) => {
        const { first_name, last_name, id } = el;
        return { value: id, label: `${first_name} ${last_name}` };
      });
      setLearnersOptions(options);
    }
  };
  useEffect(() => {
    record?.manual_type !== 'mass' &&
      learnerUsers.length < 1 &&
      fetchLearners();
  }, []);

  if (record?.manual_type === 'mass') {
    return (
      <MassTable
        program_id={record.id}
        massLearner={record?.mass_learner?.id ? [record?.mass_learner] : []}
        debouncedCallApi={debouncedCallApi}
      />
    );
  }

  return (
    <Table
      program_id={record.id}
      // statusOptions={statusOptions}
      doc_revisions={null} // record.doc_revisions
      learnerUsers={learnerUsers}
      debouncedCallApi={debouncedCallApi}
    />
  );
};

export default TableComponent;
