import { Button, Row, Col, Input, Form } from 'antd';
import UseResetPassword from './useResetPassword';
import logo from '../../assets/AYDF_logo.png';
import '../login/index.less';
import './index.less';

const ForgotPassword = () => {
  const [onFinish, onFinishFailed, loading, form] = UseResetPassword(Form);
  let passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

  return (
    <div className='forgot-most-parent-div'>
      <Row justify='center' align='middle' className='parent-row'>
        <Col>
          <div
            className='forgot-password-parent-div'
            style={{
              minHeight: '428px',
              height: '100%',
            }}
          >
            <Row justify='center' gutter={[0, 20]}>
              <Col>
                <div className='logo-div'>
                  <img src={logo} alt='' />
                </div>
              </Col>
              <Col span={24}>
                <div className='login-text reset-text-div'>
                  <p>Enter Your Password</p>
                  <p className='reset-span'>
                    <span>
                      Password must be at least six characters in length
                      containing both uppercase and lowercase characters (a-z
                      and A-Z) and must contain at least one number (0-9) and
                      one special character (* ! #).
                    </span>
                  </p>
                </div>
              </Col>
            </Row>

            <Row justify='center'>
              <Col span={22}>
                <Form
                  form={form}
                  name='reset-form'
                  className='reset-form'
                  onFinish={onFinish}
                  wrapperCol={{
                    span: 24,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  labelCol={{ span: 24 }}
                  onFinishFailed={onFinishFailed}
                  autoComplete='off'
                  validateTrigger={null}
                >
                  <Row justify='center' align='middle'>
                    <Col span={24}>
                      <Form.Item
                        label='PASSWORD'
                        name='password'
                        validateTrigger='onChange'
                        rules={[
                          {
                            required: true,
                            message: 'Required!',
                          },
                          {
                            pattern: passwordRegex,
                            message: 'Invalid!',
                          },
                        ]}
                      >
                        <Input.Password
                          type='password'
                          className='email-input forgot-email-input'
                          placeholder='Enter password'
                        />
                      </Form.Item>
                      <Form.Item
                        label='CONFIRM PASSWORD'
                        name='password_confirmation'
                        validateTrigger='onChange'
                        dependencies={['password']}
                        rules={[
                          {
                            required: true,
                            message: 'Required!',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue('password') === value
                              ) {
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                new Error('Confirm password not matched!')
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          type='password'
                          className='email-input forgot-email-input'
                          placeholder='Confirm password'
                        />
                      </Form.Item>
                      <Form.Item style={{ textAlign: 'center' }}>
                        <Button
                          htmlType='submit'
                          className='send-btn'
                          loading={loading}
                          style={{
                            marginTop: '15px',
                            marginBottom: '20px',
                          }}
                        >
                          CONFIRM
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
