import React from "react";
import { DatePicker, Form, Input, Select } from "antd";
import ImageComponent from "./useAttachments";
import { useSelector } from "react-redux";

const { Option } = Select;
const { TextArea } = Input;

const RevisionsEditableCell = ({
  cell,
  images,
  setImages,
  attachPopupVisible,
  setAttachPopupVisible,
  currentUpdateObj,
}) => {
  const {
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  } = cell;

  let inputNode;

  const { allLearnerStatuses } = useSelector(
    (state) => state.learnerStatusReducer
  );

  switch (dataIndex) {
    case "revision_date":
      inputNode = (
        <DatePicker size="small" suffixIcon={null} allowClear={false} />
      );
      break;
    case "username":
      inputNode = (
        <Input readOnly className="border-less-input" placeholder="" />
      );
      break;
    case "rev":
      inputNode = <Input placeholder="Rev" size="small" />;
      break;
    case "received_by":
      inputNode = <Input placeholder="Received By" size="small" />;
      break;
    case "transmittal_no":
      inputNode = <Input placeholder="Transmittal Number" size="small" />;
      break;
    case "comment":
      inputNode = <TextArea rows={2} placeholder="Insert Comment" />;
      break;
    case "revision_status_id":
      inputNode = (
        <Select
          showSearch
          //   className="activities-selector"
          getPopupContainer={() =>
            document.getElementsByClassName(
              "ant-layout-content layout-content site-layout-background custom-textarea"
            )[0]
          }
          dropdownClassName="select-dropdown-custom"
          placeholder="Status"
          size="small"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allLearnerStatuses.map(({ id, status_name }) => (
            <Option key={id} value={id}>
              {status_name}
            </Option>
          ))}
        </Select>
      );
      break;

    case "images":
      inputNode = (
        <ImageComponent
          images={images}
          setImages={setImages}
          attachPopupVisible={attachPopupVisible}
          setAttachPopupVisible={setAttachPopupVisible}
          currentUpdateObj={currentUpdateObj}
        />
      );
      break;
    default:
      break;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: dataIndex === "images" ? false : true,
              message: ``, // Required!
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default RevisionsEditableCell;
