import axios from "axios";
import { message as AntdAlert } from "antd";
import { put } from "redux-saga/effects";
import * as actions from "../actions";
import { baseURL } from "../../../../globalUtils/axiosConfig";
import { errorHandler } from "../../../../components/unauthorizeHandler";

export function* handleFetchProjectStatuses({ data }) {
  // const { search, ...rest } = data;
  // Object.keys(search).forEach((key) => {
  //   if (search[key]?.length < 1 || search[key] === null) delete search[key];
  // });

  try {
    const response = yield axios.get(`${baseURL}/api/v1/project_statuses`, {
      // params: { ...rest, ...(Object.keys(search).length > 0 && { ...search }) },
    });
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchProjectStatusesSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchProjectStatusesError());
    AntdAlert.error("Something went wrong!");
    console.log(error);
  }
}

export function* handleUpdateProjectStatuses({ data }) {
  const { color, id, show_by_default, status_name } = data;

  try {
    const response = yield axios.put(
      `${baseURL}/api/v1/project_statuses/${id}`,
      {
        show_by_default,
        status_name,
        color,
      }
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateProjectStatusesSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateProjectStatusesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateProjectStatusesError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleDeleteProjectStatuses({ data }) {
  const { id } = data;
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v1/project_statuses/${id}`
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteProjectStatusesSuccess(id));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteProjectStatusesError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteProjectStatusesError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleCreateProjectStatuses({ data }) {
  try {
    const newData = JSON.parse(JSON.stringify(data));
    delete newData.id;
    const response = yield axios.post(`${baseURL}/api/v1/project_statuses`, {
      ...newData,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createProjectStatusesSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createProjectStatusesError(data.id));
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateProjectOrder({ data }) {
  console.log("handleUpdateProjectOrder", data);

  const fd = new FormData();
  data.map((el, i) => fd.append(`project_status[${el.id}]`, i + 1));

  try {
    const response = yield axios.patch(
      `${baseURL}/api/v1/project_statuses/sort`,
      fd
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateProjectsOrderSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateProjectsOrderError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateProjectsOrderError());
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}
