import React, { useEffect, useState } from 'react';
import { Table, Input, Space, Popconfirm, Select } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './redux/actions';
import SpinnerComponent from '../../components/spinner';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
// import CheckSvg from '../../assets/check.svg.png';
import CustomPagination from '../../components/customPagination/CustomPagination';
// import AMultiSelect from '../../components/AMultiSelect';
import { capitalizeFirstLetterofEachWord } from '../../globalUtils/capitalizeFirstLetterofEachWord';
const { Option } = Select;

export const targetOptions = [
  {
    label: 'Any Manual',
    value: 'any_manual',
  },
  {
    label: 'Sum of all manual',
    value: 'sum_of_all_manual',
  },
];

const DocumentsTypesTable = ({ canUpdate, canDelete }) => {
  const [sortedInfo, setSortedInfo] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [currentDelRecord, setCurrentDelRecord] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(() => 1);
  const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [search, setSearch] = useState({});
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);

  const {
    allTemplates,
    fetchTemplateLoading,
    deleteTemplateLoading,
    totalRecords,
  } = useSelector((state) => state.interventionTypesReducer);

  // const toTitleCase = (str) => {
  //   return str.replace(/\w\S*/g, function (txt) {
  //     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  //   });
  // };

  useEffect(() => {
    if (page && resultPerPage && page > 0 && resultPerPage > 0) {
      dispatch(
        actions.fetchAllInterventionTypes({
          page: page,
          per_page: resultPerPage,
          search,
          sort: sortedInfo?.order && sortedInfo?.columnKey,
          order: sortedInfo?.order
            ? sortedInfo?.order === 'ascend'
              ? 'asc'
              : 'desc'
            : null,
        })
      );
    }
  }, [req]);

  useEffect(() => {
    let paramsPage = 1;
    if (paramsPage && resultPerPage && paramsPage > 0 && resultPerPage > 0) {
      dispatch(
        actions.fetchAllInterventionTypes({
          page: paramsPage,
          per_page: resultPerPage,
          search,
        })
      );
      setPage(1);
    }
  }, [defaultPageOneReq]);

  useEffect(() => {
    debouncedCallApi();
  }, [sortedInfo]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );
  useEffect(() => {
    setDataSource(allTemplates);
  }, [allTemplates]);

  const handleChange = (pagination, filter, sorter) => {
    setSortedInfo(sorter);
  };

  const handleDelete = (record) => {
    setCurrentDelRecord(record);
    dispatch(actions.deleteInterventionType(record));
  };

  const searchInput = (key) => (
    <Input
      disabled={key === 'manuals'}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e.target.value,
        });
        debouncedDefaultPageOneCallApi();
      }}
    />
  );
  const handleSelectOnchange = (value, options, key, setHandler) => {
    if (value == undefined) {
      setHandler(null);
      handleMultiSelect('', key);
    } else {
      value = [value];
      if (value.includes('all')) {
        setHandler([...options.map((item) => item.value)]);
        value = options.map((item) => item.value);
      } else if (value.includes('not-all')) {
        setHandler([]);
        value = [];
      } else {
        setHandler(value);
      }
      handleMultiSelect(value, key);
    }
  };

  const handleSearchOnchange = (value, key, setHandler) => {
    if (value === undefined) {
      setHandler(null);
      handleMultiSelect('', key);
    } else {
      setHandler(value);
      handleMultiSelect(value, key);
    }
  };
  const handleMultiSelect = (list, key) => {
    setSearch({
      ...search,
      [key]: list,
    });
    debouncedCallApi();
  };
  const handleNavigate = (id) =>
    navigate(`/settings/intervention-types-manager/update/${id}`);

  const handleManuals = (params) => {
    let str = '';
    return params?.map((data, i) => {
      let comma = i < params.length - 1 ? ', ' : '';
      return str + data.name + comma;
    });
  };

  const columns = [
    {
      title: () => {
        return (
          <div align='center' className='select-parent-div '>
            <div className='special-table-header-text columns-text'>
              INTERVENTION NAME
            </div>
            <div>{searchInput('name')}</div>
          </div>
        );
      },
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      ellipsis: true,
      width: '15%',
      render: (name, { id }) => (
        <div onClick={() => handleNavigate(id)}>{name}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text-center'>
              MODULAR WEIGHT
            </div>
            <div>{searchInput('modular_weight')}</div>
          </div>
        );
      },
      dataIndex: 'modular_weight',
      key: 'modular_weight',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'modular_weight' && sortedInfo?.order,
      ellipsis: true,
      width: '12%',
      align: 'center',
      render: (modular_weight, { id }) => (
        <div onClick={() => handleNavigate(id)}>{modular_weight}%</div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text-center'>
              MODULAR TARGET
            </div>
            {/* <div>{searchInput('modular_target')}</div> */}
            <div>
              <Select
                style={{
                  width: '100%',
                  minHeight: '25px',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                }}
                onClick={(e) => e.stopPropagation()}
                allowClear={true}
                readOnly={true}
                className='Select Project'
                dropdownClassName='select-dropdown-custom'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                showSearch
                placeholder=''
                optionFilterProp='children'
                onChange={(e) => {
                  setSearch({
                    ...search,
                    modular_target: e,
                  });
                  debouncedDefaultPageOneCallApi();
                }}
                optionLabelProp='label'
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {targetOptions?.map(({ label, value }) => {
                  return (
                    <Option value={value} label={label}>
                      {label || ''}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        );
      },
      dataIndex: 'modular_target',
      key: 'modular_target',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'modular_target' && sortedInfo?.order,
      ellipsis: true,
      width: '12%',
      align: 'center',
      render: (modular_target, { id, modular_type }) => (
        <div onClick={() => handleNavigate(id)}>
          {`${modular_target} / year`}
          <br />
          {capitalizeFirstLetterofEachWord(modular_type)}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='center' className='select-parent-div'>
            <div className='special-table-header-text'>MANUALS</div>
            <div
              style={{
                marginBottom: '5px',
              }}
            >
              {searchInput('manuals')}
            </div>
          </div>
        );
      },
      dataIndex: 'manuals',
      key: 'manuals',
      sorter: false, // true,
      sortOrder: sortedInfo?.columnKey === 'manuals' && sortedInfo?.order,
      width: '25%',
      align: 'left',
      render: (manuals, { id, user_manuals }) => (
        <div onClick={() => handleNavigate(id)}>
          {handleManuals(user_manuals)}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='center' className='select-parent-div '>
            <div className='special-table-header-text-center columns-text'>
              MASS WEIGHT
            </div>
            <div>{searchInput('mass_weight')}</div>
          </div>
        );
      },
      dataIndex: 'mass_weight',
      key: 'mass_weight',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'mass_weight' && sortedInfo?.order,
      width: '12%',
      align: 'center',
      render: (mass_weight) => <>{mass_weight}%</>,
    },
    {
      title: () => {
        return (
          <div align='center' className='select-parent-div '>
            <div className='special-table-header-text-center columns-text'>
              MASS TARGET
            </div>
            <div>{searchInput('mass_target')}</div>
          </div>
        );
      },
      dataIndex: 'mass_target',
      key: 'mass_target',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'mass_target' && sortedInfo?.order,
      width: '12%',
      align: 'center',
      render: (mass_target) => <> {mass_target} / year</>,
    },
    {
      title: null,
      dataIndex: '',
      key: 'action',
      width: '12%',
      align: 'center',
      render: (_, record) => {
        if (!canUpdate && !canDelete) {
          return;
        }

        return deleteTemplateLoading && record.id === currentDelRecord.id ? (
          <SpinnerComponent fontSize={14} />
        ) : (
          <Space size={15} align='baseline'>
            {canUpdate && (
              <EditIcon
                width={18}
                height={18}
                className='custom-icon'
                onClick={() => {
                  handleNavigate(record.id);
                  dispatch(actions.setInterventionTypeIsEdit(true));
                }}
              />
            )}
            {canDelete && (
              <Popconfirm
                title='Are you sure you want to delete this intervention type?'
                placement='topRight'
                onConfirm={() => handleDelete(record)}
                okText='Yes'
                cancelText='No'
              >
                <DeleteIcon width={18} height={18} className='custom-icon' />
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <Table
          className='special-table doc-type-table'
          columns={columns}
          scroll={{ x: !fetchTemplateLoading }}
          onChange={handleChange}
          dataSource={dataSource}
          pagination={false}
          sortUpIcon={<UpOutlined />}
          sortDownIcon={<DownOutlined />}
          loading={fetchTemplateLoading}
        />
      </div>
      <CustomPagination
        page={page}
        setPage={setPage}
        resultPerPage={resultPerPage}
        setResultPerPage={setResultPerPage}
        debouncedCallApi={debouncedCallApi}
        totalRecord={totalRecords}
      />
    </>
  );
};
export default DocumentsTypesTable;
