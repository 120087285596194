const FETCH_LEARNER_STATUSES= "FETCH_LEARNER_STATUSES";
const FETCH_LEARNER_STATUSES_SUCCESS = "FETCH_LEARNER_STATUSES_SUCCESS";
const FETCH_LEARNER_STATUSES_ERROR = "FETCH_LEARNER_STATUSES_ERROR";
const UPDATE_LEARNER_STATUSES = "UPDATE_LEARNER_STATUSES";
const UPDATE_LEARNER_STATUSES_SUCCESS = "UPDATE_LEARNER_STATUSES_SUCCESS";
const UPDATE_LEARNER_STATUSES_ERROR = "UPDATE_LEARNER_STATUSES_ERROR";
const DELETE_LEARNER_STATUSES = "DELETE_LEARNER_STATUSES";
const DELETE_LEARNER_STATUSES_SUCCESS = "DELETE_LEARNER_STATUSES_SUCCESS";
const DELETE_LEARNER_STATUSES_ERROR = "DELETE_LEARNER_STATUSES_ERROR";
const CREATE_LEARNER_STATUSES = "CREATE_LEARNER_STATUSES";
const CREATE_LEARNER_STATUSES_SUCCESS = "CREATE_LEARNER_STATUSES_SUCCESS";
const CREATE_LEARNER_STATUSES_ERROR = "CREATE_LEARNER_STATUSES_ERROR";
const UPDATE_ORDER_LEARNER_STATUS = "UPDATE_ORDER_LEARNER_STATUS";
const UPDATE_ORDER_LEARNER_STATUS_SUCCESS = "UPDATE_ORDER_LEARNER_STATUS_SUCCESS";
const UPDATE_ORDER_LEARNER_STATUS_ERROR = "UPDATE_ORDER_LEARNER_STATUS_ERROR";
const IS_ADDING_UPDATE = "IS_ADDING_UPDATE";

export {
  FETCH_LEARNER_STATUSES,
  FETCH_LEARNER_STATUSES_SUCCESS,
  FETCH_LEARNER_STATUSES_ERROR,
  UPDATE_LEARNER_STATUSES,
  UPDATE_LEARNER_STATUSES_SUCCESS,
  UPDATE_LEARNER_STATUSES_ERROR,
  DELETE_LEARNER_STATUSES,
  DELETE_LEARNER_STATUSES_SUCCESS,
  DELETE_LEARNER_STATUSES_ERROR,
  CREATE_LEARNER_STATUSES,
  CREATE_LEARNER_STATUSES_SUCCESS,
  CREATE_LEARNER_STATUSES_ERROR,
  UPDATE_ORDER_LEARNER_STATUS,
  UPDATE_ORDER_LEARNER_STATUS_SUCCESS,
  UPDATE_ORDER_LEARNER_STATUS_ERROR,
  IS_ADDING_UPDATE,
};
