import React from 'react';
import { Form, Input, InputNumber } from 'antd';
import ImageComponent from './useAttachments';

const RevisionsEditableCell = ({
  cell,
  images,
  setImages,
  attachPopupVisible,
  setAttachPopupVisible,
  currentUpdateObj,
  handleOnchange,
}) => {
  const {
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  } = cell;

  let inputNode;

  switch (dataIndex) {
    case 'description':
    case 'total_attendence':
    case 'average_age':
      inputNode = (
        <Input
          readOnly
          className='border-less-input'
          placeholder=''
          style={{
            textTransform: 'capitalize',
          }}
        />
      );
      break;

    case 'african_male':
    case 'african_female':
    case 'african_lgbt':
    case 'asian_male':
    case 'asian_female':
    case 'asian_lgbt':
    case 'color_male':
    case 'color_female':
    case 'color_lgbt':
    case 'white_male':
    case 'white_female':
    case 'white_lgbt':
    case 'total_age':
      inputNode = (
        <InputNumber
          placeholder=''
          size='small'
          min={0}
          onChange={(e) => handleOnchange(dataIndex, e)}
        />
      );
      break;

    case 'images':
      inputNode = (
        <ImageComponent
          images={images}
          setImages={setImages}
          attachPopupVisible={attachPopupVisible}
          setAttachPopupVisible={setAttachPopupVisible}
          currentUpdateObj={currentUpdateObj}
        />
      );
      break;
    default:
      break;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: dataIndex === 'images' ? false : true,
              message: ``,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default RevisionsEditableCell;
