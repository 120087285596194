import React, { useRef, useState, useEffect } from 'react';
import { ReactComponent as ImportIcon } from '../../../../assets/import.svg';
import { ReactComponent as ExportIcon } from '../../../../assets/export.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete-icon.svg';
import { Row, Col, Button, Popover, Select, message } from 'antd';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { CloseOutlined } from '@ant-design/icons';
// import { useSelector } from 'react-redux';
import axios from 'axios';

const { Option } = Select;

const ImportDoc = ({ debounce, npo_id }) => {
  const [selectedId, setSelectedId] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [success, setSuccess] = useState(true);
  const [loading, setLoading] = useState(false);
  const [filename, setFilename] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(null);
  const [importBtnClicked, setImportBtnClicked] = useState(false);
  const [flowCompleted, setFlowCompleted] = useState(false);
  // const { allTemplates } = useSelector((state) => state.docTypesReducer);
  const inputFile = useRef(null);

  // useEffect(() => {
  //   handleSave('');
  // }, []);

  const handleDownload = () => {
    let url = `${baseURL}/${filename}`;
    window.open(url, '_blank');
  };

  const handleSave = async (id) => {
    setSelectedId(id);

    setLoading(true);
    const res = await axios.get(
      `${baseURL}/api/v1/programs/export?npo_id=${npo_id}`, // document_control/export_document
      {
        params: {
          sample_doc: true,
          // document_template_id: id,
        },
      }
    );

    const { status } = res;

    if (status === 200) {
      const { filename } = res.data;
      // let url = `${baseURL}/${filename}`;
      let updatedFileName = res.data?.file?.to_path
        ? res.data?.file?.to_path?.slice(7)
        : filename;
      setFilename(updatedFileName); // filename
      // handleDownload(filename);
      // setSuccess(true);
    } else {
      message.error('Something went wrong!');
    }
    setLoading(false);
  };

  const cleanupFun = () => {
    // setSelectedId(null);
    // setSuccess(false);
    // setFilename(null);
    setFileUploaded(null);
    // setImportBtnClicked(false);
    // setFlowCompleted(false);
  };

  const onOpenChange = (e) => {
    if (!e) {
      cleanupFun();
    }
  };

  const handleClose = () => {
    setIsVisible(false);
    cleanupFun();
  };

  useEffect(() => {
    return () => {
      cleanupFun();
    };
  }, []);

  const handleImage = (imgObj, e) => {
    e.currentTarget.value = null;

    const isValid = /\.(xls|xlsx|csv|)$/i.test(imgObj.name);

    if (isValid) {
      if (imgObj) {
        setFileUploaded(imgObj);
      }
    } else {
      message.error('This file format is not supported.');
    }
  };

  const handleImportFile = async () => {
    setLoading(true);

    const fd = new FormData();
    fd.append('file', fileUploaded);

    try {
      const res = await axios.post(
        `${baseURL}/api/v1/programs/import?npo_id=${npo_id}`,
        fd
      );

      const { status } = res;

      if (status === 200) {
        const { success } = res.data;
        if (success) {
          debounce();
          setFlowCompleted(true);
        } else {
          message.error(res.data?.errors);
        }
      } else {
        message.error('Something went wrong!');
      }
      setLoading(false);
    } catch (error) {
      message.error('Something went wrong!');
      setLoading(false);
      console.log('error', error);
    }
  };

  const handleDeleteFile = () => {
    setFileUploaded(null);
    setImportBtnClicked(false);
  };

  const CloseBtnSection = () => {
    return (
      <Row justify='center' gutter={[0, 20]} className='close-btn-row'>
        <Col span={24}>
          <div className='success-text'>Document Imported Successfully</div>
        </Col>
        <Col span={24}>
          <Button size='small' onClick={handleClose} className='close-btn'>
            CLOSE
          </Button>
        </Col>
      </Row>
    );
  };

  const SuccessSection = () => {
    return (
      <Col span={24}>
        {fileUploaded === null ? (
          <Row justify='center' gutter={[0, 20]} className='success-row'>
            <Col span={24}>
              <div
                style={{
                  paddingLeft: '6px',
                }}
                className='file-text'
                onClick={handleDownload}
              >
                {filename}
              </div>
            </Col>
            <Col span={24}>
              <Button
                icon={<ExportIcon />}
                size='small'
                onClick={() => inputFile.current.click()}
                className='upload-btn'
              >
                UPLOAD FILE
              </Button>
              <input
                type='file'
                id='file'
                multiple
                accept=''
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={(e) => handleImage(e.target.files[0], e)}
              />
            </Col>
          </Row>
        ) : (
          <Row
            justify='center'
            gutter={[0, 20]}
            className='successfully-uploaded'
          >
            <Col span={24}>
              <div className='disabled-text'>{filename}</div>
            </Col>
            <Col span={24} className='file-row'>
              <Row>
                <Col span={18}>
                  <div className='file-text' onClick={handleDownload}>
                    {fileUploaded.name}
                  </div>
                  <div className='span-div'>
                    <span>File Uploaded Successfully</span>
                  </div>
                </Col>
                <Col>
                  {!flowCompleted && (
                    <DeleteIcon
                      className='del-icon'
                      onClick={handleDeleteFile}
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              {importBtnClicked ? (
                flowCompleted ? (
                  <CloseBtnSection />
                ) : (
                  <Row className='confirm-row' gutter={[0, 20]}>
                    <Col span={24}>
                      <span className='text-col'>
                        You are about to Import the above file into Document
                        Control. Please note this action cannot be undone.
                      </span>
                    </Col>
                    <Col span={24} className='btns-col'>
                      <Button
                        loading={loading}
                        size='small'
                        onClick={handleImportFile}
                        className='confirm-btn'
                      >
                        Confirm
                      </Button>
                      <Button
                        size='small'
                        onClick={cleanupFun}
                        className='cancel-btn'
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                )
              ) : (
                <Button
                  icon={<ExportIcon />}
                  size='small'
                  onClick={() => setImportBtnClicked(true)} // handleImportFile
                  className='import-file-btn'
                >
                  IMPORT FILE
                </Button>
              )}
            </Col>
          </Row>
        )}
      </Col>
    );
  };

  const UploadFileBtn = () => {
    return (
      <Button
        size='middle'
        icon={<ImportIcon />}
        loading={loading}
        // onClick={handleSave}
        className={`export-btn`}
      >
        UPLOAD FILE
      </Button>
    );
  };

  const Content = () => {
    return (
      <Row justify='center' gutter={[0, 20]} className='parent-div'>
        <Col span={24} className='choose-col'>
          <div className='choose-select-div'>
            <div
              className='file-text'
              onClick={() => fileUploaded === null && handleSave('')}
              style={{
                color: '#2660a4',
                paddingLeft: '6px',
                lineHeight: '16px',
                letterSpacing: '0px',
                fontWeight: 600,
                fontStyle: 'normal',
                fontSize: '13px',
                marginTop: '15px',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              Download Template
            </div>
          </div>
          {/* <div className='choose-select-div'>
            <label>{'DOCUMENT TYPE'}</label>
            <Row className={flowCompleted ? 'readonly-select-row' : ''}>
              <Select
                placeholder='Select Document Type'
                disabled={flowCompleted}
                size='small'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                onChange={(e) => handleSave(e)}
                value={selectedId}
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {allTemplates.map((el) => {
                  const { id, name } = el;
                  return (
                    <Option value={id} key={id}>
                      {name}
                    </Option>
                  );
                })}
                {[{ id: 1, name: 'test' }].map((el) => {
                  const { id, name } = el;
                  return (
                    <Option value={id} key={id}>
                      {name}
                    </Option>
                  );
                })}
              </Select>
            </Row>
          </div> */}
        </Col>
        {success ? (
          <SuccessSection />
        ) : (
          <Col>{filename !== null && <UploadFileBtn />}</Col>
        )}
      </Row>
    );
  };

  const Title = () => {
    return (
      <Row justify='space-between'>
        <Col className='title'>IMPORT</Col>
        <Col>
          <CloseOutlined onClick={handleClose} />
        </Col>
      </Row>
    );
  };

  return (
    <Popover
      overlayClassName='import-popover'
      placement='topRight'
      getPopupContainer={() =>
        document.getElementsByClassName(
          'ant-layout-content layout-content site-layout-background custom-textarea'
        )[0]
      }
      content={<Content />}
      onOpenChange={onOpenChange}
      title={<Title />}
      trigger='click'
      open={isVisible}
      onVisibleChange={(e) => {
        setIsVisible(e);
      }}
    >
      <ImportIcon />
    </Popover>
  );
};

export default ImportDoc;
