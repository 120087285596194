export const preAndPostKeys = [
  'mkt_pre',
  'mkt_post',
  'ses_pre',
  'ses_post',
  'leq_pre',
  'leq_post',
];

export const preAndPostColors = [
  '#FFC732',
  '#E31C21',
  '#FFC732',
  '#E31C21',
  '#FFC732',
  '#E31C21',
];

