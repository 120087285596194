import React from 'react';
import Table from './Table';

const TableComponent = ({ record }) => {
  let programs = record.programs || [];
  let classes = programs.map((el) => ({ ...el, ...el.program_learners[0] }));

  return <Table classes={classes} />;
};

export default TableComponent;
