import React, { useEffect } from 'react';
import { Col, Row, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createTemplate, updateTemplates } from '../redux/actions';
import AddFieldsComponent from './addFieldsComponents';
import { UseTemplateFields } from './useFields';
import { useNavigate } from 'react-router-dom';

const TemplateDetail = ({ isTemplateUpdate, canUpdate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { createTemplateLoading, currentTemplateObj, updateLoading } =
    useSelector((state) => state.templatesManagerReducer);

  const [
    inputFields,
    setInputFields,
    createField,
    deleteField,
    updateField,
    onEditClick,
    createOption,
    updateOption,
    deleteOption,
    onEditClickOption,
  ] = UseTemplateFields();

  const handleSave = () => {
    let template_fields = [];

    inputFields.forEach((el) => template_fields.push(el.id));

    if (isTemplateUpdate) {
      return dispatch(
        updateTemplates({
          template_fields,
          id: currentTemplateObj.id,
          navigate,
        })
      );
    } else {
      dispatch(
        createTemplate({
          template_fields,
          navigate,
        })
      );
    }
  };

  useEffect(() => {
    setInputFields(currentTemplateObj.template_fields);
  }, [currentTemplateObj]);

  return (
    <>
      <Row>
        <Col span={24} className='heading-col'>
          <Row align='middle' justify='space-between'>
            <Col>
              <div>
                <span className='heading-span'>
                  {currentTemplateObj?.name} Template
                  {/* {isTemplateUpdate ? ' UPDATE' : ' CREATION'} */}
                </span>
              </div>
            </Col>
          </Row>
        </Col>

        <Col span={24} className={`user-details-section document-type-section`}>
          <Row justify='center'>
            <Col md={23} lg={22} xl={20} xxl={18}>
              <Col>
                <AddFieldsComponent
                  inputFields={inputFields}
                  setInputFields={setInputFields}
                  createField={createField}
                  deleteField={deleteField}
                  updateField={updateField}
                  onEditClick={onEditClick}
                  createOption={createOption}
                  updateOption={updateOption}
                  deleteOption={deleteOption}
                  onEditClickOption={onEditClickOption}
                  canUpdate={canUpdate}
                />
              </Col>
            </Col>
            {canUpdate && <Col span={23} className='heading-col'></Col>}
            <Col md={24} lg={23} xl={20} xxl={16}>
              {canUpdate && (
                <Row gutter={[20, 10]} justify='center'>
                  <Row align='middle' justify='center'>
                    <Col>
                      <Button
                        type='primary'
                        className='save-btn'
                        size='middle'
                        onClick={handleSave}
                        loading={updateLoading || createTemplateLoading}
                      >
                        {isTemplateUpdate ? 'Update' : 'Save'} Template
                      </Button>
                    </Col>
                  </Row>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <div className='empty-div'></div>
    </>
  );
};
export default TemplateDetail;
