import * as constants from "./constants";

export const fetchDocStatuses = (data) => {
  return {
    type: constants.FETCH_DOC_STATUSES,
    data,
  };
};

export const fetchDocStatusesSuccess = (data) => {
  return {
    type: constants.FETCH_DOC_STATUSES_SUCCESS,
    data: data,
  };
};

export const fetchDocStatusesError = () => {
  return {
    type: constants.FETCH_DOC_STATUSES_ERROR,
  };
};

export const updateDocStatuses = (data) => {
  return {
    type: constants.UPDATE_DOC_STATUSES,
    data: data,
  };
};

export const updateDocStatusesSuccess = (data) => {
  return {
    type: constants.UPDATE_DOC_STATUSES_SUCCESS,
    data: data,
  };
};

export const updateDocStatusesError = () => {
  return {
    type: constants.UPDATE_DOC_STATUSES_ERROR,
  };
};

export const updateDocStatusesOrder = (data) => {
  return {
    type: constants.UPDATE_DOC_STATUS_ORDER,
    data: data,
  };
};

export const updateDocStatusesOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_DOC_STATUS_ORDER_SUCCESS,
    data: data,
  };
};

export const updateDocStatusesOrderError = () => {
  return {
    type: constants.UPDATE_DOC_STATUS_ORDER_ERROR,
  };
};

export const deleteDocStatuses = (data) => {
  return {
    type: constants.DELETE_DOC_STATUSES,
    data: data,
  };
};

export const deleteDocStatusesSuccess = (data) => {
  return {
    type: constants.DELETE_DOC_STATUSES_SUCCESS,
    data: data,
  };
};

export const deleteDocStatusesError = () => {
  return {
    type: constants.DELETE_DOC_STATUSES_ERROR,
  };
};

export const createDocStatuses = (data) => {
  return {
    type: constants.CREATE_DOC_STATUSES,
    data: data,
  };
};

export const createDocStatusesSuccess = (data) => {
  return {
    type: constants.CREATE_DOC_STATUSES_SUCCESS,
    data: data,
  };
};

export const createDocStatusesError = (id) => {
  return {
    type: constants.CREATE_DOC_STATUSES_ERROR,
    id: id,
  };
};

export const isDocStatusAdding = (data) => {
  return {
    type: constants.IS_DOC_STATUSES_ADD_UPDATE,
    data,
  };
};

export const setDocCompletedStatus = (data) => {
  return {
    type: constants.SET_DOC_COMPLETED_STATUS,
    data,
  };
};

export const updateDocCompletedStatuses = (data) => {
  return {
    type: constants.UPDATE_DOC_COMPLETED_STATUS,
    data: data,
  };
};
