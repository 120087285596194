import * as constants from './constants';

const initialState = {
  allSiteTypes: [],
  fetchingLoading: true,
  updateLoading: false,
  totalRecords: 0,
  isAdding: false,
};

const siteTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_SITE_TYPES:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_SITE_TYPES_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allSiteTypes: [...state.allSiteTypes, action.data],
        isAdding: false,
      };
    case constants.CREATE_SITE_TYPES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };

    case constants.UPDATE_ORDER_SITE_TYPES:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.UPDATE_ORDER_SITE_TYPES_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allSiteTypes: action.data,
      };
    case constants.UPDATE_ORDER_SITE_TYPES_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };

    case constants.FETCH_SITE_TYPES:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_SITE_TYPES_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allSiteTypes: action.data,
        isAdding: false,
      };
    case constants.FETCH_SITE_TYPES_ERROR:
      return {
        ...state,
        fetchingLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_SITE_TYPES:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_SITE_TYPES_SUCCESS:
      const updated = state.allSiteTypes.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        allSiteTypes: updated,
        updateLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_SITE_TYPES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_SITE_TYPES:
      return {
        ...state,
        updateLoading: true,
        isAdding: false,
      };
    case constants.DELETE_SITE_TYPES_SUCCESS:
      const updatedData = state.allSiteTypes.filter(
        (el) => el.id !== action.data
      );
      return {
        ...state,
        allSiteTypes: updatedData,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_SITE_TYPES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.IS_ADDING_UPDATE:
      return {
        ...state,
        isAdding: action.data,
      };

    default:
      return state;
  }
};

export default siteTypesReducer;
