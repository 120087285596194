import React, { useState } from 'react';
import { Table, Input, Col, Row } from 'antd';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { filterData } from './utils';

function ClassesTable({ classes }) {
  const [data, setData] = useState(classes);
  const [sortedInfo, setSortedInfo] = useState(null);
  const [showTable, setShowTable] = useState(false);

  const [search, setSearch] = useState({
    createdDateString: '',
  });

  const searchInput = (key) => (
    <Input
      placeholder={''}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        const { value } = e.target;
        let values = { ...search, [key]: value };
        filterData({ values, classes, setData });
        setSearch({ ...values });
      }}
    />
  );

  const columns = [
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>IMPL. Date</div>
            <div>{searchInput('program_date')}</div>
          </div>
        );
      },
      dataIndex: 'program_date',
      key: 'program_date',
      sorter: (a, b) =>
        moment(a.program_date).unix() - moment(b.program_date).unix(),
      sortOrder: sortedInfo?.columnKey === 'program_date' && sortedInfo?.order,
      width: 110,
      render: (date) => {
        return moment(date).format('DD MMMM YY');
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Site</div>
            <div>{searchInput('site_id')}</div>
          </div>
        );
      },
      dataIndex: 'site_id',
      key: 'site_id',
      sorter: (a, b) => a.site_id - b.site_id,
      sortOrder: sortedInfo?.columnKey === 'site_id' && sortedInfo?.order,
      width: 120,
      align: 'left',
      render: (site_id, record) => {
        if (!record?.pillar_status) {
          return;
        }

        const { pillar_status } = record;
        if (pillar_status.length < 1) {
          return;
        }

        return (
          <div>
            <div>{record?.site?.name}</div>
            <Row gutter={[4, 0]} className='colors-div'>
              {pillar_status.map((el, i) => {
                return (
                  <Col key={i}>
                    <div className='actual-div'>
                      <span className={`dot ${el}`}></span>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Coach</div>
            <div>{searchInput('user_id')}</div>
          </div>
        );
      },
      dataIndex: 'user_id',
      key: 'user_id',
      sorter: (a, b) =>
        `${a.coach.first_name} ${a.coach.last_name}`.localeCompare(
          `${b.coach.first_name} ${b.coach.last_name}`
        ),
      sortOrder: sortedInfo?.columnKey === 'user_id' && sortedInfo?.order,
      width: 110,
      align: 'left',
      render: (user_id, record) => {
        const { first_name, last_name } = record.coach;
        let name = `${first_name} ${last_name}`;
        return <div>{name}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              class <br />
              name
            </div>
            <div>{searchInput('name')}</div>
          </div>
        );
      },
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      width: 80,
      align: 'center',
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Topic title</div>
            <div>{searchInput('topic')}</div>
          </div>
        );
      },
      dataIndex: 'topic',
      key: 'topic',
      sorter: (a, b) => a.topic.localeCompare(b.topic),
      sortOrder: sortedInfo?.columnKey === 'topic' && sortedInfo?.order,
      width: 110,
      align: 'left',
      render: (topic, record) => {
        const {
          aydf_audited,
          aydf_coordinator_audited,
          npo_coordinator_audited,
        } = record;
        return (
          <div>
            <div>{record?.topic}</div>
            <Row gutter={[4, 0]} className='colors-div'>
              {[
                aydf_audited,
                aydf_coordinator_audited,
                npo_coordinator_audited,
              ]?.map((el, i) => {
                return (
                  <Col key={i}>
                    <div className='topic-div'>
                      <span
                        className={`dot ${el ? 'filled' : 'unfilled'}`}
                      ></span>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>NPO</div>
            <div>{searchInput('npo_id', '')}</div>
          </div>
        );
      },
      dataIndex: 'npo_id',
      key: 'npo_id',
      sorter: (a, b) => a.npo.name.localeCompare(b.npo.name),
      sortOrder: sortedInfo?.columnKey === 'npo_id' && sortedInfo?.order,
      width: 110,
      render: (topic, record) => <div>{record.npo.name}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              Programme <br />
              Type
            </div>
            <div>{searchInput('type')}</div>
          </div>
        );
      },
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.name.localeCompare(b.type.name),
      sortOrder: sortedInfo?.columnKey === 'type' && sortedInfo?.order,
      width: 150,
      align: 'left',
      render: (type, record) => <div>{record?.type?.name}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              Intervention <br />
              Type
            </div>
            <div>{searchInput('intervention')}</div>
          </div>
        );
      },
      dataIndex: 'intervention',
      key: 'intervention',
      sorter: (a, b) =>
        a?.intervention?.name.localeCompare(b?.intervention?.name),
      sortOrder: sortedInfo?.columnKey === 'intervention' && sortedInfo?.order,
      width: 150,
      align: 'left',
      render: (intervention, record) => <div>{record?.intervention?.name}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              Manual <br />
              Type
            </div>
            <div>{searchInput('manual')}</div>
          </div>
        );
      },
      dataIndex: 'manual',
      key: 'manual',
      sorter: (a, b) => a?.manual?.name?.localeCompare(b?.manual?.name),
      sortOrder: sortedInfo?.columnKey === 'manual' && sortedInfo?.order,
      width: 150,
      align: 'left',
      render: (received_by, record) => {
        let isMass = record.manual_type === 'mass';
        let manual = isMass ? 'Mass' : record?.manual?.name;
        return <div>{manual}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              MKT <br />
              Pre
            </div>
            <div>{searchInput('mkt_pre')}</div>
          </div>
        );
      },
      dataIndex: 'mkt_pre',
      key: 'mkt_pre',
      sorter: (a, b) => a.mkt_pre - b.mkt_pre,
      sortOrder: sortedInfo?.columnKey === 'mkt_pre' && sortedInfo?.order,
      width: 80,
      align: 'center',
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              MKT <br />
              post
            </div>
            <div>{searchInput('mkt_post')}</div>
          </div>
        );
      },
      dataIndex: 'mkt_post',
      key: 'mkt_post',
      sorter: (a, b) => a.mkt_post - b.mkt_post,
      sortOrder: sortedInfo?.columnKey === 'mkt_post' && sortedInfo?.order,
      width: 80,
      align: 'center',
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              ses <br />
              Pre
            </div>
            <div>{searchInput('ses_pre')}</div>
          </div>
        );
      },
      dataIndex: 'ses_pre',
      key: 'ses_pre',
      sorter: (a, b) => a.ses_pre - b.ses_pre,
      sortOrder: sortedInfo?.columnKey === 'ses_pre' && sortedInfo?.order,
      width: 80,
      align: 'center',
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              ses <br />
              post
            </div>
            <div>{searchInput('ses_post')}</div>
          </div>
        );
      },
      dataIndex: 'ses_post',
      key: 'ses_post',
      sorter: (a, b) => a.ses_post - b.ses_post,
      sortOrder: sortedInfo?.columnKey === 'ses_post' && sortedInfo?.order,
      width: 80,
      align: 'center',
    },

    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              leq <br />
              Pre
            </div>
            <div>{searchInput('leq_pre')}</div>
          </div>
        );
      },
      dataIndex: 'leq_pre',
      key: 'leq_pre',
      sorter: (a, b) => a.leq_pre - b.leq_pre,
      sortOrder: sortedInfo?.columnKey === 'leq_pre' && sortedInfo?.order,
      width: 80,
      align: 'center',
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              leq <br />
              post
            </div>
            <div>{searchInput('leq_post')}</div>
          </div>
        );
      },
      dataIndex: 'leq_post',
      key: 'leq_post',
      sorter: (a, b) => a.leq_post - b.leq_post,
      sortOrder: sortedInfo?.columnKey === 'leq_post' && sortedInfo?.order,
      width: 80,
      align: 'center',
    },

    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              Total <br />
              att
            </div>
            <div>{searchInput('total_attendance')}</div>
          </div>
        );
      },
      dataIndex: 'total_attendance',
      key: 'total_attendance',
      sorter: (a, b) => a.total_attendance - b.total_attendance,
      sortOrder:
        sortedInfo?.columnKey === 'total_attendance' && sortedInfo?.order,
      width: 60,
      align: 'left',

      render: (total_attendance) => <div>{total_attendance}</div>,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              AVG. <br />
              age
            </div>
            <div>{searchInput('average_age')}</div>
          </div>
        );
      },
      dataIndex: 'average_age',
      key: 'average_age',
      sorter: (a, b) => a.average_age - b.average_age,
      sortOrder: sortedInfo?.columnKey === 'average_age' && sortedInfo?.order,
      width: 60,
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          dataIndex: col.dataIndex,
          title: col.title,
        };
      },
    };
  });

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  return (
    <Row className='revisions-parent-row'>
      <Col span={24} className='heading-col'>
        <Row align='middle'>
          <Col>
            <div className='revisions-heading-div'>
              <div onClick={() => setShowTable(!showTable)}>
                {showTable ? (
                  <UpCircleOutlined className='revision-up-down-icon' />
                ) : (
                  <DownCircleOutlined className='revision-up-down-icon' />
                )}
              </div>
              <div>
                <span className='heading-span'>
                  Classes {classes?.length > 0 && `(${classes.length})`}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Col>

      {showTable && (
        <Col span={24}>
          <Table
            scroll={true} // { x: 1400 }
            className='special-table ims-doc-table revisions-table'
            rowKey={(record) => record.id}
            rowClassName='editable-row'
            dataSource={data}
            columns={mergedColumns}
            onChange={handleChange}
            pagination={false}
          />
        </Col>
      )}
    </Row>
  );
}

export default ClassesTable;
