import React from 'react';
import { DatePicker, Form, Input, Select } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
// import ImageComponent from './useAttachments';
import { requiredFeilds, getErrorText } from '../utils';

const { Option } = Select;
// const { TextArea } = Input;

const EditableCell = ({
  cell,
  expandedRowKeys,
  setExpandedRowKeys,
  // images,
  // setImages,
  // attachPopupVisible,
  // setAttachPopupVisible,
  // currentUpdateObj,
  values,
  handleChangeDocType,
  // getCloumnName,
  isRevisionUpdateCase,
  dueDateRequired,
}) => {
  const {
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  } = cell;
  const shouldDisabled = values?.document_template_id > 0 ? false : true;

  // const { allTemplates } = useSelector((state) => state.docTypesReducer);
  const { allSiteStatuses } = useSelector((state) => state.siteStatusReducer);

  const { allNpos } = useSelector((state) => state.npoSetupReducer);
  const { allSiteTypes } = useSelector((state) => state.siteTypesReducer);

  let inputNode;
  let classname = `${isRevisionUpdateCase ? 'ims-doc-readonly-input' : ''}`;

  let isRequired = [...requiredFeilds];
  dueDateRequired && isRequired.push('document_due_date');

  switch (dataIndex) {
    case 'site_date':
      inputNode = (
        <DatePicker
          format={'DD MMM YYYY'}
          size='large'
          style={{ minWidth: '115px' }}
          suffixIcon={null}
          allowClear={false}
          className={classname}
          disabled={isRevisionUpdateCase}
        />
      );
      break;
    case 'npo_id':
      inputNode = (
        <Select
          showSearch
          disabled={true} // {shouldDisabled || isRevisionUpdateCase}
          className={`activities-selector ${classname}`}
          style={{ maxWidth: '100px', width: '100%' }}
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          dropdownClassName='select-dropdown-custom'
          placeholder='Select NPO'
          size='small'
          optionFilterProp='children'
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={(e) => {
            handleChangeDocType(e);
            // let key = expandedRowKeys[0];
            // setExpandedRowKeys([]);
            // setExpandedRowKeys([key]);
          }}
        >
          {allNpos.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      );
      break;
    case 'npo_region':
      inputNode = <Input readOnly className='border-less-input' />;
      break;
    case 'name':
      inputNode = (
        <Input
          // disabled={shouldDisabled || isRevisionUpdateCase}
          placeholder='Site Name'
          size='large'
          className={classname}
          readOnly={isRevisionUpdateCase}
        />
      );
      break;
    case 'principal_name':
      inputNode = (
        <Input
          // disabled={shouldDisabled || isRevisionUpdateCase}
          placeholder='Principal Name'
          size='large'
          className={classname}
          readOnly={isRevisionUpdateCase}
        />
      );
      break;
    case 'principal_surname':
      inputNode = (
        <Input
          // disabled={shouldDisabled || isRevisionUpdateCase}
          placeholder='Principal Surname'
          size='large'
          className={classname}
          readOnly={isRevisionUpdateCase}
        />
      );
      break;

    case 'type_id':
      inputNode = (
        <Select
          showSearch
          className={`activities-selector ${classname}`}
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          style={{ maxWidth: '100px', width: '100%' }}
          dropdownClassName='select-dropdown-custom'
          placeholder='Select Site Type'
          size='small'
          // disabled={isRevisionUpdateCase}
          optionFilterProp='children'
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          // onChange={(e) => {
          //   handleChangeDocType(e);
          //   let key = expandedRowKeys[0];
          //   setExpandedRowKeys([]);
          //   setExpandedRowKeys([key]);
          // }}
        >
          {allSiteTypes.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      );
      break;

    case 'status_id':
      inputNode = (
        <Select
          showSearch
          // disabled={shouldDisabled || isRevisionUpdateCase}
          className={`activities-selector ${classname}`}
          style={{ maxWidth: '100px', width: '100%' }}
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          dropdownClassName='select-dropdown-custom'
          placeholder='Status'
          size='small'
          optionFilterProp='children'
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allSiteStatuses.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      );
      break;

    default:
      break;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          className={`ims-doc-first-level ${classname}`}
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
