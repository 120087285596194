import React, { useEffect, useState } from 'react';
import { message as AntdAlert } from 'antd';
import axios from 'axios';
import { baseURL } from '../../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../../components/unauthorizeHandler';
let url = `${baseURL}/api/v1`;

const initialData = {
  allImsRevisions: [],
  fetchingLoading: false,
  updateLoading: false,
  deleteAttachLoading: false,
  fetchUtilsLoading: false,
  isAddImsRevision: false,
  isUpdateImsRevision: false,
  deletingLoading: false,
  updateColLoading: false,
};

const UseMassLearners = () => {
  const [learnersData, setLearnersData] = useState(initialData);
  const [data, setData] = useState([]);
  const [updateData, setUpdateData] = useState(false);

  useEffect(() => {
    // console.log('useEffect', learnersData.allImsRevisions);
    if (learnersData.allImsRevisions.length === 0) {
      return setData(learnersData.allImsRevisions);
    } else if (learnersData.allImsRevisions.length > 0) {
      let updatedData = learnersData.allImsRevisions.map((el) => {
        const { attachments_urls, ...rest } = el;

        let updatedImages = [];
        if (attachments_urls?.length > 0) {
          updatedImages = attachments_urls.map((img) => {
            const { url, id } = img;
            let name = url.split('/')[url.split('/').length - 1].split('?')[0];

            return { key: id, id, image: url, isLocal: false, name };
          });
        }
        return { ...el, images: updatedImages, attachments_urls };
      });
      console.log('updatedData', updatedData);
      setData(updatedData);
      setLearnersData({ ...learnersData, allImsRevisions: updatedData });
    }
  }, [updateData]);

  const handleCreate = async (data) => {
    setLearnersData({ ...learnersData, updateLoading: true });
    const fd = new FormData();
    const { debouncedCallApi, ...rest } = data;
    for (const [key, value] of Object.entries(rest)) {
      if (key === 'images' && value !== undefined && value !== null) {
        Array.from(value).forEach((image) => {
          image.isLocal && fd.append(`attachments[]`, image);
        });
      } else if (value !== null && value !== undefined) {
        fd.append(key, value);
      }
    }

    try {
      const response = await axios.post(`${baseURL}/api/v1/mass_learners`, fd, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      const { success, message } = response.data;
      if (success) {
        AntdAlert.success(message);
        setData([response.data.data, ...learnersData.allImsRevisions]);
        let updated = {
          ...learnersData,
          updateLoading: false,
          allImsRevisions: [
            response.data.data,
            ...learnersData.allImsRevisions,
          ],
          isAddImsRevision: false,
          isUpdateImsRevision: false,
        };
        setLearnersData(updated);
        setTimeout(() => {
          setUpdateData((prev) => !prev);
        }, 500);
        debouncedCallApi();
      } else if (!success) {
        AntdAlert.error(response.data?.errors[0]);
        setLearnersData({
          ...learnersData,
          updateLoading: false,
          isAddImsRevision: false,
          isUpdateImsRevision: false,
        });
        errorHandler(response.data);
      }
    } catch (error) {
      console.log('error', error);
      setLearnersData({
        ...learnersData,
        updateLoading: false,
        isAddImsRevision: false,
        isUpdateImsRevision: false,
      });
      AntdAlert.error('Something went wrong!');
    }
  };

  const handleUpdate = async (data) => {
    setLearnersData({ ...learnersData, updateColLoading: true });
    const fd = new FormData();
    console.log('data', data);
    const { debouncedCallApi, id, ...rest } = data;

    for (const [key, value] of Object.entries(rest)) {
      if (key === 'images' && value !== undefined && value !== null) {
        Array.from(value).forEach((image) => {
          image.isLocal && fd.append(`attachments[]`, image);
        });
      } else if (value !== null && value !== undefined) {
        fd.append(key, value);
      }
    }

    try {
      const response = await axios.put(
        `${baseURL}/api/v1/mass_learners/${id}`,
        fd,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      );
      const { success, message } = response.data;
      // console.log('response.data', response.data);
      if (success) {
        AntdAlert.success(message);
        setData([response.data.data]);
        let updated = {
          ...learnersData,
          updateLoading: false,
          allImsRevisions: [response.data.data],
          isAddImsRevision: false,
          isUpdateImsRevision: false,
        };
        setLearnersData(updated);
        debouncedCallApi();
        // handleUpdateSuccess();
      } else if (!success) {
        AntdAlert.error(response.data?.errors[0]);
        setLearnersData({
          ...learnersData,
          updateColLoading: false,
          isAddImsRevision: false,
          isUpdateImsRevision: false,
        });
        errorHandler(response.data);
      }
    } catch (error) {
      console.log('error', error);
      setLearnersData({
        ...learnersData,
        updateColLoading: false,
        isAddImsRevision: false,
        isUpdateImsRevision: false,
      });
      AntdAlert.error('Something went wrong!');
    }
  };

  const fetchLearners = (learner) => {
    console.log('fetchLearners learner', learner);
    setData(learner);

    setLearnersData({
      ...learnersData,
      allImsRevisions: learner,
    });
    setTimeout(() => {
      setUpdateData((prev) => !prev);
    }, 500);
  };

  const deleteLearners = async (id, onDeleteSuccess) => {
    setLearnersData({ ...learnersData, deletingLoading: true });

    try {
      const response = await axios.delete(`${url}/mass_learners/${id}`);
      const { success, message } = response.data;

      if (success) {
        // console.log('response.data', response.data);
        const filtered = learnersData.allImsRevisions.filter(
          (el) => el.id !== id
        );
        setData(filtered);

        AntdAlert.success(message);
        let state = {
          ...learnersData,
          allImsRevisions: filtered,
          deletingLoading: false,
        };
        setLearnersData(state);
        setTimeout(() => {
          setUpdateData((prev) => !prev);
        }, 500);
        onDeleteSuccess();
      } else {
        AntdAlert.error('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
      AntdAlert.error('Something went wrong!');
    }
  };

  const handleIsIsUpdate = (isAdd, isUpdate) => {
    setLearnersData({
      ...learnersData,
      isAddImsRevision: isAdd,
      isUpdateImsRevision: isUpdate,
    });
  };

  const handleDeleteAttach = async (data) => {
    const { recordId, filteredImages } = data;
    let updated = [];
    learnersData.allImsRevisions.map((el) => {
      // let attachments_urls = el.attachments_urls.filter(
      //   (img) => img.id != key
      // );
      if (el.id === recordId) {
        updated.push({
          ...el,
          attachments_urls: filteredImages,
          images: filteredImages,
        });
      } else {
        updated.push(el);
      }
    });
    setData(updated);
    return {
      ...learnersData,
      allImsRevisions: updated,
      deleteAttachLoading: false,
    };
  };

  return {
    learnersData,
    data,
    setData,
    setLearnersData,
    handleCreate,
    fetchLearners,
    deleteLearners,
    handleIsIsUpdate,
    handleDeleteAttach,
    handleUpdate,
  };
};

export default UseMassLearners;
