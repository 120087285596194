import { put, takeEvery } from 'redux-saga/effects';
import { message as AntdAlert } from 'antd';
import moment from 'moment';
import axios from 'axios';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';
import * as constants from '../constants';

export function* handleFetchActivities({ data }) {
  const { search, ...rest } = data;

  Object.keys(search).forEach((key) => {
    if (search[key]?.length < 1 || search[key] === null) delete search[key];
  });

  let searchDatesKeys = ['start_date', 'end_date'];

  let params = { ...rest, ...(Object.keys(search).length > 0 && { search }) };

  const fd = new FormData();

  for (const [key, value] of Object.entries(params)) {
    if (value === null || value === undefined) {
    } else if (key === 'template_field' && value.length > 0) {
      value.forEach((ele) => {
        for (const [key1, value1] of Object.entries(ele)) {
          if (key1.includes('date')) {
            fd.append(
              `template_field[][${key1}]`,
              moment(value1).format('DD MMM YYYY')
            );
          } else {
            fd.append(`template_field[][${key1}]`, value1);
          }
        }
      });
    } else if (key === 'distributions' || key === 'revisions') {
      value.forEach((ele) => {
        for (const [key1, value1] of Object.entries(ele)) {
          if (['date_added', 'last_updated'].includes(key1)) {
            // fd.append(
            //   `${key}[][${key1}]`,
            //   moment(value1).format('DD MMM YYYY')
            // );
            fd.append(key1, moment(value1).format('DD MMM YYYY'));
          } else {
            // fd.append(`${key}[][${key1}]`, value1);
            fd.append(key1, moment(value1));
          }
        }
      });
    } else if (key === 'search') {
      for (const [key2, value2] of Object.entries(value)) {
        if (['document_template_id', 'document_status_id', 'fullname'].includes(key2)) {
          value2.forEach((ele) => {
            fd.append(`${key}[${key2}][]`, ele);
          });
        } else if (searchDatesKeys.includes(key2)) {
          fd.append(`search[${key2}]`, moment(value2).format('DD MMM YYYY'));
        } else {
          fd.append(`search[${key2}]`, value2);
        }
      }
    } else if (value !== null && value !== undefined) {
      fd.append(key, value);
    }
  }

  try {
    const url = `${baseURL}/api/v1/document_control/document_control_graph?${new URLSearchParams(
      fd
    ).toString()}`;

    // const response = yield axios.get(
    //   `${baseURL}/api/v1/document_control/document_control_graph`,
    //   {
    //     params: { ...rest, ...(Object.keys(search).length > 0 && { search }) },
    //   }
    // );
    const response = yield axios.get(url);
    const { success } = response.data;

    if (success) {
      yield put(actions.fetchActivitiesSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchCoachesDocs({ data }) {
  const { search, ...rest } = data;

  Object.keys(search).forEach((key) => {
    if (search[key]?.length < 1 || search[key] === null) delete search[key];
  });

  let searchDatesKeys = [
    'class_start_date',
    'class_end_date',
    'start_date',
    'end_date',
  ];

  let params = { ...rest, ...(Object.keys(search).length > 0 && { search }) };

  const fd = new FormData();

  let filterDateFields = ['date_added', 'last_updated'];

  for (const [key, value] of Object.entries(params)) {
    // console.log("key,value", key, value);
    if (value === null || value === undefined) {
    } else if (key === 'template_field' && value.length > 0) {
      value.forEach((ele) => {
        for (const [key1, value1] of Object.entries(ele)) {
          if (key1.includes('date')) {
            fd.append(
              `template_field[][${key1}]`,
              moment(value1).format('DD MMM YYYY')
            );
          } else {
            fd.append(`template_field[][${key1}]`, value1);
          }
        }
      });
    } else if (key === 'distributions' || key === 'revisions') {
      value.forEach((ele) => {
        for (const [key1, value1] of Object.entries(ele)) {
          if (filterDateFields.includes(key1)) {
            fd.append(key1, moment(value1).format('DD MMM YYYY'));
          } else {
            // fd.append(`${key}[][${key1}]`, value1);
            fd.append(key1, value1);
          }
        }
      });
    } else if (key === 'search') {
      for (const [key2, value2] of Object.entries(value)) {
        if (['fullname','npo_id', 'site_id', 'type_id', 'status_id'].includes(key2)) {
          value2.forEach((ele) => {
            fd.append(`${key2}[]`, ele);
          });
        } else if (searchDatesKeys.includes(key2)) {
          fd.append(`${key2}`, moment(value2).format('DD MMM YYYY'));
        } else if (value2 !== null && value2 !== undefined) {
          fd.append(`${key2}`, value2);
        }
      }
    } else if (value !== null && value !== undefined) {
      // if (searchDatesKeys.includes(key)) {
      //   fd.append(key, moment(value).format('DD MMM YYYY'));
      // } else {
      fd.append(key, value);
      // }
    }
  }

  try {
    const url = `${baseURL}/api/v1/coaches?${new URLSearchParams(
      fd
    ).toString()}`;

    const response = yield axios.get(url);
    const { success } = response.data;

    if (success) {
      yield put(actions.fetchCoachesDocsSuccess(response.data.data));
    } else if (!success) {
      yield put(actions.fetchCoachesDocsError());
      errorHandler(response.data);
    }
  } catch (error) {
    AntdAlert.error('Something went wrong!');
    yield put(actions.fetchCoachesDocsError());
    console.log(error);
  }
}

export function* handleBatchUpdate({ data }) {
  let searchDatesKeys = [
    'end_date',
    'document_start_date',
    'document_end_date',
  ];

  const fd = new FormData();

  for (const [key, value] of Object.entries(data)) {
    if (value === null || value === undefined) {
    } else if (key === 'document_control' && value.length > 0) {
      // console.log("value", value);
      value.forEach((ele) => {
        fd.append(`${key}[]`, ele);
      });
    } else if (key === 'template_field' && value.length > 0) {
      value.forEach((ele) => {
        for (const [key1, value1] of Object.entries(ele)) {
          if (key1.includes('date')) {
            fd.append(
              `template_field[][${key1}]`,
              moment(value1).format('DD MMM YYYY')
            );
          } else {
            fd.append(`template_field[][${key1}]`, value1);
          }
        }
      });
    } else if (key === 'defaultField' && value.length > 0) {
      value.forEach((ele) => {
        for (const [key1, value1] of Object.entries(ele)) {
          if ([0, 4].includes(key1)) {
            fd.append(
              `defaultField[][${key1}]`,
              moment(value1).format('DD MMM YYYY')
            );
          } else {
            fd.append(`defaultField[][${key1}]`, value1);
          }
        }
      });
    } else if (key === 'distributions' || key === 'revisions') {
      value.forEach((ele) => {
        for (const [key1, value1] of Object.entries(ele)) {
          if (['date_added', 'last_updated'].includes(key1)) {
            fd.append(`${key}[${key1}]`, moment(value1).format('DD MMM YYYY'));
          } else {
            fd.append(`${key}[${key1}]`, value1);
          }
        }
      });
    } else if (key === 'search') {
      for (const [key2, value2] of Object.entries(value)) {
        // console.log("key2, value2", key2, value2);
        if (['document_template_id', 'document_status_id'].includes(key2)) {
          value2.forEach((ele) => {
            fd.append(`${key}[${key2}][]`, ele);
          });
        } else if (searchDatesKeys.includes(key2)) {
          fd.append(`search[${key2}]`, moment(value2).format('DD MMM YYYY'));
        } else {
          fd.append(`search[${key2}]`, value2);
        }
      }
    } else if (key === 'revisions_ids' || key === 'distributions_ids') {
      if (value?.length > 0) {
        value.map((id) => fd.append(`${key}[]`, id));
      }
    } else if (value !== null && value !== undefined) {
      fd.append(key, value);
    }
  }

  try {
    const url = `${baseURL}/api/v1/document_control/batch_update?${new URLSearchParams(
      fd
    ).toString()}`;

    const response = yield axios.post(url);
    const { success, message } = response.data;

    if (success) {
      yield put(actions.fetchBatchUpdateSuccess(response.data.data));
      AntdAlert.success(message);
    } else if (!success) {
      yield put(actions.fetchBatchUpdateError());
      AntdAlert.error(message || 'Something went wrong!');
      errorHandler(response.data);
    }
  } catch (error) {
    AntdAlert.error('Something went wrong!');
    yield put(actions.fetchBatchUpdateError());
    console.log(error);
  }
}

export function* handleFetchActivitiesUtilsData({ data }) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v1/sales_activities/search_lists`
    );
    const { success } = response.data;

    if (success) {
      yield put(actions.fetchActivitiesSearchUtilsSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watcherCoachesSaga() {
  yield takeEvery(constants.FETCH_COACHES_DOCS, handleFetchCoachesDocs);
  yield takeEvery(constants.FETCH_BATCH_UPDATE, handleBatchUpdate);
  yield takeEvery(constants.FETCH_ACTIVITIES, handleFetchActivities);
  yield takeEvery(
    constants.FETCH_ACTIVITIES_SEARCH_DATA,
    handleFetchActivitiesUtilsData
  );
}
