import React, { useEffect, useState } from 'react';
import {
  Table,
  Input,
  Tag,
  Space,
  Popconfirm,
  Select,
  Col,
  Row,
  Button,
  Tooltip,
} from 'antd';
import { DownOutlined, UpOutlined, PlusOutlined } from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteUser,
  fetchAllUsers,
  setUserDetailsIsEdit,
} from './redux/actions';
import SpinnerComponent from '../../components/spinner';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import CustomPagination from '../../components/customPagination/CustomPagination';
import { capitalizeFirstLetterofEachWord } from '../../globalUtils/capitalizeFirstLetterofEachWord';
import { statusOptions, userTypeOptions } from './utils';
import AMultiSelect from '../../components/AMultiSelect';
import ExportDoc from './export';
import ImportDoc from './import';
import UsePermissions from './hooks/usePermissions';

const { Option } = Select;

const UsersRegisterTable = ({ userType, userTypeName }) => {
  const [sortedInfo, setSortedInfo] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [currentDelRecord, setCurrentDelRecord] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(() => 1);
  const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [search, setSearch] = useState({});
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);
  const [dynamicTypes, setDynamicTypes] = useState([]);
  const [dynamicStatuses, setDynamicStatuses] = useState([]);
  const [statusCheckedList, setStatusCheckedList] = useState([]);
  const [typeCheckedList, setTypeCheckedList] = useState([]);
  const [npoData, setNpoData] = useState([]);
  const [npoCheckedList, setNpoCheckedList] = useState([]);
  const { canView, canEdit, canExport, canImport, canDelete } =
    UsePermissions();

  const { allUsers, fetchUsersLoading, deleteUserLoading, totalRecords } =
    useSelector((state) => state.userManagerReducer);
  const { user } = useSelector((state) => state.authReducer);
  const { allNpos } = useSelector((state) => state.npoSetupReducer);
  const { allCoachTypes } = useSelector((state) => state.coachTypesReducer);
  const { allLearnerTypes } = useSelector((state) => state.learnerTypesReducer);
  const { allCoachStatuses } = useSelector((state) => state.coachStatusReducer);
  const { allLearnerStatuses } = useSelector(
    (state) => state.learnerStatusReducer
  );

  useEffect(() => {
    if (page && resultPerPage && page > 0 && resultPerPage > 0) {
      dispatch(
        fetchAllUsers({
          page: page,
          per_page: resultPerPage,
          search,
          sort: sortedInfo?.order && sortedInfo?.columnKey,
          order: sortedInfo?.order
            ? sortedInfo?.order === 'ascend'
              ? 'asc'
              : 'desc'
            : null,
          user_type: userType,
        })
      );
    }
  }, [req]);

  useEffect(() => {
    let paramsPage = 1;
    if (paramsPage && resultPerPage && paramsPage > 0 && resultPerPage > 0) {
      dispatch(
        fetchAllUsers({
          page: paramsPage,
          per_page: resultPerPage,
          user_type: userType,
          search,
        })
      );
      setPage(1);
    }
  }, [defaultPageOneReq, userType]);

  useEffect(() => {
    debouncedCallApi();
  }, [sortedInfo]);

  useEffect(() => {
    setDynamicTypes(userType === 'coach' ? allCoachTypes : allLearnerTypes);

    let statuses = userType === 'coach' ? allCoachStatuses : allLearnerStatuses;
    let types = userType === 'coach' ? allCoachTypes : allLearnerTypes;

    let updatedStatuses = statuses.map((el) => {
      const { id, name } = el;
      return { value: id, label: name };
    });
    setDynamicStatuses(updatedStatuses);

    let updatedTypes = types.map((el) => {
      const { id, name } = el;
      return { value: id, label: name };
    });
    setDynamicTypes(updatedTypes);
  }, [
    allCoachStatuses,
    allCoachTypes,
    allLearnerStatuses,
    allLearnerTypes,
    userType,
  ]);

  useEffect(() => {
    let updatedNpos = allNpos.map((el) => {
      const { id, name } = el;
      return { value: id, label: name };
    });
    setNpoData(updatedNpos);
  }, [allNpos]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );

  useEffect(() => {
    setDataSource(allUsers);
  }, [allUsers]);

  useEffect(() => {
    setFetchLoading(fetchUsersLoading);
  }, [fetchUsersLoading]);

  // useEffect(() => {
  //   setFetchLoading(deleteUserLoading);
  // }, [deleteUserLoading]);

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleDelete = (record) => {
    setCurrentDelRecord(record);
    dispatch(deleteUser(record));
  };

  const searchInput = (key) => (
    <Input
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e.target.value,
        });
        debouncedDefaultPageOneCallApi();
      }}
    />
  );

  const handleNavigate = (id) =>
    navigate(`/settings/${userTypeName}-users-manager/update/${id}`);

  const handleMultiSelect = (list, key) => {
    setSearch({
      ...search,
      [key]: list,
    });
  };

  const handleSelectOnchange = (value, options, key, setHandler) => {
    if (value.includes('all')) {
      setHandler([...options.map((item) => item.value)]);
      value = options.map((item) => item.value);
    } else if (value.includes('not-all')) {
      setHandler([]);
      value = [];
    } else {
      setHandler(value);
    }
    handleMultiSelect(value, key);
  };

  const columns = [
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>First name</div>
            <div>{searchInput('first_name')}</div>
          </div>
        );
      },
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'first_name' && sortedInfo?.order,
      ellipsis: true,
      width: '13%',
      render: (first_name, { id }) => (
        <div onClick={() => handleNavigate(id)}>{first_name}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>last name</div>
            <div>{searchInput('last_name')}</div>
          </div>
        );
      },
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'last_name' && sortedInfo?.order,
      ellipsis: true,
      width: '15%',
      render: (last_name, { id }) => (
        <div onClick={() => handleNavigate(id)}>{last_name}</div>
      ),
    },
    userType.includes('system')
      ? {
          title: () => {
            return (
              <div align='top' className='select-parent-div'>
                <div className='special-table-header-text'>user type</div>
                <div>
                  <Select
                    style={{
                      width: '100%',
                      minHeight: '25px',
                      fontSize: '12px',
                      textTransform: 'capitalize',
                    }}
                    onClick={(e) => e.stopPropagation()}
                    allowClear={true}
                    readOnly={true}
                    className='Select Project'
                    dropdownClassName='select-dropdown-custom'
                    getPopupContainer={() =>
                      document.getElementsByClassName(
                        'ant-layout-content layout-content site-layout-background custom-textarea'
                      )[0]
                    }
                    showSearch
                    placeholder=''
                    optionFilterProp='children'
                    onChange={(e) => {
                      setSearch({
                        ...search,
                        search_user_type: e,
                      });
                      debouncedDefaultPageOneCallApi();
                    }}
                    optionLabelProp='label'
                    filterOption={(input, option) =>
                      option?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {userTypeOptions?.map(({ label, value }) => {
                      return (
                        <Option value={value} label={label}>
                          {label || ''}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            );
          },
          dataIndex: 'user_type',
          key: 'user_type',
          sorter: true,
          sortOrder: sortedInfo?.columnKey === 'user_type' && sortedInfo?.order,
          ellipsis: true,
          width: '13%',
          render: (user_type, { id }) => (
            <div onClick={() => handleNavigate(id)}>
              {user_type && capitalizeFirstLetterofEachWord(user_type)}
            </div>
          ),
        }
      : {
          title: () => {
            return (
              <div align='top' className='select-parent-div'>
                <div className='special-table-header-text'>
                  {userTypeName} type
                </div>
                <div>
                  <AMultiSelect
                    dropdownClassName='status-selector'
                    debouncedCallApi={debouncedCallApi}
                    disabled={false}
                    placeholder=''
                    selectTags={typeCheckedList}
                    onSelectChange={(value) =>
                      handleSelectOnchange(
                        value,
                        dynamicTypes,
                        `${userTypeName}_type`,
                        setTypeCheckedList
                      )
                    }
                    data={dynamicTypes}
                    filterOption={(input, option) => {
                      return (
                        String(option?.label)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  />
                </div>
              </div>
            );
          },
          dataIndex: `${userTypeName}_type`,
          key: `${userTypeName}_type`,
          sorter: true,
          sortOrder:
            sortedInfo?.columnKey === `${userTypeName}_type` &&
            sortedInfo?.order,
          ellipsis: true,
          width: '13%',
          render: (user_type, { id, type }) => (
            <div onClick={() => handleNavigate(id)}>
              {type && capitalizeFirstLetterofEachWord(type?.name)}
            </div>
          ),
        },
    userType.includes('system')
      ? {
          title: () => {
            return (
              <div align='top' className='select-parent-div'>
                <div className='special-table-header-text'>job title</div>
                <div>{searchInput('job_title')}</div>
              </div>
            );
          },
          dataIndex: 'job_title',
          key: 'job_title',
          sorter: true,
          sortOrder: sortedInfo?.columnKey === 'job_title' && sortedInfo?.order,
          ellipsis: true,
          width: '20%',
          render: (job_title, { id }) => (
            <div onClick={() => handleNavigate(id)}>{job_title}</div>
          ),
        }
      : {
          title: () => {
            return (
              <div align='top' className='select-parent-div'>
                <div className='special-table-header-text'>NPO</div>
                <div>
                  <AMultiSelect
                    dropdownClassName='status-selector'
                    debouncedCallApi={debouncedCallApi}
                    disabled={false}
                    placeholder=''
                    selectTags={npoCheckedList}
                    onSelectChange={(value) =>
                      handleSelectOnchange(
                        value,
                        npoData,
                        'npo_id',
                        setNpoCheckedList
                      )
                    }
                    data={npoData}
                    filterOption={(input, option) => {
                      return (
                        String(option?.label)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  />
                </div>
              </div>
            );
          },
          dataIndex: 'npo_id',
          key: 'npo_id',
          sorter: true,
          sortOrder: sortedInfo?.columnKey === 'npo_id' && sortedInfo?.order,
          ellipsis: true,
          width: '20%',
          render: (npo_, { id, npo }) => (
            <div onClick={() => handleNavigate(id)}>{npo?.name}</div>
          ),
        },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>email</div>
            <div>{searchInput('email')}</div>
          </div>
        );
      },
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order,
      ellipsis: true,
      width: '20%',
      render: (email, { id }) => (
        <div onClick={() => handleNavigate(id)}>{email}</div>
      ),
    },
    userType.includes('system')
      ? {
          title: () => {
            return (
              <div align='top' style={{ width: '100%' }}>
                <div className='special-table-header-text'>status</div>
                <div>
                  <Select
                    style={{
                      width: '100%',
                      minHeight: '25px',
                      fontSize: '12px',
                      textTransform: 'capitalize',
                    }}
                    onClick={(e) => e.stopPropagation()}
                    allowClear={true}
                    readOnly={true}
                    className='Select Project'
                    dropdownClassName='select-dropdown-custom'
                    getPopupContainer={() =>
                      document.getElementsByClassName(
                        'ant-layout-content layout-content site-layout-background custom-textarea'
                      )[0]
                    }
                    showSearch
                    placeholder=''
                    optionFilterProp='children'
                    onChange={(e) => {
                      setSearch({
                        ...search,
                        user_status: e,
                      });
                      debouncedDefaultPageOneCallApi();
                    }}
                    optionLabelProp='label'
                    filterOption={(input, option) =>
                      option?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {statusOptions?.map(({ label, value }) => {
                      return (
                        <Option value={value} label={label}>
                          {label || ''}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            );
          },
          dataIndex: 'user_status',
          key: 'user_status',
          sorter: true,
          sortOrder:
            sortedInfo?.columnKey === 'user_status' && sortedInfo?.order,
          ellipsis: true,
          width: '11%',
          align: 'center',
          render: (user_status, { id }) => {
            let color;
            let text;
            switch (user_status) {
              case 'active':
                color = '#7cd175';
                text = 'Active';
                break;
              case 'inactive':
                color = '#fa7470';
                text = 'Inactive';
                break;
              default:
                break;
            }
            return (
              <Tag
                onClick={() => handleNavigate(id)}
                color={color}
                style={{
                  color: '#231f20',
                  width: '70px',
                  height: '20px',
                  textAlign: 'center',
                }}
              >
                {text}
              </Tag>
            );
          },
        }
      : {
          title: () => {
            return (
              <div align='top' style={{ width: '100%' }}>
                <div className='special-table-header-text'>status</div>
                <div>
                  <AMultiSelect
                    dropdownClassName='status-selector'
                    debouncedCallApi={debouncedCallApi}
                    disabled={false}
                    placeholder=''
                    selectTags={statusCheckedList}
                    onSelectChange={(value) =>
                      handleSelectOnchange(
                        value,
                        dynamicStatuses,
                        'status_id',
                        setStatusCheckedList
                      )
                    }
                    data={dynamicStatuses}
                    filterOption={(input, option) => {
                      return (
                        String(option?.label)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  />
                </div>
              </div>
            );
          },
          dataIndex: 'status_id',
          key: 'status_id',
          sorter: true,
          sortOrder: sortedInfo?.columnKey === 'status_id' && sortedInfo?.order,
          ellipsis: true,
          width: '11%',
          align: 'center',
          render: (status_id, { id, status }) => {
            let name = status?.name || '';
            let color = status?.color || '';

            return (
              <Tag
                onClick={() => handleNavigate(id)}
                color={color}
                style={{
                  color: '#231f20',
                  width: '90px',
                  height: '20px',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {name}
              </Tag>
            );
          },
        },
    {
      title: null,
      dataIndex: '',
      key: 'action',
      width: '10%',
      align: 'center',
      render: (_, record) => {
        if (!canEdit && !canDelete) {
          return;
        }

        return deleteUserLoading && record.id === currentDelRecord.id ? (
          <SpinnerComponent fontSize={14} />
        ) : (
          <Space size={15} align='baseline'>
            {canEdit && user?.user_type !== 'coach' && (
              <EditIcon
                width={18}
                height={18}
                className='custom-icon'
                onClick={() => {
                  setTimeout(() => {
                    dispatch(setUserDetailsIsEdit(true));
                  }, 1000);
                  handleNavigate(record.id);
                }}
              />
            )}
            {canDelete && (
              <Popconfirm
                title='Are you sure you want to delete this user?'
                placement='topRight'
                onConfirm={() => handleDelete(record)}
                okText='Yes'
                cancelText='No'
              >
                <DeleteIcon width={18} height={18} className='custom-icon' />
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Col span={24} className='heading-col'>
        <Row align='middle' gutter={[50, 0]} justify='space-between'>
          <Col>
            <Row align='middle' gutter={[30, 0]}>
              <Col>
                <div>
                  <span className='heading-span'>
                    {userTypeName} Users Register
                  </span>
                </div>
              </Col>
              <Col>
                {canEdit && (
                  <Button
                    onClick={() => {
                      dispatch(setUserDetailsIsEdit(true));
                      navigate(
                        `/settings/${userTypeName}-users-manager/create`
                      );
                    }}
                    icon={<PlusOutlined />}
                    className='add-user-btn'
                    size='middle'
                  >
                    {userTypeName} USER
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
          <Col>
            <Space size={20} align='center'>
              {canExport && (
                <Tooltip text='Export'>
                  <ExportDoc userType={userType} userTypeName={userTypeName} />
                </Tooltip>
              )}
              {canImport && (
                <Tooltip text='Import'>
                  <ImportDoc
                    userType={userType}
                    userTypeName={userTypeName}
                    debounce={debouncedDefaultPageOneCallApi}
                  />
                </Tooltip>
              )}
            </Space>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Table
          className='special-table users-manager-table'
          columns={columns}
          scroll={{ x: true }}
          onChange={handleChange}
          dataSource={dataSource}
          pagination={false}
          sortUpIcon={<UpOutlined />}
          sortDownIcon={<DownOutlined />}
          loading={fetchLoading}
        />
        <CustomPagination
          page={page}
          setPage={setPage}
          resultPerPage={resultPerPage}
          setResultPerPage={setResultPerPage}
          debouncedCallApi={debouncedCallApi}
          totalRecord={totalRecords}
        />
      </Col>
    </>
  );
};

export default UsersRegisterTable;
