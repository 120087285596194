import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
// import { setDocumentTemplateIsEdit } from './redux/actions';
import Table from './Table';
import './index.less';

const TemplatesManager = () => {
  const [canUpdate, setCanUpdate] = useState(false);
  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (user?.permissions?.templates_manager?.add_edit) {
      setCanUpdate(true);
    }
  }, [user]);

  return (
    <div className='page-top-div'>
      <span className='text-span'>Template Manager</span>
      <Row className='production-manager-parent-row'>
        <Col span={24} className='heading-col'>
          <Row align='middle' gutter={[50, 0]}>
            <Col>
              <div>
                <span className='heading-span' style={{ color: '#0d2843' }}>
                  Templates Register
                </span>
              </div>
            </Col>
            {/* <Col>
              {canUpdate && (
                <Button
                  onClick={() => {
                    navigate('/settings/templates-manager/create');
                    dispatch(setDocumentTemplateIsEdit(true));
                  }}
                  icon={<PlusOutlined />}
                  className='add-user-btn'
                  size='middle'
                  style={{ width: 125 }}
                >
                  Template
                </Button>
              )}
            </Col> */}
          </Row>
        </Col>

        <Col span={24}>
          <Table canUpdate={canUpdate} />
        </Col>
      </Row>
    </div>
  );
};

export default TemplatesManager;
