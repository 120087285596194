import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Input,
  Select,
  Col,
  Row,
  Popconfirm,
  Space,
  DatePicker,
} from 'antd';
import { ReactComponent as DeleteIcon } from '../../../../../assets/delete-icon.svg';
import { ReactComponent as SaveIcon } from '../../../../../assets/circular-save-btn.svg';
import { ReactComponent as CrossIcon } from '../../../../../assets/crossIcon.svg';
import { useSelector } from 'react-redux';
import moment from 'moment';

const { Option } = Select;

const CustomFieldsComponent = ({
  inputFields,
  setInputFields,
  createField,
  deleteField,
  updateField,
  canUpdate,
  selectedTemplate,
  setSelectedTemplate,
  handleSelectTemplate,
  dynamicFieldsOptions,
  setDynamicFieldsOptions,
}) => {
  // const { allTemplates } = useSelector((state) => state.docTypesReducer);

  const handleDeleteLocally = (item) => {
    const filteredInputs = inputFields.filter((el) => el.id !== item.id);
    setInputFields(filteredInputs);
  };

  // const handleChangeDocType = (value) => {
  //   handleSelectTemplate();
  //   const filteredItem = allTemplates.filter((el) => el.id === value)[0];
  //   setDynamicFieldsOptions(filteredItem?.template_fields || []);

  //   setSelectedTemplate(filteredItem);
  // };

  const addChooseField = () => {
    let field = {
      //   name: "choose",
      //   field_type: "choose",
      isEdit: true,
      field_type: 'select',
      name: '',
      //   isEdit: true,
      isLocal: true,
      id: Math.floor(Math.random() * 10000000),
      label: 'Select Field',
      placeholder: `Select Site Template Field`,
      isSelect: true,
    };
    setInputFields([...inputFields, { ...field }]);
  };

  const handleChangeField = (value, ele, option) => {
    const { index } = ele;
    let fields = [...inputFields];
    fields[index] = {
      ...ele,
      id: value,
      value,
      option,
    };
    setInputFields(fields);
  };

  const handleDisable = (id) => {
    let item = inputFields.filter((el) => el.id === id && !el.isSelect);

    let shuoldDisabled = item.length > 0 ? true : false;

    return shuoldDisabled;
  };

  const SelectOption = ({ ele }) => (
    <Select
      style={{ display: 'block' }}
      placeholder={ele?.placeholder}
      size='small'
      getPopupContainer={() =>
        document.getElementsByClassName(
          'ant-layout-content layout-content site-layout-background custom-textarea'
        )[0]
      }
      disabled={!canUpdate}
      onChange={(e, option) => handleChangeField(e, ele, option)}
      value={ele?.value || null}
    >
      {dynamicFieldsOptions.map((el) => {
        const { id, name, field_type, field_options } = el;
        return (
          <Option
            value={id}
            key={id}
            disabled={handleDisable(id)}
            field_type={field_type}
            field_options={field_options}
          >
            {name}
          </Option>
        );
      })}
    </Select>
  );

  const RenderFieldTypes = ({ item, localName, setLocalName }) => {
    const { placeholder, option, index } = item;

    if (option.field_type === 'date_field') {
      return (
        <DatePicker
          format={'DD MMM YYYY'}
          size='small'
          allowClear={false}
          suffixIcon={null}
          placeholder={'Select Date'}
          value={localName}
          disabled={!canUpdate}
          onChange={(e) => {
            setLocalName(moment(e));
            inputFields[index].name = moment(e);
            setInputFields(inputFields);
          }}
        />
      );
    }

    if (option.field_type === 'select_field') {
      const { field_options } = option;
      return (
        <Select
          placeholder={`Select ${placeholder}`}
          size='small'
          disabled={!canUpdate}
          value={localName || null}
          onChange={(e) => setLocalName(e)}
        >
          {field_options.map((el) => {
            const { id, name } = el;
            return (
              <Option value={id} key={id} name={name}>
                {name}
              </Option>
            );
          })}
        </Select>
      );
    }

    return (
      <Input
        placeholder={placeholder}
        value={localName}
        disabled={!canUpdate}
        onChange={(e) => {
          const { value } = e.target;
          setLocalName(value);
          inputFields[index].name = value;
          setInputFields(inputFields);
        }}
      />
    );
  };

  const getSelectName = (options, id) => {
    let option = options.filter((el) => el.id == id)[0];
    return option?.name || '';
  };

  const SelectedInput = ({ item }) => {
    const { name, option } = item;

    const value =
      option.field_type === 'date_field'
        ? moment(name).format('DD MMM YYYY')
        : option.field_type === 'select_field'
        ? getSelectName(option.field_options, name)
        : name;

    return <Input className='border-less-input' readOnly value={value} />;
  };

  const RenderInputs = ({ ele }) => {
    const { isEdit, label, name } = ele;
    const [localName, setLocalName] = useState(name);

    return (
      <>
        <Row
          align='bottom'
          justify='start'
          className='field-row'
          gutter={[15, 0]}
        >
          <Col className='input-field' span={18} lg={17} xl={18}>
            <label>{label}</label>

            {ele?.isEdit ? (
              ele?.isSelect ? (
                <SelectOption ele={ele} />
              ) : (
                <RenderFieldTypes
                  item={ele}
                  localName={localName}
                  setLocalName={setLocalName}
                />
              )
            ) : (
              <SelectedInput item={ele} />
            )}
          </Col>
          {canUpdate && (
            <Col>
              {isEdit ? (
                <Space size={10} align='baseline'>
                  <SaveIcon
                    onClick={() =>
                      ele?.isSelect
                        ? createField({ ...ele, name: localName })
                        : updateField({ ...ele, name: localName })
                    }
                  />
                  <Popconfirm
                    title='Are you sure to cancel?'
                    placement='topRight'
                    onConfirm={() => handleDeleteLocally(ele)}
                    okText='Yes'
                    cancelText='No'
                  >
                    <CrossIcon />
                  </Popconfirm>
                </Space>
              ) : (
                <Space size={10} align='baseline'>
                  {/* <EditIcon onClick={() => onEditClick(ele)} /> */}
                  <Popconfirm
                    title='Are you sure you want to delete?'
                    placement='topRight'
                    onConfirm={() => deleteField(ele)}
                    okText='Yes'
                    cancelText='No'
                  >
                    <DeleteIcon width={18} height={18} fill='#e21c21' />
                  </Popconfirm>
                </Space>
              )}
            </Col>
          )}
        </Row>
      </>
    );
  };

  return (
    <Row gutter={[20, 0]} className='custom-fields-parent-div'>
      <Col span={18} className='choose-col'>
        {/* <div className='choose-select-div'>
          <label>{'DOCUMENT TYPE'}</label>
          <Row>
            <Select
              placeholder="Select Document Type"
              size="small"
              getPopupContainer={() =>
                document.getElementsByClassName(
                  "ant-layout-content layout-content site-layout-background custom-textarea"
                )[0]
              }
              disabled={!canUpdate}
              onChange={(e) => handleChangeDocType(e)}
              value={selectedTemplate?.id || null}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allTemplates.map((el) => {
                const { id, name } = el;
                return (
                  <Option value={id} key={id}>
                    {name}
                  </Option>
                );
              })}
            </Select>
          </Row>
        </div> */}
      </Col>
      {inputFields.map((el, i) => {
        return (
          <Col span={24} key={`item-${el.id}`} index={i}>
            <RenderInputs ele={{ ...el, index: i }} />
          </Col>
        );
      })}
      {canUpdate && (
        <Col span={24}>
          <Button
            onClick={addChooseField}
            icon={<PlusOutlined />}
            size='small'
            className='add-field-btn'
            // disabled={selectedTemplate?.id > 0 ? false : true}
          >
            Filter
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default CustomFieldsComponent;
