const FETCH_DOC_STATUSES = "FETCH_DOC_STATUSES";
const FETCH_DOC_STATUSES_SUCCESS = "FETCH_DOC_STATUSES_SUCCESS";
const FETCH_DOC_STATUSES_ERROR = "FETCH_DOC_STATUSES_ERROR";
const UPDATE_DOC_STATUSES = "UPDATE_DOC_STATUSES";
const UPDATE_DOC_STATUSES_SUCCESS = "UPDATE_DOC_STATUSES_SUCCESS";
const UPDATE_DOC_STATUSES_ERROR = "UPDATE_DOC_STATUSES_ERROR";
const DELETE_DOC_STATUSES = "DELETE_DOC_STATUSES";
const DELETE_DOC_STATUSES_SUCCESS = "DELETE_DOC_STATUSES_SUCCESS";
const DELETE_DOC_STATUSES_ERROR = "DELETE_DOC_STATUSES_ERROR";
const CREATE_DOC_STATUSES = "CREATE_DOC_STATUSES";
const CREATE_DOC_STATUSES_SUCCESS = "CREATE_DOC_STATUSES_SUCCESS";
const CREATE_DOC_STATUSES_ERROR = "CREATE_DOC_STATUSES_ERROR";
const IS_DOC_STATUSES_ADD_UPDATE = "IS_DOC_STATUSES_ADD_UPDATE";
const UPDATE_DOC_STATUS_ORDER = "UPDATE_DOC_STATUS_ORDER";
const UPDATE_DOC_STATUS_ORDER_SUCCESS = "UPDATE_DOC_STATUS_ORDER_SUCCESS";
const UPDATE_DOC_STATUS_ORDER_ERROR = "UPDATE_DOC_STATUS_ORDER_ERROR";
const UPDATE_DOC_COMPLETED_STATUS = "UPDATE_DOC_COMPLETED_STATUS";
const UPDATE_DOC_COMPLETED_STATUS_SUCCESS =
  "UPDATE_DOC_COMPLETED_STATUS_SUCCESS";
const UPDATE_DOC_COMPLETED_STATUS_ERROR = "UPDATE_DOC_COMPLETED_STATUS_ERROR";
const SET_DOC_COMPLETED_STATUS = "SET_DOC_COMPLETED_STATUS";

export {
  FETCH_DOC_STATUSES,
  FETCH_DOC_STATUSES_SUCCESS,
  FETCH_DOC_STATUSES_ERROR,
  UPDATE_DOC_STATUSES,
  UPDATE_DOC_STATUSES_SUCCESS,
  UPDATE_DOC_STATUSES_ERROR,
  DELETE_DOC_STATUSES,
  DELETE_DOC_STATUSES_SUCCESS,
  DELETE_DOC_STATUSES_ERROR,
  CREATE_DOC_STATUSES,
  CREATE_DOC_STATUSES_SUCCESS,
  CREATE_DOC_STATUSES_ERROR,
  UPDATE_DOC_STATUS_ORDER,
  UPDATE_DOC_STATUS_ORDER_SUCCESS,
  UPDATE_DOC_STATUS_ORDER_ERROR,
  IS_DOC_STATUSES_ADD_UPDATE,
  UPDATE_DOC_COMPLETED_STATUS,
  UPDATE_DOC_COMPLETED_STATUS_SUCCESS,
  UPDATE_DOC_COMPLETED_STATUS_ERROR,
  SET_DOC_COMPLETED_STATUS,
};
