const FETCH_CLASSES_LEARNERS = 'FETCH_CLASSES_LEARNERS';
const FETCH_CLASSES_LEARNERS_SUCCESS = 'FETCH_CLASSES_LEARNERS_SUCCESS';
const FETCH_CLASSES_LEARNERS_ERROR = 'FETCH_CLASSES_LEARNERS_ERROR';
const UPDATE_CLASSES_LEARNERS = 'UPDATE_CLASSES_LEARNERS';
const UPDATE_CLASSES_LEARNERS_SUCCESS = 'UPDATE_CLASSES_LEARNERS_SUCCESS';
const UPDATE_CLASSES_LEARNERS_ERROR = 'UPDATE_CLASSES_LEARNERS_ERROR';
// const FETCH_CLASSES_LEARNERS_SEARCH_DATA = "FETCH_CLASSES_LEARNERS_SEARCH_DATA";
// const FETCH_CLASSES_LEARNERS_SEARCH_DATA_SUCCESS =
//   "FETCH_CLASSES_LEARNERS_SEARCH_DATA_SUCCESS";
// const FETCH_CLASSES_LEARNERS_SEARCH_DATA_ERROR = "FETCH_CLASSES_LEARNERS_SEARCH_DATA_ERROR";
const DELETE_CLASSES_LEARNERS = 'DELETE_CLASSES_LEARNERS';
const DELETE_CLASSES_LEARNERS_SUCCESS = 'DELETE_CLASSES_LEARNERS_SUCCESS';
const DELETE_CLASSES_LEARNERS_ERROR = 'DELETE_CLASSES_LEARNERS_ERROR';
const CREATE_CLASSES_LEARNERS = 'CREATE_CLASSES_LEARNERS';
const CREATE_CLASSES_LEARNERS_SUCCESS = 'CREATE_CLASSES_LEARNERS_SUCCESS';
const CREATE_CLASSES_LEARNERS_ERROR = 'CREATE_CLASSES_LEARNERS_ERROR';
const DELETE_CLASSES_LEARNERS_ATTACH = 'DELETE_CLASSES_LEARNERS_ATTACH';
const DELETE_CLASSES_LEARNERS_ATTACH_SUCCESS =
  'DELETE_CLASSES_LEARNERS_ATTACH_SUCCESS';
const DELETE_CLASSES_LEARNERS_ATTACH_ERROR =
  'DELETE_CLASSES_LEARNERS_ATTACH_ERROR';
const UPDATE_CLASSES_LEARNERS_ISADD_ISUPDATE =
  'UPDATE_CLASSES_LEARNERS_ISADD_ISUPDATE';
const ADD_CLASSES_LEARNERS_ATTACH = 'ADD_CLASSES_LEARNERS_ATTACH';

export {
  FETCH_CLASSES_LEARNERS,
  FETCH_CLASSES_LEARNERS_SUCCESS,
  FETCH_CLASSES_LEARNERS_ERROR,
  UPDATE_CLASSES_LEARNERS,
  UPDATE_CLASSES_LEARNERS_SUCCESS,
  UPDATE_CLASSES_LEARNERS_ERROR,
  DELETE_CLASSES_LEARNERS,
  DELETE_CLASSES_LEARNERS_SUCCESS,
  DELETE_CLASSES_LEARNERS_ERROR,
  CREATE_CLASSES_LEARNERS,
  CREATE_CLASSES_LEARNERS_SUCCESS,
  CREATE_CLASSES_LEARNERS_ERROR,
  DELETE_CLASSES_LEARNERS_ATTACH,
  DELETE_CLASSES_LEARNERS_ATTACH_SUCCESS,
  DELETE_CLASSES_LEARNERS_ATTACH_ERROR,
  UPDATE_CLASSES_LEARNERS_ISADD_ISUPDATE,
  ADD_CLASSES_LEARNERS_ATTACH,
};
