import React from 'react';
import { Row, Col } from 'antd';
import CustomFieldsComponent from './customFields/index';
import { UseCustomFields } from './customFields/useCustomFields';
import RevisionsFieldsComponent from './RevisionFilters/index';
import { UseRevisionFields } from './RevisionFilters/useRevisionFields';
import { ReactComponent as FilterIcon } from '../../../../assets/filter.svg';
import { ReactComponent as CrossIcon } from '../../../../assets/crossIcon.svg';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { shouldAllow } from '../../utils';

const FilterSection = ({
  handleFilter,
  handleClose,
  selectedTemplate,
  setSelectedTemplate,
  setFilterDataFetched,
  dynamicFieldsOptions,
  setDynamicFieldsOptions,
  users,
}) => {
  const [custom, setCustom, createCustom, deleteCustom, updateCustom] =
    UseCustomFields();

  const [rev, setRev, createRev, deleteRev, updateRev] = UseRevisionFields();

  const { isCollapsed } = useSelector((state) => state.authReducer);

  const filterAdded = () => {
    if (custom.length > 0 || rev.length > 0) {
      let savedCustom = custom.filter((el) => !el.isLocal);
      let savedRev = rev.filter((el) => !el.isLocal);

      if (savedCustom.length > 0 || savedRev.length > 0) {
        return true;
      }
      return false;
    }

    return false;
  };

  const handleOnClick = () => {
    if (!filterAdded()) {
      return;
    }

    let obj = {};

    obj['document_template_id'] = selectedTemplate.id;
    obj['filter'] = true;

    let template_field = [];

    if (custom.length > 0) {
      custom.forEach((el) => {
        const { name, value } = el;
        if (shouldAllow(el)) {
          const { option } = el;
          const { field_type } = option;
          let field_value =
            field_type === 'date_field'
              ? moment(name).format('DD MMM YYYY')
              : field_type === 'select_field'
              ? option?.field_options?.filter((ele) => ele.id === name)[0].name
              : name;

          template_field.push({
            field_value,
            field_id: value,
          });
        }
      });
    }

    let revisions = [];

    if (rev.length > 0) {
      rev.forEach((el) => {
        const { name, value } = el;
        if (shouldAllow(el)) {
          revisions.push({ [value]: name });
        }
      });
    }

    if (template_field.length > 0) {
      obj['template_field'] = template_field;
    }

    if (revisions.length > 0) {
      obj['revisions'] = revisions;
    }

    handleFilter(obj);
    setFilterDataFetched(true);
  };

  const handleSelectTemplate = () => {
    setCustom([]);
    setRev([]);
  };

  const onCloseClick = () => {
    handleSelectTemplate();
    handleClose();
  };

  return (
    <Col span={24} className='ims-doc-filter-section'>
      <Row align='middle' justify='space-between' className='filter-header'>
        <Col className='filter-col'>
          <FilterIcon /> FILTER
        </Col>
        <Col>
          <CrossIcon className='cross-icon' onClick={onCloseClick} />
        </Col>
      </Row>
      <Row gutter={[40, 20]} className='sections-row'>
        <Col className={`sub-sections-col${isCollapsed ? '-collapsed' : ''}`}>
          <div className='label-div'>
            <span>LEARNER CUSTOM FIELDS FILTERS</span>
          </div>
          <RevisionsFieldsComponent
            inputFields={rev}
            setInputFields={setRev}
            createField={createRev}
            deleteField={deleteRev}
            updateField={updateRev}
            canUpdate={true}
            selectedTemplate={selectedTemplate}
            users={users}
          />
        </Col>
        <Col className={`sub-sections-col${isCollapsed ? '-collapsed' : ''}`}>
          <div className='label-div'>
            <span>LEARNER TEMPLATE FIELDS FILTERS</span>
          </div>
          <CustomFieldsComponent
            inputFields={custom}
            setInputFields={setCustom}
            createField={createCustom}
            deleteField={deleteCustom}
            updateField={updateCustom}
            canUpdate={true}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            handleSelectTemplate={handleSelectTemplate}
            dynamicFieldsOptions={dynamicFieldsOptions}
            setDynamicFieldsOptions={setDynamicFieldsOptions}
          />
        </Col>
        <Col
          className={`sub-sections-filter-col${
            isCollapsed ? '-collapsed' : ''
          }`}
        >
          <div className='filter-div'>
            <div
              onClick={handleOnClick}
              className={!filterAdded() && 'disabled-div'}
            >
              Apply <br /> Filters
            </div>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default FilterSection;
