import { Row, Col, Button, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './index.less';
import { ReactComponent as BackIcon } from '../../../assets/back-icon.svg';
import UserDetailsSection from './userDetailsSection';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUser,
  setUserDetailsIsEdit,
  userDetailCleanupFunc,
} from '../redux/actions';
// import UseSupAdminOrAdminWithUserManagerPermission from '../../../components/useSupAdminOrAdminWithUserManagerPermission';
import GeneralPermissions from './generalPermissions';
import ProjectPermissionsTable from './ProjectPermissionsTable';
// import { fetchProjectStatuses } from '../../ProjectStatuses/redux/actions';
import { fetchAllNpos } from '../../NpoSetup/redux/actions';
import { fetchCoachTypes } from '../../CoachTypes/redux/actions';
import { fetchCoachStatuses } from '../../CoachStatuses/redux/actions';
import { fetchLearnerTypes } from '../../LearnerTypes/redux/actions';
import { fetchLearnerStatuses } from '../../LearnerStatuses/redux/actions';
import { fetchNpoStatuses } from '../../NpoStatuses/redux/actions';
import { fetchImsDocs } from '../../Sites/redux/actions';
import { fetchAllTemplates } from '../../TemplatesManager/redux/actions';

const UserDetails = () => {
  const [isUserUpdate, setIsUserUpdate] = useState(false);
  const [isProfileCase, setIsProfileCase] = useState();
  const [canUpdate, setCanUpdate] = useState(false);
  const [userType, setUserType] = useState('');

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  // let canEditEveryThing = UseSupAdminOrAdminWithUserManagerPermission();

  const { currentUserObj, fetchUserDetailLoading, userDetailsIsEdit } =
    useSelector((state) => state.userManagerReducer);
  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    setIsProfileCase(pathname === '/profile');
    if (pathname !== '/profile') {
      if (pathname.includes('system-users-manager')) {
        setUserType('system_manager');
        if (user?.permissions?.system_users_manager?.add_edit) {
          setCanUpdate(true);
        }
      } else if (pathname.includes('coach-users-manager')) {
        setUserType('coach');
        if (user?.permissions?.coach_users_manager?.add_edit) {
          setCanUpdate(true);
        }
      } else if (pathname.includes('learner-users-manager')) {
        setUserType('learner');
        if (user?.permissions?.learner_users_manager?.add_edit) {
          setCanUpdate(true);
        }
      }
      // setCanUpdate(true);
    } else {
      if (user?.permissions?.system_users_manager?.add_edit) {
        setCanUpdate(true);
      }
      if (user?.permissions?.coach_users_manager?.add_edit) {
        setCanUpdate(true);
      }
      if (user?.permissions?.learner_users_manager?.add_edit) {
        setCanUpdate(true);
      }
    }
  }, [pathname, user]);

  useEffect(() => {
    if (currentUserObj?.id) {
      setIsUserUpdate(true);
      setIsProfileCase(currentUserObj.id === user.id);
      let userType =
        currentUserObj?.user_type === 'super_admin'
          ? 'system_manager'
          : currentUserObj?.user_type;
      setUserType(userType);
    } else {
      if (pathname.includes('create')) {
        dispatch(setUserDetailsIsEdit(true));
      }
    }
  }, [currentUserObj]);

  useEffect(() => {
    let user_type = '';
    if (pathname.includes('system-users-manager')) {
      user_type = 'system_manager';
    } else if (pathname.includes('coach-users-manager')) {
      user_type = 'coach';
    } else if (pathname.includes('learner-users-manager')) {
      user_type = 'learner';
    }
    if (params?.id) {
      setIsUserUpdate(true);
      dispatch(fetchUser({ id: params?.id, user_type }));
    } else if (pathname === '/profile') {
      setIsUserUpdate(true);
      dispatch(fetchUser({ id: user.id, user_type }));
    }

    return () => {
      dispatch(userDetailCleanupFunc());
    };
  }, [params.id]);

  useEffect(() => {
    dispatch(fetchAllNpos({ search: {} }));
    dispatch(fetchCoachTypes());
    dispatch(fetchCoachStatuses());
    dispatch(fetchLearnerTypes());
    dispatch(fetchLearnerStatuses());
    dispatch(fetchNpoStatuses());
    dispatch(
      fetchImsDocs({ search: {}, per_page: 'all', sort: 'name', order: 'asc' })
    );
    dispatch(fetchAllTemplates({}));
  }, []);

  let coachLearnerTypes = ['coach', 'learner'];
  let cannotUpdate = isProfileCase && coachLearnerTypes.includes(userType);

  let isCoach = user?.user_type === 'coach';
  let showHideFields =
    !userDetailsIsEdit && isCoach && pathname.includes('learner-users-manager');

  return (
    <div className='page-top-div heading-parent-div'>
      <Row justify='space-between'>
        <Col>
          <span className='text-span'>
            {isProfileCase
              ? `User - ${user?.first_name + ' ' + user?.last_name}`
              : isUserUpdate
              ? `Update ${userType.replace('_', ' ')}`
              : `Add ${userType.replace('_', ' ')} User`}
          </span>
        </Col>
        {!isProfileCase && (
          <Col>
            <Button
              onClick={() =>
                navigate(`/settings/${userType.split('_')[0]}-users-manager`)
              }
              icon={<BackIcon width={24} height={14} />}
              className='back-btn'
              size='middle'
            >
              BACK
            </Button>
          </Col>
        )}
      </Row>
      <Spin spinning={fetchUserDetailLoading}>
        <div className='user-manager-parent-div'>
          <UserDetailsSection
            isUserUpdate={isUserUpdate}
            isProfileCase={isProfileCase}
            canUpdate={canUpdate}
            userType={userType}
            cannotUpdate={cannotUpdate}
            showHideFields={showHideFields}
          />
          {isUserUpdate && (
            <>
              <GeneralPermissions
                isProfileCase={isProfileCase}
                canUpdate={canUpdate}
                userType={userType}
                cannotUpdate={cannotUpdate}
              />
              <ProjectPermissionsTable
                isProfileCase={isProfileCase}
                canUpdate={canUpdate}
                userType={userType}
                cannotUpdate={cannotUpdate}
              />
            </>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default UserDetails;
