export const filterData = ({ values, data, setData }) => {
  let noFiltered = data.filter((entry) => {
    let length = values?.no?.length;
    let input = values?.no?.toLocaleLowerCase();
    return length > 0 ? entry.no.toString().includes(input) : data;
  });

  let firstNameFiltered = noFiltered.filter((entry) => {
    let length = values?.first_name?.length;
    let input = values?.first_name?.toLocaleLowerCase();
    return length > 0
      ? entry.user.first_name.toLocaleLowerCase().includes(input)
      : data;
  });

  // console.log('firstNameFiltered', firstNameFiltered);
  let lastNameFiltered = firstNameFiltered.filter((entry) => {
    let length = values?.last_name?.length;
    let input = values?.last_name?.toLocaleLowerCase();
    return length > 0
      ? entry.user.last_name.toLocaleLowerCase().includes(input)
      : firstNameFiltered;
  });
  // console.log('lastNameFiltered', lastNameFiltered);
  let attendanceFiltered = lastNameFiltered.filter((entry) => {
    let inputLength = values?.attendance?.length;
    if (inputLength > 0) {
      let input = values?.attendance?.toString();
      const { attendance } = entry;
      return attendance.toString().includes(input);
    } else return lastNameFiltered;
  });
  //   console.log('attendanceFiltered', attendanceFiltered);
  let demographicFiltered = attendanceFiltered.filter((entry) => {
    let length = values?.demographic?.length;
    if (length > 0) {
      let input = values?.demographic?.toLocaleLowerCase();
      const { age_type, gender, race } = entry.user;
      let demographic = `${race} ${gender} ${age_type || ''}`;
      return demographic.toLocaleLowerCase().includes(input);
    } else return attendanceFiltered;
  });

  let campFiltered = demographicFiltered.filter((entry) => {
    let length = values?.learner_camp_id?.length;
    if (length > 0) {
      let input = values?.learner_camp_id?.toLocaleLowerCase();
      const { title } = entry.learner_camp;
      return title?.toLocaleLowerCase().includes(input);
    } else return demographicFiltered;
  });

  let term1Filtered = campFiltered.filter((entry) => {
    let length = values?.first_term?.length;
    if (length > 0) {
      let input = values?.first_term?.toString();
      const { first_term } = entry;
      return first_term.toString().includes(input);
    } else return campFiltered;
  });

  let term2Filtered = term1Filtered.filter((entry) => {
    let length = values?.second_term?.length;
    if (length > 0) {
      let input = values?.second_term?.toString();
      const { second_term } = entry;
      return second_term.toString().includes(input);
    } else return term1Filtered;
  });

  let term3Filtered = term2Filtered.filter((entry) => {
    let length = values?.third_term?.length;
    if (length > 0) {
      let input = values?.third_term?.toString();
      const { third_term } = entry;
      return third_term.toString().includes(input);
    } else return term2Filtered;
  });

  let term4Filtered = term3Filtered.filter((entry) => {
    let length = values?.fourth_term?.length;
    if (length > 0) {
      let input = values?.fourth_term?.toString();
      const { fourth_term } = entry;
      return fourth_term.toString().includes(input);
    } else return term3Filtered;
  });

  let mktPreFiltered = term4Filtered.filter((entry) => {
    let length = values?.mkt_pre?.length;
    if (length > 0) {
      let input = values?.mkt_pre?.toString();
      const { mkt_pre } = entry;
      return mkt_pre.toString().includes(input);
    } else return term4Filtered;
  });

  let mktPostFiltered = mktPreFiltered.filter((entry) => {
    let length = values?.mkt_post?.length;
    if (length > 0) {
      let input = values?.mkt_post?.toString();
      const { mkt_post } = entry;
      return mkt_post.toString().includes(input);
    } else return mktPreFiltered;
  });

  let sesPreFiltered = mktPostFiltered.filter((entry) => {
    let length = values?.ses_pre?.length;
    if (length > 0) {
      let input = values?.ses_pre?.toString();
      const { ses_pre } = entry;
      return ses_pre.toString().includes(input);
    } else return mktPostFiltered;
  });

  let sesPostFiltered = sesPreFiltered.filter((entry) => {
    let length = values?.ses_post?.length;
    if (length > 0) {
      let input = values?.ses_post?.toString();
      const { ses_post } = entry;
      return ses_post.toString().includes(input);
    } else return sesPreFiltered;
  });

  let leqPreFiltered = sesPostFiltered.filter((entry) => {
    let length = values?.leq_pre?.length;
    if (length > 0) {
      let input = values?.leq_pre?.toString();
      const { leq_pre } = entry;
      return leq_pre.toString().includes(input);
    } else return sesPostFiltered;
  });

  let leqPostFiltered = leqPreFiltered.filter((entry) => {
    let length = values?.leq_post?.length;
    if (length > 0) {
      let input = values?.leq_post?.toString();
      const { leq_post } = entry;
      return leq_post.toString().includes(input);
    } else return leqPreFiltered;
  });

  let attachFiltered = leqPostFiltered.filter((entry) => {
    let length = values?.attach?.length;
    if (length > 0) {
      let input = values?.attach?.toString();
      const { attachments_urls } = entry;
      return attachments_urls?.length?.toString()?.includes(input);
    } else return leqPostFiltered;
  });

  setData(attachFiltered);
};
