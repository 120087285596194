import React, { useEffect, useState } from 'react';
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Space,
  Col,
  Row,
  message,
  Tag,
  Select,
  Switch,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as CrossIcon } from '../../../assets/cross.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import { ReactComponent as SaveIcon } from '../../../assets/save-icon.svg';
import SpinnerComponent from '../../../components/spinner';
import { useNavigate } from 'react-router-dom';
import {
  createUserProject,
  deleteUserProject,
  setEditProjectPermissions,
  updateUserProject,
  fetchAllUserProjects,
} from '../redux/actions';
import _ from 'lodash';
import { fetchAllNpos } from '../../NpoSetup/redux/actions';
import { useParams } from 'react-router-dom';
import UseSupAdminOrAdminWithUserManagerPermission from '../../../components/useSupAdminOrAdminWithUserManagerPermission';
import TablePermissionsSection from './tablePermissionSection';
import {
  userProjectPermissions,
  initialEditingRowData,
  handlePermissionsCol,
} from './utils';
import AMultiSelect from '../../../components/AMultiSelect';

const ProjectPermissionsTable = ({
  isProfileCase,
  canUpdate,
  userType,
  cannotUpdate,
}) => {
  const params = useParams();
  // const [utils, setUtils] = useState({});
  // const [currentProject, setCurrentProject] = useState({});
  const [sortedInfo, setSortedInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [currentDelRecord, setCurrentDelRecord] = useState({});
  const [editingLoading, setEditingLoading] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [page, setPage] = useState(() => 1);
  const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [search, setSearch] = useState({});
  const [searchedData, setSearchedData] = useState([]);
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);
  const { Option } = Select;
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  let canEditEveryThing = UseSupAdminOrAdminWithUserManagerPermission();
  const [permissions, setPermissions] = useState({
    ...userProjectPermissions(userType),
  });
  const [editingRowData, setEditingRowData] = useState({
    ...initialEditingRowData,
  });
  const [statusCheckedList, setStatusCheckedList] = useState([]);
  const [projectStatuses, SetProjectStatuses] = useState([]);

  const isEditing = (record) => {
    return record.id === editingKey;
  };

  const {
    fetchingLoading,
    createProjectLoading,
    isEditProjectPermissions,
    currentUserObj,
    user_projects,
    fetchProjectsLoading,
  } = useSelector((state) => state.userManagerReducer);

  const userId = params?.id || currentUserObj?.id;

  const { allNpos } = useSelector((state) => state.npoSetupReducer);

  const { allNpoStatuses } = useSelector((state) => state.npoStatusReducer);

  useEffect(() => {
    if (page && resultPerPage && page > 0 && resultPerPage > 0) {
    }

    return () => {
      dispatch(setEditProjectPermissions(false));
    };
  }, [req]);

  useEffect(() => {
    userId &&
      dispatch(
        dispatch(fetchAllUserProjects({ search, sortedInfo, user_id: userId }))
      );
  }, [defaultPageOneReq, userId]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );

  useEffect(() => {
    let updatedStatuses = allNpoStatuses.map((el) => {
      const { id, name } = el;
      return { value: id, label: name };
    });
    SetProjectStatuses(updatedStatuses);
  }, [allNpoStatuses]);

  useEffect(() => {
    setLoading(fetchingLoading);
  }, [fetchingLoading]);

  useEffect(() => {
    dispatch(fetchAllNpos({ search: {}, per_page: 'all' }));
  }, []);

  const onChangeSelect = (value, option) => {
    const item = allNpos.filter((el) => el.id == option.value)[0];

    setSelectedItem(item);

    // setExpandedRowKeys([option.key]);
  };
  useEffect(() => {
    setEditingLoading(createProjectLoading);
    // if (!createProjectLoading) {
    //   setEditingKey("");
    // }
  }, [createProjectLoading]);

  useEffect(() => {
    setData(user_projects);
    setSearchedData(user_projects);
  }, [user_projects]);

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = (
      <Select
        style={{
          width: '100%',
        }}
        disabled={(isProfileCase && !canUpdate) || cannotUpdate}
        // readOnly={}
        className={isProfileCase && !canUpdate ? 'profile-case-selector' : ''}
        dropdownClassName='select-dropdown-custom'
        getPopupContainer={() =>
          document.getElementsByClassName(
            'ant-layout-content layout-content site-layout-background custom-textarea'
          )[0]
        }
        showSearch
        placeholder='Select NPO'
        optionFilterProp='children'
        onChange={onChangeSelect}
        optionLabelProp='label'
        filterOption={(inputValue, option) =>
          option.children
            .join('')
            .toLowerCase()
            .includes(inputValue.toLowerCase())
        }
      >
        {allNpos?.map(({ id, name }) => {
          const mapIds = searchedData.map((pro) => pro.npo_id);
          return (
            <Option
              key={id}
              value={id}
              label={name}
              disabled={mapIds.includes(id)}
            >
              {name}
              {mapIds.includes(id) && '(Added)'}
            </Option>
          );
        })}
      </Select>
    );

    return (
      <td title={title} {...restProps} className={editing ? 'editing-row' : ''}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: dataIndex === 'npo_id' ? true : false,
                message: `Required!`,
              },
            ]}
          >
            {dataIndex === 'npo_id'
              ? isProfileCase && !canUpdate
                ? record?.npo?.name
                : inputNode
              : dataIndex?.includes('status') && (
                  <Tag
                    color={selectedItem?.status?.color}
                    style={{
                      color: '#231f20',
                      width: '100px',
                      height: '20px',
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {selectedItem?.status?.name || ''}
                  </Tag>
                )}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
    debouncedDefaultPageOneCallApi();
  };

  const edit = (record) => {
    dispatch(setEditProjectPermissions(true));
    form.setFieldsValue({
      ...record,
      key: record.id,
    });
    setSelectedItem(record.npo);
    const {
      email_notifications,
      popup_notifications,
      project_manager,
      ...rest
    } = record;

    if (!Boolean(record?.isAdd)) {
      let updateKeyData = searchedData.map((el) => ({ ...el, key: el.id }));
      setSearchedData(updateKeyData);
    }

    setEditingRowData({
      ...editingRowData,
      email_notifications,
      popup_notifications,
    });
    setPermissions((prev) => ({
      ...prev,
      ...record.permissions,
    }));
    setEditingKey(record.id);
    setExpandedRowKeys([record.id]);
  };

  const cancel = (record) => {
    // setEditingKey("");
    dispatch(setEditProjectPermissions(false));
    if (record?.isAdd) {
      setSearchedData((data) => data.filter((el) => el.id !== record.id));
    }
    form.resetFields();
    setEditingKey('');
    setPermissions({ ...userProjectPermissions(userType) });
    setExpandedRowKeys([]);
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...searchedData];
      const index = newData.findIndex((item) => id === item.id);

      if (index > -1) {
        const item = newData[index];
        if (item?.isAdd) {
          form.submit();
        } else {
          const { user_id, npo_id, id, ...rest } = { ...item, ...row };

          let updateData = {
            id,
            user_id,
            npo_id,
            ...editingRowData,
            permissions,
          };
          dispatch(
            updateUserProject({ updateData, setExpandedRowKeys, setEditingKey })
          );
        }
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      message.error('Name should not be blank!');
    }
  };
  const handleDelete = (record) => {
    setEditingKey(record.id);
    const { user_id } = record;
    let shouldLSUpdate = userId == user_id;
    dispatch(deleteUserProject({ ...record, shouldLSUpdate }));
  };

  const handleAdd = () => {
    setPermissions({ ...userProjectPermissions(userType) });
    form.resetFields();
    dispatch(setEditProjectPermissions(true));
    let id = Math.floor(Math.random() * 1122123456);
    const newData = {
      id,
      name: '',
      isAdd: true,
      key: id,
    };
    setEditingRowData({ ...initialEditingRowData });
    setSearchedData([newData, ...searchedData]);
    setEditingKey(id);
    setExpandedRowKeys([id]);
    edit({ ...newData, ...initialEditingRowData });
    setSelectedItem(null);
  };

  const hanldeFinish = (values) => {
    const { npo_id } = values;

    dispatch(
      createUserProject({
        npo_id,
        user_id: currentUserObj.id,
        ...editingRowData,
        permissions,
      })
    );
  };

  const searchInput = (key) => (
    <Input
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        const { value } = e.target;
        setSearch({
          ...search,
          [key]: e.target.value,
        });
        debouncedDefaultPageOneCallApi();
      }}
    />
  );
  const searchFunc = (toSearch, Data) => {
    let terms = toSearch.split(' ');
    return Data.filter((object) =>
      terms.every((term) =>
        Object.values(object).some((value) => value.toString().includes(term))
      )
    );
  };
  const handleNavigate = (id) =>
    navigate(`/settings/users-manager/update-user/${id}`);

  const handleSelectOnchange = (value, options, key, setHandler) => {
    if (value.includes('all')) {
      setHandler([...options.map((item) => item.value)]);
      value = options.map((item) => item.value);
    } else if (value.includes('not-all')) {
      setHandler([]);
      value = [];
    } else {
      setHandler(value);
    }
    handleMultiSelect(value, key);
  };

  const handleMultiSelect = (list, key) => {
    setSearch({
      ...search,
      [key]: list,
    });
  };

  const columns = [
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text permission-table'>
              NPO Company Name
            </div>
            <div>{searchInput('name')}</div>
          </div>
        );
      },
      dataIndex: 'npo_id',
      key: 'npo_id',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'npo_id' && sortedInfo?.order,
      width: '20%',
      editable: true,
      render: (id, record) => {
        return <div>{record?.npo?.name}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text permission-table'>
              Permissions
            </div>
            <div style={{ marginRight: '15px' }}>
              <Input disabled />
            </div>
          </div>
        );
      },
      dataIndex: 'permissions',
      key: 'permissions',
      sortOrder: sortedInfo?.columnKey === 'permissions' && sortedInfo?.order,
      width: '30%',
      editable: false,
      render: (permissions, record) => {
        if (record?.isAdd || permissions === null) {
          return;
        }
        return handlePermissionsCol({
          permissions,
        });
      },
    },
    {
      title: () => {
        return (
          <div align='top' style={{ width: '100%' }}>
            <div className='special-table-header-text'>Status</div>
            <div style={{ width: '100%' }}>
              <AMultiSelect
                dropdownClassName='status-selector'
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                disabled={false}
                placeholder=''
                selectTags={statusCheckedList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    projectStatuses,
                    'status_id',
                    setStatusCheckedList
                  )
                }
                data={projectStatuses}
                filterOption={(input, option) => {
                  return (
                    String(option?.label)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'status_id',
      key: 'status_id',
      sortOrder: sortedInfo?.columnKey === 'status_id' && sortedInfo?.order,
      ellipsis: true,
      width: '10%',
      align: 'center',
      editable: true,
      render: (status, record, id) => {
        let color = record?.npo?.status?.color;
        let name = record?.npo?.status?.name;

        return (
          name && (
            <Tag
              onClick={() => handleNavigate(id)}
              color={color}
              style={{
                color: '#444',
                width: '84px',
                height: '20px',
                textAlign: 'center',
                fontSize: '12px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {name}
            </Tag>
          )
        );
      },
    },
    {
      title: '',
      key: 'action',
      dataIndex: '',
      width: '10%',
      align: 'center',
      render: (_, record) => {
        if (!canUpdate && !isProfileCase) {
          return;
        }

        const editable = isEditing(record);
        return editable ? (
          editingLoading && editingKey === record.id ? (
            <SpinnerComponent fontSize={14} />
          ) : (
            <Space size={15} align='center'>
              <SaveIcon
                className='custom-icon'
                fill='#f55056'
                onClick={() => save(record.id)}
              />
              <Popconfirm
                title='Sure to cancel?'
                onConfirm={() => cancel(record)}
              >
                <CrossIcon fill='#f55056' className='custom-icon' />
              </Popconfirm>
            </Space>
          )
        ) : (
          <Space size={15} align='center'>
            <EditIcon
              className={`custom-icon ${
                isEditProjectPermissions && 'disable-icon'
              }`}
              fill='#f55056'
              disabled={editingKey !== ''}
              onClick={() => !isEditProjectPermissions && edit(record)}
            />
            {data.length >= 1 &&
              (isEditProjectPermissions && canUpdate ? (
                <DeleteIcon
                  fill='#f55056'
                  className={`custom-icon disable-icon`}
                />
              ) : (
                canUpdate &&
                !cannotUpdate && (
                  <Popconfirm
                    title='Are you sure you want to delete this NPO permission?'
                    onConfirm={() => handleDelete(record)}
                    okText='Yes'
                    cancelText='No'
                  >
                    <DeleteIcon fill='#f55056' className={`custom-icon`} />
                  </Popconfirm>
                )
              ))}
          </Space>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => {
        let title = col.title;
        if (col.key === 'status_name') {
          title = record?.project?.project_status?.status_name;
        }
        return {
          record,
          inputType: 'text',
          dataIndex: col.dataIndex,
          title: title,
          editing: isEditing(record),
        };
      },
    };
  });
  const expandedRowRender = (record) => {
    if (expandedRowKeys.includes(record.id)) {
      let values = form.getFieldsValue();

      let shouldDisabled =
        values['npo_id'] === undefined || !canUpdate || cannotUpdate;

      return (
        <Row>
          <TablePermissionsSection
            isProfileCase={isProfileCase}
            setData={setEditingRowData}
            data={editingRowData}
            permissions={permissions}
            setPermissions={setPermissions}
            disabledCheckBox={shouldDisabled}
            canUpdate={canUpdate}
          />
          <Col span={24}>
            <Row gutter={[20, 20]}>
              {/* <Col>
                <div className='heading-text'>Browser notifications</div>
                <div className='switch-parent-div'>
                  <span
                    className={`${
                      !editingRowData?.popup_notifications && 'off'
                    }`}
                  >
                    Off
                  </span>{' '}
                  <Switch
                    size='small'
                    checked={editingRowData?.popup_notifications}
                    disabled={shouldDisabled && !isProfileCase}
                    onChange={(e) =>
                      setEditingRowData({
                        ...editingRowData,
                        popup_notifications: e,
                      })
                    }
                  />{' '}
                  <span
                    className={`${editingRowData?.popup_notifications && 'on'}`}
                  >
                    On
                  </span>
                </div>
              </Col> */}
              <Col>
                <div className='heading-text'>Email notifications</div>
                <div className={'switch-parent-div'}>
                  <span
                    className={`${
                      !editingRowData?.email_notifications && 'off'
                    }`}
                  >
                    Off
                  </span>{' '}
                  <Switch
                    size='small'
                    disabled={shouldDisabled && !isProfileCase}
                    checked={editingRowData.email_notifications}
                    onChange={(e) =>
                      setEditingRowData({
                        ...editingRowData,
                        email_notifications: e,
                      })
                    }
                  />{' '}
                  <span
                    className={`${editingRowData?.email_notifications && 'on'}`}
                  >
                    On
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
  };

  return (
    <Row>
      <Col span={24} className='heading-col'>
        <Row align='middle' gutter={[30, 0]}>
          <Col>
            <div>
              <span className='heading-span'>NPO Permissions</span>
            </div>
          </Col>
          <Col>
            {canUpdate && !cannotUpdate && (
              <Button
                icon={<PlusOutlined />}
                className='add-activity-btn project-button'
                size='middle'
                disabled={isEditProjectPermissions}
                onClick={handleAdd}
              >
                NPO
              </Button>
            )}
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Form form={form} component={false} onFinish={hanldeFinish}>
          <Table
            className='special-table  table project-permission-table'
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            expandable={{
              expandedRowRender,
              expandIcon: null,
              showExpandColumn: false,
              expandedRowKeys: expandedRowKeys,
            }}
            onRow={({ id }) =>
              expandedRowKeys.includes(id) && {
                className: 'expand-parent',
              }
            }
            scroll={{ x: true }}
            rowClassName='editable-row'
            dataSource={searchedData}
            // dataSource={data}
            loading={loading || fetchProjectsLoading}
            columns={mergedColumns}
            onChange={handleChange}
            pagination={false}
            // rowKey={expandedRowKeys?.length > 0 ? expandedRowKeys[0].toString() : null}
          />
        </Form>
      </Col>
    </Row>
  );
};

export default ProjectPermissionsTable;
