import React from 'react';
import { Col, DatePicker, Row, Select, Form, Input } from 'antd';
const { Option } = Select;

const FormItem = ({ item, disabled }) => {
  const { id, name, field_type, ...rest } = item;
  let classname = `${disabled ? 'ims-doc-readonly-input' : ''}`;

  if (field_type === 'date_field') {
    return (
      <Form.Item
        className={classname}
        label={name}
        name={id}
        key={id}
        labelCol={{ span: 24 }}
        // rules={[
        //   {
        //     required: true,
        //     message: 'Required!',
        //   },
        // ]}
      >
        <DatePicker
          size='small'
          style={{
            width: '100%',
          }}
          format={'DD MMM YYYY'}
          suffixIcon={null}
          placeholder={name}
          disabled={disabled}
        />
      </Form.Item>
    );
  }
  if (field_type === 'text_field') {
    return (
      <Form.Item
        className={classname}
        label={name}
        name={id}
        key={id}
        labelCol={{ span: 24 }}
        // rules={[
        //   {
        //     required: true,
        //     message: 'Required!',
        //   },
        // ]}
      >
        <Input
          placeholder={name}
          readOnly={disabled}
          disabled={disabled}
          size='small'
        />
      </Form.Item>
    );
  }

  if (field_type === 'select_field') {
    const { field_options } = rest;

    return (
      <Form.Item
        className={classname}
        label={name}
        name={id}
        key={id}
        labelCol={{ span: 24 }}
        // rules={[
        //   {
        //     required: true,
        //     message: 'Required!',
        //   },
        // ]}
      >
        <Select placeholder={name} disabled={disabled}>
          {field_options.map((el) => {
            const { id, name } = el;
            return (
              <Option value={name} key={id}>
                {name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }
};

const TemplateFields = ({ fields, formRef, onFinish, disabled }) => {
  return (
    <Row>
      <Col span={24}>
        <Form.Item
          className='templates-fields-label'
          label='TEMPLATE FIELDS'
          labelCol={{ span: 24 }}
        ></Form.Item>
      </Col>
      <Col span={24}>
        <Form
          className='templates-fields-parent'
          form={formRef}
          onFinish={onFinish}
        >
          <Row gutter={[30, 20]}>
            {fields.map((el) => {
              return (
                <Col span={8} key={el.id}>
                  <FormItem item={el} disabled={disabled} />
                </Col>
              );
            })}
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default TemplateFields;
