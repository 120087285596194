import { combineReducers } from 'redux';
import userManagerReducer from '../modules/UserManager/redux/userManagerReducer';
import departEmailsReducer from '../modules/DepartmentEmails/redux/departEmailsReducer';
import departmentReducer from '../modules/ActivityTypes/redux/activityTypesReducer';
import clientManagerReducer from '../modules/ClientsManager/redux/clientManagerReducer';
import salesReducer from '../modules/Sales/redux/salesReducer';
import salesGraphReducer from '../modules/Sales/redux/graphReducer';
import projectReducer from '../modules/Projects/redux/reducer';
import authReducer from '../modules/login/redux/authReducer';
import productionReducer from '../modules/Production/redux/productionReducer';
import secondLevelReducer from '../modules/Production/redux/secondLevelReducer';
import thirdLevelReducer from '../modules/Production/redux/thirdLevelReducer';
import fourthLevelReducer from '../modules/Production/redux/fourthLevelReducer';
import salesSecondLevelReducer from '../modules/Sales/redux/salesSecondLevelReducer';
import projectStatusReducer from '../modules/ProjectStatuses/redux/projectStatusesReducer';
import npoSetupReducer from '../modules/NpoSetup/redux/reducer';
import docStatusReducer from '../modules/DocumentStatuses/redux/docStatusesReducer';
import documentFieldReducer from '../modules/DefaultDocumentFields/redux/documentFieldReducer';
import interventionTypesReducer from '../modules/InterventionTypesManager/redux/reducer';
import documentControlReducer from '../modules/DocumentControl/redux/documentControlReducer';
import sitesReducer from '../modules/Sites/redux/reducer';
import imsRevisionsReducer from '../modules/Sites/tableSection/classes/redux/reducer';
import imsDistributionReducer from '../modules/Sites/tableSection/distributionTable/redux/distributionReducer';
import classesReducer from '../modules/Classes/redux/reducer';
import projectRevisionsReducer from '../modules/Projects/tableSection/revisions/redux/revisionsReducer';
import projectDistributionReducer from '../modules/Projects/tableSection/distributionTable/redux/distributionReducer';
import notificationsReducer from '../modules/container/notifications/notificationsRedux/notificationReducer';
import templatesManagerReducer from '../modules/TemplatesManager/redux/reducer';
import programmeTypesReducer from '../modules/ProgrammeTypes/redux/reducer';
import npoRegionsReducer from '../modules/NpoRegions/redux/reducer';
import npoStatusReducer from '../modules/NpoStatuses/redux/reducer';
import siteStatusReducer from '../modules/SiteStatuses/redux/reducer';
import siteTypesReducer from '../modules/SiteTypes/redux/reducer';
import coachStatusReducer from '../modules/CoachStatuses/redux/reducer';
import coachTypesReducer from '../modules/CoachTypes/redux/reducer';
import learnerStatusReducer from '../modules/LearnerStatuses/redux/learnerStatusesReducer';
import learnerCampsReducer from '../modules/LearnerCamps/redux/reducer';
import learnerTypesReducer from '../modules/LearnerTypes/redux/reducer';
import learnersReducer from '../modules/learners/redux/reducer';
import coachesReducer from '../modules/coaches/redux/reducer';
// import classesLearnersReducer from '../modules/Classes/tableSection/learners/redux/reducer';

export default combineReducers({
  documentControlReducer,
  interventionTypesReducer,
  documentFieldReducer,
  projectStatusReducer,
  userManagerReducer,
  departEmailsReducer,
  departmentReducer,
  clientManagerReducer,
  salesReducer,
  authReducer,
  productionReducer,
  secondLevelReducer,
  thirdLevelReducer,
  fourthLevelReducer,
  salesGraphReducer,
  salesSecondLevelReducer,
  npoSetupReducer,
  docStatusReducer,
  sitesReducer,
  imsRevisionsReducer,
  imsDistributionReducer,
  classesReducer,
  // classesLearnersReducer,
  projectReducer,
  projectRevisionsReducer,
  projectDistributionReducer,
  notificationsReducer,
  templatesManagerReducer,
  programmeTypesReducer,
  npoRegionsReducer,
  npoStatusReducer,
  siteStatusReducer,
  siteTypesReducer,
  coachStatusReducer,
  coachTypesReducer,
  learnerStatusReducer,
  learnerCampsReducer,
  learnerTypesReducer,
  learnersReducer,
  coachesReducer,
});
