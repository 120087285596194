import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Spin } from 'antd';
import { ReactComponent as BackIcon } from '../../../assets/back-icon.svg';
import '../index.less';
import {
  fetchInterventionType,
  templateCreationCleanupFunc,
} from '../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import UseSupAdminOrAdminWithUserManagerPermission from '../../../components/useSupAdminOrAdminWithUserManagerPermission';
import TemplateDetail from './details';

const InterventionTypesManager = () => {
  const [isTemplateUpdate, setIsTemplateUpdate] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  let canEditEveryThing = UseSupAdminOrAdminWithUserManagerPermission();

  const { currentTemplateObj, fetchTemplateLoading } = useSelector(
    (state) => state.interventionTypesReducer
  );

  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (user?.permissions?.intervention_types?.add_edit) {
      setCanUpdate(true);
    }
  }, [user]);

  useEffect(() => {
    if (currentTemplateObj?.id) {
      setIsTemplateUpdate(true);
    }
  }, [currentTemplateObj]);

  useEffect(() => {
    if (params?.id) {
      setIsTemplateUpdate(true);
      dispatch(fetchInterventionType(params?.id));
    }

    return () => {
      dispatch(templateCreationCleanupFunc());
    };
  }, [params.id]);

  return (
    <div className='page-top-div heading-parent-div'>
      <Row justify='space-between'>
        <Col>
          <span className='text-span'>
            {isTemplateUpdate ? 'Update ' : 'Add '} INTERVENTION TYPE
          </span>
        </Col>
        <Col>
          <Button
            onClick={() => navigate('/settings/intervention-types-manager')}
            icon={<BackIcon width={24} height={14} />}
            className='back-btn'
            size='middle'
          >
            BACK
          </Button>
        </Col>
      </Row>
      <Spin spinning={fetchTemplateLoading}>
        <div className='user-manager-parent-div'>
          <TemplateDetail
            isTemplateUpdate={isTemplateUpdate}
            canEditEveryThing={canEditEveryThing}
            canUpdate={canUpdate}
          />
        </div>
      </Spin>
    </div>
  );
};

export default InterventionTypesManager;
