import * as constants from "./constants";

const initialState = {
  allDocStatuses: [],
  fetchingLoading: false,
  updateLoading: false,
  totalRecords: 0,
  isAdding: false,
  completedStatusIsEdit: false,
};

const docStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_DOC_STATUSES:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_DOC_STATUSES_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allDocStatuses: [...state.allDocStatuses, action.data],
        isAdding: false,
      };
    case constants.CREATE_DOC_STATUSES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };

    case constants.UPDATE_DOC_STATUS_ORDER:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.UPDATE_DOC_STATUS_ORDER_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allDocStatuses: action.data,
      };
    case constants.UPDATE_DOC_STATUS_ORDER_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };

    case constants.FETCH_DOC_STATUSES:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_DOC_STATUSES_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allDocStatuses: action.data,
        // totalRecords: total_allDocStatuses,
        isAdding: false,
        completedStatusIsEdit: false
      };
    case constants.FETCH_DOC_STATUSES_ERROR:
      return {
        ...state,
        fetchingLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_DOC_STATUSES:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_DOC_STATUSES_SUCCESS:
      const updated = state.allDocStatuses.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        allDocStatuses: updated,
        updateLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_DOC_STATUSES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_DOC_STATUSES:
      return {
        ...state,
        updateLoading: true,
        isAdding: false,
      };
    case constants.DELETE_DOC_STATUSES_SUCCESS:
      const updatedData = state.allDocStatuses.filter(
        (el) => el.id !== action.data
      );
      return {
        ...state,
        allDocStatuses: updatedData,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_DOC_STATUSES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.IS_DOC_STATUSES_ADD_UPDATE:
      return {
        ...state,
        isAdding: action.data,
      };

    case constants.SET_DOC_COMPLETED_STATUS:
      return {
        ...state,
        completedStatusIsEdit: action.data,
      };

    case constants.UPDATE_DOC_COMPLETED_STATUS:
      return {
        ...state,
        updateLoading: true,
        // isAdding: false,
      };
    case constants.UPDATE_DOC_COMPLETED_STATUS_SUCCESS:
      // const updatedData = state.allDocStatuses.filter(
      //   (el) => el.id !== action.data
      // );
      return {
        ...state,
        // allDocStatuses: updatedData,
      };
    case constants.UPDATE_DOC_COMPLETED_STATUS_ERROR:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default docStatusReducer;
