import React, { useEffect, useState } from 'react';
import {
  Table,
  Input,
  Form,
  Col,
  Row,
  Space,
  Tag,
  Select,
  InputNumber,
} from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as FilterIcon } from '../../../assets/filter.svg';
import { fetchCoachesDocs, fetchBatchUpdate } from '../redux/actions'; // fetchActivities,
import { fetchActivities } from '../../learners/redux/actions';
import _ from 'lodash';
import CustomPagination from '../../../components/customPagination/CustomPagination';
import CustomCalendar from '../../../components/calendar';
import AMultiSelect from '../../../components/AMultiSelect';
import { getOptions } from '../../Sales/utils';
import ExpandedRow from './expandedRow';
import Classes from './classes';
import ExportDoc from './export';
// import ImportDoc from './import';
import FilterAndBatchUpdate from './filterAndBatchUpdate';
import Tooltip from '../../../components/tooltip-Custom';
import { useLocation } from 'react-router-dom';
import { genderOptions, raceOptions } from '../utils';

const { Option } = Select;

const CoachesTable = ({
  statusCheckList,
  setStatusCheckList,
  statusOptions,
  coachesTypesCheckList,
  setCoachesTypesCheckList,
  coachesTypesOptions,
  npoCheckList,
  setNpoCheckList,
  npoOptions,
  coachesCheckList,
  setCoachesCheckList,
  coachesOptions,
  name,
  npoDetails,
}) => {
  const [data, setData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState(null);
  const [fieldsFormRef] = Form.useForm();
  const dispatch = useDispatch();
  const [page, setPage] = useState(() => 1);
  const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);
  const [search, setSearch] = useState({ npo_id: [npoDetails?.npo_id] });
  const [date, setDate] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [fields, setFields] = useState([]);
  const [datePickerOpened, setDatePickerOpened] = useState(false);
  const [isRevisionUpdateCase, setIsRevisionUpdateCase] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [showBatch, setShowBatch] = useState(false);
  const location = useLocation();
  const [fetchByParams, setFetchByParams] = useState(true);
  const [docNumber, setDocNumber] = useState('');
  const { user } = useSelector((state) => state.authReducer);

  // const { allTemplates } = useSelector((state) => state.docTypesReducer);
  // const { allDocumentField, fetchingLoading } = useSelector(
  //   (state) => state.documentFieldReducer
  // );

  // const { allNpos } = useSelector((state) => state.npoSetupReducer);
  // const { allTemplates } = useSelector(
  //   (state) => state.templatesManagerReducer
  // );
  const { allTemplates } = useSelector(
    (state) => state.templatesManagerReducer
  );

  useEffect(() => {
    if (location?.state?.document_number?.length > 0) {
      const { document_number } = location?.state;
      if (docNumber === document_number) {
        return;
      } else {
        setFetchByParams(true);
      }
      setData([]);
      if (fetchByParams) {
        setDocNumber(document_number);
        setTimeout(() => {
          setData([]);
          setSearch((prev) => ({ ...prev, document_number }));
          debouncedDefaultPageOneCallApi();
          setFetchByParams(false);
        }, 100);
      }
    }
  }, [location, docNumber]);

  useEffect(() => {
    if (page && resultPerPage && page > 0 && resultPerPage > 0) {
      dispatch(
        fetchCoachesDocs({
          page: page,
          per_page: resultPerPage,
          user_type: 'coach',
          search,
          sort: sortedInfo?.order && sortedInfo?.columnKey,
          order: sortedInfo?.order
            ? sortedInfo?.order === 'ascend'
              ? 'asc'
              : 'desc'
            : null,
          ...filter,
          page_type: 'coaches',
        })
      );
      dispatch(
        fetchActivities({
          page,
          resultPerPage,
          user_type: 'coach',
          search: { ...search, npo_id: [npoDetails?.npo_id] },
          ...filter,
          page_type: 'coaches',
          npo_graph: true,
        })
      );
    }
  }, [req]);

  useEffect(() => {
    let paramsPage = 1;
    dispatch(
      fetchCoachesDocs({
        page: paramsPage,
        per_page: resultPerPage,
        user_type: 'coach',
        search,
        sort: sortedInfo?.order && sortedInfo?.columnKey,
        order: sortedInfo?.order
          ? sortedInfo?.order === 'ascend'
            ? 'asc'
            : 'desc'
          : null,
        ...filter,
        page_type: 'coaches',
      })
    );
    // setFilter({});
    // setFields([]);
    setExpandedRowKeys([]);
    if (paramsPage && resultPerPage && paramsPage > 0 && resultPerPage > 0) {
      dispatch(
        fetchActivities({
          page: paramsPage,
          per_page: resultPerPage,
          user_type: 'coach',
          search: { ...search, npo_id: [npoDetails?.npo_id] },
          ...filter,
          page_type: 'coaches',
          npo_graph: true,
        })
      );
      setPage(1);
    }
    fieldsFormRef.resetFields();
  }, [defaultPageOneReq, npoDetails]);

  useEffect(() => {
    debouncedCallApi();
  }, [sortedInfo]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );

  const { allDocs, fetchDocsLoading, totalRecords } = useSelector(
    (state) => state.coachesReducer
  );

  useEffect(() => {
    setData(allDocs);
  }, [allDocs]);

  useEffect(() => {
    if (allTemplates.length > 0) {
      let filtered = allTemplates.filter(
        (el) => el.name.toLocaleLowerCase() === 'coaches'
      );
      if (filtered.length === 1) {
        const { template_fields } = filtered[0];
        setFields(template_fields);
      }
    }
  }, [allTemplates]);

  const edit = (record) => {
    const { isAdd } = record;

    if (!isAdd) {
      // if (!record?.skipFilter) {
      //   let filteredItems = data.filter((el) => el.id !== editingKey);
      //   let final = filteredItems.filter((el) => el.key !== editingKey);
      //   setData(final);
      // }

      if (
        record?.user_field_value !== null &&
        record?.user_field_value !== undefined
      ) {
        const { user_field_value } = record;
        let formData = {};
        user_field_value.map((el) => {
          const { template_field_id, value, date_value, template_field } = el;
          if (template_field) {
            const { field_type } = template_field;
            formData[template_field_id] =
              field_type === 'date_field' && date_value !== null
                ? moment(date_value)
                : value;

            return {
              ...el,
              field_type,
            };
          }
        });

        let updatedFields = { ...formData };

        setTimeout(() => {
          fieldsFormRef.setFieldsValue({ ...updatedFields });
        }, 100);
      }
    }

    setExpandedRowKeys([record.id]);
  };

  const searchInput = (key) => (
    <Input
      placeholder={''}
      onClick={(e) => e.stopPropagation()}
      value={search[key] || ''}
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e.target.value,
        });
        debouncedDefaultPageOneCallApi();
      }}
    />
  );

  const numberInput = (key) => (
    <InputNumber
      placeholder={''}
      size='small'
      controls={false}
      max={100}
      min={0}
      onClick={(e) => e.stopPropagation()}
      onPressEnter={(e) => e.stopPropagation()}
      value={search[key] || null}
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e,
        });
        debouncedDefaultPageOneCallApi();
      }}
    />
  );

  const handleDateInput = (value) => {
    setDate(value);
    if (value === null) {
      setSearch({
        ...search,
        start_date: '',
        end_date: '',
      });
    } else {
      setSearch({
        ...search,
        start_date: value[0],
        end_date: value[1],
      });
    }
    debouncedDefaultPageOneCallApi();
  };

  const handleMultiSelect = (list, key) => {
    setSearch({
      ...search,
      [key]: list,
    });
    // debouncedDefaultPageOneCallApi();
  };

  const handleSelectOnchange = (value, options, key, setHandler) => {
    if (value.includes('all')) {
      setHandler([...options.map((item) => item.value)]);
      value = options.map((item) => item.value);
    } else if (value.includes('not-all')) {
      setHandler([]);
      value = [];
    } else {
      setHandler(value);
    }
    handleMultiSelect(value, key);
  };

  const handleColOnClick = (record) => {
    fieldsFormRef.resetFields();
    if (expandedRowKeys[0] !== record.id) {
      setExpandedRowKeys([]);
      setIsRevisionUpdateCase(false);
      setIsRevisionUpdateCase(false);
      setData(allDocs);
      fieldsFormRef.resetFields();
      setExpandedRowKeys([record.id]);
      // setFields([]);
      setIsRevisionUpdateCase(true);
      return edit({ ...record, isAdd: false, skipFilter: true });
    }

    if (isRevisionUpdateCase) {
      if (expandedRowKeys[0] === record.id) {
        setExpandedRowKeys([]);
        setIsRevisionUpdateCase(false);
        setIsRevisionUpdateCase(false);
      }
    } else {
      setExpandedRowKeys([record.id]);
      setIsRevisionUpdateCase(true);
      edit({ ...record, isAdd: false, skipFilter: false });
    }
  };

  let updatedGender = [...genderOptions];
  let updatedRace = [...raceOptions];
  if (['learner', 'coach'].includes(user.user_type)) {
    coachesOptions = coachesOptions.filter((c) =>
      data.some((s) => `${s.first_name} ${s.last_name}` === c.label)
    );
    npoOptions = npoOptions.filter((c) =>
      data.some((s) => s.npo_id === c.value)
    );
    coachesTypesOptions = coachesTypesOptions.filter((c) =>
      data.some((s) => s.type_id === c.value)
    );
    updatedGender = genderOptions.filter((c) =>
      data.some((s) => s.gender === c.label.toLocaleLowerCase())
    );
    updatedRace = raceOptions.filter((c) =>
      data.some((s) => s.race === c.label.toLocaleLowerCase())
    );
    statusOptions = statusOptions.filter((c) =>
      data.some((s) => s.status_id === c.value)
    );
  }

  const columns = [
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Date</div>
            <div>
              <CustomCalendar
                date={date}
                setDate={setDate}
                format={'D MMM'}
                handleDateInput={handleDateInput}
                setDatePickerOpened={setDatePickerOpened}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'coach_date',
      key: 'coach_date',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'coach_date' && sortedInfo?.order,
      width: 130,
      editable: false,
      render: (date, record) => {
        return (
          <div onClick={() => handleColOnClick(record)}>
            {moment(record.created_at).format('DD MMM YYYY')}
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Coach</div>
            <div>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                dropdownClassName='doc-type-selector'
                disabled={false}
                placeholder=''
                selectTags={coachesCheckList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    coachesOptions,
                    'fullname',
                    setCoachesCheckList
                  )
                }
                data={getOptions(coachesOptions, coachesCheckList) || []}
                filterOption={(input, option) => {
                  return (
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'fullname',
      key: 'fullname',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'fullname' && sortedInfo?.order,
      width: 120,
      editable: !isRevisionUpdateCase,
      render: (coach_user, record) => {
        const { first_name, last_name } = record;
        const name = `${first_name} ${last_name}`;
        return <div onClick={() => handleColOnClick(record)}>{name}</div>;
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>NPO</div>
            <div>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                dropdownClassName='doc-type-selector'
                disabled={false}
                placeholder=''
                selectTags={npoCheckList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    npoOptions,
                    'npo_id',
                    setNpoCheckList
                  )
                }
                data={getOptions(npoOptions, npoCheckList) || []}
                filterOption={(input, option) => {
                  return (
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'npo_id',
      key: 'npo_id',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'npo_id' && sortedInfo?.order,
      width: 130,
      editable: false,
      render: (npo, record) => (
        <div onClick={() => handleColOnClick(record)}>{record?.npo?.name}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Coach Type</div>
            <div>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                dropdownClassName='doc-type-selector'
                disabled={false}
                placeholder=''
                selectTags={coachesTypesCheckList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    coachesTypesOptions,
                    'type_id',
                    setCoachesTypesCheckList
                  )
                }
                data={
                  getOptions(coachesTypesOptions, coachesTypesCheckList) || []
                }
                filterOption={(input, option) => {
                  return (
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'type_id',
      key: 'type_id',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'type_id' && sortedInfo?.order,
      width: 120,
      align: 'left',
      editable: false,
      render: (type, record) => (
        <div onClick={() => handleColOnClick(record)}>{record?.type?.name}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Gender</div>
            <div>
              <Select
                onClick={(e) => e.stopPropagation()}
                style={{ width: '100%' }}
                allowClear={true}
                readOnly={true}
                size='small'
                className='Select Project'
                dropdownClassName='select-dropdown-custom'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                showSearch
                placeholder=''
                optionFilterProp='children'
                onChange={(e) => {
                  setSearch({
                    ...search,
                    gender: e,
                  });
                  debouncedDefaultPageOneCallApi();
                }}
                optionLabelProp='label'
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {updatedGender?.map(({ label, value }) => {
                  return (
                    <Option value={value} label={label}>
                      {label || ''}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        );
      },
      dataIndex: 'gender',
      key: 'gender',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'gender' && sortedInfo?.order,
      width: 100,
      editable: false,
      align: 'center',
      render: (gender, record) => (
        <div
          onClick={() => handleColOnClick(record)}
          style={{
            textTransform: 'capitalize',
          }}
        >
          {gender}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Race</div>
            <div>
              <Select
                onClick={(e) => e.stopPropagation()}
                allowClear={true}
                style={{ width: '100%' }}
                size='small'
                readOnly={true}
                className='Select Project'
                dropdownClassName='select-dropdown-custom'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                showSearch
                placeholder=''
                optionFilterProp='children'
                onChange={(e) => {
                  setSearch({
                    ...search,
                    race: e,
                  });
                  debouncedDefaultPageOneCallApi();
                }}
                optionLabelProp='label'
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {updatedRace?.map(({ label, value }) => {
                  return (
                    <Option value={value} label={label}>
                      {label || ''}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        );
      },
      dataIndex: 'race',
      key: 'race',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'race' && sortedInfo?.order,
      width: 100,
      editable: false,
      align: 'center',
      render: (race, record) => (
        <div
          onClick={() => handleColOnClick(record)}
          style={{
            textTransform: 'capitalize',
          }}
        >
          {race}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              TOTAL <br /> CLASSES
            </div>
            <div>{searchInput('total_classes')}</div>
          </div>
        );
      },
      dataIndex: 'total_classes',
      key: 'total_classes',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'total_classes' && sortedInfo?.order,
      width: 60, // "8%"
      align: 'center',
      editable: false,
      className: 'less-padding-col',
      render: (total_classes, record) => (
        <div onClick={() => handleColOnClick(record)}> {total_classes} </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              MKT <br />
              Pre
            </div>
            <div>{numberInput('coach_mkt_pre')}</div>
            <div />
          </div>
        );
      },
      dataIndex: 'coach_mkt_pre',
      key: 'coach_mkt_pre',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'coach_mkt_pre' && sortedInfo?.order,
      width: 60,
      align: 'center',
      editable: false,
      render: (mkt_pre, record) => (
        <div onClick={() => handleColOnClick(record)}>
          {record?.coach_mkt?.mkt_pre}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              MKT <br />
              post
            </div>
            <div>{numberInput('coach_mkt_post')}</div>
            <div />
          </div>
        );
      },
      dataIndex: 'coach_mkt_post',
      key: 'coach_mkt_post',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'coach_mkt_post' && sortedInfo?.order,
      width: 60,
      align: 'center',
      editable: false,
      render: (mkt_post, record) => (
        <div onClick={() => handleColOnClick(record)}>
          {record?.coach_mkt?.mkt_post}
        </div>
      ),
    },

    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              ses <br />
              Pre
            </div>
            <div>{numberInput('coach_ses_pre')}</div>
            <div />
          </div>
        );
      },
      dataIndex: 'coach_ses_pre',
      key: 'coach_ses_pre',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'coach_ses_pre' && sortedInfo?.order,
      width: 60,
      align: 'center',
      editable: false,
      render: (ses_pre, record) => (
        <div onClick={() => handleColOnClick(record)}>
          {record?.coach_mkt?.ses_pre}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              ses <br />
              post
            </div>
            <div>{numberInput('coach_ses_post')}</div>
            <div />
          </div>
        );
      },
      dataIndex: 'coach_ses_post',
      key: 'coach_ses_post',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'coach_ses_post' && sortedInfo?.order,
      width: 60,
      align: 'center',
      editable: false,
      render: (ses_post, record) => (
        <div onClick={() => handleColOnClick(record)}>
          {record?.coach_mkt?.ses_post}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              leq <br />
              Pre
            </div>
            <div>{numberInput('coach_leq_pre')}</div>
            <div />
          </div>
        );
      },
      dataIndex: 'coach_leq_pre',
      key: 'coach_leq_pre',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'coach_leq_pre' && sortedInfo?.order,
      width: 60,
      align: 'center',
      editable: false,
      render: (leq_pre, record) => (
        <div onClick={() => handleColOnClick(record)}>
          {record?.coach_mkt?.leq_pre}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              leq <br />
              post
            </div>
            <div>{numberInput('coach_leq_post')}</div>
            <div />
          </div>
        );
      },
      dataIndex: 'coach_leq_post',
      key: 'coach_leq_post',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'coach_leq_post' && sortedInfo?.order,
      width: 60,
      align: 'center',
      editable: false,
      render: (leq_post, record) => (
        <div onClick={() => handleColOnClick(record)}>
          {record?.coach_mkt?.leq_post}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              TOTAL <br /> Learners
            </div>
            <div>{searchInput('total_learners')}</div>
          </div>
        );
      },
      dataIndex: 'total_learners',
      key: 'total_learners',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'total_learners' && sortedInfo?.order,
      width: 80,
      align: 'center',
      editable: false,
      className: 'less-padding-col',
      render: (total_learners, record) => (
        <div onClick={() => handleColOnClick(record)}> {total_learners} </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              WEIGHTED <br /> PERF.
            </div>
            <div>{searchInput('weighted_pref')}</div>
          </div>
        );
      },
      dataIndex: 'weighted_pref',
      key: 'weighted_pref',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'weighted_pref' && sortedInfo?.order,
      width: 80,
      align: 'center',
      editable: false,
      className: 'less-padding-col',
      render: (weighted_pref, record) => (
        <div onClick={() => handleColOnClick(record)}> {weighted_pref} </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Status</div>
            <div>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                dropdownClassName='status-selector'
                disabled={false}
                placeholder=''
                selectTags={statusCheckList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    statusOptions,
                    'status_id',
                    setStatusCheckList
                  )
                }
                data={getOptions(statusOptions, statusCheckList) || []}
                filterOption={(input, option) => {
                  return (
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'status_id',
      key: 'status_id',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'status_id' && sortedInfo?.order,
      width: 80,
      editable: false,
      render: (id, record) => {
        if (!Boolean(record?.status)) {
          return;
        }

        const { status } = record;
        const { color, name } = status;
        return (
          <Tag
            color={color}
            className='doc-status-tag'
            onClick={() => handleColOnClick(record)}
          >
            {name}
          </Tag>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          dataIndex: col.dataIndex,
          title: col.title,
        };
      },
    };
  });

  const handleChange = (pagination, filters, sorter) => {
    if (datePickerOpened) {
      return;
    }
    setSortedInfo(sorter);
  };

  const expandedRowRender = (record) => {
    if (expandedRowKeys.includes(record.id)) {
      return (
        <>
          <ExpandedRow
            record={record}
            fields={fields}
            formRef={fieldsFormRef}
            onFinish={onFinishFields}
            isRevisionUpdateCase={isRevisionUpdateCase}
          />
          {isRevisionUpdateCase && (
            <Row gutter={[0, 60]} style={{ marginBottom: '30px' }}>
              <Col span={24}>
                <Classes record={record} />
              </Col>
            </Row>
          )}
        </>
      );
    }
  };

  const onFinishFields = (values) => {
    console.log('values', values);
  };

  const handleCloseBatchSection = () => {
    setShowBatch(false);
  };

  const handleCloseFilterSection = () => {
    setShowFilter(false);
    setFilter({});
    handleCloseBatchSection();
    debouncedDefaultPageOneCallApi();
  };

  const handleFilter = (filterObj) => {
    setFilter(filterObj);
    debouncedDefaultPageOneCallApi();
  };

  const handleBatchUpdate = (filterObj) => {
    setExpandedRowKeys([]);
    setIsRevisionUpdateCase(false);
    let document_control = data.map((el) => el.id);
    let revisions_ids = [];
    let distributions_ids = [];
    data.map((doc) => {
      const { doc_distributions, doc_revisions } = doc;
      doc_revisions.map((rev) => revisions_ids.push(rev.id));
      doc_distributions.map((dist) => distributions_ids.push(dist.id));
    });

    dispatch(
      fetchBatchUpdate({
        ...filterObj,
        document_control,
        revisions_ids,
        distributions_ids,
      })
    );
  };

  const shouldAddBtnDisable = () => {
    let shouldDisabled = fetchDocsLoading || isRevisionUpdateCase;
    return shouldDisabled;
  };

  const hanldeBatch = () => {
    setShowFilter(true);
    setShowBatch(true);
  };

  const { coaches } = npoDetails.permissions;

  return (
    <Row className='doc-control-parent-row'>
      <Col span={24} className='heading-col'>
        <Row align='middle' gutter={[50, 0]} justify='space-between'>
          <Col>
            <Row gutter={[50, 0]} align='middle'>
              <Col>
                <div>
                  <span className='heading-span'>{name} Register</span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col>
            <Space size={35} align='center'>
              <Tooltip text='Filter'>
                <FilterIcon
                  onClick={() => {
                    setShowFilter(!showFilter);
                    showFilter && debouncedDefaultPageOneCallApi();
                  }}
                />
              </Tooltip>
              {coaches?.export && (
                <Tooltip text='Export'>
                  {<ExportDoc npo_id={npoDetails?.npo_id} />}
                </Tooltip>
              )}
              {/* {canEdit && (
                <Tooltip text='Import'>
                  <ImportDoc debounce={debouncedDefaultPageOneCallApi} />
                </Tooltip>
              )}
              {canEdit && (
                <Tooltip text='Batch Update'>
                  <BatchUpdateIcon onClick={hanldeBatch} />
                </Tooltip>
              )} */}
            </Space>
          </Col>
        </Row>
      </Col>

      <FilterAndBatchUpdate
        handleCloseFilter={handleCloseFilterSection}
        handleFilter={handleFilter}
        handleBatchUpdate={handleBatchUpdate}
        handleCloseBatch={handleCloseBatchSection}
        loading={fetchDocsLoading}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        showBatch={showBatch}
        setShowBatch={setShowBatch}
      />

      <Col span={24}>
        <Table
          scroll={{ x: true }}
          className={`special-table ims-doc-table ${
            shouldAddBtnDisable() ? 'doc-table-is-exapnded' : ''
          }`}
          expandable={{
            expandedRowRender,
            expandIcon: null,
            showExpandColumn: false,
            expandedRowKeys: expandedRowKeys,
            rowExpandable: ({ id }) => expandedRowKeys.includes(id),
          }}
          onRow={({ id }) =>
            expandedRowKeys.includes(id) && {
              className: `expand-doc-parent ${
                isRevisionUpdateCase ? 'ims-doc-readonly-input' : ''
              }`,
            }
          }
          rowKey={(record) => record.id}
          loading={fetchDocsLoading}
          rowClassName='editable-row'
          dataSource={data}
          columns={mergedColumns}
          onChange={handleChange}
          pagination={false}
        />
      </Col>
      <Col span={24}>
        <CustomPagination
          page={page}
          setPage={setPage}
          resultPerPage={resultPerPage}
          setResultPerPage={setResultPerPage}
          debouncedCallApi={debouncedCallApi}
          totalRecord={totalRecords}
        />
      </Col>
    </Row>
  );
};

export default CoachesTable;
