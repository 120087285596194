import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as Settings } from '../../../assets/settings.svg';
import { ReactComponent as ProjectIcon } from '../../../assets/projects-icon.svg';
import { ReactComponent as SetupIcon } from '../../../assets/setup-icon.svg';
import { ReactComponent as ReportSummary } from '../../../assets/reportSummary.svg';
import UseGetUserRole from '../../../components/useGetUserRole';
import { capitalizeFirstLetterofEachWord } from '../../../globalUtils/capitalizeFirstLetterofEachWord';

const typesArray = ['coach', 'learner'];

const useSidebar = () => {
  const { user } = useSelector((state) => state.authReducer);
  const [data, setData] = useState();
  const [isSuperAdmin] = UseGetUserRole();
  const [showSettings, setShowSettings] = useState(true);

  useEffect(() => {
    setData(user);
    // if (user?.permissions !== undefined && user?.permissions !== null) {
    //   let settingsPerKeys = ["ims", "project_setup"];
    //   Object.entries(user?.permissions).map((el) => {
    //     let [key, perObj] = el;
    //     if (!settingsPerKeys.includes(key)) {
    //       if (perObj?.view) {
    //         setShowSettings(true);
    //       }
    //     }
    //   });
    // }

    if (user?.user_type === 'learner') {
      setShowSettings(false);
    }

    if (user?.user_type === 'coach') {
      let coachView = user?.permissions?.learner_users_manager?.view || false;
      setShowSettings(coachView);
    }
  }, [user]);

  const handleRoleLength = (role) =>
    role.length > 20 ? `${role.substring(0, 18) + ' ...'}` : role;

  const handleNameLength = (role) =>
    role.length > 15 ? role.substring(0, 15) + '...' : role;

  const getRole = () => {
    const { user_type } = data;

    if (typesArray.includes(user_type)) {
      return capitalizeFirstLetterofEachWord(user_type);
    } else {
      return handleRoleLength(data?.job_title || '');
    }
  };

  const menuItemsData = [
    {
      key: '1',
      src: data?.profile_picture?.length > 1 ? data?.profile_picture : null,
      SvgImg: false,
      alt: '',
      name: data
        ? handleNameLength(data?.first_name + ' ' + data?.last_name || '')
        : '',
      role: data ? getRole() : '',
      link: '/profile',
      hasSubItems: false,
      permission: true,
      hasPermission: true,
    },
    {
      key: '2',
      SvgImg: ReportSummary,
      alt: 'Report Summary',
      name: 'Report Summary',
      link: '/report-summary',
      hasSubItems: true,
      hasPermission:
        user?.permissions?.report_summary?.learners?.view ||
        user?.permissions?.report_summary?.coaches?.view ||
        user?.permissions?.report_summary?.sites?.view ||
        user?.permissions?.report_summary?.classes?.view,
      subItems: [
        {
          key: 'learners',
          SvgImg: Settings,
          alt: 'learners',
          name: 'Learners',
          link: '/reports-summary/learners',
          hasSubItems: false,
          hasPermission: user?.permissions?.report_summary?.learners?.view,
        },
        {
          key: 'coaches',
          SvgImg: Settings,
          alt: 'coaches',
          name: 'Coaches',
          link: '/reports-summary/coaches',
          hasSubItems: false,
          hasPermission: user?.permissions?.report_summary?.coaches?.view,
        },
        {
          key: 'sites',
          SvgImg: Settings,
          alt: 'sites',
          name: 'Sites',
          link: '/reports-summary/sites',
          hasSubItems: false,
          hasPermission: user?.permissions?.report_summary?.sites?.view,
        },
        {
          key: 'classes',
          SvgImg: Settings,
          alt: 'classes',
          name: 'Classes',
          link: '/reports-summary/classes',
          hasSubItems: false,
          hasPermission: user?.permissions?.report_summary?.classes?.view,
        },
      ],
    },
    {
      key: '3',
      SvgImg: ProjectIcon,
      alt: 'NPOs',
      name: "NPO's",
      // subItems: "Select Project",
      link: '/npos',
      hasSubItems: true,
      hasPermission: true,
      subItems: [
        {
          key: 'project-control',
          SvgImg: Settings,
          alt: 'Learners',
          name: 'Learners',
          link: '/npo/learners',
          hasSubItems: false,
          hasPermission: true,
        },
      ],
    },
    {
      key: '4',
      SvgImg: SetupIcon,
      alt: 'NPO Icon',
      name: 'NPO Setup',
      link: '/npo-setup',
      hasSubItems: false,
      hasPermission: user?.permissions?.npo_setup?.view,
    },
    {
      key: '6',
      SvgImg: Settings,
      alt: 'settings',
      name: 'Settings',
      link: '/settings',
      hasSubItems: true,
      // permission: ["super_admin", "admin"],
      hasPermission: showSettings,
      subItems: [
        {
          key: 'system-users-manager',
          SvgImg: Settings,
          alt: 'System Users Manager',
          name: 'System Users Manager',
          link: '/settings/system-users-manager',
          hasSubItems: false,
          hasPermission: user?.permissions?.system_users_manager?.view,
        },
        {
          key: 'coach-users-manager',
          SvgImg: Settings,
          alt: 'Coach Users Manager',
          name: 'Coach Users Manager',
          link: '/settings/coach-users-manager',
          hasSubItems: false,
          hasPermission: user?.permissions?.coach_users_manager?.view,
        },
        {
          key: 'learner-users-manager',
          SvgImg: Settings,
          alt: 'Learner Users Manager',
          name: 'Learner Users Manager',
          link: '/settings/learner-users-manager',
          hasSubItems: false,
          hasPermission: user?.permissions?.learner_users_manager?.view,
        },
        {
          key: 'templates-manager',
          SvgImg: Settings,
          alt: 'Templates Manager',
          name: 'Templates Manager',
          link: '/settings/templates-manager',
          hasSubItems: false,
          hasPermission: user?.permissions?.templates_manager?.view,
        },
        {
          key: 'divider',
          SvgImg: false,
          alt: '',
          name: 'divider',
          link: '',
          hasSubItems: false,
          hasPermission:
            user?.permissions?.system_users_manager?.view ||
            user?.permissions?.coach_users_manager?.view ||
            user?.permissions?.learner_users_manager?.view,
        },
        {
          key: 'programme-types',
          SvgImg: Settings,
          alt: 'Programme Types',
          name: 'Programme Types',
          link: '/settings/programme-types',
          hasSubItems: false,
          hasPermission: user?.permissions?.programme_types?.view,
        },
        {
          key: 'intervention-types-manager',
          SvgImg: Settings,
          alt: 'Intervention Types',
          name: 'Intervention Types',
          link: '/settings/intervention-types-manager',
          hasSubItems: false,
          hasPermission: user?.permissions?.intervention_types?.view,
        },
        {
          key: 'divider1',
          SvgImg: false,
          alt: '',
          name: 'divider',
          link: '',
          hasSubItems: false,
          hasPermission:
            user?.permissions?.programme_types?.view ||
            user?.permissions?.intervention_types?.view,
        },
        {
          key: 'npo-regions',
          SvgImg: Settings,
          alt: 'NPO Regions',
          name: 'NPO Regions',
          link: '/settings/npo-regions',
          hasSubItems: false,
          hasPermission: user?.permissions?.npo_regions?.view,
        },
        {
          key: 'npo-statuses',
          SvgImg: Settings,
          alt: 'NPO Statuses',
          name: 'NPO Statuses',
          link: '/settings/npo-statuses',
          hasSubItems: false,
          hasPermission: user?.permissions?.npo_statuses?.view,
        },
        {
          key: 'divider2',
          SvgImg: false,
          alt: '',
          name: 'divider',
          link: '',
          hasSubItems: false,
          hasPermission:
            user?.permissions?.npo_regions?.view ||
            user?.permissions?.npo_statuses?.view,
        },
        {
          key: 'site-types',
          SvgImg: Settings,
          alt: 'Site Types',
          name: 'Site Types',
          link: '/settings/site-types',
          hasSubItems: false,
          hasPermission: user?.permissions?.site_types?.view,
        },
        {
          key: 'site-statuses',
          SvgImg: Settings,
          alt: 'Site Statuses',
          name: 'Site Statuses',
          link: '/settings/site-statuses',
          hasSubItems: false,
          hasPermission: user?.permissions?.site_statuses?.view,
        },
        {
          key: 'divider3',
          SvgImg: false,
          alt: '',
          name: 'divider',
          link: '',
          hasSubItems: false,
          hasPermission:
            user?.permissions?.site_types?.view ||
            user?.permissions?.site_statuses?.view,
        },
        {
          key: 'coach-types',
          SvgImg: Settings,
          alt: 'Coach Types',
          name: 'Coach Types',
          link: '/settings/coach-types',
          hasSubItems: false,
          hasPermission: user?.permissions?.coach_types?.view,
        },
        {
          key: 'coach-statuses',
          SvgImg: Settings,
          alt: 'Coach Statuses',
          name: 'Coach Statuses',
          link: '/settings/coach-statuses',
          hasSubItems: false,
          hasPermission: user?.permissions?.coach_statuses?.view,
        },
        {
          key: 'divider4',
          SvgImg: false,
          alt: '',
          name: 'divider',
          link: '',
          hasSubItems: false,
          hasPermission:
            user?.permissions?.coach_types?.view ||
            user?.permissions?.coach_statuses?.view,
        },
        {
          key: 'learner-types',
          SvgImg: Settings,
          alt: 'Learner Types',
          name: 'Learner Types',
          link: '/settings/learner-types',
          hasSubItems: false,
          hasPermission: user?.permissions?.learner_types?.view,
        },
        {
          key: 'learner-statuses',
          SvgImg: Settings,
          alt: 'Learner Statuses',
          name: 'Learner Statuses',
          link: '/settings/learner-statuses',
          hasSubItems: false,
          hasPermission: user?.permissions?.learner_statuses?.view,
        },
        {
          key: 'learner-camps',
          SvgImg: Settings,
          alt: 'Learner Camps',
          name: 'Learner Camps',
          link: '/settings/learner-camps',
          hasSubItems: false,
          hasPermission: user?.permissions?.learner_camps?.view,
        },
      ],
    },
  ];

  return [menuItemsData];
};

export default useSidebar;
