import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';

export function* handleFetchLearnerCamps({ data }) {
  try {
    const response = yield axios.get(`${baseURL}/api/v1/learner_camps`, {});
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchLearnerCampsSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchLearnerCampsError());
    AntdAlert.error('Something went wrong!');
    console.log(error);
  }
}

export function* handleUpdateLearnerCamps({ data }) {
  const { position, id, title } = data;

  try {
    const response = yield axios.put(`${baseURL}/api/v1/learner_camps/${id}`, {
      title,
      position,
    });
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateLearnerCampsSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateLearnerCampsError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateLearnerCampsError(response.data.data));
    AntdAlert.error('Something went wrong!');
  }
}

export function* handleDeleteLearnerCamps({ data }) {
  const { id } = data;
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v1/learner_camps/${id}`
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteLearnerCampsSuccess(id));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteLearnerCampsError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteLearnerCampsError(response.data.data));
    AntdAlert.error('Something went wrong!');
  }
}

export function* handleCreateLearnerCamps({ data }) {
  try {
    const newData = JSON.parse(JSON.stringify(data));
    delete newData.id;
    const response = yield axios.post(`${baseURL}/api/v1/learner_camps`, {
      ...newData,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createLearnerCampsSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createLearnerCampsError(data.id));
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateLearnerOrder({ data }) {
  const fd = new FormData();
  data.map((el, i) => fd.append(`learner_camps[${el.id}]`, i + 1));

  try {
    const response = yield axios.patch(
      `${baseURL}/api/v1/learner_camps/sort`,
      fd
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateLearnerCampsOrderSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateLearnerCampsOrderError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateLearnerCampsOrderError());
    AntdAlert.error('Something went wrong!');
  }
}
