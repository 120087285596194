import { useSelector } from 'react-redux';

const UsePermissions = () => {
  const { user } = useSelector((state) => state.authReducer);

  let { classes } = user.permissions.report_summary;

  let canView = classes.view;
  let canEdit = classes.add_edit;
  let canExport = classes.export;
  let canImport = classes.import;
  let canDelete = classes.delete;

  return [canView, canEdit, canExport, canDelete, canImport];
};

export default UsePermissions;
