import * as constants from "./constants";

export const setDocumentTemplateIsEdit = (data) => {
  return {
    type: constants.DOCUMENT_TEMPLATE_IS_EDIT,
    data: data,
  };
};

export const createTemplate = (data) => {
  return {
    type: constants.CREATE_TEMPLATE,
    data: data,
  };
};

export const createTemplateSuccess = (data) => {
  return {
    type: constants.CREATE_TEMPLATE_SUCCESS,
    data: data,
  };
};

export const createTemplateError = () => {
  return {
    type: constants.CREATE_TEMPLATE_ERROR,
  };
};

export const fetchAllTemplates = (data) => {
  return {
    type: constants.FETCH_ALL_TEMPLATES,
    data,
  };
};

export const fetchAllTemplatesSuccess = (data) => {
  return {
    type: constants.FETCH_ALL_TEMPLATES_SUCCESS,
    data,
  };
};
export const fetchAllTemplatesError = (data) => {
  return {
    type: constants.FETCH_ALL_TEMPLATES_ERROR,
    data,
  };
};
export const updateTemplates = (data) => {
  return {
    type: constants.UPDATE_TEMPLATE,
    data: data,
  };
};

export const updateTemplatesSuccess = (data) => {
  return {
    type: constants.UPDATE_TEMPLATE_SUCCESS,
    data: data,
  };
};

export const updateTemplatesError = () => {
  return {
    type: constants.UPDATE_TEMPLATE_ERROR,
    
  };
};
export const templateCreationCleanupFunc = () => {
  return {
    type: constants.TEMPLATE_CREATION_CLEANUP_FUNC,
  };
};
export const deleteTemplate = (data) => {
  return {
    type: constants.DELETE_TEMPLATE,
    data,
  };
};

export const deleteTemplateSuccess = (data) => {
  return {
    type: constants.DELETE_TEMPLATE_SUCCESS,
    data,
  };
};

export const deleteTemplateError = (data) => {
  return {
    type: constants.DELETE_TEMPLATE_ERROR,
    data,
  };
};


export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};

export const fetchTemplate = (data) => {
  return {
    type: constants.FETCH_TEMPLATE,
    data,
  };
};

export const fetchTemplateSuccess = (data) => {
  return {
    type: constants.FETCH_TEMPLATE_SUCCESS,
    data,
  };
}

export const fetchTemplateError = () => {
  return {
    type: constants.FETCH_TEMPLATE_ERROR,
  };
}