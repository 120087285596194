import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Table from './table';

const ProgrammeTypes = () => {
  const { user } = useSelector((state) => state.authReducer);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  useEffect(() => {
    if (user?.permissions?.programme_types?.add_edit) {
      setCanUpdate(true);
    }
    if (user?.permissions?.programme_types?.delete) {
      setCanDelete(true);
    }
  }, [user]);

  return (
    <div className='page-top-div'>
      <span className='text-span'>Programme Types Manager</span>
      <Table canUpdate={canUpdate} canDelete={canDelete} />
    </div>
  );
};

export default ProgrammeTypes;
