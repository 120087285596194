import * as constants from './constants';

const initialState = {
  // docTypeGrpahData: [],
  allDocs: [],
  fetchingLoading: false, // true
  fetchDocsLoading: false, // true
  fetchGraphLoading: false,
  totalRecords: 0,
  deleteAttachLoading: false,
  totalAndCompleted: [],
  region_attendence: [],
  npo_attendence: [],
  program_attendence: [],
  mass_interventions: [],
  attendence_by_race: [],
  attendance_by_gender: [],
  attendance_by_age_group: [],
  modular_pre_and_post: [],
  modular_pre_and_post_percentage: [],
  modular_actual_vs_target: [],
};

const learnersReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_LEARNERS_DOCS:
      return {
        ...state,
        fetchDocsLoading: true,
      };
    case constants.FETCH_LEARNERS_DOCS_SUCCESS:
      return {
        ...state,
        fetchDocsLoading: false,
        allDocs: action.data.learners,
        totalRecords: action.data.total_learners,
      };
    case constants.FETCH_LEARNERS_DOCS_ERROR:
      return {
        ...state,
        fetchDocsLoading: false,
      };

    case constants.FETCH_LEARNERS_GRAPH:
      return {
        ...state,
        fetchGraphLoading: true,
      };
    case constants.FETCH_LEARNERS_GRAPH_SUCCESS:
      let {
        npo_attendence,
        program_attendence,
        region_attendence,
        mass_interventions,
        modular_actual_vs_target,
        modular_pre_and_post,
        modular_pre_and_post_percentage,
        attendence_by_race,
        attendance_by_gender,
        attendance_by_age_group,
      } = action.data;

      let regionAtt = [];

      region_attendence.forEach((el, i) => {
        const { name, mass_target, mass_actual, mode_actuall, mode_target } =
          el;
        let allNull = mass_target || mass_actual || mode_actuall || mode_target;

        if (allNull > 0) {
          let obj = {
            id: i,
            label: name,
            modularActual: mode_actuall,
            modularTarget: mode_target,
            massActual: mass_actual,
            massTarget: mass_target,
          };
          regionAtt.push(obj);
        }
      });

      let NpoAtt = [];

      npo_attendence.forEach((el, i) => {
        const { name, mass_target, mass_actual, mode_actuall, mode_target } =
          el;
        let allNull = mass_target || mass_actual || mode_actuall || mode_target;

        if (allNull > 0) {
          let obj = {
            id: i,
            label: name,
            modularActual: mode_actuall,
            modularTarget: mode_target,
            massActual: mass_actual,
            massTarget: mass_target,
          };
          NpoAtt.push(obj);
        }
      });

      let programAtt = [];

      program_attendence.forEach((el, i) => {
        const { name, mass_target, mass_actual, mode_actuall, mode_target } =
          el;
        let allNull = mass_target || mass_actual || mode_actuall || mode_target;

        if (allNull > 0) {
          let obj = {
            id: i,
            label: name,
            modularActual: mode_actuall,
            modularTarget: mode_target,
            massActual: mass_actual > 0 ? mass_actual : null,
            massTarget: mass_target,
          };
          programAtt.push(obj);
        }
      });

      let massIntervention = [];

      mass_interventions.forEach((el, i) => {
        const { name, mass_target, mass_actual } = el;
        let allNull = mass_target || mass_actual;

        if (allNull > 0) {
          let obj = {
            id: i,
            label: name,
            massActual: mass_actual,
            massTarget: mass_target,
          };
          massIntervention.push(obj);
        }
      });

      modular_actual_vs_target = modular_actual_vs_target.filter(
        (object, index, self) =>
          index === self.findIndex((o) => o.name === object.name)
      );

      modular_actual_vs_target = modular_actual_vs_target
        .filter((el) => el.modular_target || el.modular_actual)
        .sort((a, b) => a.intervention_name.localeCompare(b.intervention_name));

      modular_actual_vs_target = modular_actual_vs_target.map((el) => ({
        ...el,
        id: Math.round(Math.random() * 1234567),
        label: el.name + ' - ' + el.intervention_name,
      }));

      let groupedObjects = {};

      // Group objects by intervention_id
      for (let obj of modular_actual_vs_target) {
        if (!groupedObjects[obj.intervention_id]) {
          groupedObjects[obj.intervention_id] = [];
        }
        groupedObjects[obj.intervention_id].push(obj);
      }

      // Calculate sums and update objects
      for (let interventionId in groupedObjects) {
        let objects = groupedObjects[interventionId];
        let sumActual = objects.reduce(
          (sum, obj) => sum + obj.modular_actual,
          0
        );
        let sumTarget = objects.reduce(
          (sum, obj) => sum + obj.modular_target,
          0
        );

        const { intervention_type, modular_target } = objects[0];
        let sumObject = {
          intervention_id: interventionId,
          intervention_name: objects[0].intervention_name,
          label: 'Actual vs. Target - ' + objects[0].intervention_name,
          // modular_target: sumTarget,
          modular_target:
            intervention_type === 'any_manual' ? modular_target : sumTarget,
          modular_actual: sumActual,
          id: Math.round(Math.random() * 1234567),
        };

        // Insert the sum object after the last object with the same intervention_id
        let lastSameIdObject = objects[objects.length - 1];
        let insertIndex =
          modular_actual_vs_target.indexOf(lastSameIdObject) + 1;
        modular_actual_vs_target.splice(insertIndex, 0, sumObject);
      }
      modular_actual_vs_target = modular_actual_vs_target.map((el) => {
        if (el?.intervention_type === 'any_manual') {
          return {
            ...el,
            modular_target: null,
          };
        }
        return el;
      });

      modular_pre_and_post = modular_pre_and_post
        .filter((el) => el.manual_post || el.manual_pre)
        .sort((a, b) => a.intervention_name.localeCompare(b.intervention_name));

      modular_pre_and_post = modular_pre_and_post.map((el) => ({
        ...el,
        id: Math.round(Math.random() * 1234567),
        label: el.name + ' - ' + el.intervention_name,
        pre_total: el.manual_pre,
        post_total: el.manual_post,
      }));

      let groupedObjects2 = {};

      // Group objects by intervention_id
      for (let obj of modular_pre_and_post) {
        if (!groupedObjects2[obj.intervention_id]) {
          groupedObjects2[obj.intervention_id] = [];
        }
        groupedObjects2[obj.intervention_id].push(obj);
      }

      // Calculate sums and update objects
      for (let interventionId in groupedObjects2) {
        let objects = groupedObjects2[interventionId];
        let sumPost = objects.reduce((sum, obj) => sum + obj.post_total, 0);
        let sumPre = objects.reduce((sum, obj) => sum + obj.pre_total, 0);

        let sumObject = {
          intervention_id: interventionId,
          intervention_name: objects[0].intervention_name,
          label: 'Pre Total vs. Post Total - ' + objects[0].intervention_name,
          // manual_pre: sumPre,
          // manual_post: sumPost,
          pre_total: sumPre,
          post_total: sumPost,
          id: Math.round(Math.random() * 1234567),
        };

        // Insert the sum object after the last object with the same intervention_id
        let lastSameIdObject = objects[objects.length - 1];
        let insertIndex = modular_pre_and_post.indexOf(lastSameIdObject) + 1;
        modular_pre_and_post.splice(insertIndex, 0, sumObject);
      }

      modular_pre_and_post_percentage = modular_pre_and_post_percentage
        .filter(
          (el) =>
            el.mkt_pre ||
            el.mkt_post ||
            el.ses_pre ||
            el.ses_post ||
            el.leq_pre ||
            el.leq_post
        )
        .sort((a, b) => a.intervention_name.localeCompare(b.intervention_name));

      modular_pre_and_post_percentage = modular_pre_and_post_percentage.map(
        (el) => ({
          ...el,
          id: Math.round(Math.random() * 1234567),
          label: el.name + ' - ' + el.intervention_name,
        })
      );

      let groupedObjects3 = {};

      // Group objects by intervention_id
      for (let obj of modular_pre_and_post_percentage) {
        if (!groupedObjects3[obj.intervention_id]) {
          groupedObjects3[obj.intervention_id] = [];
        }
        groupedObjects3[obj.intervention_id].push(obj);
      }

      // Calculate sums and update objects
      for (let interventionId in groupedObjects3) {
        let objects = groupedObjects3[interventionId];
        let sumMktPre = objects.reduce((sum, obj) => sum + obj.mkt_pre, 0);
        let sumMktPost = objects.reduce((sum, obj) => sum + obj.mkt_post, 0);
        let sumSesPre = objects.reduce((sum, obj) => sum + obj.ses_pre, 0);
        let sumSesPost = objects.reduce((sum, obj) => sum + obj.ses_post, 0);
        let sumLeqPre = objects.reduce((sum, obj) => sum + obj.leq_pre, 0);
        let sumLeqPost = objects.reduce((sum, obj) => sum + obj.leq_post, 0);

        let sumObject = {
          intervention_id: interventionId,
          intervention_name: objects[0].intervention_name,
          label: 'Total vs. Target - ' + objects[0].intervention_name,
          mkt_pre: sumMktPre,
          mkt_post: sumMktPost,
          ses_pre: sumSesPre,
          ses_post: sumSesPost,
          leq_pre: sumLeqPre,
          leq_post: sumLeqPost,
          id: Math.round(Math.random() * 1234567),
        };

        // Insert the sum object after the last object with the same intervention_id
        let lastSameIdObject = objects[objects.length - 1];
        let insertIndex =
          modular_pre_and_post_percentage.indexOf(lastSameIdObject) + 1;
        // modular_pre_and_post_percentage.splice(insertIndex, 0, sumObject);
      }

      const raceObj = {
        1: 'african',
        2: 'asian',
        3: 'coloured',
        4: 'white',
      };

      let raceMergedData = {};

      attendence_by_race = attendence_by_race.sort((a, b) =>
        a.intervention_name.localeCompare(b.intervention_name)
      );
      // Iterate over each object in the array
      attendence_by_race.forEach((obj) => {
        // Generate a unique key based on intervention_id and name
        let key = `${obj.intervention_id}-${obj.name}_${obj.race}`;

        // Check if the key already exists in mergedData
        if (raceMergedData[key]) {
          // If the key exists, update the race_count by adding the current object's race_count
          raceMergedData[key].race_count += obj.race_count;
        } else {
          // If the key doesn't exist, create a new object with the initial values
          raceMergedData[key] = {
            intervention_id: obj.intervention_id,
            intervention_name: obj.intervention_name,
            name: obj.name,
            race: obj.race,
            race_count: obj.race_count,
            id: null,
            label: `${obj.name} - ${obj.intervention_name}`,
          };
        }
      });

      // Convert race values to strings using age_type_obj
      Object.values(raceMergedData).forEach((obj) => {
        obj.race = raceObj[obj.race];
      });

      // Convert the mergedData object back to an array
      let raceMergedArray = Object.values(raceMergedData);

      const raceFinal = Object.values(
        raceMergedArray.reduce((result, obj) => {
          const key = `${obj.intervention_id}-${obj.name}`;
          if (!result[key]) {
            result[key] = {
              intervention_id: obj.intervention_id,
              intervention_name: obj.intervention_name,
              name: obj.name,
              label: `${obj.name} - ${obj.intervention_name}`,
            };
          }
          result[key][obj.race] = obj.race_count;
          return result;
        }, {})
      );

      let genderObj = {
        1: 'female',
        2: 'male',
      };

      let genderMergedData = {};

      attendance_by_gender = attendance_by_gender.sort((a, b) =>
        a.intervention_name.localeCompare(b.intervention_name)
      );

      // Iterate over each object in the array
      attendance_by_gender.forEach((obj) => {
        // Generate a unique key based on intervention_id and name
        let key = `${obj.intervention_id}-${obj.name}_${obj.gender}`;

        // Check if the key already exists in mergedData
        if (genderMergedData[key]) {
          // If the key exists, update the user_gender by adding the current object's user_gender
          genderMergedData[key].user_gender += obj.user_gender;
        } else {
          // If the key doesn't exist, create a new object with the initial values
          genderMergedData[key] = {
            intervention_id: obj.intervention_id,
            intervention_name: obj.intervention_name,
            name: obj.name,
            gender: obj.gender,
            user_gender: obj.user_gender,
            id: null,
            label: `${obj.name} - ${obj.intervention_name}`,
          };
        }
      });

      // Convert gender values to strings using age_type_obj
      Object.values(genderMergedData).forEach((obj) => {
        obj.gender = genderObj[obj.gender];
      });

      // Convert the mergedData object back to an array
      let genderMergedArray = Object.values(genderMergedData);

      const genderFinal = Object.values(
        genderMergedArray.reduce((result, obj) => {
          const key = `${obj.intervention_id}-${obj.name}`;
          if (!result[key]) {
            result[key] = {
              intervention_id: obj.intervention_id,
              intervention_name: obj.intervention_name,
              name: obj.name,
              label: `${obj.name} - ${obj.intervention_name}`,
            };
          }
          result[key][obj.gender] = obj.user_gender;
          return result;
        }, {})
      );

      let ageObj = {
        1: 'child',
        2: 'youth',
        3: 'adult',
      };

      // Create an empty object to store the merged and summed data
      let mergedData = {};

      attendance_by_age_group = attendance_by_age_group.sort((a, b) =>
        a.intervention_name.localeCompare(b.intervention_name)
      );

      // Iterate over each object in the array
      attendance_by_age_group.forEach((obj) => {
        // Generate a unique key based on intervention_id and name
        let key = `${obj.intervention_id}-${obj.name}_${obj.age_type}`;

        // Check if the key already exists in mergedData
        if (mergedData[key]) {
          // If the key exists, update the age by adding the current object's age
          mergedData[key].age += obj.age;
        } else {
          // If the key doesn't exist, create a new object with the initial values
          mergedData[key] = {
            intervention_id: obj.intervention_id,
            intervention_name: obj.intervention_name,
            name: obj.name,
            age_type: obj.age_type,
            age: obj.age,
            id: null,
            label: `${obj.name} - ${obj.intervention_name}`,
          };
        }
      });

      // Convert age_type values to strings using age_type_obj
      Object.values(mergedData).forEach((obj) => {
        obj.age_type = ageObj[obj.age_type];
      });

      // Convert the mergedData object back to an array
      let mergedArray = Object.values(mergedData);

      const final = Object.values(
        mergedArray.reduce((result, obj) => {
          const key = `${obj.intervention_id}-${obj.name}`;
          if (!result[key]) {
            result[key] = {
              intervention_id: obj.intervention_id,
              intervention_name: obj.intervention_name,
              name: obj.name,
              label: `${obj.name} - ${obj.intervention_name}`,
            };
          }
          result[key][obj.age_type] = obj.age;
          return result;
        }, {})
      );

      return {
        ...state,
        fetchGraphLoading: false,
        npo_attendence: NpoAtt,
        program_attendence: programAtt,
        region_attendence: regionAtt,
        mass_interventions: massIntervention,
        modular_actual_vs_target,
        modular_pre_and_post,
        modular_pre_and_post_percentage,
        attendence_by_race: raceFinal,
        attendance_by_gender: genderFinal,
        attendance_by_age_group: final,
      };
    case constants.FETCH_LEARNERS_GRAPH_ERROR:
      return {
        ...state,
        fetchGraphLoading: false,
      };

    case constants.FETCH_BATCH_UPDATE:
      return {
        ...state,
        fetchDocsLoading: true,
      };
    case constants.FETCH_BATCH_UPDATE_SUCCESS:
      return {
        ...state,
        fetchDocsLoading: false,
        allDocs: action.data.documents,
        totalRecords: action.data.total_documents,
      };

    case constants.FETCH_BATCH_UPDATE_ERROR:
      return {
        ...state,
        fetchDocsLoading: false,
      };

    default:
      return state;
  }
};

export default learnersReducer;
