import React from 'react';
import { DatePicker, Form, Input, Select } from 'antd';
import { useSelector } from 'react-redux';
// import ImageComponent from './useAttachments';

const { Option } = Select;

const EditableCell = ({
  cell,
  expandedRowKeys,
  setExpandedRowKeys,
  // images,
  // setImages,
  // attachPopupVisible,
  // setAttachPopupVisible,
  // currentUpdateObj,
  values,
  handleChangeDocType,
  handleUpdateSite,
  // getCloumnName,
  isRevisionUpdateCase,
  manuals,
  coachUsers,
}) => {
  const {
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  } = cell;

  const { allNpos } = useSelector((state) => state.npoSetupReducer);
  const { allDocs } = useSelector((state) => state.sitesReducer);

  const { allProgrammeTypes } = useSelector(
    (state) => state.programmeTypesReducer
  );

  const { allTemplates } = useSelector(
    (state) => state.interventionTypesReducer
  );

  let inputNode;
  let classname = `${isRevisionUpdateCase ? 'ims-doc-readonly-input' : ''}`;

  switch (dataIndex) {
    case 'program_date':
      inputNode = (
        <DatePicker
          format={'DD MMM YYYY'}
          size='large'
          style={{ minWidth: '115px' }}
          suffixIcon={null}
          allowClear={false}
          className={classname}
          disabled={isRevisionUpdateCase}
        />
      );
      break;
    case 'site_id':
      inputNode = (
        <Select
          showSearch
          // disabled={shouldDisabled || isRevisionUpdateCase}
          className={`activities-selector ${classname}`}
          style={{ maxWidth: '100px', width: '100%' }}
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          dropdownClassName='select-dropdown-custom'
          placeholder='Select Site'
          size='small'
          optionFilterProp='children'
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={handleUpdateSite}
        >
          {allDocs?.map(({ id, name, npo_id }) => (
            <Option key={id} value={id} npo_id={npo_id}>
              {name}
            </Option>
          ))}
        </Select>
      );
      break;
    case 'user_id':
      inputNode = (
        <Select
          showSearch
          // disabled={shouldDisabled || isRevisionUpdateCase}
          className={`activities-selector ${classname}`}
          style={{ maxWidth: '100px', width: '100%' }}
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          dropdownClassName='select-dropdown-custom'
          placeholder='Select Coach'
          size='small'
          optionFilterProp='children'
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {coachUsers.map(({ id, first_name, last_name }) => (
            <Option key={id} value={id}>
              {`${first_name} ${last_name}`}
            </Option>
          ))}
        </Select>
      );
      break;

    case 'npo_region':
    case 'mkt_pre':
    case 'mkt_post':
    case 'ses_pre':
    case 'ses_post':
    case 'leq_pre':
    case 'leq_post':
    case 'total_attendance':
    case 'average_age':
      inputNode = <Input readOnly className='border-less-input' />;
      break;
    case 'classname':
      inputNode = (
        <Input
          // disabled={shouldDisabled || isRevisionUpdateCase}
          placeholder='Class Name'
          size='large'
          classname={classname}
          readOnly={isRevisionUpdateCase}
        />
      );
      break;

    case 'topic':
      inputNode = (
        <Input
          // disabled={shouldDisabled || isRevisionUpdateCase}
          placeholder='Topic Title'
          size='large'
          classname={classname}
          readOnly={isRevisionUpdateCase}
        />
      );
      break;
    case 'npo_id':
      inputNode = (
        <Select
          showSearch
          // disabled={shouldDisabled || isRevisionUpdateCase}
          className={`activities-selector ${classname}`}
          style={{ maxWidth: '100px', width: '100%' }}
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          dropdownClassName='select-dropdown-custom'
          placeholder='Select NPO'
          size='small'
          disabled={true}
          optionFilterProp='children'
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          // onChange={(e) => {
          //   handleChangeDocType(e);
          // }}
        >
          {allNpos.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      );
      break;

    case 'type_id':
      inputNode = (
        <Select
          showSearch
          // disabled={shouldDisabled || isRevisionUpdateCase}
          className={`activities-selector ${classname}`}
          style={{ maxWidth: '100px', width: '100%' }}
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          dropdownClassName='select-dropdown-custom'
          placeholder='Select Programme'
          size='small'
          optionFilterProp='children'
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allProgrammeTypes.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      );
      break;

    case 'intervention_id':
      inputNode = (
        <Select
          showSearch
          // disabled={shouldDisabled || isRevisionUpdateCase}
          className={`activities-selector ${classname}`}
          style={{ maxWidth: '100px', width: '100%' }}
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          dropdownClassName='select-dropdown-custom'
          placeholder='Select Intervention'
          size='small'
          optionFilterProp='children'
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={(e, option) => {
            handleChangeDocType(e, option);
          }}
        >
          {allTemplates.map(({ id, name, user_manuals }) => (
            <Option key={id} value={id} manuals={user_manuals}>
              {name}
            </Option>
          ))}
        </Select>
      );
      break;
    case 'manual_id':
      inputNode = (
        <Select
          // showSearch
          // disabled={shouldDisabled || isRevisionUpdateCase}
          className={`activities-selector ${classname}`}
          style={{ maxWidth: '100px', width: '100%' }}
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          dropdownClassName='select-dropdown-custom'
          placeholder='Select Manual'
          size='small'
          optionFilterProp='children'
          filterOption={(input, option) =>
            option?.options?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
          }
          options={
            manuals.length > 0
              ? [
                  {
                    label: 'Modular',
                    options: manuals.map(({ id, name }) => ({
                      label: name,
                      value: id,
                    })),
                  },
                  {
                    label: 'Mass',
                    options: [
                      {
                        label: 'Mass',
                        value: 'mass',
                      },
                    ],
                  },
                ]
              : [
                  {
                    label: 'Mass',
                    options: [
                      {
                        label: 'Mass',
                        value: 'mass',
                      },
                    ],
                  },
                ]
          }
        />
      );
      break;

    default:
      break;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          className={`ims-doc-first-level ${classname}`}
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: dataIndex !== 'topic', // isRequired.includes(dataIndex)
              message: '', // getErrorText(dataIndex)
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
