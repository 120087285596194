import React, { useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Col, Row, Popconfirm, Space } from 'antd';
import { ReactComponent as CrossIcon } from '../../../assets/crossIcon.svg';
import DragDropIcon from '../../../assets/dragdropicon.png';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const DragHandle = sortableHandle(() => (
  <img alt='' height={20} width={20} src={DragDropIcon} />
));

const AddFieldsComponent = ({
  inputFields,
  setInputFields,
  canUpdate,
  updateModularTarget,
  currentTemplate,
  deleteField,
  updateCalculation,
}) => {
  const inputEls = useRef([]);

  const handleDeleteLocally = (item) => {
    const { isLocal, id } = item;
    const filteredInputs = inputFields.filter((el) => el.id !== id);
    setInputFields(filteredInputs);
    if (!isLocal) {
      deleteField(id);
    }
    updateCalculation(filteredInputs);
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        inputFields.slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setInputFields(newData);
    }
  };

  const addChooseField = () => {
    setInputFields([
      ...inputFields,
      {
        name: '',
        target: '',
        weight: 0,
        manual_pre: 0,
        manual_post: 0,
        total_weight: 0,
        isLocal: true,
        // isEdit: true,
        id: Math.floor(Math.random() * 10000000),
      },
    ]);
  };

  const DataRow = ({ ele }) => {
    const [localData, setLocalData] = useState({ ...ele });
    // console.log('ele', ele);
    // console.log('inputFields[ele.index]', inputFields[ele.index]);
    let { weight, manual_post, manual_pre, total_weight } =
      inputFields[ele.index];
    // console.log('weight', weight);
    weight = weight ? parseInt(weight) : 0;
    manual_post = manual_post ? parseInt(manual_post) : 0;
    manual_pre = manual_pre ? parseInt(manual_pre) : 0;
    // console.log('weight', weight);
    // console.log('manual_pre', manual_pre);
    // console.log('manual_post', manual_post);
    let isSumOfAll = currentTemplate?.modular_type === 'sum_of_all_manual';
    let xxl = isSumOfAll ? 6 : 7;
    return (
      <Row justify='space-between' className='manual-row'>
        <Col md={xxl} lg={xxl - 1} xl={xxl} xxl={xxl}>
          <Input
            placeholder='Insert Manual Name'
            // style={{ width: '218px' }}
            value={localData.name}
            disabled={!canUpdate}
            onChange={(e) => {
              const { value } = e.target;
              setLocalData({ ...localData, name: value });
              inputFields[ele.index].name = value;
              setInputFields(inputFields);
            }}
          />
        </Col>
        {currentTemplate?.modular_type === 'sum_of_all_manual' && (
          <>
            <Col
              md={4}
              lg={3}
              xl={3}
              xxl={2}
              style={{
                textAlign: 'center',
              }}
            >
              <Input
                placeholder='Target'
                className='percentage-input'
                style={{ width: '68px' }}
                value={localData.target > 0 ? localData.target : null}
                disabled={!canUpdate}
                ref={(el) => (inputEls.current[ele.id] = el)}
                onChange={(e) => {
                  let { value } = e.target;
                  // console.log('value', value);

                  if (value.length === 0) {
                    value = '0';
                  }

                  if (!isNaN(value) && !value?.includes('.')) {
                    setLocalData({ ...localData, target: parseInt(value) });
                    inputFields[ele.index].target = parseInt(value);
                    setInputFields(inputFields);
                    updateModularTarget();
                    setTimeout(() => {
                      inputEls.current[ele.id].focus();
                    }, 100);
                  }
                }}
              />
            </Col>
            {/* <Col span={0} xl={0} xxl={3} /> */}
            <Col
              md={3}
              lg={3}
              xl={{ span: 3, offset: 1 }}
              xxl={{ span: 2, offset: 3 }}
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              <Input
                placeholder='Insert %'
                className='percentage-input'
                value={localData.weight > 0 ? localData.weight : null}
                disabled={!canUpdate}
                onChange={(e) => {
                  let { value } = e.target;
                  if (value.length === 0) {
                    value = '0';
                  }
                  if (!isNaN(value) && !value?.includes('.')) {
                    setLocalData({ ...localData, weight: parseInt(value) });
                    inputFields[ele.index].weight =
                      value > 0 ? parseInt(value) : 0;
                    inputFields[ele.index].total_weight =
                      parseInt(value) + manual_post + manual_pre;
                    setInputFields(inputFields);
                  }
                }}
              />{' '}
              %
            </Col>
            <Col
              md={3}
              lg={3}
              xl={3}
              xxl={2}
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              <Input
                placeholder='Insert %'
                className='percentage-input'
                value={localData.manual_pre > 0 ? localData.manual_pre : null}
                disabled={!canUpdate}
                onChange={(e) => {
                  let { value } = e.target;
                  if (value.length === 0) {
                    value = '0';
                  }
                  if (!isNaN(value) && !value?.includes('.')) {
                    setLocalData({ ...localData, manual_pre: parseInt(value) });
                    inputFields[ele.index].manual_pre =
                      value > 0 ? parseInt(value) : 0;
                    inputFields[ele.index].total_weight =
                      weight + parseInt(value) + manual_post;
                    setInputFields(inputFields);
                  }
                }}
              />{' '}
              %
            </Col>
            <Col
              md={3}
              lg={3}
              xl={3}
              xxl={2}
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              <Input
                placeholder='Insert %'
                className='percentage-input'
                value={localData.manual_post > 0 ? localData.manual_post : null}
                disabled={!canUpdate}
                onChange={(e) => {
                  let { value } = e.target;
                  if (value.length === 0) {
                    value = '0';
                  }
                  if (!isNaN(value) && !value?.includes('.')) {
                    setLocalData({
                      ...localData,
                      manual_post: parseInt(value),
                    });
                    inputFields[ele.index].manual_post =
                      value > 0 ? parseInt(value) : 0;
                    inputFields[ele.index].total_weight =
                      weight + parseInt(value) + manual_pre;
                    setInputFields(inputFields);
                  }
                }}
              />{' '}
              %
            </Col>
            <Col
              span={2}
              xl={2}
              xxl={2}
              className='center'
              style={{
                fontWeight: 600,
                textAlign: 'center',
              }}
            >
              {total_weight} %
            </Col>
          </>
        )}
      </Row>
    );
  };

  const RenderInputs = ({ ele, index }) => {
    return (
      <>
        <Row
          align='center'
          justify='space-between'
          className='field-row'
          key={index}
          gutter={[10, 0]}
        >
          <Col xxl={1} />
          <Col md={21} lg={21} xl={21} xxl={21}>
            <DataRow ele={ele} />
          </Col>
          {canUpdate && (
            <>
              <Col>
                <Space size={5} align='center' style={{ lineHeight: 0 }}>
                  <Popconfirm
                    getPopupContainer={() =>
                      document.getElementsByClassName(
                        'ant-layout-content layout-content site-layout-background custom-textarea'
                      )[0]
                    }
                    title='Are you sure to cancel?'
                    placement='topRight'
                    onConfirm={() => handleDeleteLocally(ele)}
                    okText='Yes'
                    cancelText='No'
                  >
                    <CrossIcon width={20} height={20} fill='#e21c21' />
                  </Popconfirm>
                  <Col>
                    <DragHandle />
                  </Col>
                </Space>
              </Col>
            </>
          )}
        </Row>
      </>
    );
  };

  const SortableItem = sortableElement(({ value, ...rest }) => {
    const { index } = value;
    return (
      <Col span={24} {...rest} key={rest.id}>
        <RenderInputs ele={{ ...value }} index={index} />
        <hr />
      </Col>
    );
  });

  const SortableContainer = sortableContainer(({ children }) => {
    return <div style={{ width: '100%' }}>{children}</div>;
  });

  return (
    // <Spin spinning={loading}>
    <SortableContainer onSortEnd={onSortEnd} useDragHandle>
      <Row gutter={[20, 10]} className='doc-template-parent-div'>
        {inputFields.map((el, i) => {
          return (
            <SortableItem
              key={`item-${el.id}`}
              index={i}
              value={{ ...el, index: i }}
            />
          );
        })}
        {canUpdate && (
          <Col span={24} key={6789}>
            <Button
              onClick={addChooseField}
              icon={<PlusOutlined />}
              className='add-field-btn'
            >
              MANUAL
            </Button>
          </Col>
        )}
      </Row>
    </SortableContainer>
    // </Spin>
  );
};

export default AddFieldsComponent;
