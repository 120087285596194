import React, { useState } from 'react';
import { Row, Col, Button, Popover, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as ExportIcon } from '../../../assets/upload-edit.svg';
import './index.less';
import axios from 'axios';
import { baseURL } from '../../../globalUtils/axiosConfig';

const Export = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDownload = (filename) => {
    let url = `${baseURL}/${filename}`;
    window.open(url, '_blank');
  };

  const handleSave = async () => {
    setLoading(true);
    const res = await axios.get(`${baseURL}/api/v1/npos/export`);

    const { status } = res;

    if (status === 200) {
      const { filename } = res.data;

      let updatedFileName = res.data?.file?.to_path
        ? res.data?.file?.to_path?.slice(7)
        : filename;
      // const name = filename.split('/')[1];
      handleDownload(updatedFileName); // name
      setSuccess(true);
    } else {
      message.error('Something went wrong!');
    }
    setLoading(false);
  };

  const cleanupFun = () => {
    setSuccess(false);
  };

  const onOpenChange = (e) => {
    if (!e) {
      cleanupFun();
    }
  };

  const handleClose = () => {
    setIsVisible(false);
    cleanupFun();
  };

  const SuccessSection = () => {
    return (
      <Col>
        <Row justify='center' gutter={[0, 20]} className='success-row'>
          <Col span={24}>
            <div className='success-text'>Document Exported Successfully</div>
          </Col>
          <Col span={24}>
            <Button size='small' onClick={handleClose} className='close-btn'>
              CLOSE
            </Button>
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <Popover
      overlayClassName='export-projects-popover'
      placement='topRight'
      getPopupContainer={() =>
        document.getElementsByClassName(
          'ant-layout-content layout-content site-layout-background custom-textarea'
        )[0]
      }
      content={
        <Row justify='center' gutter={[0, 40]} className='parent-div'>
          {success ? (
            <SuccessSection />
          ) : (
            <Col>
              <Button
                size='middle'
                icon={<ExportIcon />}
                loading={loading}
                onClick={handleSave}
                className={`export-btn`}
              >
                EXPORT NPOS
              </Button>
            </Col>
          )}
        </Row>
      }
      onOpenChange={onOpenChange}
      title={
        <Row justify='space-between'>
          <Col className='title'>EXPORT</Col>
          <Col>
            <CloseOutlined onClick={handleClose} />
          </Col>
        </Row>
      }
      trigger='click'
      open={isVisible}
      onVisibleChange={(e) => {
        setIsVisible(e);
      }}
    >
      <ExportIcon className={`export-icon`} />
    </Popover>
  );
};

export default Export;
