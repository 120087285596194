import React from 'react';
import { Col, DatePicker, Row, Select, Form, Input } from 'antd';
const { Option } = Select;

const FormItem = ({ item, isRevisionUpdateCase }) => {
  const { id, name, field_type, ...rest } = item;
  let classname = `${isRevisionUpdateCase ? 'ims-doc-readonly-input' : ''}`;
  let placeholder = isRevisionUpdateCase ? '' : name;

  if (field_type === 'date_field') {
    return (
      <Form.Item
        className={classname}
        label={name}
        name={id}
        key={id}
        labelCol={{ span: 24 }}
        // rules={
        //   [
        // {
        //   required: true,
        //   message: "Required!",
        // },
        //   ]
        // }
      >
        <DatePicker
          size='small'
          style={{
            width: '100%',
          }}
          format={'DD MMM YYYY'}
          suffixIcon={null}
          placeholder={placeholder}
          disabled={isRevisionUpdateCase}
        />
      </Form.Item>
    );
  }
  if (field_type === 'text_field') {
    return (
      <Form.Item
        className={classname}
        label={name}
        name={id}
        key={id}
        labelCol={{ span: 24 }}
        // rules={
        // [
        // {
        //   required: true,
        //   message: "Required!",
        // },
        //   ]
        // }
      >
        <Input
          placeholder={placeholder}
          readOnly={isRevisionUpdateCase}
          disabled={isRevisionUpdateCase}
          size='small'
        />
      </Form.Item>
    );
  }

  if (field_type === 'select_field') {
    const { field_options } = rest;

    return (
      <Form.Item
        className={classname}
        label={name}
        name={id}
        key={id}
        labelCol={{ span: 24 }}
        // rules={
        //   [
        // {
        //   required: true,
        //   message: "Required!",
        // },
        //   ]
        // }
      >
        <Select placeholder={placeholder} disabled={isRevisionUpdateCase}>
          {field_options.map((el) => {
            const { id, name } = el;
            return (
              <Option value={name} key={id}>
                {name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }
};

const ExpandedRow = ({
  fields,
  formRef,
  onFinish,
  isRevisionUpdateCase,
  record,
  isAdd,
}) => {
  return (
    <Row>
      <Col span={12}>
        <Row className='static-fields-row'>
          <Col className='' style={{ paddingLeft: '10px' }}>
            <Form.Item label='Site No:'>
              {isAdd ? '[Auto Fill]' : record?.site_no}
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Form
          className='ims-doc-fields-form'
          form={formRef}
          onFinish={onFinish}
        >
          <Row gutter={[20, 30]}>
            {fields.map((el) => {
              return (
                <Col span={6} key={el.id}>
                  <FormItem
                    item={el}
                    isRevisionUpdateCase={isRevisionUpdateCase}
                  />
                </Col>
              );
            })}
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default ExpandedRow;
