import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchAllNpos } from '../NpoSetup/redux/actions';
import { fetchLearnerStatuses } from '../LearnerStatuses/redux/actions';
import GraphSection from './graphSection';
import TableSection from './tableSection';
import { fetchImsDocs } from '../Sites/redux/actions';
import { fetchLearnerTypes } from '../LearnerTypes/redux/actions';
import { fetchAllTemplates } from '../TemplatesManager/redux/actions';
import axios from 'axios';
import { baseURL } from '../../globalUtils/axiosConfig';
import SpinnerComponent from '../../components/spinner';
import { Col, message, Row } from 'antd';

const NPOsLearners = () => {
  const [statusCheckList, setStatusCheckList] = useState([]);
  // const [statusOptions, setStatusOptions] = useState([]);
  const [learnersTypesCheckList, setLearnersTypesCheckList] = useState([]);
  // const [learnersTypesOptions, setLearnersTypesOptions] = useState([]);
  const [siteCheckList, setSiteCheckList] = useState([]);
  // const [siteOptions, setSiteOptions] = useState([]);
  const [npoCheckList, setNpoCheckList] = useState([]);
  // const [npoOptions, setNpoOptions] = useState([]);
  const [npoDetails, setNpoDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [learnerCheckList, setLearnerCheckList] = useState([]);
  const [learnerUsers, setLearnerUsers] = useState([]);

  // const { allTemplates } = useSelector((state) => state.docTypesReducer);
  const { allLearnerStatuses } = useSelector(
    (state) => state.learnerStatusReducer
  );
  const { allDocs } = useSelector((state) => state.sitesReducer);
  const { allNpos } = useSelector((state) => state.npoSetupReducer);
  const { allLearnerTypes } = useSelector((state) => state.learnerTypesReducer);
  const { user } = useSelector((state) => state.authReducer);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { npo_id } = useParams();
  const navigate = useNavigate();

  const onPermissionError = () => {
    message.error(
      "You don't have the permission to view NPO Learner. Please contact system admin."
    );
    return navigate('/profile');
  };

  const fetchDetails = async () => {
    setLoading(true);
    const res = await axios.get(`${baseURL}/api/v1/user_npos/${npo_id}`);
    const { success } = res.data;
    if (success) {
      const { data } = res.data;
      const { permissions } = data;
      setNpoDetails(data);
      let viewPermission = permissions?.learners.view || false;
      if (!viewPermission) {
        return onPermissionError();
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    !loading && fetchDetails();
  }, [npo_id]);

  useEffect(() => {
    if (npoDetails !== null) {
      const params = {
        search: {
          npo_id: [npoDetails.npo_id],
        },
      };
      dispatch(fetchAllNpos(params));
      dispatch(fetchLearnerTypes());
      dispatch(
        fetchImsDocs({ ...params, per_page: 'all', sort: 'name', order: 'asc' })
      );
      dispatch(fetchLearnerStatuses());
      dispatch(fetchAllTemplates({}));
    }
  }, [npoDetails]);

  useEffect(() => {
    if (npoDetails) {
      fetchLearnerUsers();
    }
  }, [npoDetails]);

  const npoOptions = useMemo(
    () =>
      allNpos.map((el) => {
        const { name, id } = el;
        return { value: id, label: name };
      }),
    [allNpos]
  );

  const learnersTypesOptions = useMemo(
    () =>
      allLearnerTypes.map((el) => {
        const { name, id } = el;
        return { value: id, label: name };
      }),
    [allLearnerTypes]
  );

  const siteOptions = useMemo(
    () =>
      allDocs.map((el) => {
        const { name, id } = el;
        return { value: id, label: name };
      }),
    [allDocs]
  );

  const statusOptions = useMemo(
    () =>
      allLearnerStatuses.map((el) => {
        const { name, id } = el;
        return { value: id, label: name };
      }),
    [allLearnerStatuses]
  );

  const fetchLearnerUsers = async () => {
    const { user_type } = user;
    let str = user_type === 'learner' ? `&email=${user.email}` : '';

    const res = await axios.get(
      `${baseURL}/api/v1/users?page=1&per_page=all&user_type=learner&npo_id[]=${npoDetails?.npo_id}${str}`
    );

    const { success, data } = res.data;

    if (success) {
      setLearnerUsers(data.users);
    }
  };

  const learnerOptions = useMemo(
    () =>
      learnerUsers.map((el) => {
        const { first_name, last_name, id } = el;
        return { value: id, label: `${first_name} ${last_name}` };
      }),
    [learnerUsers]
  );

  if (loading) {
    return (
      <Row justify='center' align='middle' style={{ height: '90vh' }}>
        <Col>
          <SpinnerComponent fontSize={50} />;
        </Col>
      </Row>
    );
  } else if (npoDetails !== null) {
    return (
      <div className='page-top-div'>
        <span
          className='text-span'
          style={{
            lineHeight: '17px',
          }}
        >
          NPO - {pathname?.split('/')[2]}
        </span>
        <GraphSection />
        <TableSection
          statusCheckList={statusCheckList}
          setStatusCheckList={setStatusCheckList}
          statusOptions={statusOptions}
          learnersTypesCheckList={learnersTypesCheckList}
          setLearnersTypesCheckList={setLearnersTypesCheckList}
          learnersTypesOptions={learnersTypesOptions}
          siteCheckList={siteCheckList}
          setSiteCheckList={setSiteCheckList}
          siteOptions={siteOptions}
          npoCheckList={npoCheckList}
          setNpoCheckList={setNpoCheckList}
          npoOptions={npoOptions}
          learnerCheckList={learnerCheckList}
          setLearnerCheckList={setLearnerCheckList}
          learnerOptions={learnerOptions}
          name={pathname?.split('/')[2]}
          npoDetails={npoDetails}
        />
      </div>
    );
  }
};

export default NPOsLearners;
