import React, { useEffect, useState } from "react";
import ProjectStatusesTable from "./activityTypesTable";
import { useSelector } from "react-redux";

const ProjectStatuses = () => {
  const { user } = useSelector((state) => state.authReducer);
  const [canUpdate, setCanUpdate] = useState(false);

  useEffect(() => {
    if (user?.permissions?.project_statuses?.add_edit) {
      setCanUpdate(true);
    }
  }, [user]);

  return (
    <div className="page-top-div">
      <span className="text-span">Project Statuses</span>
      <ProjectStatusesTable canUpdate={canUpdate} />
    </div>
  );
};

export default ProjectStatuses;
