import moment from 'moment';

export const filterData = ({ values, classes, setData }) => {
  let dateFilteredData = classes.filter((entry) => {
    let dateLength = values?.program_date?.length;
    if (dateLength > 0) {
      let date = moment(entry.program_date)
        .format('DD MMMM YY')
        .toLocaleLowerCase();
      let progDate = values?.program_date?.toLocaleLowerCase();
      return date.includes(progDate);
    } else return classes;
  });

  let siteFilteredData = dateFilteredData.filter((entry) =>
    values?.site_id?.length > 0
      ? entry.site.name
          .toLocaleLowerCase()
          .includes(values?.site_id?.toLocaleLowerCase())
      : dateFilteredData
  );

  let coachFilteredData = siteFilteredData.filter((entry) => {
    let coachInputLength = values?.user_id?.length;
    if (coachInputLength > 0) {
      const { first_name, last_name } = entry.coach;
      let name = `${first_name} ${last_name}`;
      return name
        .toLocaleLowerCase()
        .includes(values?.user_id?.toLocaleLowerCase());
    } else return siteFilteredData;
  });

  let classFilteredData = coachFilteredData.filter((entry) => {
    let inputLength = values?.name?.length;
    if (inputLength > 0) {
      const { name } = entry;

      return name
        .toLocaleLowerCase()
        .includes(values?.name?.toLocaleLowerCase());
    } else return coachFilteredData;
  });

  let topicFilteredData = classFilteredData.filter((entry) => {
    let length = values?.topic?.length;
    if (length > 0) {
      const { topic } = entry;
      return topic
        .toLocaleLowerCase()
        .includes(values?.topic?.toLocaleLowerCase());
    } else return classFilteredData;
  });

  let npoFilteredData = topicFilteredData.filter((entry) => {
    let length = values?.npo_id?.length;
    if (length > 0) {
      const { name } = entry.npo;
      return name
        .toLocaleLowerCase()
        .includes(values?.npo_id?.toLocaleLowerCase());
    } else return topicFilteredData;
  });

  let typeFilteredData = npoFilteredData.filter((entry) => {
    let length = values?.type?.length;
    if (length > 0) {
      const { name } = entry.type;
      return name
        .toLocaleLowerCase()
        .includes(values?.type?.toLocaleLowerCase());
    } else return npoFilteredData;
  });

  let interventionFilteredData = typeFilteredData.filter((entry) => {
    let length = values?.intervention?.length;
    if (length > 0) {
      return entry?.intervention?.name
        .toLocaleLowerCase()
        .includes(values?.intervention?.toLocaleLowerCase());
    } else return typeFilteredData;
  });

  let manualFilteredData = interventionFilteredData.filter((entry) => {
    let length = values?.manual?.length;
    if (length > 0) {
      return entry?.manual?.name
        .toLocaleLowerCase()
        .includes(values?.manual?.toLocaleLowerCase());
    } else return interventionFilteredData;
  });

  let mktPreFiltered = manualFilteredData.filter((entry) => {
    let length = values?.mkt_pre?.length;
    if (length > 0) {
      let input = values?.mkt_pre?.toString();
      const { mkt_pre } = entry;
      return mkt_pre.toString().includes(input);
    } else return manualFilteredData;
  });

  let mktPostFiltered = mktPreFiltered.filter((entry) => {
    let length = values?.mkt_post?.length;
    if (length > 0) {
      let input = values?.mkt_post?.toString();
      const { mkt_post } = entry;
      return mkt_post.toString().includes(input);
    } else return mktPreFiltered;
  });

  let sesPreFiltered = mktPostFiltered.filter((entry) => {
    let length = values?.ses_pre?.length;
    if (length > 0) {
      let input = values?.ses_pre?.toString();
      const { ses_pre } = entry;
      return ses_pre.toString().includes(input);
    } else return mktPostFiltered;
  });

  let sesPostFiltered = sesPreFiltered.filter((entry) => {
    let length = values?.ses_post?.length;
    if (length > 0) {
      let input = values?.ses_post?.toString();
      const { ses_post } = entry;
      return ses_post.toString().includes(input);
    } else return sesPreFiltered;
  });

  let leqPreFiltered = sesPostFiltered.filter((entry) => {
    let length = values?.leq_pre?.length;
    if (length > 0) {
      let input = values?.leq_pre?.toString();
      const { leq_pre } = entry;
      return leq_pre.toString().includes(input);
    } else return sesPostFiltered;
  });

  let leqPostFiltered = leqPreFiltered.filter((entry) => {
    let length = values?.leq_post?.length;
    if (length > 0) {
      let input = values?.leq_post?.toString();
      const { leq_post } = entry;
      return leq_post.toString().includes(input);
    } else return leqPreFiltered;
  });

  let totalAttFiltered = leqPostFiltered.filter((entry) => {
    let length = values?.total_attendance?.length;
    if (length > 0) {
      let input = values?.total_attendance?.toString();
      const { total_attendance } = entry;
      return total_attendance.toString().includes(input);
    } else return leqPostFiltered;
  });

  let avgAgeFiltered = totalAttFiltered.filter((entry) => {
    let length = values?.average_age?.length;
    if (length > 0) {
      let input = values?.average_age?.toString();
      const { average_age } = entry;
      return average_age.toString().includes(input);
    } else return totalAttFiltered;
  });

  setData(avgAgeFiltered);
};
