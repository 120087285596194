import axios from "axios";
import { message as AntdAlert } from "antd";
import { put } from "redux-saga/effects";
import * as actions from "../actions";
import { baseURL } from "../../../../globalUtils/axiosConfig";
import { errorHandler } from "../../../../components/unauthorizeHandler";
import moment from "moment";

export function* handleFetchActivities({ data }) {
  const { search, ...rest } = data;
  // const { project_id } = data;

  Object.keys(search).forEach((key) => {
    if (search[key]?.length < 1 || search[key] === null) delete search[key];
  });

  let searchDatesKeys = [
    "end_date",
    "document_start_date",
    "document_end_date",
    "document_due_data",
    "document_due_end_data",
  ];

  let params = { ...rest, ...(Object.keys(search).length > 0 && { search }) };

  const fd = new FormData();

  for (const [key, value] of Object.entries(params)) {
    if (value === null || value === undefined) {
    } else if (key === "template_field" && value.length > 0) {
      value.forEach((ele) => {
        for (const [key1, value1] of Object.entries(ele)) {
          if (key1.includes("date")) {
            fd.append(
              `template_field[][${key1}]`,
              moment(value1).format("DD MMM YYYY")
            );
          } else {
            fd.append(`template_field[][${key1}]`, value1);
          }
        }
      });
    } else if (key === "distributions" || key === "revisions") {
      value.forEach((ele) => {
        for (const [key1, value1] of Object.entries(ele)) {
          if (key1.includes("date")) {
            fd.append(
              `${key}[][${key1}]`,
              moment(value1).format("DD MMM YYYY")
            );
          } else {
            fd.append(`${key}[][${key1}]`, value1);
          }
        }
      });
    } else if (key === "search") {
      for (const [key2, value2] of Object.entries(value)) {
        // console.log("key2, value2", key2, value2);
        if (["document_template_id", "document_status_id"].includes(key2)) {
          value2.forEach((ele) => {
            fd.append(`${key}[${key2}][]`, ele);
          });
        } else if (searchDatesKeys.includes(key2)) {
          fd.append(`search[${key2}]`, moment(value2).format("DD MMM YYYY"));
        } else {
          fd.append(`search[${key2}]`, value2);
        }
      }
    } else if (value !== null && value !== undefined) {
      fd.append(key, value);
    }
  }

  try {
    // const response = yield axios.get(
    //   `${baseURL}/api/v1/document_control/document_control_graph`,
    //   {
    //     params: {
    //       ...rest,
    //       ...(Object.keys(search).length > 0 && { search }),
    //       project_id,
    //     },
    //     // params: { project_id },
    //   }
    // );
    const url = `${baseURL}/api/v1/document_control/document_control_graph?${new URLSearchParams(
      fd
    ).toString()}`;

    const response = yield axios.get(url);
    const { success } = response.data;

    if (success) {
      yield put(actions.fetchProjectActivitiesSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchProjectDocs({ data }) {
  const { onPermissionError, search, ...rest } = data;

  Object.keys(search).forEach((key) => {
    if (search[key]?.length < 1 || search[key] === null) delete search[key];
  });

  let searchDatesKeys = [
    "end_date",
    "document_start_date",
    "document_end_date",
    "document_due_data",
    "document_due_end_data",
  ];

  let params = { ...rest, ...(Object.keys(search).length > 0 && { search }) };

  const fd = new FormData();

  for (const [key, value] of Object.entries(params)) {
    // console.log("key,value", key, value);
    if (value === null || value === undefined) {
    } else if (key === "template_field" && value.length > 0) {
      value.forEach((ele) => {
        for (const [key1, value1] of Object.entries(ele)) {
          if (key1.includes("date")) {
            fd.append(
              `template_field[][${key1}]`,
              moment(value1).format("DD MMM YYYY")
            );
          } else {
            fd.append(`template_field[][${key1}]`, value1);
          }
        }
      });
    } else if (key === "distributions" || key === "revisions") {
      value.forEach((ele) => {
        for (const [key1, value1] of Object.entries(ele)) {
          if (key1.includes("date")) {
            fd.append(
              `${key}[][${key1}]`,
              moment(value1).format("DD MMM YYYY")
            );
          } else {
            fd.append(`${key}[][${key1}]`, value1);
          }
        }
      });
    } else if (key === "search") {
      for (const [key2, value2] of Object.entries(value)) {
        // console.log("key2, value2", key2, value2);
        if (["document_template_id", "document_status_id"].includes(key2)) {
          value2.forEach((ele) => {
            fd.append(`${key}[${key2}][]`, ele);
          });
        } else if (searchDatesKeys.includes(key2)) {
          fd.append(`search[${key2}]`, moment(value2).format("DD MMM YYYY"));
        } else {
          fd.append(`search[${key2}]`, value2);
        }
      }
    } else if (value !== null && value !== undefined) {
      fd.append(key, value);
    }
  }

  try {
    const url = `${baseURL}/api/v1/document_control?${new URLSearchParams(
      fd
    ).toString()}`;

    const response = yield axios.get(url);
    const { success } = response.data;

    if (success) {
      const { permissions } = response.data.data;
      if (permissions?.document_control?.view) {
        yield put(actions.fetchProjectDocsSuccess(response.data.data));
      } else {
        return onPermissionError();
      }
    } else if (!success) {
      yield put(actions.fetchProjectDocsError());
      errorHandler(response.data);
    }
  } catch (error) {
    AntdAlert.error("Something went wrong!");
    yield put(actions.fetchProjectDocsError());
    console.log(error);
  }
}

export function* handleBatchUpdate({ data }) {
  let searchDatesKeys = [
    "end_date",
    "document_start_date",
    "document_end_date",
  ];

  const fd = new FormData();

  for (const [key, value] of Object.entries(data)) {
    if (value === null || value === undefined) {
    } else if (key === "document_control" && value.length > 0) {
      // console.log("value", value);
      value.forEach((ele) => {
        fd.append(`${key}[]`, ele);
      });
    } else if (key === "template_field" && value.length > 0) {
      value.forEach((ele) => {
        for (const [key1, value1] of Object.entries(ele)) {
          if (key1.includes("date")) {
            fd.append(
              `template_field[][${key1}]`,
              moment(value1).format("DD MMM YYYY")
            );
          } else {
            fd.append(`template_field[][${key1}]`, value1);
          }
        }
      });
    } else if (key === "defaultField" && value.length > 0) {
      value.forEach((ele) => {
        for (const [key1, value1] of Object.entries(ele)) {
          if ([0, 4].includes(key1)) {
            fd.append(
              `defaultField[][${key1}]`,
              moment(value1).format("DD MMM YYYY")
            );
          } else {
            fd.append(`defaultField[][${key1}]`, value1);
          }
        }
      });
    } else if (key === "distributions" || key === "revisions") {
      value.forEach((ele) => {
        for (const [key1, value1] of Object.entries(ele)) {
          if (key1.includes("date")) {
            fd.append(`${key}[${key1}]`, moment(value1).format("DD MMM YYYY"));
          } else {
            fd.append(`${key}[${key1}]`, value1);
          }
        }
      });
    } else if (key === "search") {
      for (const [key2, value2] of Object.entries(value)) {
        // console.log("key2, value2", key2, value2);
        if (["document_template_id", "document_status_id"].includes(key2)) {
          value2.forEach((ele) => {
            fd.append(`${key}[${key2}][]`, ele);
          });
        } else if (searchDatesKeys.includes(key2)) {
          fd.append(`search[${key2}]`, moment(value2).format("DD MMM YYYY"));
        } else {
          fd.append(`search[${key2}]`, value2);
        }
      }
    } else if (key === "revisions_ids" || key === "distributions_ids") {
      if (value?.length > 0) {
        value.map((id) => fd.append(`${key}[]`, id));
      }
    } else if (value !== null && value !== undefined) {
      fd.append(key, value);
    }
  }

  try {
    const url = `${baseURL}/api/v1/document_control/batch_update?${new URLSearchParams(
      fd
    ).toString()}`;

    const response = yield axios.post(url);
    const { success, message } = response.data;

    if (success) {
      yield put(actions.fetchProjectBatchUpdateSuccess(response.data.data));
      AntdAlert.success(message);
    } else if (!success) {
      yield put(actions.fetchProjectBatchUpdateError());
      AntdAlert.error(message || "Something went wrong!");
      errorHandler(response.data);
    }
  } catch (error) {
    AntdAlert.error("Something went wrong!");
    yield put(actions.fetchProjectBatchUpdateError());
    console.log(error);
  }
}

export function* handleFetchActivitiesUtilsData({ data }) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v1/sales_activities/search_lists`
    );
    const { success } = response.data;

    if (success) {
      yield put(
        actions.fetchProjectActivitiesSearchUtilsSuccess(response.data.data)
      );
    } else if (!success) {
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateActivity({ data }) {
  const { id, debouncedDefaultPageOneCallApi, ...res } = data;

  const fd = new FormData();
  for (const [key, value] of Object.entries(res)) {
    if (key === "images" && value !== undefined) {
      Array.from(value).forEach((image) => {
        const { isLocal } = image;
        if (isLocal) {
          fd.append(`attachments[]`, image);
        }
        // else fd.append("image_ids[]", key);
      });
    } else if (key === "field_values") {
      Object.entries(value).map((entry, i) => {
        // if (
        //   entry[1] !== null &&
        //   entry[1] !== undefined
        //   //  && entry[1]?.length > 0
        // ) {
        fd.append(`field_values[][id]`, entry[0]);
        fd.append(`field_values[][value]`, entry[1]);
        // }
      });
    } else if (value !== null && value !== undefined) {
      fd.append(key, value);
    }
  }

  try {
    const response = yield axios.put(
      `${baseURL}/api/v1/document_control/${id}`,
      fd,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateProjectActivitySuccess(response.data.data));
      debouncedDefaultPageOneCallApi();
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateProjectActivityError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateProjectActivityError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleDeleteProjectDoc({ data }) {
  const { id, onDeleteSuccess } = data;
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v1/document_control/${id}`
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteProjectDocSuccess(response.data.data));
      onDeleteSuccess();
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteProjectDocError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteProjectDocError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleCreateProjectDoc({ data }) {
  const fd = new FormData();

  const { debouncedDefaultPageOneCallApi, ...res } = data;

  for (const [key, value] of Object.entries(res)) {
    if (key === "images" && value !== undefined) {
      Array.from(value).forEach((image) => {
        fd.append(`attachments[]`, image);
      });
    } else if (key === "field_values") {
      Object.entries(value).map((entry, i) => {
        // if (
        //   entry[1] !== null &&
        //   entry[1] !== undefined &&
        //   entry[1]?.length > 0
        // ) {
        fd.append(`field_values[][id]`, entry[0]);
        fd.append(`field_values[][value]`, entry[1]);
        // }
      });
    } else if (value !== null && value !== undefined) {
      fd.append(key, value);
    }
  }

  try {
    const response = yield axios.post(
      `${baseURL}/api/v1/document_control`,
      fd,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createProjectDocSuccess(response.data.data));
      debouncedDefaultPageOneCallApi();
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createProjectDocError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.createProjectDocError());
    AntdAlert.error("Something went wrong!");
  }
}

export function* handleDeleteProjectDocAttach({ data }) {
  const { key, recordId } = data;
  let params = {
    id: key,
  };

  try {
    const response = yield axios.delete(
      `${baseURL}/api/v1/document_control/delete_document_attachment`,
      { params }
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(
        actions.deleteProjectDocAttachSuccess({
          recordId,
          imgItem: response.data.data,
        })
      );
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteProjectDocAttachError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteProjectDocAttachError(response.data.data));
    AntdAlert.error("Something went wrong!");
  }
}

export function* handleAddActivityAttach({ data }) {
  const { setCurrentUpdateObj } = data;

  const { id, images } = data.data;

  const fd = new FormData();
  Array.from(images).forEach((image) => {
    const { isLocal } = image;
    if (isLocal) {
      fd.append(`attachments[]`, image);
    }
  });

  try {
    const response = yield axios.patch(
      `${baseURL}/api/v1/document_control/${id}`,
      fd,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateProjectActivitySuccess(response.data.data));
      setCurrentUpdateObj(response.data.data);
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateProjectActivityError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateProjectActivityError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}
