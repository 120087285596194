import { takeLatest, all, takeEvery } from 'redux-saga/effects';
import * as usersManager from '../modules/UserManager/redux/sagas/handleUserManager';
import * as constants from '../modules/UserManager/redux/constants';
import * as departConstants from '../modules/DepartmentEmails/redux/constants';
import * as departEmails from '../modules/DepartmentEmails/redux/sagas/handleDepartEmails';
import * as departmentsConstants from '../modules/ActivityTypes/redux/constants';
import * as departments from '../modules/ActivityTypes/redux/sagas/handleActivityTypes';
import * as prodStatuses from '../modules/ProjectStatuses/redux/sagas/handleActivityTypes';
import * as prodStatusesConstants from '../modules/ProjectStatuses/redux/constants';
import * as handleSales from '../modules/Sales/redux/sagas/handleSales';
import * as handleSalesConstants from '../modules/Sales/redux/constants';
import * as sites from '../modules/Sites/redux/sagas/sagas';
import * as sitesConstants from '../modules/Sites/redux/constants';
import * as documentControlSagas from '../modules/DocumentControl/redux/sagas/documentControlSagas';
import * as documentConstants from '../modules/DocumentControl/redux/constants';
import * as authSagas from '../modules/login/redux/sagas/handleAuth';
import * as authConstants from '../modules/login/redux/constants';
import * as productionSagas from '../modules/Production/redux/sagas/handleProduction';
import * as productionConstants from '../modules/Production/redux/constants';
import * as npoSetupSagas from '../modules/NpoSetup/redux/sagas/index';
import * as npoSetupConstants from '../modules/NpoSetup/redux/constants';
import * as learnerStatuses from '../modules/LearnerStatuses/redux/sagas/handleRevision';
import * as learnerConstants from '../modules/LearnerStatuses/redux/learnerConstants';
import * as docStatusesSagas from '../modules/DocumentStatuses/redux/sagas/handleDocStatuses';
import * as docStatusesConstants from '../modules/DocumentStatuses/redux/constants';
import * as documentfields from '../modules/DefaultDocumentFields/redux/sagas/handleActivityTypes';
import * as documentfieldConstants from '../modules/DefaultDocumentFields/redux/constants';
import * as interventionTypesSagas from '../modules/InterventionTypesManager/redux/sagas/index';
import * as interventionTypesConstants from '../modules/InterventionTypesManager/redux/constants';
import * as sitesClassesSagas from '../modules/Sites/tableSection/classes/redux/sagas/sagas';
import * as sitesClassesConstants from '../modules/Sites/tableSection/classes/redux/constants';
import * as imsDocDistributionSagas from '../modules/Sites/tableSection/distributionTable/redux/sagas/imsDistributionSagas';
import * as imsDocDistributionConstants from '../modules/Sites/tableSection/distributionTable/redux/constants';
import * as projects from '../modules/Projects/redux/sagas/projectSagas';
import * as projectsConstants from '../modules/Projects/redux/constants';
import * as projectDocRevisionsSagas from '../modules/Projects/tableSection/revisions/redux/sagas/projectRevisionsSagas';
import * as projectDocRevisionsConstants from '../modules/Projects/tableSection/revisions/redux/constants';
import * as projectDocDistributionSagas from '../modules/Projects/tableSection/distributionTable/redux/sagas/projectDistributionSagas';
import * as projectDocDistributionConstants from '../modules/Projects/tableSection/distributionTable/redux/constants';
import * as notificationsSagas from '../modules/container/notifications/notificationsRedux/saga';
import * as notificationsConstants from '../modules/container/notifications/notificationsRedux/constant';

import * as templatesManagerSagas from '../modules/TemplatesManager/redux/sagas/index';
import * as templatesManagerConstants from '../modules/TemplatesManager/redux/constants';

import * as programmeTypes from '../modules/ProgrammeTypes/redux/sagas/index';
import * as programmeTypesConstants from '../modules/ProgrammeTypes/redux/constants';

import * as npoStatuses from '../modules/NpoStatuses/redux/sagas/index';
import * as npoConstants from '../modules/NpoStatuses/redux/constants';
import * as npoRegions from '../modules/NpoRegions/redux/sagas/index';
import * as npoRegionsConstants from '../modules/NpoRegions/redux/constants';

import * as siteStatuses from '../modules/SiteStatuses/redux/sagas/index';
import * as siteConstants from '../modules/SiteStatuses/redux/constants';
import * as siteTypes from '../modules/SiteTypes/redux/sagas/index';
import * as siteTypesConstants from '../modules/SiteTypes/redux/constants';

import * as coachStatuses from '../modules/CoachStatuses/redux/sagas/handleCoachStatuses';
import * as coachConstants from '../modules/CoachStatuses/redux/constants';
import * as coachTypes from '../modules/CoachTypes/redux/sagas/index';
import * as coachTypesConstants from '../modules/CoachTypes/redux/constants';

import * as learnerCamps from '../modules/LearnerCamps/redux/sagas/index';
import * as learnerCampsConstants from '../modules/LearnerCamps/redux/constants';
import * as learnerTypes from '../modules/LearnerTypes/redux/sagas/index';
import * as learnerTypesConstants from '../modules/LearnerTypes/redux/constants';
import { watcherClassesSaga } from '../modules/Classes/redux/sagas/sagas';
import { watcherLearnersSaga } from '../modules/learners/redux/sagas/sagas';
import { watcherCoachesSaga } from '../modules/coaches/redux/sagas/sagas';
// import { watcherClassesLearnersSaga } from '../modules/Classes/tableSection/learners/redux/sagas/sagas';

function* watcherSaga() {
  yield takeEvery(
    documentConstants.CREATE_DOCUMENT_CONTROL,
    documentControlSagas.handleCreateDocument
  );
  yield takeEvery(
    documentConstants.DELETE_DOCUMENT_CONTROL,
    documentControlSagas.handleDeleteDocumentAttach
  );
  yield takeEvery(
    documentConstants.FETCH_DOCUMENT_CONTROL,
    documentControlSagas.handleFetchDocument
  );
  yield takeEvery(
    documentConstants.FETCH_DOCUMENT_CONTROL_SEARCH_DATA,
    documentControlSagas.handleFetchDocumentUtilsData
  );
  yield takeEvery(
    documentConstants.UPDATE_DOCUMENT_CONTROL,
    documentControlSagas.handleUpdateDocument
  );
  yield takeEvery(
    documentConstants.ADD_DOCUMENT_CONTROL_ATTACH,
    documentControlSagas.handleAddDocumentAttach
  );
  yield takeEvery(
    documentConstants.DELETE_DOCUMENT_CONTROL_ATTACH,
    documentControlSagas.handleDeleteDocumentAttach
  );

  yield takeLatest(
    templatesManagerConstants.FETCH_ALL_TEMPLATES,
    templatesManagerSagas.handleFetchAllTemplates
  );
  yield takeLatest(
    templatesManagerConstants.FETCH_TEMPLATE,
    templatesManagerSagas.handleFetchTemplate
  );
  yield takeLatest(
    templatesManagerConstants.UPDATE_TEMPLATE,
    templatesManagerSagas.handleUpdateTemplates
  );

  yield takeLatest(
    interventionTypesConstants.FETCH_ALL_INTERVENTION_TYPES,
    interventionTypesSagas.handleFetchAllInterventionTypes
  );
  yield takeLatest(
    interventionTypesConstants.FETCH_INTERVENTION_TYPE,
    interventionTypesSagas.handleFetchInterventionType
  );
  yield takeLatest(
    interventionTypesConstants.CREATE_INTERVENTION_TYPE,
    interventionTypesSagas.handleCreateInterventionType
  );
  yield takeLatest(
    interventionTypesConstants.UPDATE_INTERVENTION_TYPE,
    interventionTypesSagas.handleUpdateInterventionTypes
  );
  yield takeLatest(
    interventionTypesConstants.DELETE_INTERVENTION_TYPE,
    interventionTypesSagas.handleDeleteInterventionType
  );
  yield takeLatest(
    prodStatusesConstants.FETCH_PROJECT_STATUSES,
    prodStatuses.handleFetchProjectStatuses
  );
  yield takeLatest(
    prodStatusesConstants.UPDATE_PROJECT_STATUSES,
    prodStatuses.handleUpdateProjectStatuses
  );
  yield takeLatest(
    prodStatusesConstants.DELETE_PROJECT_STATUSES,
    prodStatuses.handleDeleteProjectStatuses
  );
  yield takeLatest(
    prodStatusesConstants.CREATE_PROJECT_STATUSES,
    prodStatuses.handleCreateProjectStatuses
  );

  yield takeLatest(
    prodStatusesConstants.UPDATE_ORDER,
    prodStatuses.handleUpdateProjectOrder
  );

  yield takeLatest(
    docStatusesConstants.FETCH_DOC_STATUSES,
    docStatusesSagas.handleFetchDocStatuses
  );
  yield takeLatest(
    docStatusesConstants.UPDATE_DOC_STATUSES,
    docStatusesSagas.handleUpdateDocStatuses
  );

  yield takeLatest(
    docStatusesConstants.UPDATE_DOC_COMPLETED_STATUS,
    docStatusesSagas.handleUpdateDocCompletedStatuses
  );

  yield takeLatest(
    docStatusesConstants.DELETE_DOC_STATUSES,
    docStatusesSagas.handleDeleteDocStatuses
  );
  yield takeLatest(
    docStatusesConstants.CREATE_DOC_STATUSES,
    docStatusesSagas.handleCreateDocStatuses
  );

  yield takeLatest(
    docStatusesConstants.UPDATE_DOC_STATUS_ORDER,
    docStatusesSagas.handleUpdateDocOrder
  );

  yield takeLatest(constants.CREATE_USER, usersManager.handleCreateUser);
  yield takeLatest(constants.FETCH_ALL_USERS, usersManager.handleFetchAllUsers);
  yield takeLatest(
    constants.FETCH_USERS_UTIL_DATA,
    usersManager.handleFetchUserDetailsUtils
  );
  yield takeEvery(constants.DELETE_USER, usersManager.handleDeleteUser);

  yield takeLatest(constants.SAVE_DEPARTMENT, usersManager.handleAddDepartment);
  yield takeLatest(constants.FETCH_USER, usersManager.handleFetchUser);
  yield takeEvery(constants.UPDATE_USER, usersManager.handleUpdateUser);
  yield takeEvery(
    constants.UPDATE_USER_SETTINGS,
    usersManager.handleUpdateUserSettings
  );
  yield takeLatest(
    constants.SEND_DETAILS_TO_USER,
    usersManager.handleSendDetailsToUser
  );

  yield takeLatest(
    constants.CREATE_USER_PROJECT,
    usersManager.handleCreateProject
  );
  yield takeLatest(
    constants.UPDATE_USER_PROJECT,
    usersManager.handleUpdateUserProject
  );
  yield takeLatest(
    constants.DELETE_USER_PROJECT,
    usersManager.handleDeleteUserProject
  );

  yield takeLatest(
    programmeTypesConstants.FETCH_PROGRAMME_TYPES,
    programmeTypes.handleFetchProgrammeTypes
  );
  yield takeLatest(
    programmeTypesConstants.CREATE_PROGRAMME_TYPES,
    programmeTypes.handleCreateProgrammeTypes
  );
  yield takeLatest(
    programmeTypesConstants.UPDATE_PROGRAMME_TYPES,
    programmeTypes.handleUpdateProgrammeTypes
  );
  yield takeLatest(
    programmeTypesConstants.UPDATE_ORDER_PROGRAMME_TYPES,
    programmeTypes.handleUpdateProgrammeOrder
  );
  yield takeLatest(
    programmeTypesConstants.DELETE_PROGRAMME_TYPES,
    programmeTypes.handleDeleteProgrammeTypes
  );

  yield takeLatest(
    npoRegionsConstants.FETCH_NPO_REGIONS,
    npoRegions.handleFetchNpoRegions
  );
  yield takeLatest(
    npoRegionsConstants.CREATE_NPO_REGIONS,
    npoRegions.handleCreateNpoRegions
  );
  yield takeLatest(
    npoRegionsConstants.UPDATE_NPO_REGIONS,
    npoRegions.handleUpdateNpoRegions
  );
  yield takeLatest(
    npoRegionsConstants.UPDATE_ORDER_NPO_REGIONS,
    npoRegions.handleUpdateSiteOrder
  );
  yield takeLatest(
    npoRegionsConstants.DELETE_NPO_REGIONS,
    npoRegions.handleDeleteNpoRegions
  );

  yield takeLatest(
    npoConstants.FETCH_NPO_STATUSES,
    npoStatuses.handleFetchNpoStatuses
  );
  yield takeLatest(
    npoConstants.CREATE_NPO_STATUSES,
    npoStatuses.handleCreateNpoStatuses
  );
  yield takeLatest(
    npoConstants.UPDATE_NPO_STATUSES,
    npoStatuses.handleUpdateNpoStatuses
  );
  yield takeLatest(
    npoConstants.UPDATE_ORDER_NPO_STATUS,
    npoStatuses.handleUpdateNpoOrder
  );
  yield takeLatest(
    npoConstants.DELETE_NPO_STATUSES,
    npoStatuses.handleDeleteNpoStatuses
  );

  yield takeLatest(
    siteTypesConstants.FETCH_SITE_TYPES,
    siteTypes.handleFetchSiteTypes
  );
  yield takeLatest(
    siteTypesConstants.CREATE_SITE_TYPES,
    siteTypes.handleCreateSiteTypes
  );
  yield takeLatest(
    siteTypesConstants.UPDATE_SITE_TYPES,
    siteTypes.handleUpdateSiteTypes
  );
  yield takeLatest(
    siteTypesConstants.UPDATE_ORDER_SITE_TYPES,
    siteTypes.handleUpdateSiteOrder
  );
  yield takeLatest(
    siteTypesConstants.DELETE_SITE_TYPES,
    siteTypes.handleDeleteSiteTypes
  );

  yield takeLatest(
    siteConstants.FETCH_SITE_STATUSES,
    siteStatuses.handleFetchSiteStatuses
  );
  yield takeLatest(
    siteConstants.CREATE_SITE_STATUSES,
    siteStatuses.handleCreateSiteStatuses
  );
  yield takeLatest(
    siteConstants.UPDATE_SITE_STATUSES,
    siteStatuses.handleUpdateSiteStatuses
  );
  yield takeLatest(
    siteConstants.UPDATE_ORDER_SITE_STATUS,
    siteStatuses.handleUpdateSiteOrder
  );
  yield takeLatest(
    siteConstants.DELETE_SITE_STATUSES,
    siteStatuses.handleDeleteSiteStatuses
  );

  yield takeLatest(
    coachTypesConstants.FETCH_COACH_TYPES,
    coachTypes.handleFetchCoachTypes
  );
  yield takeLatest(
    coachTypesConstants.CREATE_COACH_TYPES,
    coachTypes.handleCreateCoachTypes
  );
  yield takeLatest(
    coachTypesConstants.UPDATE_COACH_TYPES,
    coachTypes.handleUpdateCoachTypes
  );
  yield takeLatest(
    coachTypesConstants.UPDATE_ORDER_COACH_TYPES,
    coachTypes.handleUpdateCoachOrder
  );
  yield takeLatest(
    coachTypesConstants.DELETE_COACH_TYPES,
    coachTypes.handleDeleteCoachTypes
  );

  yield takeLatest(
    coachConstants.FETCH_COACH_STATUSES,
    coachStatuses.handleFetchCoachStatuses
  );
  yield takeLatest(
    coachConstants.CREATE_COACH_STATUSES,
    coachStatuses.handleCreateCoachStatuses
  );
  yield takeLatest(
    coachConstants.UPDATE_COACH_STATUSES,
    coachStatuses.handleUpdateCoachStatuses
  );
  yield takeLatest(
    coachConstants.UPDATE_ORDER_COACH_STATUS,
    coachStatuses.handleUpdateCoachOrder
  );
  yield takeLatest(
    coachConstants.DELETE_COACH_STATUSES,
    coachStatuses.handleDeleteCoachStatuses
  );

  yield takeLatest(
    learnerTypesConstants.FETCH_LEARNER_TYPES,
    learnerTypes.handleFetchLearnerTypes
  );
  yield takeLatest(
    learnerTypesConstants.CREATE_LEARNER_TYPES,
    learnerTypes.handleCreateLearnerTypes
  );
  yield takeLatest(
    learnerTypesConstants.UPDATE_LEARNER_TYPES,
    learnerTypes.handleUpdateLearnerTypes
  );
  yield takeLatest(
    learnerTypesConstants.UPDATE_ORDER_LEARNER_TYPES,
    learnerTypes.handleUpdateLearnerOrder
  );
  yield takeLatest(
    learnerTypesConstants.DELETE_LEARNER_TYPES,
    learnerTypes.handleDeleteLearnerTypes
  );

  yield takeLatest(
    learnerConstants.FETCH_LEARNER_STATUSES,
    learnerStatuses.handlefetchLearnerStatuses
  );
  yield takeLatest(
    learnerConstants.CREATE_LEARNER_STATUSES,
    learnerStatuses.handlecreateLearnerStatuses
  );
  yield takeLatest(
    learnerConstants.UPDATE_LEARNER_STATUSES,
    learnerStatuses.handleupdateLearnerStatuses
  );
  yield takeLatest(
    learnerConstants.UPDATE_ORDER_LEARNER_STATUS,
    learnerStatuses.handleUpdateLearnerOrder
  );
  yield takeLatest(
    learnerConstants.DELETE_LEARNER_STATUSES,
    learnerStatuses.handledeleteLearnerStatuses
  );

  yield takeLatest(
    learnerCampsConstants.FETCH_LEARNER_CAMPS,
    learnerCamps.handleFetchLearnerCamps
  );
  yield takeLatest(
    learnerCampsConstants.CREATE_LEARNER_CAMPS,
    learnerCamps.handleCreateLearnerCamps
  );
  yield takeLatest(
    learnerCampsConstants.UPDATE_LEARNER_CAMPS,
    learnerCamps.handleUpdateLearnerCamps
  );
  yield takeLatest(
    learnerCampsConstants.UPDATE_ORDER_LEARNER_CAMPS,
    learnerCamps.handleUpdateLearnerOrder
  );
  yield takeLatest(
    learnerCampsConstants.DELETE_LEARNER_CAMPS,
    learnerCamps.handleDeleteLearnerCamps
  );

  yield takeLatest(
    departConstants.FETCH_DEPART_EMAILS,
    departEmails.handleFetchDepartEmails
  );
  yield takeLatest(
    departConstants.UPDATE_DEPART_EMAIL,
    departEmails.handleUpdateDepartEmail
  );
  yield takeLatest(
    departmentsConstants.FETCH_DEPARTMENTS,
    departments.handleFetchDepartments
  );
  yield takeEvery(
    departmentsConstants.UPDATE_DEPARTMENTS,
    departments.handleUpdateDepartment
  );
  yield takeEvery(
    departmentsConstants.DELETE_DEPARTMENTS,
    departments.handleDeleteDepartments
  );
  yield takeEvery(
    departmentsConstants.CREATE_DEPARTMENTS,
    departments.handleCreateDepartment
  );
  yield takeLatest(
    constants.UPDATE_USER_PERMISSIONS,
    usersManager.handleUpdateUserPermissions
  );
  yield takeLatest(
    handleSalesConstants.FETCH_SALES,
    handleSales.handleFetchSalesData
  );
  yield takeLatest(
    handleSalesConstants.FETCH_SALES_SEARCH_UTILS,
    handleSales.handleFetchSalesUtilsData
  );
  yield takeLatest(
    handleSalesConstants.FETCH_SALES_SECOND_LEVEL,
    handleSales.handleFetchSalesSecondLevelData
  );
  yield takeLatest(
    handleSalesConstants.FETCH_SALES_GRAPH,
    handleSales.handleFetchSalesGraphData
  );

  yield takeEvery(sitesConstants.FETCH_IMS_DOCS, sites.handleFetchImsDocs);
  yield takeEvery(sitesConstants.FETCH_BATCH_UPDATE, sites.handleBatchUpdate);
  yield takeEvery(sitesConstants.CREATE_IMS_DOC, sites.handleCreateImsDoc);
  yield takeEvery(sitesConstants.DELETE_IMS_DOC, sites.handleDeleteImsDoc);
  yield takeEvery(sitesConstants.FETCH_ACTIVITIES, sites.handleFetchActivities);
  yield takeEvery(
    sitesConstants.FETCH_ACTIVITIES_SEARCH_DATA,
    sites.handleFetchActivitiesUtilsData
  );
  yield takeEvery(sitesConstants.UPDATE_ACTIVITY, sites.handleUpdateActivity);
  yield takeEvery(
    sitesConstants.ADD_IMS_DOC_ATTACH,
    sites.handleAddActivityAttach
  );
  yield takeEvery(
    sitesConstants.DELETE_IMS_DOC_ATTACH,
    sites.handleDeleteImsDocAttach
  );

  yield takeLatest(
    productionConstants.FETCH_PROD_SEARCH_UTILS,
    productionSagas.handleFetchProdSearchUtils
  );

  yield takeLatest(
    productionConstants.FETCH_PROD_FIRST_LEVEL,
    productionSagas.handleFetchProdFirstLevel
  );
  yield takeLatest(
    productionConstants.FETCH_PROD_GRAPH_DATA,
    productionSagas.handleFetchProdGraphData
  );
  yield takeLatest(
    productionConstants.FIRST_LEVEL_ADD_COMMENT,
    productionSagas.handleAddFirstLevelComment
  );
  yield takeEvery(
    productionConstants.ADD_PRODUCTION_ATTACH,
    productionSagas.handleAddProdAttach
  );
  yield takeEvery(
    productionConstants.DELETE_PROD_ATTACH,
    productionSagas.handleDeleteProdAttach
  );
  yield takeLatest(
    productionConstants.FETCH_PROD_SECOND_LEVEL,
    productionSagas.handleFetchProdSecondLevel
  );

  yield takeEvery(
    productionConstants.CREATE_SECOND_LEVEL,
    productionSagas.handleCreateProdSecondLevel
  );

  yield takeEvery(
    productionConstants.UPDATE_SECOND_LEVEL,
    productionSagas.handleUpdateProdSecondLevel
  );

  yield takeLatest(
    productionConstants.FETCH_PROD_THIRD_LEVEL,
    productionSagas.handleFetchProdThirdLevel
  );

  yield takeLatest(
    productionConstants.FETCH_PROD_FOURTH_LEVEL,
    productionSagas.handleFetchProdFourthLevel
  );

  yield takeEvery(
    productionConstants.VERIFY_PIN,
    productionSagas.handleVerifyPin
  );

  yield takeEvery(
    productionConstants.UPDATE_OPERATOR,
    productionSagas.handleUpdateOperator
  );

  yield takeEvery(
    productionConstants.STOP_OPERATOR,
    productionSagas.handleStopOperator
  );

  yield takeEvery(
    productionConstants.ADD_OPERATOR,
    productionSagas.handleAddOperator
  );

  yield takeEvery(authConstants.LOGIN, authSagas.handleLoginUser);
  yield takeEvery(authConstants.LOGOUT, authSagas.handleLogoutUser);
  yield takeEvery(
    authConstants.FETCH_SIDEBAR_PROJECTS,
    authSagas.handleFetchSidebarProjects
  );

  yield takeLatest(
    npoSetupConstants.FETCH_ALL_NPOS,
    npoSetupSagas.handleFetchAllNpos
  );

  yield takeLatest(npoSetupConstants.FETCH_NPO, npoSetupSagas.handleFetchNpo);
  yield takeLatest(npoSetupConstants.CREATE_NPO, npoSetupSagas.handleCreateNpo);
  yield takeLatest(npoSetupConstants.UPDATE_NPO, npoSetupSagas.handleUpdateNpo);
  yield takeLatest(npoSetupConstants.DELETE_NPO, npoSetupSagas.handleDeleteNpo);
  yield takeLatest(
    npoSetupConstants.DELETE_NPO_ATTACH,
    npoSetupSagas.handleDeleteNpoAttach
  );
  yield takeLatest(
    npoSetupConstants.UPDATE_NPO_NOTIFICATION,
    npoSetupSagas.handleUpdateNpoNotifications
  );
  yield takeLatest(
    constants.FETCH_ALL_USER_PROJECTS,
    usersManager.handleFetchAllUserProjects
  );
  yield takeLatest(
    documentfieldConstants.FETCH_DOCUMENT_FIELDS,
    documentfields.handleFetchDocumentField
  );
  yield takeLatest(
    documentfieldConstants.UPDATE_DOCUMENT_FIELDS,
    documentfields.handleUpdateDocumentField
  );
  yield takeEvery(
    sitesClassesConstants.FETCH_IMS_REVISIONS,
    sitesClassesSagas.handleFetchImsRevisions
  );
  yield takeEvery(
    sitesClassesConstants.CREATE_IMS_REVISIONS,
    sitesClassesSagas.handleCreateImsRevision
  );
  yield takeEvery(
    sitesClassesConstants.UPDATE_IMS_REVISIONS,
    sitesClassesSagas.handleUpdateImsRevision
  );
  yield takeEvery(
    sitesClassesConstants.DELETE_IMS_REVISIONS,
    sitesClassesSagas.handleDeleteImsRevision
  );
  yield takeEvery(
    sitesClassesConstants.DELETE_IMS_REVISIONS_ATTACH,
    sitesClassesSagas.handleDeleteImsRevisionAttach
  );
  yield takeEvery(
    sitesClassesConstants.ADD_IMS_REVISIONS_ATTACH,
    sitesClassesSagas.handleAddImsRevisionAttach
  );

  yield takeLatest(
    imsDocDistributionConstants.FETCH_IMS_DISTRIBUTION,
    imsDocDistributionSagas.handleFetchImsDistribution
  );
  yield takeEvery(
    imsDocDistributionConstants.CREATE_IMS_DISTRIBUTION,
    imsDocDistributionSagas.handleCreateImsDistribution
  );
  yield takeEvery(
    imsDocDistributionConstants.UPDATE_IMS_DISTRIBUTION,
    imsDocDistributionSagas.handleUpdateImsDistribution
  );
  yield takeEvery(
    imsDocDistributionConstants.DELETE_IMS_DISTRIBUTION,
    imsDocDistributionSagas.handleDeleteImsDistribution
  );
  yield takeEvery(
    imsDocDistributionConstants.DELETE_IMS_DISTRIBUTION_ATTACH,
    imsDocDistributionSagas.handleDeleteImsDistributionAttach
  );
  yield takeEvery(
    imsDocDistributionConstants.ADD_IMS_DISTRIBUTION_ATTACH,
    imsDocDistributionSagas.handleAddImsDistributionAttach
  );

  yield takeLatest(
    projectsConstants.FETCH_PROJECT_DOCS,
    projects.handleFetchProjectDocs
  );
  yield takeEvery(
    projectsConstants.FETCH_PROJECT_BATCH_UPDATE,
    projects.handleBatchUpdate
  );
  yield takeEvery(
    projectsConstants.CREATE_PROJECT_DOC,
    projects.handleCreateProjectDoc
  );
  yield takeEvery(
    projectsConstants.DELETE_PROJECT_DOC,
    projects.handleDeleteProjectDoc
  );
  yield takeEvery(
    projectsConstants.FETCH_PROJECT_ACTIVITIES,
    projects.handleFetchActivities
  );
  yield takeEvery(
    projectsConstants.FETCH_PROJECT_ACTIVITIES_SEARCH_DATA,
    projects.handleFetchActivitiesUtilsData
  );
  yield takeEvery(
    projectsConstants.UPDATE_PROJECT_ACTIVITY,
    projects.handleUpdateActivity
  );
  yield takeEvery(
    projectsConstants.ADD_PROJECT_DOC_ATTACH,
    projects.handleAddActivityAttach
  );
  yield takeEvery(
    projectsConstants.DELETE_PROJECT_DOC_ATTACH,
    projects.handleDeleteProjectDocAttach
  );

  yield takeEvery(
    projectDocRevisionsConstants.FETCH_PROJECT_REVISIONS,
    projectDocRevisionsSagas.handleFetchProjectRevisions
  );
  yield takeEvery(
    projectDocRevisionsConstants.CREATE_PROJECT_REVISIONS,
    projectDocRevisionsSagas.handleCreateProjectRevision
  );
  yield takeEvery(
    projectDocRevisionsConstants.UPDATE_PROJECT_REVISIONS,
    projectDocRevisionsSagas.handleUpdateProjectRevision
  );
  yield takeEvery(
    projectDocRevisionsConstants.DELETE_PROJECT_REVISIONS,
    projectDocRevisionsSagas.handleDeleteProjectRevision
  );
  yield takeEvery(
    projectDocRevisionsConstants.DELETE_PROJECT_REVISIONS_ATTACH,
    projectDocRevisionsSagas.handleDeleteProjectRevisionAttach
  );
  yield takeEvery(
    projectDocRevisionsConstants.ADD_PROJECT_REVISIONS_ATTACH,
    projectDocRevisionsSagas.handleAddProjectRevisionAttach
  );

  yield takeEvery(
    projectDocDistributionConstants.FETCH_PROJECT_DISTRIBUTION,
    projectDocDistributionSagas.handleFetchProjectDistribution
  );
  yield takeEvery(
    projectDocDistributionConstants.CREATE_PROJECT_DISTRIBUTION,
    projectDocDistributionSagas.handleCreateProjectDistribution
  );
  yield takeEvery(
    projectDocDistributionConstants.UPDATE_PROJECT_DISTRIBUTION,
    projectDocDistributionSagas.handleUpdateProjectDistribution
  );
  yield takeEvery(
    projectDocDistributionConstants.DELETE_PROJECT_DISTRIBUTION,
    projectDocDistributionSagas.handleDeleteProjectDistribution
  );
  yield takeEvery(
    projectDocDistributionConstants.DELETE_PROJECT_DISTRIBUTION_ATTACH,
    projectDocDistributionSagas.handleDeleteProjectDistributionAttach
  );
  yield takeEvery(
    projectDocDistributionConstants.ADD_PROJECT_DISTRIBUTION_ATTACH,
    projectDocDistributionSagas.handleAddProjectDistributionAttach
  );
  yield takeLatest(
    notificationsConstants.FETCH_NOTIFICATIONS_LIST,
    notificationsSagas.handleFetchAllNotifications
  );
  yield takeLatest(
    notificationsConstants.FETCH_NOTIFICATIONS,
    notificationsSagas.handleFetchUnreadNotificationCount
  );
  yield takeLatest(
    notificationsConstants.UPDATE_NOTIFICATION,
    notificationsSagas.handleUpdateNotification
  );
}

export function* rootSaga() {
  yield all([
    watcherSaga(),
    watcherClassesSaga(),
    // watcherClassesLearnersSaga(),
    watcherLearnersSaga(),
    watcherCoachesSaga(),
  ]);
}
