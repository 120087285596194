import React, { useRef } from 'react';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  message as AntdAlert,
  Popconfirm,
  Popover,
  Row,
} from 'antd';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import { ReactComponent as AttachIcon } from '../../../assets/attach.svg';
import { baseURL } from '../../../globalUtils/axiosConfig';
import { useDispatch } from 'react-redux';
import {
  deleteImsDocAttach,
  deleteImsDocAttachSuccess,
} from '../redux/actions';
import UsePermissions from '../usePermissions';
import axios from 'axios';

const UseAttachments = ({
  images,
  setImages,
  attachPopupVisible,
  setAttachPopupVisible,
  currentUpdateObj,
  shouldDisabled,
  isRevisionUpdateCase,
  record,
}) => {
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const [, canEdit, , canDelete] = UsePermissions();

  const handleDeleteImage = async ({ key, isLocal }) => {
    if (isLocal) {
      let filteredImages = images.filter((image) => image.key !== key);
      setImages(filteredImages);
    } else {
      const res = await axios.delete(
        `${baseURL}/api/v1/sites/delete_attachment?id=${key}`
      );

      const { success, message } = res.data;
      if (success) {
        AntdAlert.success(message);
        let filteredImages = images.filter((image) => image.key !== key);
        setImages(filteredImages);
        dispatch(
          deleteImsDocAttachSuccess({
            recordId: currentUpdateObj.id,
            key,
          })
        );
      } else {
        AntdAlert.error('Something went wrong!');
      }
    }
  };
  const handleImage = (imgObj, e) => {
    e.currentTarget.value = null;

    const isValid =
      /\.(doc|docx|xls|xlsx|ppt|pptx|pdf|jpg|jpeg|gif|png|mp4|)$/i.test(
        imgObj.name
      );

    if (isValid) {
      imgObj['key'] = Math.round(Math.random() * 1000000);
      imgObj['isLocal'] = true;
      return imgObj ? setImages([...images, imgObj]) : null;
    } else {
      AntdAlert.error('This file format is not supported.');
    }
  };

  const handleShowImage = (image) => {
    let url = image.isLocal ? URL.createObjectURL(image) : image.url;
    window.open(url, '_blank');
  };

  const hide = () => {
    !shouldDisabled && setAttachPopupVisible(false);
  };

  return (
    <Popover
      placement='bottom'
      overlayClassName='activities-attach-popover'
      getPopupContainer={() =>
        document.getElementsByClassName(
          'ant-layout-content layout-content site-layout-background custom-textarea'
        )[0]
      }
      content={
        <>
          <Row>
            <Col span={24}>
              <div className='close-icon-div'>
                <CloseOutlined onClick={hide} />
              </div>
            </Col>
            {images?.map((el) => {
              const { name, key } = el;
              return (
                <Col span={24} key={key}>
                  <Row justify='start' align='middle'>
                    <Col span={3}>
                      <AttachIcon
                        className='rotate-icon activities-attach'
                        width={16}
                        fill='#231f20'
                      />
                    </Col>
                    <Col span={18}>
                      <span
                        className='name-span'
                        onClick={() => handleShowImage(el)}
                      >
                        {name}
                      </span>
                    </Col>
                    {canDelete && (
                      <Col span={3}>
                        <Popconfirm
                          overlayClassName='activities-popconfirm'
                          title='Are you sure to delete this attachment?'
                          onConfirm={() => handleDeleteImage(el)}
                          okText='Yes'
                          cancelText='No'
                        >
                          <DeleteIcon
                            fill='#E21C21'
                            width={18}
                            height={18}
                            className='del-icon'
                          />
                        </Popconfirm>
                      </Col>
                    )}
                  </Row>
                </Col>
              );
            })}
          </Row>
          {canEdit && !isRevisionUpdateCase && (
            <>
              <Button
                icon={<PlusOutlined />}
                className='add-attach-btn'
                size='middle'
                onClick={() => inputFile.current.click()}
              >
                Attachment
              </Button>
              <input
                type='file'
                id='file'
                multiple
                accept=''
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={(e) => handleImage(e.target.files[0], e)}
              />
            </>
          )}
        </>
      }
      title={null}
      trigger='click'
      visible={attachPopupVisible}
      onVisibleChange={(visible) =>
        !shouldDisabled && setAttachPopupVisible(visible)
      }
    >
      <div
        className='attach-parent-div'
        onClick={() => !shouldDisabled && setAttachPopupVisible(true)}
      >
        <Button size='small'>Upload</Button>
      </div>
    </Popover>
  );
};

export default UseAttachments;
