import * as constants from './constants';

const initialState = {
  allUsers: [],
  currentUserObj: {
    permissions: {
      ims: { add_edit: false, view: false, export: false, delete: false },
      npo_setup: {
        add_edit: false,
        view: false,
        export: false,
        delete: false,
      },
      default_doc_fields: { add_edit: false, view: false },
      departments: { add_edit: false, view: false },
      doc_types_manager: { add_edit: false, view: false },
      document_statuses: { add_edit: false, view: false },
      project_statuses: { add_edit: false, view: false },
      revision_statuses: { add_edit: false, view: false },
      user_manager: { add_edit: false, view: false },
    },
  },
  user_projects: [],
  createProjectLoading: false,
  currentProjectObj: {},
  ProjectDetailsIsEdit: false,
  fetchProjectsLoading: false,
  deleteProjectLoading: false,
  utilsData: {},
  departmentsList: [],
  createUserLoading: false,
  userDetailsIsEdit: false,
  userPermissionsIsEdit: false,
  departmentIsEdit: false,
  fetchUsersLoading: false,
  deleteUserLoading: false,
  departmentLoading: false,
  settingsLoading: false,
  sendDetailLoading: false,
  fetchUserDetailLoading: false,
  settingsUpdatingKey: {
    popup_notifications: false,
    email_notifications: false,
  },
  departItemsIndex: null,
  totalRecords: 0,
  popupLoading: false,
  emailLoading: false,
  permissionsLoading: false,
  isEditProjectPermissions: false,
};

const userManagerReducer = (state = initialState, action) => {
  console.log('action', action);
  switch (action.type) {
    case constants.SET_DEPART_DISABLE_INDEX:
      return {
        ...state,
        departItemsIndex: action.data,
      };
    case constants.FETCH_USERS_UTIL_DATA_SUCCESS:
      const { sales_reps } = action.data;
      const sortedSalesrep = sales_reps.sort((a, b) => a.staffid - b.staffid);
      return {
        ...state,
        utilsData: {
          ...action.data,
          sales_reps: [
            { staffid: ' ', listname: 'Unselect' },
            ...sortedSalesrep,
          ],
        },
      };
    case constants.SET_USER_PROJECT_PERMISSIONS_IS_EDIT:
      return {
        ...state,
        isEditProjectPermissions: action.data,
      };
    case constants.USER_DETAILS_IS_EDIT:
      return {
        ...state,
        userDetailsIsEdit: action.data,
      };
    case constants.SET_USER_PERMISSIONS_IS_EDIT:
      return {
        ...state,
        userPermissionsIsEdit: action.data,
      };
    case constants.CREATE_USER:
      return {
        ...state,
        createUserLoading: true,
      };
    case constants.CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserLoading: false,
        currentUserObj: action.data,
        userDetailsIsEdit: false,
        departmentIsEdit: true,
      };
    case constants.CREATE_USER_ERROR:
      return {
        ...state,
        createUserLoading: false,
      };
    case constants.UPDATE_USER:
      return {
        ...state,
        createUserLoading: true,
      };
    case constants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        createUserLoading: false,
        currentUserObj: action.data,
        userDetailsIsEdit: false,
      };
    case constants.UPDATE_USER_ERROR:
      return {
        ...state,
        createUserLoading: false,
      };
    case constants.UPDATE_USER_PERMISSIONS:
      return {
        ...state,
        permissionsLoading: true,
      };
    case constants.UPDATE_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissionsLoading: false,
        currentUserObj: action.data,
        userPermissionsIsEdit: false,
      };
    case constants.UPDATE_USER_PERMISSIONS_ERROR:
      return {
        ...state,
        permissionsLoading: false,
      };
    case constants.ADD_USER_IN_MANAGER:
      return {
        ...state,
        createUserLoading: true,
      };
    case constants.FETCH_ALL_USERS:
      return {
        ...state,
        fetchUsersLoading: true,
      };
    case constants.FETCH_ALL_USERS_SUCCESS:
      const { total_users, users } = action.data;
      return {
        ...state,
        allUsers: users,
        fetchUsersLoading: false,
        totalRecords: total_users,
      };
    case constants.FETCH_ALL_USERS_ERROR:
      return {
        ...state,
        fetchUsersLoading: false,
      };
    case constants.DELETE_USER:
      return {
        ...state,
        deleteUserLoading: true,
      };
    case constants.DELETE_USER_SUCCESS:
      let updatedUsers = state.allUsers.filter(
        (el) => el.id !== action.data.id
      );
      let totalRecords = state.totalRecords - 1;
      return {
        ...state,
        totalRecords: totalRecords,
        deleteUserLoading: false,
        allUsers: updatedUsers,
      };
    case constants.DELETE_USER_ERROR:
      return {
        ...state,
        deleteUserLoading: false,
      };
    case constants.SAVE_DEPARTMENT:
      return {
        ...state,
        departmentLoading: true,
        departmentIsEdit: true,
      };
    case constants.SAVE_DEPARTMENT_SUCCESS:
      // let updatedUsers = state.allUsers.filter(
      //   (el) => el.id !== action.data.id
      // );
      const { departments } = action.data;
      return {
        ...state,
        departmentLoading: false,
        departmentIsEdit: false,
        currentUserObj: {
          ...state.currentUserObj,
          departments,
          departments_with_steps: departments,
        },
        // allUsers: updatedUsers,
      };
    case constants.SAVE_DEPARTMENT_ERROR:
      return {
        ...state,
        departmentLoading: false,
        departmentIsEdit: false,
      };
    case constants.CREATE_USER_PROJECT:
      return {
        ...state,
        createProjectLoading: true,
      };
    case constants.CREATE_USER_PROJECT_SUCCESS:
      const newData = [action.data, ...state.user_projects];
      return {
        ...state,
        createProjectLoading: false,
        user_projects: newData,
        ProjectDetailsIsEdit: false,
        ProjectIsEdit: false,
        isEditProjectPermissions: false,
      };
    case constants.CREATE_USER_PROJECT_ERROR:
      return {
        ...state,
        createProjectLoading: false,
      };

    case constants.DELETE_USER_PROJECT:
      return {
        ...state,
        createProjectLoading: true,
      };
    case constants.DELETE_USER_PROJECT_SUCCESS:
      const { shouldLSUpdate } = action.data;
      // console.log("reducer shouldLSUpdate", shouldLSUpdate);
      // let user = JSON.parse(localStorage.getItem("user"));
      const filteredProjects = state.user_projects.filter(
        (el) => el.id !== action.data.id
      );
      // console.log("filteredProjects", filteredProjects);
      let lselectedProjects = JSON.parse(
        localStorage.getItem('lsselectedProjects')
      );

      let lfilteredProjects = JSON.parse(
        localStorage.getItem('lsfilteredProjects')
      );

      let lisCheck = JSON.parse(localStorage.getItem('lsisCheck'));
      let llist = JSON.parse(localStorage.getItem('lslist'));
      if (
        filteredProjects.length >= 0 &&
        lselectedProjects !== null &&
        shouldLSUpdate
      ) {
        if (filteredProjects.length === 0) {
          console.log('filteredProjects.length', filteredProjects);
          localStorage.removeItem('lsselectedProjects');
          localStorage.removeItem('lsfilteredProjects');
          localStorage.removeItem('lsisCheck');
          localStorage.removeItem('lslist');
        } else {
          // if (filteredProjects[0].user_id !== user.id) {
          let userPojectsIds = filteredProjects.map((el) => el.id); // npo_id
          let updatedlsselectedProjects = lselectedProjects.filter((el) =>
            userPojectsIds.includes(el.id)
          );
          let updatedLsfilteredProjects = lfilteredProjects.filter((el) =>
            userPojectsIds.includes(el.id)
          );

          let updatedlsisCheck = lisCheck.filter((id) =>
            userPojectsIds.includes(id)
          );
          let updatedlslist = llist.filter((el) =>
            userPojectsIds.includes(el.id)
          );

          console.log('lsselectedProjects', updatedlsselectedProjects);
          // const setToLocalStorage = (selectedProjects) => {

          localStorage.setItem(
            'lsselectedProjects',
            JSON.stringify(updatedlsselectedProjects)
          );
          localStorage.setItem(
            'lsfilteredProjects',
            JSON.stringify(updatedLsfilteredProjects)
          );

          localStorage.setItem('lsisCheck', JSON.stringify(updatedlsisCheck));
          localStorage.setItem('lslist', JSON.stringify(updatedlslist));
          // };
          // }
        }
      }
      return {
        ...state,
        createProjectLoading: false,
        user_projects: filteredProjects,
        ProjectDetailsIsEdit: false,
        ProjectIsEdit: false,
        isEditProjectPermissions: false,
      };
    case constants.DELETE_USER_PROJECT_ERROR:
      return {
        ...state,
        createProjectLoading: false,
        isEditProjectPermissions: false,
      };
    case constants.UPDATE_USER_PROJECT:
      return {
        ...state,
        createProjectLoading: true,
      };
    case constants.UPDATE_USER_PROJECT_SUCCESS:
      const updatedUserProjects = state.user_projects.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );

      return {
        ...state,
        createProjectLoading: false,
        user_projects: updatedUserProjects,
        isEditProjectPermissions: false,
      };
    case constants.UPDATE_USER_PROJECT_ERROR:
      return {
        ...state,
        createProjectLoading: false,
      };
    case constants.FETCH_ALL_USER_PROJECTS:
      return {
        ...state,
        fetchProjectsLoading: true,
      };

    case constants.FETCH_ALL_USER_PROJECTS_SUCCESS:
      let { projects, user_id } = action.data;

      // const { user_projects } = action.data;
      let lsselectedProjects = JSON.parse(
        localStorage.getItem('lsselectedProjects')
      );

      let lsfilteredProjects = JSON.parse(
        localStorage.getItem('lsfilteredProjects')
      );

      let lsisCheck = JSON.parse(localStorage.getItem('lsisCheck'));
      let lslist = JSON.parse(localStorage.getItem('lslist'));
      // console.log('projects', projects);
      // console.log('lsselectedProjects', lsselectedProjects);
      if (projects.length >= 0 && lsselectedProjects !== null) {
        if (projects.length > 0 && user_id == projects[0].user_id) {
          let userPojectsIds = projects.map((el) => el.id); // npo_id
          // console.log("userPojectsIds", userPojectsIds);
          let updatedlsselectedProjects = lsselectedProjects.filter((el) =>
            userPojectsIds.includes(el.id)
          );
          // console.log('lsselectedProjects', updatedlsselectedProjects);
          let updatedLsfilteredProjects = lsfilteredProjects.filter((el) =>
            userPojectsIds.includes(el.id)
          );
          // console.log("updatedLsfilteredProjects", updatedLsfilteredProjects);
          let updatedlsisCheck = lsisCheck.filter((id) =>
            userPojectsIds.includes(id)
          );
          // console.log("updatedlsisCheck", updatedlsisCheck);
          let updatedlslist = lslist.filter((el) =>
            userPojectsIds.includes(el.id)
          );
          // console.log("updatedlslist", updatedlslist);

          // const setToLocalStorage = (selectedProjects) => {
          localStorage.setItem(
            'lsselectedProjects',
            JSON.stringify(updatedlsselectedProjects)
          );
          localStorage.setItem(
            'lsfilteredProjects',
            JSON.stringify(updatedLsfilteredProjects)
          );

          localStorage.setItem('lsisCheck', JSON.stringify(updatedlsisCheck));
          localStorage.setItem('lslist', JSON.stringify(updatedlslist));
          // };
        }
      }

      return {
        ...state,
        fetchProjectsLoading: false,
        user_projects: projects,
      };
    case constants.FETCH_ALL_USER_PROJECTS_ERROR:
      return {
        ...state,
        fetchProjectsLoading: false,
      };
    case constants.SET_EXPANDED_ROW_KEYS:
      return {};
    case constants.FETCH_USER:
      return {
        ...state,
        // departmentLoading: true,
        fetchUserDetailLoading: true,
      };
    case constants.FETCH_USER_SUCCESS:
      return {
        ...state,
        currentUserObj: action.data,
        fetchUserDetailLoading: false,
      };
    case constants.FETCH_USER_ERROR:
      return {
        ...state,
        fetchUserDetailLoading: false,
      };
    case constants.SEND_DETAILS_TO_USER:
      return {
        ...state,
        sendDetailLoading: true,
      };
    case constants.SEND_DETAILS_TO_USER_SUCCESS:
      return {
        ...state,
        sendDetailLoading: false,
      };
    case constants.SEND_DETAILS_TO_USER_ERROR:
      return {
        ...state,
        sendDetailLoading: false,
      };
    case constants.UPDATE_USER_SETTINGS:
      const { data, loadingObj } = action.data;
      const { id, ...res } = data;
      return {
        ...state,
        settingsLoading: true,
        settingsUpdatingKey: { ...state.settingsUpdatingKey, ...res },
        ...loadingObj,
      };
    case constants.UPDATE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        currentUserObj: { ...state.currentUserObj, ...action.data.data },
        // settingsUpdatingKey: { ...state.settingsUpdatingKey, ...res },
        settingsLoading: false,
        ...action.data.loadingObj,
      };
    case constants.UPDATE_USER_SETTINGS_ERROR:
      return {
        ...state,
        settingsLoading: false,
        currentClientObj: {
          ...state.currentClientObj,
        },
      };
    case constants.USER_DETAIL_CLEANUP_FUNC:
      return {
        ...state,
        allUsers: [],
        createUserLoading: false,
        currentUserObj: {},
        user_projects: [],
        userDetailsIsEdit: false,
        fetchUsersLoading: false,
        deleteUserLoading: false,
        createProjectLoading: false,
        currentProjectObj: {},
        ProjectDetailsIsEdit: false,
        fetchProjectsLoading: false,
        deleteProjectLoading: false,
        departmentLoading: false,
        departmentIsEdit: false,
        departmentsList: [],
        departItemsIndex: null,
        isEditProjectPermissions: false,
      };
    default:
      return state;
  }
};

export default userManagerReducer;
