import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put } from 'redux-saga/effects';
import * as actions from '../actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';

export function* handleFetchNpoRegions({ data }) {
  try {
    const response = yield axios.get(`${baseURL}/api/v1/npo_regions`, {});
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchNpoRegionsSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchNpoRegionsError());
    AntdAlert.error('Something went wrong!');
    console.log(error);
  }
}

export function* handleUpdateNpoRegions({ data }) {
  const { position, id, name } = data;

  try {
    const response = yield axios.put(`${baseURL}/api/v1/npo_regions/${id}`, {
      name,
      position,
    });
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateNpoRegionsSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateNpoRegionsError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateNpoRegionsError(response.data.data));
    AntdAlert.error('Something went wrong!');
  }
}

export function* handleDeleteNpoRegions({ data }) {
  const { id } = data;
  try {
    const response = yield axios.delete(`${baseURL}/api/v1/npo_regions/${id}`);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteNpoRegionsSuccess(id));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteNpoRegionsError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteNpoRegionsError(response.data.data));
    AntdAlert.error('Something went wrong!');
  }
}

export function* handleCreateNpoRegions({ data }) {
  try {
    const newData = JSON.parse(JSON.stringify(data));
    delete newData.id;
    const response = yield axios.post(`${baseURL}/api/v1/npo_regions`, {
      ...newData,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createNpoRegionsSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createNpoRegionsError(data.id));
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateSiteOrder({ data }) {
  const fd = new FormData();
  data.map((el, i) => fd.append(`npo_regions[${el.id}]`, i + 1));

  try {
    const response = yield axios.patch(
      `${baseURL}/api/v1/npo_regions/sort`,
      fd
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateNpoRegionsOrderSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateNpoRegionsOrderError());
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateNpoRegionsOrderError());
    AntdAlert.error('Something went wrong!');
  }
}
