import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import './index.less';
import { useDispatch, useSelector } from 'react-redux';
import { setNpoDetailsIsEdit } from './redux/actions';
import Table from './Table';
import { PlusOutlined } from '@ant-design/icons';
import { fetchNpoStatuses } from '../NpoStatuses/redux/actions';
import { fetchNpoRegions } from '../NpoRegions/redux/actions';
import ExportComponent from './export';
import Tooltip from '../../components/tooltip-Custom';

const NpoSetup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const [canUpdate, setCanUpdate] = useState(false);

  useEffect(() => {
    if (user?.permissions?.npo_setup?.add_edit) {
      setCanUpdate(true);
    }
  }, [user]);

  useEffect(() => {
    dispatch(fetchNpoStatuses());
    dispatch(fetchNpoRegions());

  }, []);

  return (
    <div className='page-top-div'>
      <span className='text-span'>NPO Setup</span>
      <Row className='production-manager-parent-row'>
        <Col span={24} className='heading-col'>
          <Row align='middle' gutter={[50, 0]} justify='space-between'>
            <Col>
              <Row align='middle' gutter={[80, 0]}>
                <Col>
                  <div>
                    <span className='heading-span'>NPO Register</span>
                  </div>
                </Col>
                <Col>
                  {canUpdate && (
                    <Button
                      onClick={() => {
                        navigate('/npo-setup/create');
                        dispatch(setNpoDetailsIsEdit(true));
                      }}
                      icon={<PlusOutlined />}
                      className='add-user-btn'
                      size='middle'
                      style={{
                        width: '105%',
                      }}
                    >
                      NPO
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
            <Col>
              {user?.permissions?.npo_setup?.export && (
                <Tooltip text='Export'>
                  <ExportComponent />
                </Tooltip>
              )}
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Table
            canUpdate={canUpdate}
            canDelete={user?.permissions?.npo_setup?.delete || false}
          />
        </Col>
      </Row>
    </div>
  );
};

export default NpoSetup;
