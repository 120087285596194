import { useState, useEffect } from 'react';
import { Col, Modal, Row, Button, Checkbox } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSidebarProject } from '../../../login/redux/actions';
import CheckboxComponent from './checkbox';

const SelectProjectsPopup = ({
  visible,
  setVisible,
  setSelectedProjects,
  selectedProjects,
}) => {
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [showAll, setShowAll] = useState(false);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  const dispatch = useDispatch();
  const { projects, user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    const filtered = projects.filter((el) => {
      let showByDefault = el?.npo?.status?.show_by_default;
      return showByDefault && el?.is_deleted === false;
    });
    setFilteredProjects(filtered);

    setList(filtered);
  }, [projects]);

  useEffect(() => {
    if (isCheck.length > 0 && isCheck.length === list.length) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
  }, [isCheck, list]);

  useEffect(() => {
    let ls = localStorage.getItem('lsselectedProjects');
    if (ls !== null) {
      setSelectedProjects(JSON.parse(ls));
      setTimeout(() => {
        getFromLocalStorage();
      }, 2000);
    }
  }, []);

  useEffect(() => {
    // getFromLocalStorage();
  }, [selectedProjects]);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const handleShowAll = (e) => {
    const { checked } = e.target;
    setShowAll(checked);

    let updatedList = checked ? projects : filteredProjects;
    setList(updatedList);

    // setIsCheck([...isCheck, id]);
    // if (!checked) {
    //   setIsCheck(isCheck.filter((item) => item !== id));
    // }
  };

  useEffect(() => {
    dispatch(fetchSidebarProject({ user_id: user.id }));

    return () => {
      setShowAll(false);
    };
  }, [visible]);

  const handleOk = () => {};

  const handleCancel = () => {
    setVisible(false);
  };

  const setToLocalStorage = (selectedProjects) => {
    localStorage.setItem(
      'lsselectedProjects',
      JSON.stringify(selectedProjects)
    );
    localStorage.setItem(
      'lsfilteredProjects',
      JSON.stringify(filteredProjects)
    );

    localStorage.setItem('lsisCheck', JSON.stringify(isCheck));
    localStorage.setItem('lslist', JSON.stringify(list));
  };

  const getFromLocalStorage = () => {
    setFilteredProjects(
      JSON.parse(localStorage.getItem('lsfilteredProjects')) || []
    );
    setIsCheck(JSON.parse(localStorage.getItem('lsisCheck')) || []);
    setList(JSON?.parse(localStorage.getItem('lslist')) || []);
  };

  const handleApplyBtn = () => {
    let selectedProjects = projects.filter((el) => isCheck.includes(el.id));
    setSelectedProjects(selectedProjects);
    setVisible(false);
    setToLocalStorage(selectedProjects);
  };

  const ModalBody = () => {
    const catalog = list.map((el) => {
      const { id, npo } = el;
      const { name } = npo;

      return (
        <Col span={24} className='checkbox-col'>
          <CheckboxComponent
            key={id}
            name={name}
            id={id}
            handleClick={handleClick}
            isChecked={isCheck.includes(id)}
          />
          <span>{name}</span>
        </Col>
      );
    });

    return (
      <Row className='checkbox-parent-div' gutter={[0, 10]}>
        {catalog}
      </Row>
    );
  };

  const Footer = () => {
    return (
      <Row justify='center' gutter={[20, 0]}>
        <Col>
          <Button className='apply-btn' size='small' onClick={handleApplyBtn}>
            Apply
          </Button>
        </Col>
        <Col>
          <Button className='cancel-btn' size='small' onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    );
  };

  const Title = () => {
    return (
      <Row>
        <Col span={24}>
          <Row justify='start' gutter={[20, 0]} className='header-row'>
            <Col span={12}>
              <div className='checkbox-div'>
                <Checkbox
                  name='selectAll'
                  id='selectAll'
                  onChange={handleSelectAll}
                  checked={isCheckAll}
                />
                <span>Select All</span>
              </div>
            </Col>
            <Col>
              <div className='checkbox-div'>
                <Checkbox
                  id='showAll'
                  name='showAll'
                  onChange={handleShowAll}
                  checked={showAll}
                />
                <span>Show All NPO's</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Modal
        className='select-projects-modal'
        title={<Title />}
        visible={visible}
        width={393}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={<Footer />}
        closeIcon={<></>}
      >
        <ModalBody />
      </Modal>
    </>
  );
};

export default SelectProjectsPopup;
