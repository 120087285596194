import React from 'react';
import { Form, Input, Select, InputNumber } from 'antd';
import ImageComponent from './useAttachments';
import { useSelector } from 'react-redux';

const { Option } = Select;

let nonRequired = [
  'images',
  'no',
  'first_term',
  'second_term',
  'third_term',
  'fourth_term',
  'mkt_pre',
  'mkt_post',
  'ses_pre',
  'ses_post',
  'leq_pre',
  'leq_post',
];

const RevisionsEditableCell = ({
  cell,
  images,
  setImages,
  attachPopupVisible,
  setAttachPopupVisible,
  currentUpdateObj,
  handleChangeUser,
  learnerUsers,
  allLearners,
  inputEls,
  isColUpdate,
}) => {
  const {
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  } = cell;

  let inputNode;

  const { allLearnerCamps } = useSelector((state) => state.learnerCampsReducer);

  switch (dataIndex) {
    case 'no':
    case 'demographic':
      inputNode = (
        <Input
          readOnly
          className='border-less-input'
          placeholder=''
          style={{
            textTransform: 'capitalize',
          }}
        />
      );
      break;

    case 'first_name':
      inputNode = (
        <Select
          showSearch
          className='activities-selector'
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          style={{
            width: '100px',
          }}
          dropdownClassName='select-dropdown-custom'
          placeholder='Select Learner'
          size='small'
          optionFilterProp='children'
          filterOption={(input, option) =>
            option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={(e, option) => {
            handleChangeUser(e, option);
          }}
        >
          {learnerUsers.map(
            ({ id, first_name, last_name, id_number, ...rest }) => {
              const ids = allLearners.map((el) => el.user_id);
              return (
                <Option
                  disabled={ids.includes(id)}
                  key={id}
                  value={id}
                  first_name={first_name}
                  last_name={last_name}
                  id_number={id_number}
                  {...rest}
                >
                  {first_name + ' ' + last_name + ' - ' + id_number}
                  {ids.includes(id) && '(Added)'}
                </Option>
              );
            }
          )}
        </Select>
      );
      break;
    case 'last_name':
      inputNode = (
        <Input
          placeholder=''
          className='border-less-input'
          size='small'
          readOnly
        />
      );
      break;
    case 'received_by':
      inputNode = <Input placeholder='Received By' size='small' />;
      break;
    case 'first_term':
    case 'second_term':
    case 'third_term':
    case 'fourth_term':
    case 'attendance':
    case 'mkt_pre':
    case 'mkt_post':
    case 'ses_pre':
    case 'ses_post':
    case 'leq_pre':
    case 'leq_post':
      inputNode = isColUpdate ? (
        <InputNumber
          ref={(el) => (inputEls[record.id] = el?.value)}
          placeholder=''
          size='small'
          min={0}
          max={100}
          value={inputEls[record.id]}
          defaultValue={inputEls[record.id]}
        />
      ) : (
        <InputNumber placeholder='' size='small' min={0} max={100} />
      );
      break;
    case 'learner_camp_id':
      inputNode = (
        <Select
          showSearch
          //   className="activities-selector"
          getPopupContainer={() =>
            document.getElementsByClassName(
              'ant-layout-content layout-content site-layout-background custom-textarea'
            )[0]
          }
          style={{
            width: '110px',
          }}
          dropdownClassName='select-dropdown-custom'
          placeholder='Select camp'
          size='small'
          optionFilterProp='children'
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allLearnerCamps.map(({ id, title }) => (
            <Option key={id} value={id}>
              {title}
            </Option>
          ))}
        </Select>
      );
      break;

    case 'images':
      inputNode = (
        <ImageComponent
          images={images}
          setImages={setImages}
          attachPopupVisible={attachPopupVisible}
          setAttachPopupVisible={setAttachPopupVisible}
          currentUpdateObj={currentUpdateObj}
        />
      );
      break;
    default:
      break;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={isColUpdate ? `${dataIndex}_${record.id}` : dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: !nonRequired.includes(dataIndex),
              message: ``, // Required!
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default RevisionsEditableCell;
