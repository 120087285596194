import axios from 'axios';
import { message as AntdAlert } from 'antd';
import { put } from 'redux-saga/effects';
import * as actions from '../actions';
import * as authActions from '../../../login/redux/actions';
import { baseURL } from '../../../../globalUtils/axiosConfig';
import { errorHandler } from '../../../../components/unauthorizeHandler';

export function* handleCreateUser({ data }) {
  try {
    const fd = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (key === 'permissions' && value !== null) {
        Object.entries(value).map((entry) => {
          const [rep, obj] = entry;
          Object.entries(obj).map((mainObj) => {
            const [objectName, objectValue] = mainObj;
            Object.entries(objectValue).map((lastObj) => {
              const [name, value] = lastObj;
              fd.append(`permissions[${rep}][${objectName}][${name}]`, value);
            });
          });
        });
      } else if (key === 'field_values') {
        Object.entries(value).map((entry) => {
          fd.append(`field_values[][id]`, entry[0]);
          fd.append(`field_values[][value]`, entry[1]);
        });
      } else if (
        key.includes('kinship_attributes') ||
        key.includes('supervisor_attributes')
      ) {
        let [first, second, third] = key.split('_');
        fd.append(`${first}_${second}[${third}]`, value || '');
      } else if (value !== null && value !== undefined) {
        fd.append(key, value);
      }
    }
    const response = yield axios.post(`${baseURL}/api/v1/users`, fd, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.createUserSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createUserError());
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
    AntdAlert.error('Something went wrong!');

    yield put(actions.createUserError());
  }
}

export function* handleUpdateUser({ data }) {
  try {
    const fd = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (key === 'permissions') {
        fd.append('permissions', JSON.stringify(value));
      } else if (key === 'field_values') {
        Object.entries(value).map((entry) => {
          fd.append(`field_values[][id]`, entry[0]);
          fd.append(`field_values[][value]`, entry[1]);
        });
      } else if (
        key.includes('kinship_attributes') ||
        key.includes('supervisor_attributes')
      ) {
        if (value !== null && value !== undefined) {
          let [first, second, third] = key.split('_');
          fd.append(`${first}_${second}[${third}]`, value);
        }
      } else if (value !== null && value !== undefined) {
        fd.append(key, value);
      }
    }
    const response = yield axios.put(`${baseURL}/api/v1/users/${data.id}`, fd, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateUserSuccess(response.data.data));
      let user = JSON.parse(localStorage.getItem('user'));
      const { id, user_type } = response.data.data;
      if (user?.id === id && user.user_type === user_type) {
        yield put(authActions.setUser(response.data.data));
        localStorage.setItem('user', JSON.stringify(response.data.data));
      }
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.updateUserError());
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.updateUserError());
    AntdAlert.error('Something went wrong!');
    console.log(error);
  }
}

export function* handleUpdateUserPermissions({ data }) {
  const { permissions, id } = data;
  try {
    const response = yield axios.put(`${baseURL}/api/v1/users/${id}`, {
      permissions,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateUserPermissionsSuccess(response.data.data));
      let user = JSON.parse(localStorage.getItem('user'));
      const { id, user_type } = response.data.data;
      if (user?.id === id && user.user_type === user_type) {
        yield put(authActions.setUser(response.data.data));
        localStorage.setItem('user', JSON.stringify(response.data.data));
      }
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.updateUserPermissionsError());
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleUpdateUserSettings({ data }) {
  const { loadingObj } = data;

  let obj = {};
  if (loadingObj?.popupLoading) {
    obj = { popupLoading: false };
  }
  if (loadingObj?.emailLoading) {
    obj = { emailLoading: false };
  }

  try {
    const response = yield axios.patch(
      `${baseURL}/api/v1/users/${data.data.id}`,
      {
        ...data.data,
      }
    );
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(
        actions.updateUserSettingsSuccess({
          data: response.data.data,
          loadingObj: obj,
        })
      );
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      errorHandler(response.data);
      yield put(actions.updateUserSettingsError());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchUserDetailsUtils() {
  try {
    const response = yield axios.get(`${baseURL}/api/v1/users/user_details`);
    const { success } = response.data;

    console.log('detail', response);
    if (success) {
      yield put(actions.fetchUserDetailsUtilsSuccess(response.data.data));
    }
    errorHandler(response.data);
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchAllUsers({ data }) {
  const { search, ...rest } = data;
  Object.keys(search).forEach((key) => {
    if (search[key]?.length < 1 || search[key] === null) delete search[key];
  });

  try {
    const response = yield axios.get(`${baseURL}/api/v1/users`, {
      params: { ...rest, ...(Object.keys(search).length > 0 && { ...search }) },
    });
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchAllUsersSuccess(response.data.data));
    }
    errorHandler(response.data);
  } catch (error) {
    AntdAlert.error('Something went wrong!');
    yield put(actions.fetchAllUsersError());
    console.log(error);
  }
}

export function* handleDeleteUser({ data }) {
  const { id, user_type } = data;
  try {
    const response = yield axios.delete(
      `${baseURL}/api/v1/users/${id}?user_type=${user_type}`
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteUserSuccess(response.data.data));
    } else if (!success) {
      errorHandler(response.data);
      yield put(actions.deleteUserError(response.data.data));
    }
  } catch ({ response }) {
    yield put(actions.deleteUserError(response.data.data));
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}

export function* handleAddDepartment({ data }) {
  try {
    const response = yield axios.post(`${baseURL}/api/v1/departments`, {
      ...data,
    });
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.saveDepartmentSuccess(response.data.data));
      yield put(actions.setDepartDisableIndex(data.departments.length - 1));
    } else if (!success) {
      errorHandler(response.data);
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.saveDepartmentError());
    }
  } catch (error) {
    console.log(error);
  }
}

export function* handleFetchUser({ data }) {
  const { id, user_type } = data;
  try {
    const response = yield axios.get(`${baseURL}/api/v1/users/${id}`);
    const { success } = response.data;

    if (success) {
      // const { permissions, ...res } = response.data.data;
      yield put(actions.fetchUserSuccess(response.data.data));
      let user = JSON.parse(localStorage.getItem('user'));
      const { id, user_type } = response.data.data;
      if (user?.id === id && user.user_type === user_type) {
        yield put(authActions.setUser(response.data.data));
        localStorage.setItem('user', JSON.stringify(response.data.data));
      }
    } else {
      yield put(actions.updateUserError());
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.updateUserError());
    console.log(error);
    AntdAlert.error('Something went wrong!');
  }
}

export function* handleSendDetailsToUser({ data }) {
  try {
    const response = yield axios.get(
      `${baseURL}/api/v1/send_user_invite?id=${data}`
    );
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      const { permissions, ...res } = response.data.data;
      yield put(actions.sendDetailsToUserSuccess({ ...res, ...permissions }));
    } else if (!success) {
      AntdAlert.error(message);
      errorHandler(response.data);
      yield put(actions.sendDetailsToUserError());
    }
  } catch ({ response }) {
    const { status } = response;
    if (status == 500 || status == 404) {
      AntdAlert.error('Something went wrong!');
      yield put(actions.sendDetailsToUserError());
    }
  }
}
export function* handleCreateProject({ data }) {
  try {
    const response = yield axios.post(`${baseURL}/api/v1/user_npos`, {
      ...data,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createUserProjectSuccess(response.data.data));
      const { user_id } = response.data.data;
      yield put(authActions.fetchSidebarProject({ user_id }));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createUserProjectError());
      errorHandler(response);
    }
  } catch (error) {
    console.log(error);
  }
}
export function* handleUpdateUserProject({ data }) {
  const { updateData, setExpandedRowKeys, setEditingKey } = data;
  const { id, ...rest } = updateData;
  try {
    const response = yield axios.patch(`${baseURL}/api/v1/user_npos/${id}`, {
      ...rest,
    });
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateUserProjectSuccess(response.data.data));
      setExpandedRowKeys([]);
      setEditingKey('');
      const { user_id } = updateData;
      yield put(authActions.fetchSidebarProject({ user_id }));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.updateUserProjectError(response.data.data));

      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateUserProjectError());
    AntdAlert.error('Something went wrong!');
    console.log(response);
  }
}
export function* handleDeleteUserProject({ data }) {
  const { shouldLSUpdate, user_id } = data;
  // try {
  const response = yield axios.delete(`${baseURL}/api/v1/user_npos/${data.id}`);
  const { success, message } = response.data;
  if (success) {
    AntdAlert.success(message);
    let resData = { ...response.data.data, shouldLSUpdate };
    yield put(actions.deleteUserProjectSuccess({ ...resData }));
    yield put(authActions.fetchSidebarProject({ user_id }));
  } else if (!success) {
    errorHandler(response.data);
    yield put(actions.deleteUserProjectError());
  }
  // } catch ({ response }) {
  //   yield put(actions.deleteUserProjectError());
  //   AntdAlert.error("Something went wrong!");
  //   console.log(response);
  // }
}

export function* handleFetchAllUserProjects({ data }) {
  try {
    let params = {};
    if (data?.search) {
      params = {
        ...params,
        ...data.search,
      };
    }
    if (data?.sortedInfo?.order) {
      params = {
        ...params,
        sort:
          data.sortedInfo.field === 'npo_id'
            ? 'npos.name'
            : data.sortedInfo.field,
        order: data?.sortedInfo?.order === 'ascend' ? 'asc' : 'desc',
      };
    }
    const response = yield axios.get(
      `${baseURL}/api/v1/users/${data.user_id}/npos`,
      {
        params: params,
      }
    );
    const { success, message } = response.data;
    if (success) {
      // AntdAlert.success(message);
      let params = { projects: response.data.data, user_id: data.user_id };
      yield put(actions.fetchAllUserProjectsSuccess({ ...params }));
    } else if (!success) {
      errorHandler(response.data);
      yield put(actions.fetchAllUserProjectsError());
    }
  } catch ({ response }) {
    yield put(actions.fetchAllUserProjectsError());
  }
}
