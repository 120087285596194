import axios from "axios";
import { message as AntdAlert } from "antd";
import { put } from "redux-saga/effects";
import * as actions from "../actions";
import { baseURL } from "../../../../globalUtils/axiosConfig";
import { errorHandler } from "../../../../components/unauthorizeHandler";

export function* handleFetchDepartments({ data }) {
  const { search, ...rest } = data;
  Object.keys(search).forEach((key) => {
    if (search[key]?.length < 1 || search[key] === null) delete search[key];
  });

  try {
    // const response = yield axios.get(`${baseURL}/api/v1/activity_types`, {
    const response = yield axios.get(`${baseURL}/api/v1/departments`, {
      params: { ...rest, ...(Object.keys(search).length > 0 && { ...search }) },
    });
    const { success } = response.data;
    if (success) {
      yield put(actions.fetchDepartmentsSuccess(response.data.data));
    } else {
      errorHandler(response.data);
    }
  } catch (error) {
    yield put(actions.fetchDepartmentsError());
    AntdAlert.error("Something went wrong!");
    console.log(error);
  }
}

export function* handleUpdateDepartment({ data }) {
  const { name, id } = data;
  try {
    const response = yield axios.patch(`${baseURL}/api/v1/departments/${id}`, {
      name,
    });
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.updateDepartmentSuccess(data));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.updateDepartmentError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.updateDepartmentError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}

export function* handleDeleteDepartments({ data }) {
  const { id } = data;
  try {
    const response = yield axios.delete(`${baseURL}/api/v1/departments/${id}`);
    const { success, message } = response.data;

    if (success) {
      AntdAlert.success(message);
      yield put(actions.deleteDepartmentSuccess(id));
    } else if (!success) {
      AntdAlert.error(message);
      yield put(actions.deleteDepartmentError(response.data.data));
      errorHandler(response.data);
    }
  } catch ({ response }) {
    yield put(actions.deleteDepartmentError(response.data.data));
    AntdAlert.error("Something went wrong!");
    console.log(response);
  }
}
export function* handleCreateDepartment({ data }) {
  try {
    const response = yield axios.post(`${baseURL}/api/v1/departments`, {
      name: data.name,
    });
    const { success, message } = response.data;
    if (success) {
      AntdAlert.success(message);
      yield put(actions.createDepartmentSuccess(response.data.data));
    } else if (!success) {
      AntdAlert.error(response.data?.errors[0]);
      yield put(actions.createDepartmentError());
      errorHandler(response.data);
    }
  } catch (error) {
    console.log(error);
  }
}
