import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchAllNpos } from '../NpoSetup/redux/actions';
import { fetchCoachStatuses } from '../CoachStatuses/redux/actions';
// import GraphSection from './graphSection';
import GraphSection from '../learners/graphSection/index';
import TableSection from './tableSection';
import { fetchImsDocs } from '../Sites/redux/actions';
import { fetchCoachTypes } from '../CoachTypes/redux/actions';
import { fetchAllTemplates } from '../TemplatesManager/redux/actions';
import axios from 'axios';
import { baseURL } from '../../globalUtils/axiosConfig';
import { Col, message, Row } from 'antd';
import SpinnerComponent from '../../components/spinner';

const Coaches = () => {
  const [statusCheckList, setStatusCheckList] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [coachesTypesCheckList, setCoachesTypesCheckList] = useState([]);
  const [coachesTypesOptions, setCoachesTypesOptions] = useState([]);
  const [coachesCheckList, setCoachesCheckList] = useState([]);
  const [coachesOptions, setCoachesOptions] = useState([]);
  const [npoDetails, setNpoDetails] = useState(null);
  const [npoCheckList, setNpoCheckList] = useState([]);
  const [npoOptions, setNpoOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { npo_id } = useParams();

  const { allCoachStatuses } = useSelector((state) => state.coachStatusReducer);
  const { allNpos } = useSelector((state) => state.npoSetupReducer);
  const { allCoachTypes } = useSelector((state) => state.coachTypesReducer);

  const onPermissionError = () => {
    message.error(
      "You don't have the permission to view NPO Coaches. Please contact system admin."
    );
    return navigate('/profile');
  };

  const fetchDetails = async () => {
    setLoading(true);
    const res = await axios.get(`${baseURL}/api/v1/user_npos/${npo_id}`);
    const { success } = res.data;
    if (success) {
      const { data } = res.data;
      const { permissions } = data;
      setNpoDetails(data);
      let viewPermission = permissions?.coaches?.view || false;
      if (!viewPermission) {
        return onPermissionError();
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    !loading && fetchDetails();
  }, [npo_id]);

  useEffect(() => {
    if (npoDetails !== null) {
      const params = {
        search: {
          npo_id: [npoDetails.npo_id],
        },
      };
      dispatch(fetchAllNpos(params));
      dispatch(fetchCoachTypes());
      dispatch(fetchImsDocs(params));
      dispatch(fetchCoachStatuses());
      dispatch(fetchAllTemplates({}));
    }
  }, [npoDetails]);

  useEffect(() => {
    let npoOptions = allNpos.map((el) => {
      const { name, id } = el;
      return { value: id, label: name };
    });
    setNpoOptions(npoOptions);
  }, [allNpos]);

  useEffect(() => {
    let options = allCoachTypes.map((el) => {
      const { name, id } = el;
      return { value: id, label: name };
    });
    setCoachesTypesOptions(options);
  }, [allCoachTypes]);

  useEffect(() => {
    let statusOptions = allCoachStatuses.map((el) => {
      const { name, id } = el;
      return { value: id, label: name };
    });
    setStatusOptions(statusOptions);
  }, [allCoachStatuses]);

  const fetchCoachUsers = async () => {
    const res = await axios.get(
      `${baseURL}/api/v1/users?page=1&per_page=all&user_type=coach&npo_id[]=${npoDetails?.npo_id}`
    );

    const { success, data } = res.data;
    if (success) {
      // setCoachUsers(onlyCoachUsers);

      let options = data.users.map((el) => {
        const { first_name, last_name, id } = el;
        return { value: id, label: `${first_name} ${last_name}` };
      });
      setCoachesOptions(options);
    }
  };

  useEffect(() => {
    if (npoDetails) {
      fetchCoachUsers();
    }
  }, [npoDetails]);

  if (loading) {
    return (
      <Row justify='center' align='middle' style={{ height: '90vh' }}>
        <Col>
          <SpinnerComponent fontSize={50} />;
        </Col>
      </Row>
    );
  }
  if (npoDetails !== null) {
    return (
      <div className='page-top-div'>
        <span
          className='text-span'
          style={{
            lineHeight: '17px',
          }}
        >
          NPO - {pathname?.split('/')[2]}
        </span>
        <GraphSection />
        <TableSection
          statusCheckList={statusCheckList}
          setStatusCheckList={setStatusCheckList}
          statusOptions={statusOptions}
          coachesTypesCheckList={coachesTypesCheckList}
          setCoachesTypesCheckList={setCoachesTypesCheckList}
          coachesTypesOptions={coachesTypesOptions}
          npoCheckList={npoCheckList}
          setNpoCheckList={setNpoCheckList}
          npoOptions={npoOptions}
          coachesCheckList={coachesCheckList}
          setCoachesCheckList={setCoachesCheckList}
          coachesOptions={coachesOptions}
          name={pathname?.split('/')[2]}
          npoDetails={npoDetails}
        />
      </div>
    );
  }
};

export default Coaches;
