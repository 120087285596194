import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Input,
  Select,
  Col,
  Row,
  Popconfirm,
  Space,
  message as AntdAlert,
  Spin,
} from 'antd';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import { ReactComponent as SaveIcon } from '../../../assets/save-icon.svg';
import { ReactComponent as CrossIcon } from '../../../assets/crossIcon.svg';
import DragDropIcon from '../../../assets/dragdropicon.png';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import axios from 'axios';
import { errorHandler } from '../../../components/unauthorizeHandler';
import { baseURL } from '../../../globalUtils/axiosConfig';

const { Option } = Select;

const DragHandle = sortableHandle(() => (
  <img alt='' height={20} width={20} src={DragDropIcon} />
));

const AddFieldsComponent = ({
  inputFields,
  setInputFields,
  createField,
  deleteField,
  updateField,
  onEditClick,
  createOption,
  updateOption,
  deleteOption,
  onEditClickOption,
  canUpdate,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteLocally = (item) => {
    const filteredInputs = inputFields.filter((el) => el.id !== item.id);
    setInputFields(filteredInputs);
  };

  const handleCancel = ({ index, name }) => {
    let fields = [...inputFields];
    fields[index].isEdit = false;
    fields[index].name = name;
    setInputFields(fields);
  };

  const handleDeleteOptionLocally = (optionItem) => {
    const { parentId, id } = optionItem;

    const inputItem = inputFields.filter((el) => el.id === parentId)[0];

    const updatedOptions = inputItem.options.filter((el) => el.id !== id);

    const final = inputFields.map((el) =>
      el.id === parentId ? { ...el, options: updatedOptions } : el
    );
    setInputFields(final);
  };

  const handleCancelOption = (optionItem) => {
    let fields = [...inputFields];

    const { parentIndex, index, name } = optionItem;
    fields[parentIndex].options[index].isEdit = false;
    fields[parentIndex].options[index].isEdit = false;
    fields[parentIndex].options[index].name = name;
    setInputFields([...fields]);
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      setLoading(true);
      const newData = arrayMoveImmutable(
        inputFields.slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);

      const fd = new FormData();
      newData.map((el, i) => fd.append(`template_fields[${el.id}]`, i + 1));

      const response = await axios.patch(
        `${baseURL}/api/v1/template_fields/sort`,
        fd
      );
      const { success, message } = response.data;
      setLoading(false);
      if (success) {
        AntdAlert.success(message);
        setInputFields(newData);
      } else if (!success) {
        AntdAlert.error(message);
        setInputFields((prev) => prev);

        errorHandler(response.data);
      }
    }
  };

  const handleTypes = (el) => {
    const handleChange = (value, index) => {
      let fields = [...inputFields];
      fields[index] = {
        field_type: value,
        name: '',
        isEdit: true,
        isLocal: true,
        id: Math.floor(Math.random() * 10000000),
        options: [],
      };
      setInputFields(fields);
    };

    switch (el.field_type) {
      case 'text_field':
        return <Input value={el.name} />;
      case 'date_field':
        return <Input value={el.name} />;
      case 'choose':
        return (
          <Select
            placeholder='Select Field Type'
            style={{
              width: 220,
            }}
            disabled={!canUpdate}
            onChange={(e) => handleChange(e, el.index)}
          >
            <Option value='date_field'>Date Field</Option>
            <Option value='select_field'>Selector Field (Dropdown)</Option>
            <Option value='text_field'>Text Field</Option>
          </Select>
        );

      default:
        break;
    }
  };

  const addChooseField = () => {
    setInputFields([
      ...inputFields,
      { name: 'choose', field_type: 'choose', isEdit: true },
    ]);
  };

  const addOption = (inputId) => {
    const inputObj = inputFields.filter((el) => el.id === inputId)[0];
    let newOption = {
      name: '',
      isEdit: true,
      isLocal: true,
      id: Math.floor(Math.random() * 10000000),
    };
    let updated = {
      ...inputObj,
      options: [...inputObj.options, { ...newOption }],
    };

    let finalInputs = inputFields.map((el) =>
      el.id === inputId ? { ...el, ...updated } : el
    );

    setInputFields(finalInputs);
  };

  const RenderOptions = ({ ele, parentId, parentIndex, index }) => {
    let { isLocal, isEdit, name } = ele;
    const [localName, setLocalName] = useState(name);

    return (
      <Row
        align='bottom'
        justify='space-between'
        style={{ width: '100%' }}
        key={index + name}
      >
        <Col span={11} offset={1}>
          <label>{`OPTION ${index + 1} NAME`}</label>
          {isEdit ? (
            <Input
              placeholder={`Insert Option ${index + 1} Name`}
              value={localName}
              disabled={!canUpdate}
              onChange={(e) => {
                const { value } = e.target;
                setLocalName(value);

                let options = inputFields[parentIndex].options;

                options[index] = {
                  ...options[index],
                  name: value,
                };

                setInputFields(inputFields);
              }}
            />
          ) : (
            <Input className='border-less-input' readOnly value={name} />
          )}
        </Col>
        {canUpdate && (
          <>
            <Col>
              {isEdit ? (
                <Space size={15} align='baseline'>
                  <SaveIcon
                    width={18}
                    height={18}
                    fill='#e21c21'
                    onClick={() =>
                      isLocal
                        ? createOption({ ...ele, parentId, name: localName })
                        : updateOption({ ...ele, name: localName })
                    }
                  />
                  <Popconfirm
                    title='Are you sure to cancel?'
                    getPopupContainer={() =>
                      document.getElementsByClassName(
                        'ant-layout-content layout-content site-layout-background custom-textarea'
                      )[0]
                    }
                    placement='topRight'
                    onConfirm={() =>
                      isLocal
                        ? handleDeleteOptionLocally({
                            ...ele,
                            parentId,
                          })
                        : handleCancelOption({ ...ele, parentIndex, index })
                    }
                    okText='Yes'
                    cancelText='No'
                  >
                    <CrossIcon width={18} height={18} fill='#e21c21' />
                  </Popconfirm>
                </Space>
              ) : (
                <Space size={15} align='baseline'>
                  <EditIcon
                    width={18}
                    height={18}
                    fill='#e21c21'
                    onClick={() => onEditClickOption(ele)}
                  />
                  <Popconfirm
                    getPopupContainer={() =>
                      document.getElementsByClassName(
                        'ant-layout-content layout-content site-layout-background custom-textarea'
                      )[0]
                    }
                    title='Are you sure you want to delete?'
                    placement='topRight'
                    onConfirm={() => deleteOption(ele)}
                    okText='Yes'
                    cancelText='No'
                  >
                    <DeleteIcon width={18} height={18} fill='#e21c21' />
                  </Popconfirm>
                </Space>
              )}
            </Col>
            <Col span={3}></Col>
          </>
        )}
      </Row>
    );
  };

  const RenderInputs = ({ ele, index }) => {
    const { field_type, isLocal, isEdit, name, id, options } = ele;
    const [localName, setLocalName] = useState(name);

    let label =
      field_type === 'select_field'
        ? 'Selector Field Name'
        : field_type.split('_').join(' ') + ' Name';

    let placeholder = `Insert ${
      field_type === 'text_field'
        ? 'Text'
        : field_type === 'date_field'
        ? 'Date'
        : 'Selector'
    } Field Name`;

    let showOption =
      field_type === 'select_field' && canUpdate && !isLocal ? true : false;

    return (
      <>
        <Row
          align='bottom'
          justify='space-between'
          className='field-row'
          key={index}
        >
          <Col span={12}>
            <label>{label}</label>
            {isEdit ? (
              <Input
                placeholder={placeholder}
                value={localName}
                disabled={!canUpdate}
                onChange={(e) => {
                  const { value } = e.target;
                  setLocalName(value);
                  inputFields[index].name = value;
                  setInputFields(inputFields);
                }}
              />
            ) : (
              <Input className='border-less-input' readOnly value={name} />
            )}
          </Col>
          {canUpdate && (
            <>
              <Col>
                {isEdit ? (
                  <Space size={15} align='baseline'>
                    <SaveIcon
                      width={18}
                      height={18}
                      fill='#e21c21'
                      onClick={() =>
                        isLocal
                          ? createField({ ...ele, name: localName })
                          : updateField({ ...ele, name: localName })
                      }
                    />
                    <Popconfirm
                      getPopupContainer={() =>
                        document.getElementsByClassName(
                          'ant-layout-content layout-content site-layout-background custom-textarea'
                        )[0]
                      }
                      title='Are you sure to cancel?'
                      placement='topRight'
                      onConfirm={() =>
                        !isLocal
                          ? handleCancel({ ...ele })
                          : handleDeleteLocally(ele)
                      }
                      okText='Yes'
                      cancelText='No'
                    >
                      <CrossIcon width={18} height={18} fill='#e21c21' />
                    </Popconfirm>
                  </Space>
                ) : (
                  <Space size={15} align='baseline'>
                    <EditIcon
                      width={18}
                      height={18}
                      fill='#e21c21'
                      onClick={() => onEditClick(ele)}
                    />
                    <Popconfirm
                      getPopupContainer={() =>
                        document.getElementsByClassName(
                          'ant-layout-content layout-content site-layout-background custom-textarea'
                        )[0]
                      }
                      title='Are you sure you want to delete?'
                      placement='topRight'
                      onConfirm={() => deleteField(ele)}
                      okText='Yes'
                      cancelText='No'
                    >
                      <DeleteIcon width={18} height={18} fill='#e21c21' />
                    </Popconfirm>
                  </Space>
                )}
              </Col>
              <Col span={3}>{!ele?.isLocal && <DragHandle />}</Col>
            </>
          )}
        </Row>
        {field_type === 'select_field' && (
          <Row className='select-options-parent-row' key={`${index}:${id}`}>
            <Col span={24}>
              <Row className='item-dev' gutter={[0, 5]}>
                {options.map((el, i) => {
                  return (
                    <Col
                      style={{ height: '50px', margin: '10px 0px' }}
                      span={24}
                      key={`${i}:asd${index}`}
                    >
                      <Row gutter={[0, 60]}>
                        <RenderOptions
                          ele={el}
                          parentId={id}
                          parentIndex={index}
                          index={i}
                        />
                      </Row>
                    </Col>
                  );
                })}
                {showOption && (
                  <Col span={20}>
                    <Button
                      onClick={() => addOption(id)}
                      icon={<PlusOutlined style={{ lineHeight: 1 }} />}
                      className='add-option-btn'
                      size='middle'
                    >
                      Option
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        )}
      </>
    );
  };

  const SortableItem = sortableElement(({ value, ...rest }) => {
    const { index } = value;
    return (
      <Col span={24} {...rest} key={rest.id}>
        <RenderInputs ele={{ ...value }} index={index} />
        <hr />
      </Col>
    );
  });

  const SortableContainer = sortableContainer(({ children }) => {
    return <div style={{ width: '100%' }}>{children}</div>;
  });

  return (
    <Spin spinning={loading}>
      <SortableContainer onSortEnd={onSortEnd} useDragHandle>
        <Row gutter={[20, 10]} className='doc-template-parent-div'>
          {inputFields.map((el, i) => {
            const { field_type } = el;
            if (field_type !== 'choose') {
              return (
                <SortableItem
                  key={`item-${el.id}`}
                  index={i}
                  value={{ ...el, index: i }}
                />
              );
            } else {
              return (
                <Col span={24} className='choose-col' key={900}>
                  <div className='choose-select-div'>
                    <label>Field Type</label>
                    <Row>{handleTypes({ ...el, index: i })}</Row>
                  </div>
                  <hr />
                </Col>
              );
            }
          })}
          {canUpdate && (
            <Col span={24} key={6789}>
              <Button
                onClick={addChooseField}
                icon={<PlusOutlined />}
                className='add-field-btn'
              >
                FIELD
              </Button>
            </Col>
          )}
        </Row>
      </SortableContainer>
    </Spin>
  );
};

export default AddFieldsComponent;
