import * as constants from "./learnerConstants";

const initialState = {
  allLearnerStatuses: [],
  fetchingLoading: true,
  updateLoading: false,
  totalRecords: 0,
  isAdding: false,
};

const learnerStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_LEARNER_STATUSES:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.CREATE_LEARNER_STATUSES_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        allLearnerStatuses: [...state.allLearnerStatuses, action.data],
        isAdding: false,
      };
    case constants.CREATE_LEARNER_STATUSES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };

    case constants.UPDATE_ORDER_LEARNER_STATUS:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.UPDATE_ORDER_LEARNER_STATUS_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allLearnerStatuses: action.data,
      };
    case constants.UPDATE_ORDER_LEARNER_STATUS_ERROR:
      return {
        ...state,
        fetchingLoading: false,
      };

    case constants.FETCH_LEARNER_STATUSES:
      return {
        ...state,
        fetchingLoading: true,
      };
    case constants.FETCH_LEARNER_STATUSES_SUCCESS:
      return {
        ...state,
        fetchingLoading: false,
        allLearnerStatuses: action.data,
        isAdding: false,
      };
    case constants.FETCH_LEARNER_STATUSES_ERROR:
      return {
        ...state,
        fetchingLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_LEARNER_STATUSES:
      return {
        ...state,
        updateLoading: true,
      };
    case constants.UPDATE_LEARNER_STATUSES_SUCCESS:
      const updated = state.allLearnerStatuses.map((el) =>
        el.id === action.data.id ? { ...el, ...action.data } : el
      );
      return {
        ...state,
        allLearnerStatuses: updated,
        updateLoading: false,
        isAdding: false,
      };
    case constants.UPDATE_LEARNER_STATUSES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_LEARNER_STATUSES:
      return {
        ...state,
        updateLoading: true,
        isAdding: false,
      };
    case constants.DELETE_LEARNER_STATUSES_SUCCESS:
      const updatedData = state.allLearnerStatuses.filter(
        (el) => el.id !== action.data
      );
      return {
        ...state,
        allLearnerStatuses: updatedData,
        updateLoading: false,
        isAdding: false,
      };
    case constants.DELETE_LEARNER_STATUSES_ERROR:
      return {
        ...state,
        updateLoading: false,
        isAdding: false,
      };
    case constants.IS_ADDING_UPDATE:
      return {
        ...state,
        isAdding: action.data,
      };

    default:
      return state;
  }
};

export default learnerStatusReducer;
