import * as constants from './constants';

export const fetchSiteTypes = (data) => {
  return {
    type: constants.FETCH_SITE_TYPES,
    data,
  };
};

export const fetchSiteTypesSuccess = (data) => {
  return {
    type: constants.FETCH_SITE_TYPES_SUCCESS,
    data: data,
  };
};

export const fetchSiteTypesError = (data) => {
  return {
    type: constants.FETCH_SITE_TYPES_ERROR,
    data: data,
  };
};

export const updateSiteTypes = (data) => {
  return {
    type: constants.UPDATE_SITE_TYPES,
    data: data,
  };
};

export const updateSiteTypesSuccess = (data) => {
  return {
    type: constants.UPDATE_SITE_TYPES_SUCCESS,
    data: data,
  };
};

export const updateSiteTypesError = () => {
  return {
    type: constants.UPDATE_SITE_TYPES_ERROR,
  };
};

export const updateSiteTypesOrder = (data) => {
  return {
    type: constants.UPDATE_ORDER_SITE_TYPES,
    data: data,
  };
};

export const updateSiteTypesOrderSuccess = (data) => {
  return {
    type: constants.UPDATE_ORDER_SITE_TYPES_SUCCESS,
    data: data,
  };
};

export const updateSiteTypesOrderError = () => {
  return {
    type: constants.UPDATE_ORDER_SITE_TYPES_ERROR,
  };
};

export const deleteSiteTypes = (data) => {
  return {
    type: constants.DELETE_SITE_TYPES,
    data: data,
  };
};

export const deleteSiteTypesSuccess = (data) => {
  return {
    type: constants.DELETE_SITE_TYPES_SUCCESS,
    data: data,
  };
};

export const deleteSiteTypesError = () => {
  return {
    type: constants.DELETE_SITE_TYPES_ERROR,
  };
};

export const createSiteTypes = (data, id) => {
  return {
    type: constants.CREATE_SITE_TYPES,
    data: data,
  };
};

export const createSiteTypesSuccess = (data) => {
  return {
    type: constants.CREATE_SITE_TYPES_SUCCESS,
    data: data,
  };
};

export const createSiteTypesError = (id) => {
  return {
    type: constants.CREATE_SITE_TYPES_ERROR,
    id,
  };
};

export const isAddingAction = (data) => {
  return {
    type: constants.IS_ADDING_UPDATE,
    data,
  };
};
