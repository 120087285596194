const CREATE_NPO = "CREATE_NPO";
const CREATE_NPO_SUCCESS = "CREATE_NPO_SUCCESS";
const CREATE_NPO_ERROR = "CREATE_NPO_ERROR";
const UPDATE_NPO = "UPDATE_NPO";
const UPDATE_NPO_SUCCESS = "UPDATE_NPO_SUCCESS";
const UPDATE_NPO_ERROR = "UPDATE_NPO_ERROR";
const FETCH_NPO = "FETCH_NPO";
const FETCH_NPO_SUCCESS = "FETCH_NPO_SUCCESS";
const FETCH_NPO_ERROR = "FETCH_NPO_ERROR";
const NPO_DETAILS_IS_EDIT = "NPO_DETAILS_IS_EDIT";
const ADD_USER_IN_MANAGER = "ADD_USER_IN_MANAGER";
const FETCH_NPOS_UTIL_DATA = "FETCH_NPOS_UTIL_DATA";
const FETCH_NPOS_UTIL_DATA_SUCCESS = "FETCH_NPOS_UTIL_DATA_SUCCESS";
const FETCH_ALL_NPOS = "FETCH_ALL_NPOS";
const FETCH_ALL_NPOS_SUCCESS = "FETCH_ALL_NPOS_SUCCESS";
const  FETCH_ALL_NPOS_ERROR = " FETCH_ALL_NPOS_ERROR"
const DELETE_NPO = "DELETE_NPO";
const DELETE_NPO_SUCCESS = "DELETE_NPO_SUCCESS";
const DELETE_NPO_ERROR = "DELETE_NPO_ERROR";
const DELETE_NPO_ATTACH = "DELETE_NPO_ATTACH";
const DELETE_NPO_ATTACH_SUCCESS = "DELETE_NPO_ATTACH_SUCCESS";
const DELETE_NPO_ATTACH_ERROR = "DELETE_NPO_ATTACH_ERROR";
const NPO_DETAIL_CLEANUP_FUNC = "NPO_DETAIL_CLEANUP_FUNC";
const NPO_NOTIF_IS_EDIT = "NPO_NOTIF_IS_EDIT";
const UPDATE_NPO_NOTIFICATION = "UPDATE_NPO_NOTIFICATION";
const UPDATE_NPO_NOTIFICATION_SUCCESS =
  "UPDATE_NPO_NOTIFICATION_SUCCESS";
const UPDATE_NPO_NOTIFICATION_ERROR = "UPDATE_NPO_NOTIFICATION_ERROR";


export {
  CREATE_NPO,
  CREATE_NPO_SUCCESS,
  CREATE_NPO_ERROR,
  UPDATE_NPO,
  UPDATE_NPO_SUCCESS,
  UPDATE_NPO_ERROR,
  FETCH_NPO,
  FETCH_NPO_SUCCESS,
  FETCH_NPO_ERROR,
  ADD_USER_IN_MANAGER,
  NPO_DETAILS_IS_EDIT,
  FETCH_NPOS_UTIL_DATA,
  FETCH_NPOS_UTIL_DATA_SUCCESS,
  FETCH_ALL_NPOS,
  FETCH_ALL_NPOS_SUCCESS,
  FETCH_ALL_NPOS_ERROR,
  DELETE_NPO,
  DELETE_NPO_SUCCESS,
  DELETE_NPO_ERROR,
  DELETE_NPO_ATTACH,
  DELETE_NPO_ATTACH_SUCCESS,
  DELETE_NPO_ATTACH_ERROR,
  NPO_DETAIL_CLEANUP_FUNC,
  NPO_NOTIF_IS_EDIT,
  UPDATE_NPO_NOTIFICATION,
  UPDATE_NPO_NOTIFICATION_SUCCESS,
  UPDATE_NPO_NOTIFICATION_ERROR,
};
