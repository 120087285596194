import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchAllNpos } from '../NpoSetup/redux/actions';
import { fetchLearnerStatuses } from '../LearnerStatuses/redux/actions';
import GraphSection from './graphSection';
import TableSection from './tableSection';
import { fetchImsDocs } from '../Sites/redux/actions';
import { fetchLearnerTypes } from '../LearnerTypes/redux/actions';
import { fetchAllTemplates } from '../TemplatesManager/redux/actions';
import { baseURL } from '../../globalUtils/axiosConfig';
import axios from 'axios';

const params = {
  search: {},
};

const Learners = () => {
  const [statusCheckList, setStatusCheckList] = useState([]);
  // const [statusOptions, setStatusOptions] = useState([]);
  const [learnersTypesCheckList, setLearnersTypesCheckList] = useState([]);
  // const [learnersTypesOptions, setLearnersTypesOptions] = useState([]);
  const [siteCheckList, setSiteCheckList] = useState([]);
  // const [siteOptions, setSiteOptions] = useState([]);
  const [npoCheckList, setNpoCheckList] = useState([]);
  // const [npoOptions, setNpoOptions] = useS
  const [learnerCheckList, setLearnerCheckList] = useState([]);
  const [learnerUsers, setLearnerUsers] = useState([]);

  // const { allTemplates } = useSelector((state) => state.docTypesReducer);
  const { allLearnerStatuses } = useSelector(
    (state) => state.learnerStatusReducer
  );
  const { allDocs } = useSelector((state) => state.sitesReducer);
  const { allNpos } = useSelector((state) => state.npoSetupReducer);
  // const { allNpoRegions } = useSelector((state) => state.npoRegioInsReducer);
  const { allLearnerTypes } = useSelector((state) => state.learnerTypesReducer);
  // const { allUsers } = useSelector((state) => state.userManagerReducer);
  const { user } = useSelector((state) => state.authReducer);

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(fetchAllNpos(params));
    dispatch(fetchLearnerTypes());
    dispatch(
      fetchImsDocs({ search: {}, per_page: 'all', sort: 'name', order: 'asc' })
    );
    dispatch(fetchLearnerStatuses());
    dispatch(fetchAllTemplates({}));
    fetchLearnerUsers();
  }, []);

  const npoOptions = useMemo(
    () =>
      allNpos.map((el) => {
        const { name, id } = el;
        return { value: id, label: name };
      }),
    [allNpos]
  );

  const learnersTypesOptions = useMemo(
    () =>
      allLearnerTypes.map((el) => {
        const { name, id } = el;
        return { value: id, label: name };
      }),
    [allLearnerTypes]
  );

  const siteOptions = useMemo(
    () =>
      allDocs.map((el) => {
        const { name, id } = el;
        return { value: id, label: name };
      }),
    [allDocs]
  );

  const statusOptions = useMemo(
    () =>
      allLearnerStatuses.map((el) => {
        const { name, id } = el;
        return { value: id, label: name };
      }),
    [allLearnerStatuses]
  );

  const fetchLearnerUsers = async () => {
    const { user_type } = user;
    let str = user_type === 'learner' ? `&email=${user.email}` : '';
    const res = await axios.get(
      `${baseURL}/api/v1/users?page=1&per_page=all&user_type=learner${str}`
    );

    const { success, data } = res.data;

    if (success) {
      const filteredUsers = data.users.filter((el) => el.status?.deleteable);
      setLearnerUsers(filteredUsers);
    }
  };

  const learnerOptions = useMemo(
    () =>
      learnerUsers.map((el) => {
        const { first_name, last_name, id } = el;
        return { value: id, label: `${first_name} ${last_name}` };
      }),
    [learnerUsers]
  );

  return (
    <div className='page-top-div'>
      <span
        className='text-span'
        style={{
          lineHeight: '17px',
        }}
      >
        REPORT SUMMARY - {pathname?.split('/')[2]}
      </span>
      <GraphSection />
      <TableSection
        statusCheckList={statusCheckList}
        setStatusCheckList={setStatusCheckList}
        statusOptions={statusOptions}
        learnersTypesCheckList={learnersTypesCheckList}
        setLearnersTypesCheckList={setLearnersTypesCheckList}
        learnersTypesOptions={learnersTypesOptions}
        siteCheckList={siteCheckList}
        setSiteCheckList={setSiteCheckList}
        siteOptions={siteOptions}
        npoCheckList={npoCheckList}
        setNpoCheckList={setNpoCheckList}
        npoOptions={npoOptions}
        learnerCheckList={learnerCheckList}
        setLearnerCheckList={setLearnerCheckList}
        learnerOptions={learnerOptions}
        name={pathname?.split('/')[2]}
      />
    </div>
  );
};

export default Learners;
