import React, { useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Col, Row } from 'antd';
import { BasicTooltip } from '@nivo/tooltip';

// const colors = ['#FFC732', '#E31C21', '#E56C37', '#D74550'];

const LabelRow = ({ onlyMass, label }) => {
  if (label === 'Programme Attendance Actual') {
    return (
      <div className='ordered-compl-label-with-text'>
        <div className='dot-parent-div' title='Modular Actual'>
          <span className='modular-actual'></span>Mod. Actual
        </div>
        <div className='dot-parent-div' title='Mass Actual'>
          <span className='mass-actual'></span>Mass Actual
        </div>
      </div>
    );
  }

  return (
    <div className='ordered-compl-label-with-text'>
      {!onlyMass && (
        <>
          <div className='dot-parent-div' title='Modular Actual'>
            <span className='modular-actual'></span>Mod. Actual
          </div>
          <div className='dot-parent-div' title='Modular Target'>
            <span className='modular-target'></span>
            Mod. Target
          </div>
        </>
      )}
      <div className='dot-parent-div' title='Mass Actual'>
        <span className='mass-actual'></span>Mass Actual
      </div>
      <div className='dot-parent-div' title='Mass Target'>
        <span className='mass-target'></span>
        Mass Target
      </div>
    </div>
  );
};

const VerticalGroupedGraph = ({
  data,
  label,
  BottomLegend,
  tickRotation,
  onlyMass,
  screenWidth,
  keys,
  colors
}) => {
  const ValueOutside = ({ bars }) => {
    return bars.map((bar) => {
      const { key, width, height, x, y, data, color } = bar;

      const { value } = data;

      let updatedValue = value
        ? value > 999
          ? `${(value / 1000).toFixed(1)}K`
          : Math.trunc(value)
        : value;

      return (
        <g transform={`translate(${x}, ${y})`} key={key}>
          <text
            x={width / 2}
            y={-15} // the important bit!!
            textAnchor='middle'
            dominantBaseline='central'
            style={{
              fontSize: 11,
              pointerEvents: 'none',
              fill: 'black',
              opacity: 0.75,
            }}
          >
            {updatedValue}
          </text>
        </g>
      );
    });
  };

  const CustomLeftLabel = (data) => {
    const { x, y, value } = data;
    return (
      <g>
        <text x={x - 25} y={y + 4} fontSize='12px' fill='#757575'>
          {value > 999 ? `${value / 1000}K` : value}
        </text>
      </g>
    );
  };

  const getWidth = (length) => {
    if (screenWidth > 1599) {
      if (length < 4) {
        return '100%';
      }
      return length * 110;
    } else if (screenWidth > 1199) {
      if (length < 8) {
        return '100%';
      }
      return length * 120;
    } else if (screenWidth > 991) {
      if (length < 4) {
        return '100%';
      }
      return length * 130;
    } else {
      if (length < 4) {
        return '100%';
      }
      return length * 110;
    }
  };

  let width = getWidth(data.length);
  let hasScroll = width !== '100%';

  const getId = (str) => {
    let words = str.split(/(?=[A-Z])/);
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const BarTooltip = (props) => {
    let { value, color, id } = props;

    const memoizedValue = useMemo(() => getId(id), [id]);

    return (
      <BasicTooltip
        id={memoizedValue}
        value={value}
        color={color}
        enableChip={false}
      />
    );
  };

  return (
    <>
      <div className='production-by-client-div'>
        <span className='graph-text-span' title={label}>
          {label}
        </span>
      </div>
      <LabelRow onlyMass={onlyMass} label={label} />
      <Row>
        <Col span={1} className='left-axis-legend-col'>
          <div>Mod. Actual. Mod. Target</div>
        </Col>
        <Col span={23}>
          <div
            style={{
              maxWidth: '100%',
              overflowX: hasScroll && 'scroll',
              height: '280px',
              overflowY: hasScroll && 'hidden',
            }}
            className={hasScroll && 'graphs-parent-div'}
          >
            <div style={{ width, height: '100%' }}>
              <ResponsiveBar
                data={data}
                keys={keys}
                indexBy='label'
                margin={{
                  top: 20,
                  right: 0,
                  bottom: 80,
                  left: 40,
                }}
                // colors={!onlyMass ? colors : massColors}
                colors={colors}
                // barComponent={BarComponent}
                // padding={!onlyMass ? 0.4 : 0.2}
                padding={0.2}
                groupMode='grouped'
                layout='vertical'
                borderColor='inherit:darker(1.6)'
                enableLabel={false}
                enableGridX={false}
                enableGridY={true}
                theme={{
                  axis: {
                    ticks: {
                      line: {
                        stroke: null,
                      },
                    },
                    domain: {
                      line: {
                        stroke: '',
                        strokeWidth: 2,
                      },
                    },
                    legend: {
                      text: {
                        fontWeight: '600',
                        // letterSpacing: "2px",
                        color: '#383838',
                        fill: '#383838',
                        fontSize: '13px',
                        fontFamily: 'Open Sans',
                        lineHeight: '16px',
                      },
                    },
                  },

                  grid: {
                    line: {
                      stroke: `rgb(231 232 236 / 80%)`,
                      strokeWidth: 2,
                      strokeDasharray: '2, 4',
                    },
                  },
                }}
                labelTextColor='white'
                layers={['grid', 'axes', 'bars', 'markers', ValueOutside]}
                legends={[
                  {
                    dataFrom: 'keys',
                    // data: keys.map((id, index) => ({
                    //   color: colorsArray[index],
                    //   id,
                    //   //   label: id === "id" ? 1 : 2,
                    //   label: 1,
                    // })),
                    anchor: 'bottom',
                    direction: 'row',
                    translateY: 80,
                    itemWidth: 50,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    symbolSize: 20,
                  },
                ]}
                axisLeft={{
                  format: (v) => {
                    return v.length > 22 ? (
                      <tspan>
                        {v.substring(0, 22) + '...'}
                        <title>{v}</title>
                      </tspan>
                    ) : (
                      v
                    );
                  },
                  tickValues: 3,
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: -100,
                  renderTick: CustomLeftLabel,
                }}
                tooltip={BarTooltip}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation,
                  legend: BottomLegend,
                  legendPosition: 'middle',
                  legendOffset: 60,
                  // renderTick: HorizontalTick,
                  tickValues: 6,
                  format: (v) => {
                    if (tickRotation !== 0) {
                      return v.length > 9 ? (
                        <tspan>
                          {v.substring(0, 9) + '...'}
                          <title>{v}</title>
                        </tspan>
                      ) : (
                        v
                      );
                    }

                    let arr = v.split(' ');

                    return arr.map((el, i) =>
                      el.length > 6 ? (
                        <tspan dy={12 * i} x={8}>
                          {el.substring(0, 6) + '...'}
                          <title>{v}</title>
                        </tspan>
                      ) : (
                        el
                      )
                    );
                  },
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(VerticalGroupedGraph);
