import * as constants from './constants';

const initialState = {
  statusOptions: [],
  allNpos: [],
  currentNpoObj: {
    onboard_date: null,
  },
  utilsData: {},
  updateNotLoading: false,
  departmentsList: [],
  createNpoLoading: false,
  npoDetailsIsEdit: false,
  fetchNposLoading: false,
  deleteNpoLoading: false,
  fetchNpoDetailLoading: false,
  totalRecords: 0,
  deleteAttachLoading: false,
  npoNotificationIsEdit: false,
};

const npoSetupReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_NPOS_UTIL_DATA_SUCCESS:
      // const { sales_reps } = action.data;
      return {
        ...state,
        utilsData: {
          ...action.data,
        },
      };
    case constants.NPO_DETAILS_IS_EDIT:
      return {
        ...state,
        npoDetailsIsEdit: action.data,
      };

    case constants.CREATE_NPO:
      return {
        ...state,
        createNpoLoading: true,
      };
    case constants.CREATE_NPO_SUCCESS:
      return {
        ...state,
        createNpoLoading: false,
        currentNpoObj: action.data,
        npoDetailsIsEdit: false,
      };
    case constants.CREATE_NPO_ERROR:
      return {
        ...state,
        createNpoLoading: false,
      };
    case constants.UPDATE_NPO:
      return {
        ...state,
        createNpoLoading: true,
      };
    case constants.UPDATE_NPO_SUCCESS:
      return {
        ...state,
        createNpoLoading: false,
        currentNpoObj: action.data,
        npoDetailsIsEdit: false,
      };
    case constants.UPDATE_NPO_ERROR:
      return {
        ...state,
        createNpoLoading: false,
      };

    case constants.UPDATE_NPO_NOTIFICATION:
      return {
        ...state,
        updateNotLoading: true,
      };
    case constants.UPDATE_NPO_NOTIFICATION_SUCCESS:
      return {
        ...state,
        updateNotLoading: false,
        npoNotificationIsEdit: false,
      };
    case constants.UPDATE_NPO_NOTIFICATION_ERROR:
      return {
        ...state,
        updateNotLoading: false,
      };

    case constants.FETCH_ALL_NPOS:
      return {
        ...state,
        fetchNposLoading: true,
      };
    case constants.FETCH_ALL_NPOS_SUCCESS:
      const { total_npos, npos } = action.data;
      return {
        ...state,
        allNpos: npos,
        fetchNposLoading: false,
        totalRecords: total_npos,
      };
    case constants.FETCH_ALL_NPOS_ERROR:
      return {
        ...state,
        fetchNposLoading: false,
      };
    case constants.DELETE_NPO:
      return {
        ...state,
        deleteNpoLoading: true,
      };
    case constants.DELETE_NPO_SUCCESS:
      let updatedNpos = state.allNpos.filter((el) => el.id !== action.data.id);
      let totalRecords = state.totalRecords - 1;
      let lselectedNpos = JSON.parse(localStorage.getItem('lsselectedNpos'));

      if (lselectedNpos?.length > 0) {
        let lisCheck = JSON.parse(localStorage.getItem('lsisCheck'));
        let llist = JSON.parse(localStorage.getItem('lslist'));
        let lfilteredNpos = JSON.parse(localStorage.getItem('lsfilteredNpos'));
        let updatedlsselectedNpos = lselectedNpos.filter(
          (npo) => npo.id !== action.data.id
        );

        if (updatedlsselectedNpos.length > 0) {
          localStorage.setItem(
            'lsselectedNpos',
            JSON.stringify(updatedlsselectedNpos)
          );

          if (lselectedNpos !== null) {
            let userPojectsIds = updatedNpos.map((el) => el.id);

            let updatedLsfilteredNpos = lfilteredNpos.filter((el) =>
              userPojectsIds.includes(el.id)
            );

            let updatedlsisCheck = lisCheck.filter((id) =>
              userPojectsIds.includes(id)
            );
            let updatedlslist = llist.filter((el) =>
              userPojectsIds.includes(el.id)
            );

            localStorage.setItem(
              'lsselectedNpos',
              JSON.stringify(updatedlsselectedNpos)
            );
            localStorage.setItem(
              'lsfilteredNpos',
              JSON.stringify(updatedLsfilteredNpos)
            );

            localStorage.setItem('lsisCheck', JSON.stringify(updatedlsisCheck));
            localStorage.setItem('lslist', JSON.stringify(updatedlslist));
          }
        } else {
          localStorage.removeItem('lsselectedNpos');
          localStorage.removeItem('lsfilteredNpos');
          localStorage.removeItem('lsisCheck');
          localStorage.removeItem('lslist');
        }
      }

      return {
        ...state,
        totalRecords: totalRecords,
        deleteNpoLoading: false,
        allNpos: updatedNpos,
      };
    case constants.DELETE_NPO_ERROR:
      return {
        ...state,
        deleteNpoLoading: false,
      };

    case constants.DELETE_NPO_ATTACH:
      return {
        ...state,
        deleteAttachLoading: true,
      };
    case constants.DELETE_NPO_ATTACH_SUCCESS:
      const { id } = action.data;

      let updatedDocs = state.currentNpoObj.document_urls.filter(
        (el) => el.id != id
      );

      return {
        ...state,
        deleteAttachLoading: false,
        currentNpoObj: {
          ...state.currentNpoObj,
          document_urls: updatedDocs,
        },
      };
    case constants.DELETE_NPO_ATTACH_ERROR:
      return {
        ...state,
        deleteAttachLoading: false,
      };

    case constants.FETCH_NPO:
      return {
        ...state,
        // departmentLoading: true,
        fetchNpoDetailLoading: true,
      };
    case constants.FETCH_NPO_SUCCESS:
      return {
        ...state,
        currentNpoObj: action.data,
        fetchNpoDetailLoading: false,
      };
    case constants.FETCH_NPO_ERROR:
      return {
        ...state,
        fetchNpoDetailLoading: false,
      };

    case constants.NPO_NOTIF_IS_EDIT:
      return {
        ...state,
        npoNotificationIsEdit: action.data,
      };

    case constants.NPO_DETAIL_CLEANUP_FUNC:
      return {
        ...state,
        allNpos: [],
        createNpoLoading: false,
        currentNpoObj: {},
        npoDetailsIsEdit: false,
        fetchNposLoading: false,
        deleteNpoLoading: false,
        npoNotificationIsEdit: false,
      };
    default:
      return state;
  }
};

export default npoSetupReducer;
