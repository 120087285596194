import React, { useEffect, useState } from 'react';
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Col,
  Row,
  Space,
  DatePicker,
  InputNumber,
} from 'antd';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import { ReactComponent as SaveIcon } from '../../../assets/save-icon.svg';
// import { ReactComponent as AttachIcon } from '../../../assets/attach.svg';
import { ReactComponent as FilterIcon } from '../../../assets/filter.svg';
import { ReactComponent as CrossIcon } from '../../../assets/crossIcon.svg';
// import { ReactComponent as BatchUpdateIcon } from '../../../assets/patch-update.svg';
import {
  createImsDoc,
  deleteImsDoc,
  // fetchActivities,
  fetchImsDocs,
  updateActivity,
  updateIsAddIsUpdate,
  fetchBatchUpdate,
} from '../redux/actions';
import { fetchActivities } from '../../learners/redux/actions';
import SpinnerComponent from '../../../components/spinner';
import _ from 'lodash';
import CustomPagination from '../../../components/customPagination/CustomPagination';
// import NonEditRowAttach from './nonEditRowAttachView';
import CustomCalendar from '../../../components/calendar';
import AMultiSelect from '../../../components/AMultiSelect';
import { getOptions } from '../../Sales/utils';
import ExpandedRow from './expandedRow';
import EditableCell from './editableCell';
import Learners from './learners';
// import Distribution from './distributionTable';
import ExportDoc from './export';
import ImportDoc from './import';
import FilterAndBatchUpdate from './filterAndBatchUpdate';
import UsePermissions from '../usePermissions';
import Tooltip from '../../../components/tooltip-Custom';
import { useLocation } from 'react-router-dom';
import UseWindowSize from '../../container/sidebar/useWindowSize';

let massOption = {
  label: 'Mass',
  value: 'mass',
};

const ClassesTable = ({
  sitesCheckList,
  setSitesCheckList,
  sitesOptions,
  coachesCheckList,
  setCoachesCheckList,
  coachesOptions,
  npoCheckList,
  setNpoCheckList,
  npoOptions,
  programmeTypeCheckList,
  setProgrammeTypeCheckList,
  programmeTypeOptions,
  interventionTypesCheckList,
  setInterventionTypesCheckList,
  interventionTypesOptions,
  name,
  manualTypeCheckList,
  setManualTypeCheckList,
  coachUsers,
  manualTypeOptions,
  npoDetails,
}) => {
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [sortedInfo, setSortedInfo] = useState(null);
  const [editingLoading, setEditingLoading] = useState(false);
  const [currentUpdateObj, setCurrentUpdateObj] = useState({});
  const [form] = Form.useForm();
  const [fieldsFormRef] = Form.useForm();
  const dispatch = useDispatch();
  const [page, setPage] = useState(() => 1);
  const [resultPerPage, setResultPerPage] = useState(() => 10);
  const [req, setReq] = useState(false);
  const [defaultPageOneReq, setDefaultPageOneReq] = useState(false);
  const [images, setImages] = useState([]);
  // images contains all attachments of item
  const [attachPopupVisible, setAttachPopupVisible] = useState(false);
  const [search, setSearch] = useState({ npo_id: [npoDetails?.npo_id] });
  const [date, setDate] = useState(null);
  // const [dueDate, setDueDate] = useState(null);
  const [deleteDocId, setDeleteDocId] = useState();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [fields, setFields] = useState([]);
  const [datePickerOpened, setDatePickerOpened] = useState(false);
  const [isRevisionUpdateCase, setIsRevisionUpdateCase] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [showBatch, setShowBatch] = useState(false);
  const location = useLocation();
  const [fetchByParams, setFetchByParams] = useState(true);
  const [docNumber, setDocNumber] = useState('');
  const [manuals, setManuals] = useState([]);
  const screenWidth = UseWindowSize();
  // const [manualTypeOptions, setManualTypeOptions] = useState([massOption]);

  // const [attachSearch, setAttachSearch] = useState(null);

  // const { allTemplates } = useSelector((state) => state.docTypesReducer);
  // const { allDocumentField, fetchingLoading } = useSelector(
  //   (state) => state.documentFieldReducer
  // );

  const { allInterventions } = useSelector((state) => ({
    allInterventions: state.interventionTypesReducer.allTemplates,
  }));
  const { allTemplates } = useSelector(
    (state) => state.templatesManagerReducer
  );

  const { classes } = npoDetails.permissions;
  let canView = classes?.view || false;
  let canEdit = classes?.add_edit || false;
  let canExport = classes?.export || false;
  let canDelete = classes?.delete || false;
  let canImport = classes?.import || false;

  useEffect(() => {
    if (location?.state?.document_number?.length > 0) {
      const { document_number } = location?.state;
      if (docNumber === document_number) {
        return;
      } else {
        setFetchByParams(true);
      }
      setData([]);
      if (fetchByParams) {
        setDocNumber(document_number);
        setTimeout(() => {
          setData([]);
          setSearch((prev) => ({ ...prev, document_number }));
          debouncedDefaultPageOneCallApi();
          setFetchByParams(false);
        }, 100);
      }
    }
  }, [location, docNumber]);

  useEffect(() => {
    const template = allTemplates.filter(
      (el) => el.name.toLowerCase() === name
    );
    if (template.length > 0) {
      const { template_fields } = template[0];
      setFields(template_fields);
    }
  }, [allTemplates]);

  useEffect(() => {
    if (page && resultPerPage && page > 0 && resultPerPage > 0) {
      dispatch(
        fetchImsDocs({
          page: page,
          per_page: resultPerPage,
          search,
          sort: sortedInfo?.order && sortedInfo?.columnKey,
          order: sortedInfo?.order
            ? sortedInfo?.order === 'ascend'
              ? 'asc'
              : 'desc'
            : null,
          ...filter,
          page_type: 'classes',
        })
      );
      dispatch(
        fetchActivities({
          page,
          per_page: resultPerPage,
          search: { ...search, npo_id: [npoDetails?.npo_id] },
          ...filter,
          page_type: 'classes',
          npo_graph: true,
        })
      );
    }
  }, [req]);

  useEffect(() => {
    let paramsPage = 1;
    dispatch(
      fetchImsDocs({
        page: paramsPage,
        per_page: resultPerPage,
        search,
        sort: sortedInfo?.order && sortedInfo?.columnKey,
        order: sortedInfo?.order
          ? sortedInfo?.order === 'ascend'
            ? 'asc'
            : 'desc'
          : null,
        ...filter,
        page_type: 'classes',
      })
    );
    // setFilter({});
    // setFields([]);
    setExpandedRowKeys([]);
    setEditingKey('');
    if (paramsPage && resultPerPage && paramsPage > 0 && resultPerPage > 0) {
      dispatch(
        fetchActivities({
          page: paramsPage,
          per_page: resultPerPage,
          search: { ...search, npo_id: [npoDetails?.npo_id] },
          ...filter,
          page_type: 'classes',
          npo_graph: true,
        })
      );
      setPage(1);
    }
    form.resetFields();
    fieldsFormRef.resetFields();
    // if (search?.intervention_id?.length > 0) {
    //   updateManualsOptions(search?.intervention_id);
    // } else {
    //   setManualTypeOptions([massOption]);
    // }
  }, [defaultPageOneReq]);

  useEffect(() => {
    debouncedCallApi();
  }, [sortedInfo]);

  const [debouncedCallApi] = useState(() =>
    _.debounce(() => setReq((prev) => !prev), 1000)
  );

  const [debouncedDefaultPageOneCallApi] = useState(() =>
    _.debounce(() => setDefaultPageOneReq((prev) => !prev), 1000)
  );

  const isEditing = (record) =>
    record.key === editingKey || record.id === editingKey;

  const { user } = useSelector((state) => state.authReducer);
  const {
    allDocs,
    fetchDocsLoading,
    updateLoading,
    totalRecords,
    isAddImsDoc,
    isUpdateImsDoc,
    deletingLoading,
  } = useSelector((state) => state.classesReducer);

  const handleUpdateStatus = (isAdd, isUpdate) => {
    dispatch(
      updateIsAddIsUpdate({
        isAddImsDoc: isAdd,
        isUpdateImsDoc: isUpdate,
      })
    );
  };

  useEffect(() => {
    setEditingLoading(updateLoading);
    if (!updateLoading) {
      setEditingKey('');
      setExpandedRowKeys([]);
    }

    return () => {
      handleUpdateStatus(false, false);
    };
  }, [updateLoading]);

  const setUpdatedData = () => {
    let updatedData = allDocs.map((el) => {
      const { attachments_urls, ...rest } = el;
      // if (isEditing(el)) {
      //   edit({ ...el, isAdd: false, skipFilter: false });
      // }
      let updatedImages = [];
      if (attachments_urls?.length > 0) {
        updatedImages = attachments_urls.map((img) => {
          const { url, id } = img;
          let name = url.split('/')[url.split('/').length - 1].split('?')[0];

          return { id, key: id, url, isLocal: false, name };
        });
      }

      return { ...rest, images: updatedImages };
    });
    setData(updatedData);
  };

  useEffect(() => {
    if (allDocs.length === 0) {
      return setData(allDocs);
    } else if (allDocs.length > 0) {
      setUpdatedData();
    }
  }, [allDocs]);

  const edit = (record) => {
    const { isAdd } = record;
    setCurrentUpdateObj(record);
    setImages(record?.images || []);

    const {
      program_date,
      document_template_id,
      document_status_id,
      aydf_audited,
      aydf_coordinator_audited,
      npo_coordinator_audited,
      manual_id,
      manual_type,
    } = record;
    let manualId = manual_type === 'mass' ? manual_type : manual_id;
    form.setFieldsValue({
      ...record,
      program_date: moment(program_date),
      document_template_id,
      document_status_id,
      isAdd,
      aydf_audited,
      aydf_coordinator_audited,
      npo_coordinator_audited,
      manual_id: manualId,
    });

    if (!isAdd) {
      if (!record?.skipFilter) {
        let filteredItems = data.filter((el) => el.id !== editingKey);
        let final = filteredItems.filter((el) => el.key !== editingKey);
        setData(final);
      }

      setImages(record.images || []);
      if (document_template_id !== null) {
        const { document_field_value } = record;
        let formData = {};
        document_field_value.map((el) => {
          const { template_field_id, value, date_value, template_field } = el;
          if (template_field) {
            const { field_type } = template_field;

            formData[template_field_id] =
              field_type === 'date_field' && date_value !== null
                ? moment(date_value)
                : value;
          }
        });
        // const template = allTemplates.filter(
        //   (el) => el.id === document_template_id
        // );
        const template = [];
        let template_fields =
          template.length > 0 ? template[0].template_fields : [];
        let values = {};
        template_fields.map((el) => (values[el.id] = ''));
        let updatedFields = { ...values, ...formData };
        // console.log('updatedFields', updatedFields);
        setTimeout(() => {
          fieldsFormRef.setFieldsValue({ ...updatedFields });
        }, 1000);
        // console.log('template_fields', template_fields);
        // setFields(document_field_value); // template_fields
        handleUpdateStatus(false, true);
      }
    }

    setEditingKey(record.id);
    setExpandedRowKeys([record.id]);
  };

  const cancel = () => {
    if (isUpdateImsDoc) {
      setData(data);
    } else {
      // if (editingKey) {
      let filteredItems = data.filter((el) => el.id !== editingKey);
      let final = filteredItems.filter((el) => el.key !== editingKey);
      setData(final);
      // }
    }
    // setFields([]);
    form.resetFields();
    fieldsFormRef.resetFields();
    handleUpdateStatus(false, false);
    setEditingKey('');
    setExpandedRowKeys([]);
  };

  const save = async (key) => {
    setEditingKey(key);
    try {
      const row = await form.validateFields();
      const formFields = await fieldsFormRef.validateFields();
      const newData = [...data];
      const index = newData.findIndex(
        (item) => key === item.key || key === item.id
      );
      if (index > -1) {
        form.submit();
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      // const { errorFields } = errInfo;
      // errorFields.forEach((el) => {
      //   el.errors[0] !== 'Required!' && message.error(el.errors[0]);
      // });
    }
  };

  const searchInput = (key) => (
    <Input
      placeholder={''}
      onClick={(e) => e.stopPropagation()}
      value={search[key] || ''}
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e.target.value,
        });
        debouncedDefaultPageOneCallApi();
      }}
    />
  );

  const numberInput = (key) => (
    <InputNumber
      placeholder={''}
      size='small'
      controls={false}
      max={100}
      min={0}
      onClick={(e) => e.stopPropagation()}
      onPressEnter={(e) => e.stopPropagation()}
      value={search[key] || null}
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e,
        });
        debouncedDefaultPageOneCallApi();
      }}
    />
  );

  const handleDateInput = (value) => {
    setDate(value);
    if (value === null) {
      setSearch({
        ...search,
        start_date: '',
        end_date: '',
      });
    } else {
      setSearch({
        ...search,
        start_date: value[0],
        end_date: value[1],
      });
    }
    debouncedDefaultPageOneCallApi();
  };

  const handleMultiSelect = (list, key) => {
    setSearch({
      ...search,
      [key]: list,
    });
    // debouncedDefaultPageOneCallApi();
  };

  // const updateManualsOptions = (ids) => {
  //   let filteredInterventions = interventionTypesOptions.filter((el) =>
  //     ids.includes(el.value)
  //   );
  //   console.log('filteredInterventions', filteredInterventions)
  //   let AllOptions = [];
  //   AllOptions.push(massOption);

  //   filteredInterventions.forEach((el) => {
  //     const { label, user_manuals } = el;
  //     user_manuals.forEach((manual) => {
  //       let updatedLabel = `${manual.label} - (${label})`;
  //       AllOptions.push({ ...manual, label: updatedLabel });
  //     });
  //   });

  //   setManualTypeOptions(AllOptions);
  // };

  const handleSelectOnchange = (value, options, key, setHandler) => {
    if (value.includes('all')) {
      setHandler([...options.map((item) => item.value)]);
      value = options.map((item) => item.value);
    } else if (value.includes('not-all')) {
      setHandler([]);
      value = [];
    } else {
      setHandler(value);
    }
    handleMultiSelect(value, key);
  };

  const onDeleteSuccess = () => {
    debouncedCallApi();
    setDeleteDocId();
    debouncedDefaultPageOneCallApi();
  };

  const deleteDocHandler = (record) => {
    setDeleteDocId(record.id);
    dispatch(deleteImsDoc({ ...record, onDeleteSuccess }));
  };

  // const getCloumnName = (i) =>
  //   allDocumentField.length > 0 && allDocumentField[i].name;

  const dateInput = (key) => (
    <DatePicker
      style={{ width: '100%', minWidth: '80px' }}
      placeholder=''
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className='activities-time-picker prod-due-date'
      getPopupContainer={() =>
        document.getElementsByClassName(
          'ant-layout-content layout-content site-layout-background custom-textarea'
        )[0]
      }
      suffixIcon={null}
      format='D MMM YYYY'
      // popupClassName="activities-time-picker"
      onChange={(e) => {
        setSearch({
          ...search,
          [key]: e,
        });
        debouncedCallApi();
      }}
      onOpenChange={(e) => setDatePickerOpened(e)}
    />
  );

  const handleColOnClick = (record) => {
    if ((isAddImsDoc || isUpdateImsDoc) && !isRevisionUpdateCase) {
      return;
    }
    if (
      isAddImsDoc ||
      ((isRevisionUpdateCase || isUpdateImsDoc) &&
        expandedRowKeys[0] !== record.id)
    ) {
      setExpandedRowKeys([]);
      setIsRevisionUpdateCase(false);
      setEditingKey('');
      setIsRevisionUpdateCase(false);
      handleUpdateStatus(false, false);
      setData(allDocs); // setUpdatedData();
      form.resetFields();
      fieldsFormRef.resetFields();
      setExpandedRowKeys([record.id]);
      // setFields([]);
      setIsRevisionUpdateCase(true);
      return edit({ ...record, isAdd: false, skipFilter: true });
    }

    if (isRevisionUpdateCase) {
      if (expandedRowKeys[0] === record.id) {
        setExpandedRowKeys([]);
        setIsRevisionUpdateCase(false);
        setEditingKey('');
        setIsRevisionUpdateCase(false);
        handleUpdateStatus(false, false);
      }
    } else {
      setExpandedRowKeys([record.id]);
      setIsRevisionUpdateCase(true);
      edit({ ...record, isAdd: false, skipFilter: false });
    }
  };

  // const handleSearchOnchange = (value) => {
  //   if (value === undefined) {
  //     setAttachSearch(null);
  //     handleMultiSelect('', 'has_attach');
  //   } else {
  //     setAttachSearch(value);
  //     handleMultiSelect(value, 'has_attach');
  //   }
  //   debouncedDefaultPageOneCallApi();
  // };

  if (['learner', 'coach'].includes(user.user_type)) {
    sitesOptions = sitesOptions.filter((c) =>
      data.some((s) => s.site_id === c.value)
    );
    npoOptions = npoOptions.filter((c) =>
      data.some((s) => s.npo_id === c.value)
    );
    npoOptions = npoOptions.filter((c) =>
      data.some((s) => s.npo_id === c.value)
    );

    coachesOptions = coachesOptions.filter((c) =>
      data.some((s) => `${s.coach.first_name} ${s.coach.last_name}` === c.label)
    );
    programmeTypeOptions = programmeTypeOptions.filter((c) =>
      data.some((s) => s.type_id === c.value)
    );
    interventionTypesOptions = interventionTypesOptions.filter((c) =>
      data.some((s) => s.intervention_id === c.value)
    );
    manualTypeOptions = manualTypeOptions.filter((c) =>
      data.some((s) => c.label.includes(s?.manual?.name))
    );
  }

  const columns = [
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Impl. Date</div>
            <div>
              <CustomCalendar
                date={date}
                setDate={setDate}
                format={'D MMM'}
                handleDateInput={handleDateInput}
                setDatePickerOpened={setDatePickerOpened}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'program_date',
      key: 'program_date',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'program_date' && sortedInfo?.order,
      width: 110,
      editable: !isRevisionUpdateCase,
      className: 'less-padding-col',
      render: (date, record) => {
        return (
          <div onClick={() => handleColOnClick(record)}>
            {moment(date).format('DD MMM YYYY')}
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Site</div>
            <div>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                dropdownClassName='doc-type-selector'
                disabled={false}
                placeholder=''
                selectTags={sitesCheckList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    sitesOptions,
                    'site_id',
                    setSitesCheckList
                  )
                }
                data={getOptions(sitesOptions, sitesCheckList) || []}
                filterOption={(input, option) => {
                  return (
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'site_id',
      key: 'site_id',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'site_id' && sortedInfo?.order,
      width: 80,
      editable: !isRevisionUpdateCase,
      className: 'less-padding-col',
      render: (doc_template, record) => {
        return (
          <div onClick={() => handleColOnClick(record)}>
            <div>{record?.site?.name}</div>
            <Row gutter={[4, 0]} className='colors-div'>
              {record?.pillar_status?.map((el, i) => {
                return (
                  <Col key={i}>
                    <div className='actual-div'>
                      <span className={`dot ${el}`}></span>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Coach</div>
            <div>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                dropdownClassName='doc-type-selector'
                disabled={false}
                placeholder=''
                selectTags={coachesCheckList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    coachesOptions,
                    'user_id',
                    setCoachesCheckList
                  )
                }
                data={getOptions(coachesOptions, coachesCheckList) || []}
                filterOption={(input, option) => {
                  return (
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'user_id',
      key: 'user_id',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'user_id' && sortedInfo?.order,
      width: 80,
      editable: !isRevisionUpdateCase,
      className: 'less-padding-col',
      render: (coach_user, record) => {
        if (record?.coach) {
          const { first_name, last_name } = record?.coach;
          const name = `${first_name} ${last_name}`;
          return <div onClick={() => handleColOnClick(record)}>{name}</div>;
        }
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              Class <br /> Name
            </div>
            <div>{searchInput('classname')}</div>
          </div>
        );
      },
      dataIndex: 'classname',
      key: 'classname',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'classname' && sortedInfo?.order,
      width: 100,
      className: 'classname-td less-padding-col',
      editable: !isRevisionUpdateCase,
      render: (classname, record) => (
        <div onClick={() => handleColOnClick(record)}>{record?.name}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>Topic title</div>
            <div>{searchInput('topic')}</div>
          </div>
        );
      },
      dataIndex: 'topic',
      key: 'topic',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'topic' && sortedInfo?.order,
      width: 100,
      editable: !isRevisionUpdateCase,
      className: 'less-padding-col',
      render: (topic, record) => {
        const {
          aydf_audited,
          aydf_coordinator_audited,
          npo_coordinator_audited,
        } = record;
        return (
          <div onClick={() => handleColOnClick(record)}>
            <div>{record?.topic}</div>
            <Row gutter={[4, 0]} className='colors-div'>
              {[
                aydf_audited,
                aydf_coordinator_audited,
                npo_coordinator_audited,
              ]?.map((el, i) => {
                return (
                  <Col key={i}>
                    <div className='topic-div'>
                      <span
                        className={`dot ${el ? 'filled' : 'unfilled'}`}
                      ></span>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>NPO</div>
            <div>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                dropdownClassName='doc-type-selector'
                disabled={false}
                placeholder=''
                selectTags={npoCheckList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    npoOptions,
                    'npo_id',
                    setNpoCheckList
                  )
                }
                data={getOptions(npoOptions, npoCheckList) || []}
                filterOption={(input, option) => {
                  return (
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'npo_id',
      key: 'npo_id',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'npo_id' && sortedInfo?.order,
      width: 80,
      editable: !isRevisionUpdateCase,
      className: 'less-padding-col',
      render: (doc_template, record) => (
        <div onClick={() => handleColOnClick(record)}>{record?.npo?.name}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              Programme <br />
              type
            </div>
            <div>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                dropdownClassName='doc-type-selector'
                disabled={false}
                placeholder=''
                selectTags={programmeTypeCheckList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    programmeTypeOptions,
                    'type_id',
                    setProgrammeTypeCheckList
                  )
                }
                data={
                  getOptions(programmeTypeOptions, programmeTypeCheckList) || []
                }
                filterOption={(input, option) => {
                  return (
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'type_id',
      key: 'type_id',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'type_id' && sortedInfo?.order,
      width: 80,
      editable: !isRevisionUpdateCase,
      className: 'less-padding-col',
      render: (doc_template, record) => (
        <div onClick={() => handleColOnClick(record)}>{record?.type?.name}</div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              Intervention <br />
              type
            </div>
            <div>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                dropdownClassName='doc-type-selector'
                disabled={false}
                placeholder=''
                selectTags={interventionTypesCheckList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    interventionTypesOptions,
                    'intervention_id',
                    setInterventionTypesCheckList
                  )
                }
                data={
                  getOptions(
                    interventionTypesOptions,
                    interventionTypesCheckList
                  ) || []
                }
                filterOption={(input, option) => {
                  return (
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'intervention_id',
      key: 'intervention_id',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'intervention_id' && sortedInfo?.order,
      width: 80,
      editable: !isRevisionUpdateCase,
      className: 'less-padding-col',
      render: (doc_template, record) => (
        <div onClick={() => handleColOnClick(record)}>
          {record?.intervention?.name}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              Manual <br />
              type
            </div>
            <div>
              <AMultiSelect
                debouncedCallApi={debouncedDefaultPageOneCallApi}
                dropdownClassName='doc-type-selector'
                disabled={false}
                placeholder=''
                selectTags={manualTypeCheckList}
                onSelectChange={(value) =>
                  handleSelectOnchange(
                    value,
                    manualTypeOptions,
                    'manual_id',
                    setManualTypeCheckList
                  )
                }
                data={getOptions(manualTypeOptions, manualTypeCheckList) || []}
                filterOption={(input, option) => {
                  return (
                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                }}
              />
            </div>
          </div>
        );
      },
      dataIndex: 'manual_id',
      key: 'manual_id',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'manual_id' && sortedInfo?.order,
      width: 80,
      editable: !isRevisionUpdateCase,
      className: 'less-padding-col',
      render: (doc_template, record) => (
        <div onClick={() => handleColOnClick(record)}>
          {record?.manual_type === 'mass' ? 'Mass' : record?.manual?.name}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              MKT <br />
              Pre
            </div>
            <div>{numberInput('mkt_pre')}</div>
          </div>
        );
      },
      dataIndex: 'mkt_pre',
      key: 'mkt_pre',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'mkt_pre' && sortedInfo?.order,
      width: 40,
      className: 'less-padding-col',
      align: 'center',
      editable: false,
      render: (mkt_pre, record) => (
        <div onClick={() => handleColOnClick(record)}>
          {record?.manual_type === 'mass' ? '-' : mkt_pre}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              MKT <br />
              post
            </div>
            <div>{numberInput('mkt_post')}</div>
          </div>
        );
      },
      dataIndex: 'mkt_post',
      key: 'mkt_post',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'mkt_post' && sortedInfo?.order,
      width: 40,
      className: 'less-padding-col',
      align: 'center',
      editable: false,
      render: (mkt_post, record) => (
        <div onClick={() => handleColOnClick(record)}>
          {record?.manual_type === 'mass' ? '-' : mkt_post}
        </div>
      ),
    },

    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              ses <br />
              Pre
            </div>
            <div>{numberInput('ses_pre')}</div>
          </div>
        );
      },
      dataIndex: 'ses_pre',
      key: 'ses_pre',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'ses_pre' && sortedInfo?.order,
      width: 40,
      className: 'less-padding-col',
      align: 'center',
      editable: false,
      render: (ses_pre, record) => (
        <div onClick={() => handleColOnClick(record)}>
          {record?.manual_type === 'mass' ? '-' : ses_pre}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              ses <br />
              post
            </div>
            <div>{numberInput('ses_post')}</div>
          </div>
        );
      },
      dataIndex: 'ses_post',
      key: 'ses_post',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'ses_post' && sortedInfo?.order,
      width: 40,
      className: 'less-padding-col',
      align: 'center',
      editable: false,
      render: (ses_post, record) => (
        <div onClick={() => handleColOnClick(record)}>
          {record?.manual_type === 'mass' ? '-' : ses_post}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              leq <br />
              Pre
            </div>
            <div>{numberInput('leq_pre')}</div>
          </div>
        );
      },
      dataIndex: 'leq_pre',
      key: 'leq_pre',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'leq_pre' && sortedInfo?.order,
      width: 40,
      className: 'less-padding-col',
      align: 'center',
      editable: false,
      render: (leq_pre, record) => (
        <div onClick={() => handleColOnClick(record)}>
          {record?.manual_type === 'mass' ? '-' : leq_pre}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-no-padding'>
              leq <br />
              post
            </div>
            <div>{numberInput('leq_post')}</div>
          </div>
        );
      },
      dataIndex: 'leq_post',
      key: 'leq_post',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'leq_post' && sortedInfo?.order,
      width: 40,
      className: 'less-padding-col',
      align: 'center',
      editable: false,
      render: (leq_post, record) => (
        <div onClick={() => handleColOnClick(record)}>
          {record?.manual_type === 'mass' ? '-' : leq_post}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              total <br />
              att.
            </div>
            <div>{searchInput('total_attendance')}</div>
          </div>
        );
      },
      dataIndex: 'total_attendance',
      key: 'total_attendance',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'total_attendance' && sortedInfo?.order,
      width: 50,
      align: 'center',
      className: 'less-padding-col',
      editable: false,
    },
    {
      title: () => {
        return (
          <div align='top' className='select-parent-div'>
            <div className='special-table-header-text'>
              AVG. <br />
              age
            </div>
            <div>{searchInput('average_age')}</div>
          </div>
        );
      },
      dataIndex: 'average_age',
      key: 'average_age',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'average_age' && sortedInfo?.order,
      width: 50,
      align: 'center',
      className: 'less-padding-col',
      editable: false,
    },
    {
      title: '',
      dataIndex: 'operation',
      align: 'center',
      className: 'less-padding-col',
      width: 70,
      render: (_, record) => {
        const editable = isEditing(record);
        let key = record?.key || record?.id;
        let updateCondition =
          isUpdateImsDoc && editingKey !== key && editingKey > 0;
        let shouldDisabled =
          isAddImsDoc || (isRevisionUpdateCase && !editable) || updateCondition;

        return editable && !isRevisionUpdateCase ? (
          editingLoading && editingKey === key ? (
            <SpinnerComponent fontSize={14} />
          ) : (
            <Space size={20} align='center'>
              <Popconfirm
                placement='topRight'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                overlayClassName='activities-popconfirm'
                title='Are you sure you want to save?'
                onConfirm={() => save(record.key || record.id)}
                okText='Yes'
                cancelText='No'
              >
                <SaveIcon className='custom-icon' />
              </Popconfirm>
              {/* {!isUpdateImsDoc && ( */}
              <Popconfirm
                placement='topRight'
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    'ant-layout-content layout-content site-layout-background custom-textarea'
                  )[0]
                }
                title='Sure to cancel?'
                onConfirm={cancel}
              >
                <CrossIcon
                  className='custom-icon'
                  style={{
                    height: '20px',
                    width: '20px',
                  }}
                />
              </Popconfirm>
              {/* // )} */}
            </Space>
          )
        ) : (
          <div>
            <Space size={20} align='center'>
              {deletingLoading && record.id === deleteDocId ? (
                <SpinnerComponent fontSize={16} />
              ) : (
                <>
                  {canEdit && (
                    <EditIcon
                      className={`custom-icon ${
                        shouldDisabled ? 'disable-icon' : ''
                      }`}
                      disabled={editingKey !== ''}
                      onClick={() => {
                        if (shouldDisabled) {
                          return;
                        }
                        if (isRevisionUpdateCase) {
                          setIsRevisionUpdateCase(false);
                        } else {
                          setManualsOnEditClick(record.intervention_id);
                          edit({
                            ...record,
                            isAdd: false,
                            skipFilter: false,
                          });
                        }
                      }}
                    />
                  )}
                  {!shouldDisabled
                    ? canDelete && (
                        <Popconfirm
                          getPopupContainer={() =>
                            document.getElementsByClassName(
                              'ant-layout-content layout-content site-layout-background custom-textarea'
                            )[0]
                          }
                          placement='topRight'
                          title='Are you sure you want to delete?'
                          onConfirm={() => deleteDocHandler(record)}
                        >
                          <DeleteIcon className={`custom-icon`} />
                        </Popconfirm>
                      )
                    : canDelete && (
                        <DeleteIcon
                          className={`custom-icon ${
                            (isAddImsDoc || isUpdateImsDoc) && 'disable-icon'
                          }`}
                        />
                      )}
                </>
              )}
            </Space>
          </div>
        );
      },
    },
  ];

  const handleAdd = () => {
    // setFields([]);
    // const template = allTemplates.filter(
    //   (el) => el.name.toLowerCase() === name
    // );
    // if (template.length > 0) {
    //   const { template_fields } = template[0];
    //   setFields(template_fields);
    // }
    form.resetFields();
    fieldsFormRef.resetFields();
    setSortedInfo(null);
    handleUpdateStatus(true, false);
    setCurrentUpdateObj({});
    let key = Math.ceil(Math.random() * 2345678);
    const newData = {
      key,
      id: key,
      program_date: moment(),
      document_template_id: null,
      document_status_id: null,
      document_number: '',
      isAdd: true,
      coach: { first_name: '', last_name: '' },
      aydf_audited: false,
      aydf_coordinator_audited: false,
      npo_coordinator_audited: false,
      npo_id: npoDetails.npo_id,
    };
    let final = { ...newData, sites_field_values: [] };
    setData((data) => [final, ...data]);
    edit(final);
    setImages([]);
    form.setFieldsValue({
      ...newData,
    });

    setEditingKey(key);
    setExpandedRowKeys([key]);
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          // inputType:,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        };
      },
    };
  });

  const hanldeFinish = (item) => {
    let values = fieldsFormRef.getFieldsValue();

    const {
      program_date,
      document_template_id,
      document_status_id,
      manual_id,
    } = item;
    const { classname, ...rest } = item;

    let manual_type = manual_id === 'mass' ? 'mass' : 'modular';
    let manualId = manual_id === 'mass' ? null : manual_id;

    const { id, npo_region } = npoDetails.npo;

    if (isUpdateImsDoc) {
      dispatch(
        updateActivity({
          ...rest,
          name: classname,
          program_date: moment(program_date),
          document_template_id,
          document_status_id,
          id: currentUpdateObj.id,
          ...(images.length > 0 && { images }),
          field_values: values,
          debouncedDefaultPageOneCallApi,
          manual_type,
          manual_id: manualId,
        })
      );
    } else {
      dispatch(
        createImsDoc({
          ...rest,
          name: classname,
          program_date: moment(program_date),
          document_template_id,
          document_status_id,
          // user_id: user.id,
          ...(images.length > 0 && { images }),
          field_values: values,
          debouncedDefaultPageOneCallApi,
          manual_type,
          manual_id: manualId,
          npo_id: id,
          npo_region: npo_region?.name,
        })
      );
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    if (datePickerOpened) {
      return;
    }
    setSortedInfo(sorter);
  };

  const expandedRowRender = (record) => {
    if (expandedRowKeys.includes(record.id)) {
      return (
        <>
          {/* {fields.length > 0 && (
          <> */}
          <ExpandedRow
            record={record}
            fields={fields}
            formRef={fieldsFormRef}
            onFinish={onFinishFields}
            isRevisionUpdateCase={isRevisionUpdateCase}
            images={images}
            setImages={setImages}
            attachPopupVisible={attachPopupVisible}
            setAttachPopupVisible={setAttachPopupVisible}
            currentUpdateObj={currentUpdateObj}
            shouldDisabled={false} // {shouldDisabled}
          />
          {/* </>
         )} */}
          {isRevisionUpdateCase && canView && (
            <Row gutter={[0, 60]} style={{ marginBottom: '30px' }}>
              {/* {record?.document_template?.revision_control && canView && ( */}
              <Col span={24}>
                <Learners
                  record={record}
                  debouncedCallApi={debouncedCallApi}
                  permissions={npoDetails.permissions.classes}
                />
              </Col>
              {/* )} */}
            </Row>
          )}
        </>
      );
      // }
    }
  };

  const handleChangeDocType = (e, option) => {
    // const { name } = allNpos.filter((el) => el.id === id)[0].npo_region;
    const { manuals } = option;
    setManuals(manuals);
    form.setFieldValue('manual_id', null);

    // const { template_fields } = allTemplates.filter((el) => el.id === id)[0];
    // template_fields.map((el) => {
    //   fieldsFormRef.setFieldValue({ [el.id]: '' });
    //   return { [el.id]: null };
    // });
    // setFields(template_fields);
  };

  const handleUpdateSite = (e, option) => {
    const { npo_id } = option;
    form.setFieldValue('npo_id', npo_id);
  };

  const setManualsOnEditClick = (interventionId) => {
    const filteredManuals = allInterventions.filter(
      (el) => el.id == interventionId
    );
    if (filteredManuals.length > 0) {
      let { user_manuals } = filteredManuals[0];
      setManuals(user_manuals);
    }
  };

  const onFinishFields = (values) => {
    console.log('values', values);
  };

  const handleCloseBatchSection = () => {
    setShowBatch(false);
  };

  const handleCloseFilterSection = () => {
    setShowFilter(false);
    setFilter({});
    handleCloseBatchSection();
    debouncedDefaultPageOneCallApi();
  };

  const handleFilter = (filterObj) => {
    setFilter(filterObj);
    debouncedDefaultPageOneCallApi();
  };

  const handleBatchUpdate = (filterObj) => {
    setExpandedRowKeys([]);
    setEditingKey('');
    setIsRevisionUpdateCase(false);
    let document_control = data.map((el) => el.id);
    let revisions_ids = [];
    let distributions_ids = [];
    data.map((doc) => {
      const { doc_distributions, doc_revisions } = doc;
      doc_revisions.map((rev) => revisions_ids.push(rev.id));
      doc_distributions.map((dist) => distributions_ids.push(dist.id));
    });

    dispatch(
      fetchBatchUpdate({
        ...filterObj,
        document_control,
        revisions_ids,
        distributions_ids,
      })
    );
  };

  const shouldAddBtnDisable = () => {
    let shouldDisabled =
      isAddImsDoc || fetchDocsLoading || isRevisionUpdateCase || isUpdateImsDoc;
    return shouldDisabled;
  };

  const hanldeBatch = () => {
    setShowFilter(true);
    setShowBatch(true);
  };

  // console.count('Render ClassesTable', data);

  return (
    <Row className='doc-control-parent-row'>
      <Col span={24} className='heading-col'>
        <Row align='middle' gutter={[50, 0]} justify='space-between'>
          <Col>
            <Row gutter={[50, 0]} align='middle'>
              <Col>
                <div>
                  <span className='heading-span'>{name} Register</span>
                </div>
              </Col>
              {canEdit && (
                <Col>
                  <Button
                    icon={<PlusOutlined />}
                    className='add-doc-btn class-btn'
                    size='middle'
                    onClick={handleAdd}
                    disabled={shouldAddBtnDisable()}
                  >
                    Class
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
          <Col>
            <Space size={35} align='center'>
              <Tooltip text='Filter'>
                <FilterIcon
                  onClick={() => {
                    setShowFilter(!showFilter);
                    showFilter && debouncedDefaultPageOneCallApi();
                  }}
                />
              </Tooltip>
              {canExport && (
                <Tooltip text='Export'>
                  {<ExportDoc npo_id={npoDetails.npo_id} />}
                </Tooltip>
              )}
              {canImport && (
                <Tooltip text='Import'>
                  <ImportDoc
                    debounce={debouncedDefaultPageOneCallApi}
                    npo_id={npoDetails.npo_id}
                  />
                </Tooltip>
              )}
              {/* {canEdit && (
                <Tooltip text='Batch Update'>
                  <BatchUpdateIcon onClick={hanldeBatch} />
                </Tooltip>
              )} */}
            </Space>
          </Col>
        </Row>
      </Col>

      <FilterAndBatchUpdate
        handleCloseFilter={handleCloseFilterSection}
        handleFilter={handleFilter}
        handleBatchUpdate={handleBatchUpdate}
        handleCloseBatch={handleCloseBatchSection}
        loading={fetchDocsLoading}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        showBatch={showBatch}
        setShowBatch={setShowBatch}
      />

      <Col span={24}>
        <Form form={form} component={false} onFinish={hanldeFinish}>
          <Table
            scroll={screenWidth < 1920 && { x: true }}
            className={`special-table ims-doc-table ${
              shouldAddBtnDisable() ? 'doc-table-is-exapnded' : ''
            }`}
            components={{
              body: {
                cell: (props) => (
                  <EditableCell
                    cell={props}
                    expandedRowKeys={expandedRowKeys}
                    setExpandedRowKeys={setExpandedRowKeys}
                    currentUpdateObj={currentUpdateObj}
                    values={form.getFieldsValue()}
                    setFields={setFields}
                    fields={fields}
                    handleChangeDocType={handleChangeDocType}
                    handleUpdateSite={handleUpdateSite}
                    isRevisionUpdateCase={isRevisionUpdateCase}
                    manuals={manuals}
                    coachUsers={coachUsers}
                  />
                ),
              },
            }}
            expandable={{
              expandedRowRender,
              expandIcon: null,
              showExpandColumn: false,
              expandedRowKeys: expandedRowKeys,
              rowExpandable: ({ id }) => expandedRowKeys.includes(id),
            }}
            onRow={({ id }) => {
              if (expandedRowKeys.length > 0) {
                let isExpanded = expandedRowKeys.includes(id);
                let expandClassNames = `expand-doc-parent ${
                  isRevisionUpdateCase ? 'ims-doc-readonly-input' : ''
                }`;
                let unExpandClassnames = isRevisionUpdateCase
                  ? ''
                  : 'disabled-row';
                return {
                  className: isExpanded ? expandClassNames : unExpandClassnames,
                };
              }
            }}
            rowKey={(record) => record.id}
            loading={fetchDocsLoading} // || fetchingLoading
            rowClassName='editable-row'
            dataSource={data}
            columns={mergedColumns}
            onChange={handleChange}
            pagination={false}
          />
        </Form>
      </Col>
      <Col span={24}>
        <CustomPagination
          page={page}
          setPage={setPage}
          resultPerPage={resultPerPage}
          setResultPerPage={setResultPerPage}
          debouncedCallApi={debouncedCallApi}
          totalRecord={totalRecords}
        />
      </Col>
    </Row>
  );
};

export default ClassesTable;
