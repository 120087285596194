import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchAllNpos } from '../NpoSetup/redux/actions';
import { fetchSiteStatuses } from '../SiteStatuses/redux/actions';
import { fetchSiteTypes } from '../SiteTypes/redux/actions';
import { fetchAllTemplates } from '../TemplatesManager/redux/actions';
// import GraphSection from './graphSection';
import GraphSection from '../learners/graphSection/index';
import TableSection from './tableSection';
import './index.less';
import { fetchNpoRegions } from '../NpoRegions/redux/actions';
import { fetchImsDocs } from '../Sites/redux/actions';
import { fetchProgrammeTypes } from '../ProgrammeTypes/redux/actions';
import { fetchAllInterventionTypes } from '../InterventionTypesManager/redux/actions';
import { fetchLearnerCamps } from '../LearnerCamps/redux/actions';
import axios from 'axios';
import { baseURL } from '../../globalUtils/axiosConfig';

const searchParams = {
  search: {},
};

let sitesParams = {
  search: {},
  per_page: 'all',
  sort: 'name',
  order: 'asc',
};

let massOption = {
  label: 'Mass',
  value: 'mass',
};

const Classes = () => {
  const [sitesCheckList, setSitesCheckList] = useState([]);
  const [sitesOptions, setSitesOptions] = useState([]);
  const [coachesCheckList, setCoachesCheckList] = useState([]);
  const [coachesOptions, setCoachesOptions] = useState([]);
  const [npoCheckList, setNpoCheckList] = useState([]);
  const [npoOptions, setNpoOptions] = useState([]);
  const [programmeTypeCheckList, setProgrammeTypeCheckList] = useState([]);
  const [programmeTypeOptions, setProgrammeTypeOptions] = useState([]);
  const [coachUsers, setCoachUsers] = useState([]);
  const [interventionTypesCheckList, setInterventionTypesCheckList] = useState(
    []
  );
  const [interventionTypesOptions, setInterventionTypesOptions] = useState([]);
  const [manualTypeCheckList, setManualTypeCheckList] = useState([]);
  const [manualTypeOptions, setManualTypeOptions] = useState([massOption]);

  const { allDocs, allNpos, allProgrammeTypes, allTemplates } = useSelector(
    (state) => ({
      allDocs: state.sitesReducer.allDocs,
      allNpos: state.npoSetupReducer.allNpos,
      allProgrammeTypes: state.programmeTypesReducer.allProgrammeTypes,
      allTemplates: state.interventionTypesReducer.allTemplates,
    })
  );

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const fetchInitialData = useCallback(() => {
    dispatch(fetchAllTemplates({}));
    dispatch(fetchAllNpos(searchParams));
    dispatch(fetchImsDocs(sitesParams));
    dispatch(fetchSiteTypes());
    dispatch(fetchSiteStatuses());
    dispatch(fetchNpoRegions());
    dispatch(fetchProgrammeTypes());
    dispatch(fetchAllInterventionTypes(searchParams));
    dispatch(fetchLearnerCamps());
  }, [dispatch]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  useEffect(() => {
    let allManualsOptions = [massOption];
    let options = allTemplates.map((el) => {
      const { name, id, user_manuals } = el;
      const updated = user_manuals.map((manual) => {
        let manualOptionObj = {
          value: manual.id,
          label: `${manual.name} - ${name}`,
        };
        allManualsOptions.push(manualOptionObj);
        return { value: manual.id, label: manual.name };
      });
      setManualTypeOptions(allManualsOptions);
      return { value: id, label: name, manuals: updated };
    });
    setInterventionTypesOptions(options);
  }, [allTemplates]);

  useEffect(() => {
    if (interventionTypesOptions.length > 0) {
    }
  }, [interventionTypesOptions]);

  useEffect(() => {
    let options = allProgrammeTypes.map((el) => {
      const { name, id } = el;
      return { value: id, label: name };
    });
    setProgrammeTypeOptions(options);
  }, [allProgrammeTypes]);

  useEffect(() => {
    if (allDocs) {
      let sitesOptions = allDocs.map((el) => {
        const { name, id } = el;
        return { value: id, label: name };
      });
      setSitesOptions(sitesOptions);
    }
  }, [allDocs]);

  useEffect(() => {
    let npoOptions = allNpos.map((el) => {
      const { name, id } = el;
      return { value: id, label: name };
    });
    setNpoOptions(npoOptions);
  }, [allNpos]);

  const fetchCoachUsers = async () => {
    const res = await axios.get(`${baseURL}/api/v1/users/users_except_learner`);

    const { success, data } = res.data;
    if (success) {
      const onlyCoachUsers = data.filter(
        (el) => el.user_type === 'coach' && el.status?.deleteable
      );
      setCoachUsers(onlyCoachUsers);
      let options = onlyCoachUsers.map((el) => {
        const { first_name, last_name, id } = el;
        return { value: id, label: `${first_name} ${last_name}` };
      });
      setCoachesOptions(options);
    }
  };

  useEffect(() => {
    fetchCoachUsers();
  }, []);

  return (
    <div className='page-top-div'>
      <span
        className='text-span'
        style={{
          lineHeight: '17px',
        }}
      >
        REPORT SUMMARY - {pathname?.split('/')[2]}
      </span>
      <GraphSection />
      <TableSection
        sitesCheckList={sitesCheckList}
        setSitesCheckList={setSitesCheckList}
        sitesOptions={sitesOptions}
        coachesCheckList={coachesCheckList}
        setCoachesCheckList={setCoachesCheckList}
        coachesOptions={coachesOptions}
        npoCheckList={npoCheckList}
        setNpoCheckList={setNpoCheckList}
        npoOptions={npoOptions}
        programmeTypeCheckList={programmeTypeCheckList}
        setProgrammeTypeCheckList={setProgrammeTypeCheckList}
        programmeTypeOptions={programmeTypeOptions}
        interventionTypesCheckList={interventionTypesCheckList}
        setInterventionTypesCheckList={setInterventionTypesCheckList}
        interventionTypesOptions={interventionTypesOptions}
        manualTypeCheckList={manualTypeCheckList}
        setManualTypeCheckList={setManualTypeCheckList}
        manualTypeOptions={manualTypeOptions}
        name={pathname?.split('/')[2]}
        coachUsers={coachUsers}
      />
    </div>
  );
};

export default React.memo(Classes);
