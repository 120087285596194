import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllNpos } from '../../NpoSetup/redux/actions';
import { fetchCoachTypes } from '../../CoachTypes/redux/actions';
import { fetchCoachStatuses } from '../../CoachStatuses/redux/actions';
import { fetchLearnerTypes } from '../../LearnerTypes/redux/actions';
import { fetchLearnerStatuses } from '../../LearnerStatuses/redux/actions';

const UseUserManager = () => {
  const [userType, setUserType] = useState('');
  const [userTypeName, setUserTypeName] = useState('');
  const { pathname } = useLocation();
  const [canUpdate, setCanUpdate] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    dispatch(fetchAllNpos({ search: {} }));
    dispatch(fetchCoachTypes());
    dispatch(fetchCoachStatuses());
    dispatch(fetchLearnerTypes());
    dispatch(fetchLearnerStatuses());
  }, []);

  useEffect(() => {
    if (pathname.includes('system-users-manager')) {
      setUserType('system_manager');
      setUserTypeName('system');
      if (user?.permissions?.system_users_manager?.add_edit) {
        setCanUpdate(true);
      } else {
        setCanUpdate(false);
      }
    } else if (pathname.includes('coach-users-manager')) {
      setUserType('coach');
      setUserTypeName('coach');
      if (user?.permissions?.coach_users_manager?.add_edit) {
        setCanUpdate(true);
      } else {
        setCanUpdate(false);
      }
    } else if (pathname.includes('learner-users-manager')) {
      setUserType('learner');
      setUserTypeName('learner');
      if (user?.permissions?.learner_users_manager?.add_edit) {
        setCanUpdate(true);
      } else {
        setCanUpdate(false);
      }
    } else {
      setCanUpdate(false);
    }
  }, [pathname]);

  return [userType, userTypeName, canUpdate, dispatch, navigate];
};

export default UseUserManager;
