import React, { useState, useEffect } from 'react';
import {
  Col,
  Input,
  Row,
  Button,
  Form,
  Divider,
  Select,
  message,
  Checkbox,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  createInterventionType,
  updateInterventionTypes,
} from '../redux/actions';
import AddFieldsComponent from './addFieldsComponents';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const TemplateDetail = ({ isTemplateUpdate, canUpdate }) => {
  const [currentTemplate, setCurrentTemplate] = useState({});
  const [inputFields, setInputFields] = useState([]);
  const [deletedIds, setDeletedIds] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { createTemplateLoading, currentTemplateObj, updateLoading } =
    useSelector((state) => state.interventionTypesReducer);

  useEffect(() => {
    setCurrentTemplate({
      ...currentTemplateObj,
      totalMassWeight:
        currentTemplateObj?.modular_weight + currentTemplateObj?.mass_weight,
    });
    form.setFieldsValue({
      ...currentTemplateObj,
    });

    setInputFields(currentTemplateObj.manuals_attributes);
  }, [currentTemplateObj]);

  const deleteField = (id) => {
    setDeletedIds([...deletedIds, id]);
  };

  function absString(n) {
    let numberString = '';
    numberString = n.toString();
    if (numberString[0] === '-') {
      return numberString.substring(1);
    } else {
      return numberString;
    }
  }

  const handleOnchangeInput = (key, value) => {
    let ignoreCal = ['modular_type', 'name'];
    if (!ignoreCal.includes(key)) {
      value = absString(value);
      if (value.length === 0 || !value || value === 'NaN' || isNaN(value)) {
        value = 0;
      }
    }
    let obj = { [key]: value };
    const { modular_weight, mass_weight } = currentTemplate;

    if (['modular_weight', 'mass_weight'].includes(key)) {
      obj = {
        modular_weight,
        mass_weight,
        ...obj,
      };
      let sum = parseInt(obj.modular_weight) + parseInt(obj.mass_weight);
      obj['totalMassWeight'] = sum;
    }

    setCurrentTemplate({
      ...currentTemplate,
      ...obj,
    });
  };

  const updateModularTarget = () => {
    let sum = inputFields.reduce(
      (acc, current) => acc + Number.parseInt(current.target),
      0
    );

    setCurrentTemplate({
      ...currentTemplate,
      modular_target: sum,
    });
  };

  // console.log('currentTemplate', currentTemplate);

  const ManualSelector = () => (
    <div className='choose-select-div'>
      <label>Target Type</label>
      <br />
      <Select
        placeholder='Select Target Type'
        className='target-type-select'
        // style={{ width: 150 }}
        disabled={!canUpdate || isTemplateUpdate}
        onChange={(e) => {
          handleOnchangeInput('modular_type', e);
          if (e) {
            setInputFields([]);
          }
        }}
        value={currentTemplate?.modular_type}
      >
        <Option value='any_manual'>Any Manual</Option>
        <Option value='sum_of_all_manual'>Sum of All Manuals</Option>
      </Select>
    </div>
  );

  const onFinish = () => {
    let attributes = [];

    if (inputFields.length === 0) {
      return message.error('Please add manuals!');
    }

    // console.log('currentTemplate', currentTemplate);
    // console.log('inputFields', inputFields);
    let { modular_weight, mass_weight } = currentTemplate;
    let sum = parseInt(modular_weight) + parseInt(mass_weight);

    let totalWeight = 0;
    if (currentTemplate?.modular_type === 'sum_of_all_manual') {
      inputFields.forEach((el, i) => {
        const {
          name,
          target,
          weight,
          manual_pre,
          manual_post,
          total_weight,
          isLocal,
          id,
        } = el;
        totalWeight += total_weight;
        attributes.push({
          name,
          target,
          weight,
          manual_pre,
          manual_post,
          total_weight,
          position: i,
          ...(!isLocal && { id }),
        });
      });
    } else {
      inputFields.forEach((el, i) => {
        const { name, isLocal, id } = el;
        // totalWeight += total_weight;
        attributes.push({
          name,
          position: i,
          ...(!isLocal && { id }),
        });
      });
    }
    // console.log('modular_weight,totalWeight', modular_weight, totalWeight);
    // console.log('currentTemplate', currentTemplate);
    let condition =
      parseInt(modular_weight) !== totalWeight &&
      currentTemplate?.modular_type === 'sum_of_all_manual';
    // console.log('condition', condition);
    if (condition) {
      return message.error(
        'The total weighting must equal to Modular weighting'
      );
    }
    // console.log('sum', sum)
    // console.log('totalWeight', totalWeight);
    // console.log('attributes', attributes);
    if (sum !== 100) {
      return message.error('The Modular and Mass Weighting must equal 100%');
    }

    if (currentTemplate.modular_type === 'any_manual') {
      let {
        any_manual_weight,
        any_manual_pre,
        any_manual_post,
        modular_weight,
      } = currentTemplate;

      any_manual_weight = parseInt(any_manual_weight);
      any_manual_pre = parseInt(any_manual_pre);
      any_manual_post = parseInt(any_manual_post);
      modular_weight = parseInt(modular_weight);

      let totalWeightTarget =
        any_manual_weight + any_manual_pre + any_manual_post;

      if (totalWeightTarget != modular_weight) {
        return message.error(
          'The Weighting Target per Year must be equal to Modular Weighting!'
        );
      }
    }

    if (isTemplateUpdate) {
      dispatch(
        updateInterventionTypes({
          ...currentTemplate,
          manuals_attributes: attributes,
          id: currentTemplateObj.id,
          navigate,
          manual_ids: deletedIds,
        })
      );
    } else {
      dispatch(
        createInterventionType({
          ...currentTemplate,
          manuals_attributes: attributes,
          navigate,
        })
      );
    }
  };

  const updateCalculation = (inputs) => {
    let updatedModularTarget = 0;
    // let updatedTotalWeight = 0;

    inputs.forEach((el) => {
      const { target, total_weight } = el;
      updatedModularTarget += target;
      // updatedTotalWeight += total_weight;
    });

    // let totalMassWeight = updatedTotalWeight + currentTemplateObj?.mass_weight;

    setCurrentTemplate((prev) => ({
      ...prev,
      modular_target: updatedModularTarget,
      // modular_weight: updatedTotalWeight,
      // totalMassWeight: totalMassWeight,
    }));
  };

  return (
    <>
      <Row>
        <Col span={24} className='heading-col'>
          <Row align='middle' justify='space-between'>
            <Col>
              <div>
                <span className='heading-span'>
                  INTERVENTION TYPE {isTemplateUpdate ? ' UPDATE' : ' CREATION'}
                </span>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Form
            {...formItemLayout}
            form={form}
            name='user-details'
            onFinish={onFinish}
            initialValues={currentTemplate}
            scrollToFirstError
            labelWrap={true}
            layout='horizontal'
            className='user-detail-form'
            requiredMark={false}
            disabled={createTemplateLoading}
          >
            <Col
              span={24}
              className={`user-details-section document-type-section intervention-types`}
            >
              <Row justify='center'>
                <Col md={23} lg={24} xl={23} xxl={23}>
                  <Row gutter={[0, 0]} justify='space-between'>
                    <Col span={10} xxl={10}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'Required!',
                          },
                        ]}
                        name='name'
                        label='INTERVENTION TYPE NAME'
                      >
                        <Input
                          disabled={!canUpdate}
                          readOnly={createTemplateLoading}
                          placeholder='Insert Intervention Type Name'
                          onChange={(e) =>
                            handleOnchangeInput('name', e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    {/* <Col md={8} lg={6} xl={9} xxl={9} /> */}
                    <Col className='total-col'>
                      <div>
                        <div class='ant-form-item-label'>
                          <label class='' title='MODULAR + MASS WEIGHTING'>
                            MODULAR + MASS WEIGHTING
                          </label>
                        </div>
                        <div className='total-per-div'>
                          <div>
                            {currentTemplate?.totalMassWeight > 0
                              ? currentTemplate?.totalMassWeight + ' %'
                              : '0 %'}
                          </div>
                          <span>Must equal 100%</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Col span={24} className='heading-col'></Col>
                  <label>1. MODULAR</label>
                  <Col
                    span={24}
                    style={{ minHeight: '25vh', marginTop: '25px' }}
                  >
                    <Row>
                      <Col lg={0} xxl={1} />
                      <Col md={24} lg={24} xl={24} xxl={23}>
                        <Row>
                          <Col span={24}>
                            <Row justify='start' align='bottom'>
                              <Col md={7} lg={6} xl={7} xxl={7}>
                                <ManualSelector />
                              </Col>
                              {currentTemplate?.modular_type ===
                                'sum_of_all_manual' && (
                                <>
                                  <Col
                                    md={6}
                                    lg={5}
                                    xxl={5}
                                    className='label coach-target-per-year'
                                    style={{
                                      paddingTop: '5px',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    Coach Target / Year
                                    <br />
                                    <div
                                      style={{
                                        fontWeight: 'normal',
                                        margin: '10px 0px 0px 30px',
                                        fontSize: '13px',
                                      }}
                                    >
                                      {currentTemplate?.modular_target > 0
                                        ? currentTemplate?.modular_target
                                        : '-'}
                                    </div>
                                  </Col>
                                  <Col md={6} lg={8} xl={7} xxl={7}></Col>
                                  <Col
                                    md={4}
                                    xxl={4}
                                    className='label'
                                    style={{
                                      textAlign: 'center',
                                      paddingTop: '5px',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    Modular Weighting
                                    <br />
                                    <Input
                                      className='input'
                                      placeholder='Insert %'
                                      style={{
                                        marginTop: '5px',
                                      }}
                                      value={
                                        currentTemplate?.modular_weight > 0
                                          ? currentTemplate?.modular_weight
                                          : null
                                      }
                                      onChange={(e) => {
                                        let { value } = e.target;
                                        if (value.length === 0) {
                                          value = '0';
                                        }
                                        return handleOnchangeInput(
                                          'modular_weight',
                                          parseInt(e.target.value)
                                        );
                                      }}
                                    />{' '}
                                    %
                                  </Col>
                                </>
                              )}
                              {currentTemplate?.modular_type ===
                                'any_manual' && (
                                <>
                                  <Col
                                    span={5}
                                    xl={4}
                                    xxl={5}
                                    className='label'
                                    style={{
                                      paddingTop: '5px',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    Coach Target / Year
                                    <br />
                                    <div>
                                      <Input
                                        style={{
                                          marginTop: '5px',
                                          marginLeft: '20px',
                                        }}
                                        className='input'
                                        placeholder='Target'
                                        value={
                                          currentTemplate?.modular_target > 0
                                            ? currentTemplate?.modular_target
                                            : null
                                        }
                                        onChange={(e) => {
                                          let { value } = e.target;
                                          if (value.length === 0) {
                                            value = '0';
                                          }
                                          return handleOnchangeInput(
                                            'modular_target',
                                            parseInt(e.target.value)
                                          );
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col
                                    span={7}
                                    xl={8}
                                    xxl={7}
                                    className='label weighting-target-year'
                                    style={{
                                      textAlign: 'center',
                                      // bottom: '12px',
                                    }}
                                  >
                                    <div
                                      style={{
                                        // marginBottom: '10px',
                                        bottom: '20px',
                                        position: 'relative',
                                        height: '0px',
                                      }}
                                    >
                                      <span
                                        style={{
                                          textDecoration: 'underline',
                                        }}
                                      >
                                        WEIGHTING TARGET / YEAR
                                      </span>
                                    </div>
                                    <Row
                                      justify='center'
                                      align='bottom'
                                      style={
                                        {
                                          // marginTop: '3px',
                                        }
                                      }
                                    >
                                      <Col
                                        className='label'
                                        style={{
                                          paddingLeft: '0px',
                                        }}
                                        span={6}
                                        md={8}
                                        xl={8}
                                        xxl={7}
                                      >
                                        <span
                                          style={{
                                            paddingRight: '12px',
                                          }}
                                        >
                                          WEIGHTING
                                        </span>
                                        <br />
                                        <div>
                                          <Input
                                            className='input per-input'
                                            style={{
                                              marginTop: '5px',
                                            }}
                                            placeholder='Weighting'
                                            value={
                                              currentTemplate?.any_manual_weight >
                                              0
                                                ? currentTemplate?.any_manual_weight
                                                : null
                                            }
                                            onChange={(e) => {
                                              let { value } = e.target;
                                              if (value.length === 0) {
                                                value = '0';
                                              }
                                              return handleOnchangeInput(
                                                'any_manual_weight',
                                                parseInt(e.target.value)
                                              );
                                            }}
                                          />{' '}
                                          %
                                        </div>
                                      </Col>
                                      <Col
                                        className='label'
                                        style={{
                                          paddingLeft: '0px',
                                        }}
                                        span={6}
                                        md={8}
                                        xl={8}
                                        xxl={7}
                                      >
                                        <span
                                          style={{
                                            paddingRight: '12px',
                                          }}
                                        >
                                          PRE
                                        </span>
                                        <br />
                                        <div>
                                          <Input
                                            style={{
                                              marginTop: '5px',
                                            }}
                                            className='input per-input'
                                            placeholder='Pre'
                                            value={
                                              currentTemplate?.any_manual_pre >
                                              0
                                                ? currentTemplate?.any_manual_pre
                                                : null
                                            }
                                            onChange={(e) => {
                                              let { value } = e.target;
                                              if (value.length === 0) {
                                                value = '0';
                                              }
                                              return handleOnchangeInput(
                                                'any_manual_pre',
                                                parseInt(e.target.value)
                                              );
                                            }}
                                          />{' '}
                                          %
                                        </div>
                                      </Col>
                                      <Col
                                        className='label'
                                        style={{
                                          paddingLeft: '0px',
                                        }}
                                        span={6}
                                        md={8}
                                        xl={8}
                                        xxl={7}
                                      >
                                        <span
                                          style={{
                                            paddingRight: '12px',
                                          }}
                                        >
                                          POST
                                        </span>
                                        <br />
                                        <div>
                                          <Input
                                            className='input per-input'
                                            placeholder='Post'
                                            style={{
                                              marginTop: '5px',
                                            }}
                                            value={
                                              currentTemplate?.any_manual_post >
                                              0
                                                ? currentTemplate?.any_manual_post
                                                : null
                                            }
                                            onChange={(e) => {
                                              let { value } = e.target;
                                              if (value.length === 0) {
                                                value = '0';
                                              }
                                              return handleOnchangeInput(
                                                'any_manual_post',
                                                parseInt(e.target.value)
                                              );
                                            }}
                                          />{' '}
                                          %
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col
                                    span={5}
                                    lg={5}
                                    xxl={5}
                                    className='label'
                                    style={{
                                      textAlign: 'center',
                                      paddingTop: '5px',
                                    }}
                                  >
                                    Modular Weighting
                                    <br />
                                    <Input
                                      style={{
                                        marginTop: '5px',
                                      }}
                                      className='input'
                                      placeholder='Insert %'
                                      value={
                                        currentTemplate?.modular_weight > 0
                                          ? currentTemplate?.modular_weight
                                          : null
                                      }
                                      onChange={(e) => {
                                        let { value } = e.target;
                                        if (value.length === 0) {
                                          value = '0';
                                        }
                                        return handleOnchangeInput(
                                          'modular_weight',
                                          parseInt(e.target.value)
                                        );
                                      }}
                                    />{' '}
                                    %
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Divider />
                          </Col>
                          {currentTemplate.modular_type && (
                            <>
                              <Col span={24}>
                                <Row
                                  justify='start'
                                  className={`label ${
                                    currentTemplate?.modular_type ===
                                    'sum_of_all_manual'
                                      ? 'sum-of-all-labels'
                                      : ''
                                  }`}
                                >
                                  <Col
                                    md={6}
                                    lg={5}
                                    xl={6}
                                    xxl={7}
                                    className='label'
                                  >
                                    MANUAL NAME
                                  </Col>
                                  {currentTemplate?.modular_type ===
                                    'sum_of_all_manual' && (
                                    <>
                                      <Col
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        xxl={3}
                                        className='label target-year'
                                      >
                                        TARGET / YEAR
                                      </Col>
                                      <Col span={0} xl={0} xxl={2} />
                                      <Col
                                        md={3}
                                        lg={4}
                                        xl={3}
                                        xxl={3}
                                        className='label weighting'
                                      >
                                        WEIGHTING
                                      </Col>
                                      <Col
                                        md={3}
                                        lg={3}
                                        xl={3}
                                        xxl={3}
                                        className='label pre'
                                      >
                                        PRE
                                      </Col>
                                      <Col
                                        md={3}
                                        lg={3}
                                        xl={4}
                                        xxl={2}
                                        className='label post'
                                      >
                                        POST
                                      </Col>
                                      <Col
                                        md={3}
                                        lg={3}
                                        xl={2}
                                        xxl={3}
                                        className='label total-weighting'
                                        style={{
                                          textAlign: 'center',
                                        }}
                                      >
                                        TOTAL <br /> WEIGHTING
                                      </Col>
                                    </>
                                  )}
                                </Row>
                              </Col>
                              <Divider />
                              <Col span={24}>
                                <AddFieldsComponent
                                  inputFields={inputFields}
                                  setInputFields={setInputFields}
                                  canUpdate={canUpdate}
                                  updateModularTarget={updateModularTarget}
                                  currentTemplate={currentTemplate}
                                  deleteField={deleteField}
                                  updateCalculation={updateCalculation}
                                />
                                <Col className='campus-checkbox-col'>
                                  <Checkbox
                                    checked={currentTemplate?.include_campus}
                                    onChange={(e) =>
                                      setCurrentTemplate({
                                        ...currentTemplate,
                                        include_campus: e.target.checked,
                                      })
                                    }
                                  >
                                    Include Campus and Term 1, 2, 3 & 4
                                  </Checkbox>
                                </Col>
                              </Col>
                              {/* {canUpdate && <hr />} */}
                            </>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24} className='heading-col'></Col>
                  <label>2. MASS</label>

                  <Col span={24} style={{ marginTop: '25px' }}>
                    <Row>
                      <Col lg={0} span={0} xl={1} xxl={1} />
                      <Col md={24} lg={24} xl={23} xxl={23}>
                        <Row>
                          <Col span={24}>
                            <Row justify='start' align='center'>
                              <Col md={6} xl={6} xxl={7}>
                                <div className='mass-name'>
                                  {currentTemplate?.mass_name}
                                </div>
                              </Col>
                              <Col
                                md={6}
                                lg={6}
                                xl={5}
                                xxl={5}
                                className='label label-input-col'
                              >
                                Coach Target / Year
                                <br />
                                <Input
                                  style={{
                                    marginTop: '5px',
                                    marginLeft: '20px',
                                  }}
                                  placeholder='Target'
                                  className='input'
                                  value={
                                    currentTemplate?.mass_target > 0
                                      ? currentTemplate?.mass_target
                                      : null
                                  }
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    return handleOnchangeInput(
                                      'mass_target',
                                      parseInt(value)
                                    );
                                  }}
                                />
                              </Col>
                              <Col md={6} lg={6} xl={8} xxl={7} />
                              <Col
                                md={6}
                                lg={6}
                                xl={5}
                                xxl={3}
                                className='label'
                                style={{
                                  textAlign: 'center',
                                  marginBottom: '5px',
                                }}
                              >
                                Mass Weighting
                                <br />
                                <Input
                                  style={{
                                    marginTop: '5px',
                                  }}
                                  className='input'
                                  value={
                                    currentTemplate?.mass_weight > 0
                                      ? currentTemplate?.mass_weight
                                      : null
                                  }
                                  placeholder='Insert %'
                                  onChange={(e) =>
                                    handleOnchangeInput(
                                      'mass_weight',
                                      parseInt(e.target.value)
                                    )
                                  }
                                />{' '}
                                %
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Divider /> */}
                </Col>
                {canUpdate && <Col span={23} className='heading-col'></Col>}
                <Col md={24} lg={23} xl={20} xxl={16}>
                  {canUpdate && (
                    <Row gutter={[20, 10]} justify='center'>
                      <Row align='middle' justify='center'>
                        <Col>
                          <Button
                            type='primary'
                            className='save-btn'
                            size='middle'
                            onClick={onFinish}
                            // htmlType='submit'
                            loading={updateLoading || createTemplateLoading}
                          >
                            {isTemplateUpdate ? 'Update' : 'Save'} INTERVENTION
                            TYPE
                          </Button>
                        </Col>
                      </Row>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          </Form>
        </Col>
      </Row>
      <div className='empty-div'></div>
    </>
  );
};
export default TemplateDetail;
