import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import FullWidthGraph from '../../../components/graphs/fullWidthGraph';

const ByDemographics = ({ screenWidth }) => {
  const { attendence_by_race, attendance_by_gender, attendance_by_age_group } =
    useSelector((state) => state.learnersReducer);

  return (
    <Row gutter={[20, 40]}>
      <Col span={24}>
        <FullWidthGraph
          data={attendence_by_race}
          label='Modular Manuals Attendance By Race'
          LeftLegend='Attendance'
          tickRotation={0}
          type='attByRace'
          keys={['african', 'coloured', 'asian', 'white']}
          colors={['#FFC732', '#E56C37', '#E31C21', '#D74550']}
          screenWidth={screenWidth}
        />
      </Col>
      <Col span={24}>
        <FullWidthGraph
          data={attendance_by_gender}
          label='Modular Manuals Attendance By Gender'
          LeftLegend='Attendance'
          tickRotation={0}
          type='attByGender'
          keys={['male', 'female']}
          colors={['#FFC732', '#E31C21']}
          screenWidth={screenWidth}
        />
      </Col>

      <Col span={24}>
        <FullWidthGraph
          data={attendance_by_age_group}
          label='Modular Manuals Attendance by Age Group'
          LeftLegend='Attendance'
          tickRotation={0}
          type='attByAgeGroup'
          keys={['child', 'youth', 'adult']}
          colors={['#FFC732', '#E31C21', '#E56C37']}
          screenWidth={screenWidth}
        />
      </Col>
    </Row>
  );
};

export default React.memo(ByDemographics);
